

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";

import Checkbox from '@mui/material/Checkbox';


import Line1 from '../../compontents/Line1.js'
import Line2 from '../../compontents/Line2.js'
import Swiper from '../../compontents/Swiper.js'
import TitleName from '../../compontents/TitleName.js'

import closewtcircleImg from '../../assets/closewtcircle.png'
import menuDownArrowImg from '../../assets/arrow.png';


import circle1Img from '../../assets/home/circle1.png'
import circle2Img from '../../assets/home/circle2.png'
import circle3Img from '../../assets/home/circle3.png'
import crossImg from '../../assets/home/cross.png'

import pic1Img from '../../assets/1.png'
import pic2Img from '../../assets/2.png'
import pic3Img from '../../assets/3.png'

import hallbgImg from '../../assets/hallbg.png'

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';



import linesImg from '../../assets/lines.png'
import lines2Img from '../../assets/home/lines2.png'

import galleryBgImg from '../../assets/galleryBg.png'

import swipe1Img from '../../assets/swipe1.png'
import swipe2Img from '../../assets/swipe2.png'
import swipe3Img from '../../assets/swipe3.jpg'

import seeMoreImg from '../../assets/seeMore.png'
import { separatorNumber, useGlobalFunc } from "../../global/constants.js";
import UserApi from "../../services/UserApi.jsx";
import ArtGrid from "../../compontents/ArtBox.js";
import PathLocation from "../../compontents/PathLocation.js";
import ArtistBox from "../../compontents/ArtistBox.js";
import { useSelector } from "react-redux";

// import homeshadowImg from '../../assets/homeshadow.png'
// import homescrollImg from '../../assets/homescroll.png'

// import homepic1Img from '../../assets/homepic1.png'
// import homepic2Img from '../../assets/homepic2.png'


const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);


    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };




    const handleMenulist = (selIndex) => {
        const temp = menulist.map((item, index) => {
            if (index === selIndex) {
                return { ...item, open: !item.open };
            }
            return item;
        });
        setMenulist(temp);
    };

    function removeSelTagListItem(mainIndex, subIndex) {
        const newItems = selTagList;
        for (let i = 0; i < newItems.length; i = i + 1) {
            if (newItems[i].mainIndex == mainIndex && newItems[i].subIndex == subIndex) {
                console.log()
                newItems.splice(i, 1); // 從複製的陣列中刪除指定元素
            }
        }
        console.log(newItems)
        setSelTagList(newItems); // 更新狀態以反映更改
    }

    const handleMenulistCheckbox = (mainIndex, subIndex) => {
        console.log(mainIndex, subIndex)
        const temp = menulist.map((item, index) => {
            if (index === mainIndex) {
                let tempSub = item.sub
                console.log(tempSub)
                tempSub[subIndex].sel = !tempSub[subIndex].sel
                if (tempSub[subIndex].sel) {
                    setSelTagList([...selTagList,
                    {
                        mainIndex: mainIndex, subIndex: subIndex, title: tempSub[subIndex].title, value: tempSub[subIndex].value
                    }
                    ])
                }
                else {
                    removeSelTagListItem(mainIndex, subIndex)
                }

                return { ...item, sub: tempSub };
            }
            return item;
        });
        setMenulist(temp);
    };

    const [selTagList, setSelTagList] = useState([])

    const [menulist, setMenulist] = useState([
        {
            title: "媒材",
            open: false,
            sub: [
                {
                    title: "1",
                    value: "2",
                    sel: false
                },
            ]
        },
        {
            title: "風格",
            open: false,
            sub: [
                {
                    title: "1",
                    value: "2",
                    sel: false
                },
            ]
        },
        {
            title: "尺寸",
            open: false,
            sub: [
                {
                    title: "1",
                    value: "2",
                    sel: false
                },
            ]
        },
        {
            title: "價格區間",
            open: false,
            sub: [
                {
                    title: "1",
                    value: "2",
                    sel: false
                },
            ]
        },
        {
            title: "顏色",
            open: false,
            sub: [
                {
                    title: "1",
                    value: "2",
                    sel: false
                },
            ]
        },
    ])


 


    const loading = useSelector(state => state.loading);

    const { setLoading, successToast, errorToast } = useGlobalFunc()
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
        getDataList(value)
    };

    const [dataList, setDataList] = useState(null)


    async function getDataList(page) {
        setLoading(true)
        try {
            let res = await UserApi.artistSearch({ page: page })
            console.log(res.data)
            setDataList(res.data.data)
            setTotalPage(res.data.total == 0 ? 1 : Math.ceil(res.data.total / 10))

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)

    }

    useEffect(() => {
        getDataList(page)
    }, []);


    return <div className="w-full" >

        <div className=" relative pt-[40px] pb-20 bg-main9" >
            <div className="session1">
                <PathLocation color={"text-white"}/>

                <div className="text-5xl md:text-7xl text-sec4 titleFont1" >
                    Artist
                </div>
                <div className="grid grid-cols-3 gap-3">
                    <div className=" col-span-2 ">
                        <div className=" grid grid-cols-2 gap-3 mb-8">
                            <button className=" font-bold  text-sm w-full h-full py-3 text-main9 rounded-full" style={{ "background": "linear-gradient(90deg, #CA9849 -0.08%, #FFF4AF 85.1%)" }}>藝術家</button>
                            <Link to="/artlist"><button className="font-bold w-full text-sm h-full py-3  text-sec2 rounded-full bg-main8 border border-solid border-sec4">藝術品</button></Link>

                        </div>
                        <Line2 />
                        <div className="flex flex-wrap gap-2 mb-8">
                            {
                                selTagList.map(i => <div key={i.title} onClick={() => handleMenulistCheckbox(i.mainIndex, i.subIndex)} className=" border-solid border-white border cursor-pointer flex pl-4 pr-3 py-2 items-center bg-main95 text-white rounded-full">
                                    <p>{i.title}</p>
                                    <img className="ml-3 w-6 p-1  object-contain" src={closewtcircleImg} />
                                </div>)
                            }
                        </div>
                    </div>
                    <div className=" col-span-1 hidden">
                        <div className="bg-main9 rounded-[10px] p-2">
                            {
                                menulist.map((i, index) => <div key={i.title}>
                                    <ListItemButton onClick={() => handleMenulist(index)} sx={{ px: 0 }}>
                                        <ListItemText primary={menulist[index].title} primaryTypographyProps={
                                            {
                                                color: menulist[index].open ? '#FFFFFF' : '#FFFFFF',
                                                fontWeight: 600,
                                            }} />
                                        <img className={`w-[19px] h-[19px] object-contain ${menulist[index].open ? " rotate-180" : ""}`} src={menuDownArrowImg} />
                                    </ListItemButton>
                                    <Collapse in={menulist[index].open} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {
                                                menulist[index].sub.map((i, subIndex) =>
                                                    <ListItemButton onClick={() => handleMenulistCheckbox(index, subIndex)} key={i.path} sx={{ pl: 0 }} >
                                                        <div className="pointer-events-none">
                                                            <Checkbox
                                                                checked={i.sel}
                                                                {...label}
                                                                sx={{
                                                                    '& .MuiSvgIcon-root': { fontSize: 24 }, '&.Mui-checked': {
                                                                        color: '#FFFFFF',
                                                                    },
                                                                    color: '#FFFFFF',

                                                                }}
                                                            />
                                                        </div>
                                                        <ListItemText primary={i.title} primaryTypographyProps={
                                                            {
                                                                color: '#FFFFFF',
                                                                fontWeight: 400,
                                                            }} />
                                                    </ListItemButton>
                                                )
                                            }
                                        </List>
                                    </Collapse>
                                </div>)
                            }

                        </div>


                    </div>

                </div>

            </div>
        </div>

        <div className="session1 mx-auto py-14">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-[50px]">
                {
                    dataList && dataList.map(i => <ArtistBox key={i.id} data={i} />)
                }

            </div>
        </div>


    </div >
}

export default Home