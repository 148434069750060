
import { useTranslation } from "react-i18next";


import arrowRightWLImg from '../assets/arrowRightWL.png'

const Tag = ({ bgColor = "bg-main4", title }) => {
  const { t } = useTranslation();

  return (
    <div className={`${bgColor} px-[14px] py-[6px] rounded-lg text-xs font-semibold text-white`}>{title}</div>
  )
}
export default Tag