

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";

import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useSelector } from "react-redux";
import UserApi from "../../../../services/UserApi.jsx";
import { useGlobalFunc } from "../../../../global/constants.js";




const Setting = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const userInfo = useSelector(state => state.userInfo);


    const { setLoading, successToast, errorToast, updateMe } = useGlobalFunc()



    const [isShowUpdateMeDialog, setShowUpdateMeDialog] = useState(false);

    const openUpdateMeDialog = () => {
        setEditData(userInfo)
        setShowUpdateMeDialog(true);
    }

    const closeUpdateMeDialog = () => {
        setShowUpdateMeDialog(false);
    }

    const [editData, setEditData] = useState(null);

    const updateData = (i, value) => {
        setEditData(prevData => ({ ...prevData, [i]: value }));
    };



    async function updateMeAction() {
        setLoading(true)
        try {
            let res = await UserApi.updateMe({
                //phone: editData.phone,
                city: editData.city,
                address: editData.address,
            })
            console.log(res.data.data)
            updateMe()
            closeUpdateMeDialog()

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)

    }


    useEffect(() => {


    }, []);


    return (userInfo && <div>


        <div className="mt-8 px-6 py-8 bg-white border-solid border-4 border-main3 rounded-[20px] relative  w-full ">

            <div className="text-black9 text-center text-xl font-bold mb-12">會員憑證</div>

            <div className="flex text-black9 break-all w-full sm:max-w-[500px] mx-auto">
                <div>
                    <div className="mb-6">
                        加入時間：{userInfo.createdAt}
                    </div>
                    <div className="mb-6 hidden">
                        會員階級：鑽石會員
                    </div>
                    <div className="mb-6 hidden">
                        會員憑證：0xd7DEC3F3EE36B70A8473fac5d2470C746E97D215
                    </div>

                    <div className="mt-8 flex justify-center hidden">
                        <button className="bg-main6 px-6 py-3 rounded-full font-semibold text-sm text-white">前往 Etherscan</button>
                    </div>

                </div>

            </div>

        </div>

        <div className="mt-8 px-6 py-8 bg-white border-solid border-4 border-main3 rounded-[20px] relative  w-full ">


            <div className="w-full sm:max-w-[500px] mx-auto">
                <div className="text-black9 text-center text-xl font-bold mb-12">會員資料</div>



                <div className="flex justify-between  text-black9 font-bold">
                    <div>登入帳號</div>
                    <div className=" col-span-2 mb-6">{userInfo.email}</div>
                </div>
                <div className="flex justify-between  text-black9 font-bold">

                    <div>真實姓名</div>
                    <div className=" col-span-2 mb-6">{userInfo.actualName}</div>
                </div>
                <div className="flex justify-between  text-black9 font-bold">
                    <div>出生年月日</div>
                    <div className=" col-span-2 mb-6">{userInfo.birthDate}</div>
                </div>
                <div className="flex justify-between  text-black9 font-bold">
                    <div>身分證字號</div>
                    <div className=" col-span-2 mb-6">{userInfo.kyc.idNumber}</div>
                </div>
                <div className="flex justify-between  text-black9 font-bold">
                    <div>性別</div>
                    <div className=" col-span-2 mb-6">{userInfo.gender}</div>
                </div>
                <div className="flex justify-between  text-black9 font-bold">
                    <div>聯絡電話</div>
                    <div className=" col-span-2 mb-6">{userInfo.phone}</div>
                </div>
                <div className="flex justify-between  text-black9 font-bold">
                    <div>所在地區</div>
                    <div className=" col-span-2 mb-6">{userInfo.city}</div>
                </div>
                <div className="flex justify-between  text-black9 font-bold">
                    <div>地址</div>
                    <div className=" col-span-2 mb-6">{userInfo.address}</div>
                </div>
                <div className="mt-8 flex justify-center">
                    <button onClick={() => openUpdateMeDialog()} className="bg-main6 px-6 py-3 rounded-full font-semibold text-sm text-white">修改個人資料</button>
                </div>

            </div>


        </div>



        <Dialog
            PaperProps={{
                style: {
                    width: "400px",
                    backgroundColor: 'transparent'
                },
            }}
            open={isShowUpdateMeDialog}
            onClose={closeUpdateMeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {
                editData ?
                    <div className="bg-white rounded-xl  border border-main border-solid">
                        <DialogTitle className="flex justify-between items-center gap-2 border-b border-main border-solid">
                            <p className='text-base font-bold'>編輯個人資料</p>

                            <div className=' flex-none w-8 cursor-pointer p-1' onClick={closeUpdateMeDialog}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" fill="none">
                                    <path d="M7.28033 4.54L15.0003 12.26L22.6803 4.58C22.85 4.39943 23.0543 4.25499 23.2812 4.15532C23.508 4.05566 23.7526 4.00283 24.0003 4C24.5308 4 25.0395 4.21071 25.4145 4.58579C25.7896 4.96086 26.0003 5.46957 26.0003 6C26.005 6.2452 25.9595 6.48877 25.8666 6.71576C25.7738 6.94275 25.6355 7.14837 25.4603 7.32L17.6803 15L25.4603 22.78C25.79 23.1025 25.9832 23.5392 26.0003 24C26.0003 24.5304 25.7896 25.0391 25.4145 25.4142C25.0395 25.7893 24.5308 26 24.0003 26C23.7454 26.0106 23.4911 25.968 23.2536 25.8751C23.016 25.7821 22.8003 25.6408 22.6203 25.46L15.0003 17.74L7.30033 25.44C7.13134 25.6145 6.92945 25.7539 6.70633 25.85C6.4832 25.9461 6.24325 25.9971 6.00033 26C5.46989 26 4.96118 25.7893 4.58611 25.4142C4.21104 25.0391 4.00033 24.5304 4.00033 24C3.99566 23.7548 4.04116 23.5112 4.13402 23.2842C4.22687 23.0572 4.36514 22.8516 4.54033 22.68L12.3203 15L4.54033 7.22C4.2107 6.89752 4.0174 6.46082 4.00033 6C4.00033 5.46957 4.21104 4.96086 4.58611 4.58579C4.96118 4.21071 5.46989 4 6.00033 4C6.48033 4.006 6.94033 4.2 7.28033 4.54Z" fill="#666666" />
                                </svg>
                            </div>
                        </DialogTitle>
                        <DialogContent>
                            <div className=" pt-6 pb-4">

                                <div className="hidden flex justify-between items-center mb-6  text-black9 font-bold">
                                    <div>聯絡電話</div>
                                    <input className="col-span-2" onChange={(e) => updateData("phone", e.target.value)} />
                                </div>
                                <div className="flex justify-between items-center mb-6 text-black9 font-bold">
                                    <div>所在地區</div>
                                    <input className="col-span-2" onChange={(e) => updateData("city", e.target.value)} />
                                </div>
                                <div className="flex justify-between items-center mb-6 text-black9 font-bold">
                                    <div>地址</div>
                                    <input className="col-span-2" onChange={(e) => updateData("address", e.target.value)} />
                                </div>

                            </div>

                            <div className='flex  justify-center gap-2'>

                                <button onClick={closeUpdateMeDialog} className="btn2 rounded-full w-full">取消</button>


                                <button onClick={() => updateMeAction()} className="btn1 rounded-full w-full">確定</button>


                            </div>
                        </DialogContent>

                    </div>
                    : ""
            }
        </Dialog>

    </div>)

}


export default Setting