

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";
import UserApi from "../../services/UserApi";
//import { auth, signInWithEmailAndPassword, signInWithGoogle } from "../../firebase";

import { useDispatch } from "react-redux";

import loginlogoImg from '../../assets/google.png'
import logintitleImg from '../../assets/google.png'



import googleloginImg from '../../assets/google.png'
import fbloginImg from '../../assets/fb.png'



import axios from 'axios'
import { useGlobalFunc } from "../../global/constants";

const Home = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const { setLoading, successToast, errorToast, updateMe } = useGlobalFunc()

    const [step, setStep] = useState(0)

    const banner = []
    const [currBanner, setCurrBanner] = useState(0)
    const countRef = useRef()



    const [faqs, setFAQ] = useState([


    ]);

    const [account, setAccount] = useState("")
    const [pw, setPw] = useState("")

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [forgetInput, setForgetInput] = useState("")
    const [otp, setOTP] = useState("")
    const [infoName, setInfoname] = useState("")
    const [infoPassword, setInfoPassword] = useState("")
    const [infobirth, setInfobirth] = useState("")

    const [isLoginError, setIsLoginError] = useState(false)
    const [isOTPError, setIsOTPError] = useState(false)



    const [FAQType, setFAQType] = useState(0);
    const [isFisrt, doneFisrt] = useState(false)

    function changeFAQType(type) {
        setFAQType(type)
    }

    function changeFAQItem(index) {
        console.log(faqs[FAQType][index])
        faqs[FAQType][index].open = !faqs[FAQType][index].open
        console.log(faqs[FAQType][index])
        setFAQ([...faqs])
    }



    async function loginAction() {
        if (account == "" || pw == "") {
            successToast("請輸入帳號和密碼")
            return
        }

        setLoading(true)

        try {
            let res = await UserApi.signIn({ phone: "+886" + account, password: pw })
            console.log(res)

            localStorage.setItem("userToken", res.data.data.token)
            updateMe()
            navigate('/membercenter')
            //window.location.reload()
        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }

        setLoading(false)

    }





    useEffect(() => {

        const token = query.get('token')
        if (token != null) {
            //localStorage.setItem("userAuth",token)
            setStep(100)


        }


    }, []);


    return <div className="" >

        <div className="flex w-full h-full bg-main3 md:py-10">
            <div className="m-auto bg-white  w-full max-w-[470px] p-7 md:rounded-[20px]">
                {
                    step == 0 ?
                        <div className="relative py-7">
                            <img className="w-[62px] mx-auto  object-contain mb-6" src={loginlogoImg} />

                            <p className="text-[#9FA2B4] text-xl text-center  titleFont1">Tian Yao</p>


                            <p className="text-lg md:text-xl text-[#111111] font-bold text-center mb-4">註冊/登入</p>

                            <p className="text-black9 font-bold">電話號碼</p>
                            <div className="flex gap-2 items-center text-black9 mb-6">
                                +886 <input value={account} onChange={e => setAccount(e.target.value)} type="text" className=" text-lg  w-full" />
                            </div>

                            <p className="text-black9 font-bold">密碼</p>
                            <input placeholder="" value={pw} onChange={e => setPw(e.target.value)} type="password" className="mb-6  relative    w-full  text-lg" />


                            <button onClick={() => loginAction()} className=" font-bold bg-main8  text-white w-full rounded-full py-4 mb-7">登入</button>

                            <div className=" grid grid-cols-2 justify-center gap-4 mb-7 hidden">
                                <div className="cursor-pointer py-3 flex justify-center items-center border-solid border-black4 text-[#9FA2B4] border rounded-[10px]">
                                    <img className="w-8 h-8 mr-3  object-contain  rounded-full " src={googleloginImg} />
                                    <div>
                                        Google<br />帳號登入
                                    </div>
                                </div>
                                <div className="cursor-pointer py-3 flex justify-center items-center border-solid border-black4 text-[#9FA2B4] border rounded-[10px]">
                                    <img className="w-8 h-8 mr-3  object-contain  rounded-full  " src={fbloginImg} />
                                    <div>
                                        Google<br />帳號登入
                                    </div>
                                </div>


                            </div>

                            <p className="text-base text-[#9FA2B4] font-bold text-center mt-7 ">還沒註冊天堯會員? <span onClick={() => navigate("/register")} className="text-[#F2994A] underline cursor-pointer">點此註冊</span></p>




                        </div>
                        : ""
                }

                {
                    step == 1 ?
                        <div className="relative ">
                            <div className="flex justify-between mb-[100px]">
                                <button className="w-7 h-7"><img className="w-full h-full object-contain" onClick={() => setStep(step - 1)} /></button>
                                <p className="text-lg md:text-xl text-[#111111] font-bold text-center">註冊/登入</p>
                                <div className="w-7 h-7"></div>
                            </div>


                            <div className="px-3 ">
                                <div className="flex items-center  mb-2">
                                    <img className="w-7 h-7 mr-[10px] object-contain " />
                                    <input className="text-lg text-sec4 placeholder-[#B7B7B7]" onChange={(e) => setEmail(e.target.value)} placeholder="Email" type="text" />

                                </div>
                                <div className="w-full h-[2px] bg-[#D9D9D9] mb-[128px]"> </div>


                                <div className="w-[220px] rounded-full bg-black cursor-pointer text-white text-center mx-auto py-[14px] font-medium mb-10">
                                    註冊/登入
                                </div>
                            </div>

                        </div>
                        : ""
                }

                {
                    step == 2 ?
                        <div className="relative ">
                            <div className="flex justify-between mb-[40px]">
                                <button className="w-7 h-7"><img className="w-full h-full object-contain" onClick={() => setStep(step - 1)} /></button>
                                <p className="text-lg md:text-xl text-[#111111] font-bold text-center">登入</p>
                                <div className="w-7 h-7"></div>
                            </div>

                            <p className="text-xl font-medium text-[#111111]">歡迎回來！</p>
                            <p className="text-base text-[#111111] mb-[40px]">{email}</p>


                            <div className="px-3 ">
                                <div className="flex items-center  mb-2">
                                    <img className="w-7 h-7 mr-[10px] object-contain " />
                                    <input className="text-lg text-sec4 placeholder-[#B7B7B7]" onChange={(e) => setPassword(e.target.value)} placeholder="" type="password" />

                                </div>
                                <div className={`w-full h-[2px]  ${isLoginError == true ? "bg-[#EB480D]" : "bg-[#D9D9D9]"} `}> </div>

                                <div className="mt-2 flex justify-between">
                                    {
                                        isLoginError == true ?
                                            <p className="text-[#EB480D] text-sm">您輸入的密碼錯誤</p> :
                                            <p></p>
                                    }
                                    <button className='underline text-sm text-[#111111] font-medium'>忘記密碼？</button>
                                </div>

                                <div className="mt-[68px] w-[220px] rounded-full bg-black cursor-pointer text-white text-center mx-auto py-[14px] font-medium mb-10">
                                    登入
                                </div>
                            </div>

                        </div>
                        : ""
                }

                {
                    step == 30 ?
                        <div className="relative ">
                            <div className="flex justify-between mb-[40px]">
                                <button className="w-7 h-7"><img className="w-full h-full object-contain" onClick={() => setStep(step - 1)} /></button>
                                <p className="text-lg md:text-xl text-[#111111] font-bold text-center">忘記密碼</p>
                                <div className="w-7 h-7"></div>
                            </div>

                            <p className="text-xl font-medium text-[#111111]">輸入您的 Email</p>
                            <p className="text-base text-[#888888] mb-[40px]">會將新密碼寄至您的信箱</p>



                            <div className="px-3 ">
                                <div className="flex items-center  mb-2">
                                    <img className="w-7 h-7 mr-[10px] object-contain " />
                                    <input className="text-lg text-sec4 placeholder-[#B7B7B7]" onChange={(e) => setPassword(e.target.value)} placeholder="" type="text" />

                                </div>
                                <div className={`w-full h-[2px] bg-[#D9D9D9] `}> </div>



                                <div className="mt-[98px] w-[220px] rounded-full bg-black cursor-pointer text-white text-center mx-auto py-[14px] font-medium mb-10">
                                    寄送新密碼
                                </div>
                            </div>

                        </div>
                        : ""
                }
                {
                    step == 3 ?
                        <div className="relative ">
                            <div className="flex justify-between mb-[40px]">
                                <button className="w-7 h-7"><img className="w-full h-full object-contain" onClick={() => setStep(step - 1)} /></button>
                                <p className="text-lg md:text-xl text-[#111111] font-bold text-center">輸入驗證碼</p>
                                <div className="w-7 h-7"></div>
                            </div>

                            <p className="text-xl font-medium text-[#111111]">已將驗證碼傳送到您的信箱</p>
                            <p className="text-base text-[#888888] mb-[40px]">{email}</p>



                            <div className="px-3 ">
                                <div className="flex items-center  mb-2">

                                    <input className="text-lg text-sec4 placeholder-[#B7B7B7]" onChange={(e) => setOTP(e.target.value)} placeholder="驗證碼" type="text" />

                                </div>
                                <div className={`w-full h-[2px]  ${isOTPError == true ? "bg-[#EB480D]" : "bg-[#D9D9D9]"} `}> </div>
                                <p className="mt-2 text-[#EB480D] text-sm">{isOTPError == true ? "驗證碼已過期" : ""}</p>




                                <div className="mt-[98px] w-[220px] rounded-full bg-black cursor-pointer text-white text-center mx-auto py-[14px] font-medium mb-6">
                                    下一步
                                </div>

                                <div className="mt-2 flex justify-center">
                                    <p className="text-[#B7B7B7] text-sm mr-[10px]">重新發送驗證碼</p>
                                    <button className=' text-sm text-[#B7B7B7] font-medium'>1m 40s</button>
                                </div>
                            </div>

                        </div>
                        : ""
                }

                {
                    step == 100 ?
                        <div className="relative py-7">
                            <Link to="/home">
                                <div className="text-black absolute -top-2 right-0 text-base font-medium ">略過
                                </div>
                            </Link>


                            <p className="text-2xl font-bold text-[#111111] text-center mb-[20px]">歡迎加入 天堯！</p>
                            <p className="text-lg text-[#888888] mb-[37px] text-center ">您已經成功註冊 天堯<br />
                                接下來，透過 天堯 APP 來設置您的錢包</p>



                            <div className="px-3 bg-[#F5F5F5] rounded-[10px] pt-[64px] pb-[45px]">
                                <img className="bg-white mx-auto w-[210px]  object-contain mb-[45px] p-2" />

                                <div className="w-full flex justify-center items-center gap-[17px]  mb-2">
                                    <a href="https://play.google.com/store/apps/details?id=com.sourcekode.天堯" target="_blank">
                                        <img className="  w-[125px]  object-contain " />
                                    </a>
                                    <a href="https://apps.apple.com/tw/app/天堯/id1623769299" target="_blank">
                                        <img className="  w-[125px]  object-contain p-[6px]" />
                                    </a>

                                </div>


                            </div>

                        </div>
                        : ""
                }



            </div>





        </div>



    </div>
}

export default Home