

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";


import TitleName from '../../../compontents/TitleName.js'
import TitleName2 from '../../../compontents/TitleName2.js'

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import userIconImg from '../../../assets/userIcon.png'


import { useSelector } from "react-redux";




const ArtistCenterNavBar = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search);
    const userInfo = useSelector(state => state.userInfo);

    const banner = []
    const [functionIndex, setFunctionIndex] = useState(0)
    const countRef = useRef()

    const functionList = ["artManage", "commissionedapply", "intro", "exhibitionExperience", "artistInfo"]




    const [currentPathName, setCurrentPathName] = useState('');

    const handleChange = (event) => {
        navigate(`/artistcenter/${functionList[event.target.value]}`)
        setFunctionIndex(event.target.value);

    };

    // orderRecord, registerPlace, successOrder, artCert, applyFunction, monthlySystem, myFavor, setting


    // applyFunction 申請功能
    // 鑑價申請 appraisalApply
    // 委託申請 commissionedApply
    // 藝品NFT憑證轉移 transferNFT
    // 回購申請 buyBackApply
    // 藝術家申請 artistRegister





    const [FAQType, setFAQType] = useState(0);



    useEffect(() => {
        let pathname = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)

        let typeIndex = functionList.indexOf(pathname)
        if (typeIndex != -1) {
            setFunctionIndex(typeIndex)
            console.log(typeIndex)
        }



    }, []);


    return <div className="" >




        {
            userInfo && userInfo.artistProfile ?
                <div className="bg-black2 pt-[40px] relative">
                    <div className=" absolute top-0 left-0 w-full h-full brightness-[200%] blur-[0px] opacity-25"><img className="w-full h-full object-cover" src={userInfo.artistProfile.banner} /></div>
                    <div className="session1 relative ">

                        <TitleName title1="Artist Center" title2="藝術家中心" />


                        <div className="mt-4">
                            <div className="flex items-center relative">


                                <div className="w-60 aspect-[1/1] gradiBorder p-[4px] rounded-full " >
                                    <img className=" bg-white w-full h-full object-cover rounded-full " src={userInfo.artistProfile.avatar} />
                                </div>
                                <div className="px-8">
                                    <p className="text-2xl text-black9 font-semibold mb-0 leading-normal">Hello!</p>
                                    <p className="text-3xl text-black9 font-semibold  leading-normal">{userInfo.artistProfile.name}</p>
                                    <p className="text-sm text-black8 mb-2 leading-normal font-bold">藝術家編號：{userInfo.artistProfile.id}</p>
                                </div>

                            </div>

                            <div className="flex my-10 justify-end pb-4">
                                <Link to="/membercenter">
                                    <button className="text-main8 font-semibold ">切換會員中心</button>
                                </Link>
                            </div>

                        </div>

                    </div>
                </div>
                : ""
        }


        <div className="session1 mx-auto relative w-full  pt-14">


            <div className="hidden lg:flex flex-wrap gap-3 flex-none w-full  mb-6 ">

                {
                    functionList.map((i, index) => <div className={`  font-bold text-base `} key={i}>
                        <button onClick={() => navigate(`/artistcenter/${functionList[index]}`)} className={`${index == functionIndex ? "bg-main8 text-white" : "bg-main1 text-main4 "} px-4 py-2 rounded-lg`}>{t(`memberCenterType.${i}`)}</button>
                    </div>)
                }

            </div>
            <div className=" lg:hidden mb-6 text-black">

                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={functionIndex}
                    label=""
                    className="w-full"
                    onChange={handleChange}
                >
                    {
                        functionList.map((i, index) =>
                            <MenuItem key={i} value={index}>{t(`memberCenterType.${i}`)}</MenuItem>
                        )
                    }
                </Select>

            </div>


            <div className=" w-full grow ">
                <TitleName2 title1={t(`memberCenterType.${functionList[functionIndex]}`)} />

            </div>

        </div>




    </div >
}

export default ArtistCenterNavBar