


const Line2 = (props) => {


  return (

    <div className="relative w-full Line1 h-[1px] my-4" >
      <div className="absolute left-0 top-1/2 w-1 h-1 bg-[#D49F4A] transform -translate-y-1/2 rotate-45"> </div>
      <div className="absolute right-0 top-1/2 w-1 h-1 bg-[#FFF4AF] transform -translate-y-1/2 rotate-45"> </div>

    </div>


  )
}
export default Line2