
import { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import moment from 'moment'



const CountDownTimer = (props) => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const [closeTime, setCloseTime] = useState(
        ((moment(props.endDate).valueOf() - moment().valueOf()) / 1000)
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCloseTime((prevCloseTime) => (prevCloseTime - 1 < 0 ? prevCloseTime : prevCloseTime - 1));
        }, 1000);

        return () => clearInterval(interval); // 在組件卸載時清除 interval
    }, []);

    return <div className="w-full mb-4 gradiBorder p-[2px] rounded-full overflow-hidden " >
        <div className="bg-white  rounded-full px-4 py-2 text-center">
            {
                closeTime > 0 ?
                    <div className="w-full max-w-[500px] mx-auto flex py-1 justify-between items-center  text-black9 ">
                        <p className="text-sm md:text-sm">距離結束 </p>

                        <p className="text-base md:text-lg font-bold">{Math.floor(closeTime / 86400)}</p>
                        <p className="text-xs md:text-sm ">{t('days')}</p>

                        <p className="text-base md:text-lg font-bold">{Math.floor(closeTime / 3600) < 10 ? `0${Math.floor(closeTime % 86400 / 3600)}` : Math.floor(closeTime % 86400 / 3600)}</p>
                        <p className="text-xs md:text-sm ">{t('hours')}</p>

                        <p className="text-base md:text-lg font-bold">{Math.floor(closeTime % 3600 / 60) < 10 ? `0${Math.floor(closeTime % 3600 / 60)}` : Math.floor(closeTime % 3600 / 60)}</p>
                        <p className="text-xs md:text-sm ">{t('minutes')}</p>

                        <p className="text-base md:text-lg font-bold">{Math.floor(closeTime % 60 < 10 ? `0${closeTime % 60}` : closeTime % 60)}</p>
                        <p className="text-xs md:text-sm ">{t('seconds')}</p>

                    </div>
                    :
                    <div className="flex py-1 justify-center items-center  text-black9">
                        <p className="text-sm md:text-sm font-bold">已結束競價</p>
                    </div>
            }
        </div>
    </div>

}
export default CountDownTimer