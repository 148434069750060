

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";

import UserApi from "../../../../services/UserApi.jsx";
import { separatorNumber, useGlobalFunc } from "../../../../global/constants.js";
import { Pagination } from "@mui/material";






// import homeshadowImg from '../../../assets/homeshadow.png'
// import homescrollImg from '../../../assets/homescroll.png'

// import homepic1Img from '../../../assets/homepic1.png'
// import homepic2Img from '../../../assets/homepic2.png'


const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search);


    const { setLoading, successToast, errorToast } = useGlobalFunc()
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
        getDataList(value)
    };

    const [dataList, setDataList] = useState(null)

    async function getDataList(page) {
        setLoading(true)
        try {
            let res = await UserApi.bidRegisterSearch({ page: page,type:"record" })
            console.log(res.data.data)
            setDataList(res.data.data)
            setTotalPage(res.data.total == 0 ? 1 : Math.ceil(res.data.total / 10))

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)

    }

    useEffect(() => {
       // getDataList(1)
    }, []);

    return <div className="mt-8   relative  w-full px-6 py-3 bg-white border-solid border-4 border-main3 rounded-[20px]">
        <div className="   whitespace-nowrap w-full  overflow-x-auto pb-10">
            <table className="w-full text-sm" >
                <thead className="">

                    <tr className="  text-main6 font-semibold ">
                        <th className="">{t('session')}</th>
                        <th className="">主題</th>
                        <th className="">拍品名稱</th>
                        <th className="">出價紀錄</th>
                        <th className="">成交落槌價</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        dataList && dataList.map(i => <tr key={i.id} className="align-top text-black9 font-semibold ">
                            <td className="">{i.sessionsInfo.name}</td>
                            <td className="">{i.themeInfo.name}</td>
                            <td className="">{i.bidRecords.length > 0 && i.bidRecords[0].productInfo.collectionName}</td>
                            <td className="">{i.bidRecords.map((i, index) => <div className="flex">
                                <div className="min-w-[25px]">{index + 1}.</div> <p>NT${i.bidAmount}</p>
                            </div>)}</td>
                            <td className="">{i.bidRecords[0].productInfo.winningBidAmount? `NT$${separatorNumber(i.bidRecords[0].productInfo.winningBidAmount)}`:"仍在拍賣中"}</td> 
                        </tr>
                        )
                    }

                </tbody>
            </table>



        </div>
        {
            dataList != null ?
                <>
                    {
                        dataList.length == 0 ?
                            <div className="  text-center text-gray-400 text-lg w-full font-bold  mb-16 ">
                                <div>暫無紀錄</div>
                            </div> : ""

                    }

                    <div className="flex w-full  mt-4 mb-2">
                        <div className="mx-auto">
                            <Pagination count={totalPage} page={page} onChange={handleChange} />
                        </div>
                    </div>
                </>
                : ""
        }
    </div>

}

export default Home