import axios from 'axios'

export default ({ url, method, headers, data }, option = {}) => {
    return axios(process.env.REACT_APP_API_URL + url, { // url 請求位置
        method: method || 'GET', //請求方法
        // 發送給伺服器的資料
        data: data ? data : null,
        headers: headers || localStorage.getItem('userToken') ? {
            "Authorization": `Bearer ${localStorage.getItem('userToken')}`,
            'Content-Type': 'application/json'
        } : {
            'Content-Type': 'application/json'
        }
        ,
        mode: 'cors', // 跨域處理
        ...option // 其他配置
    });
}