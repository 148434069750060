

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";


import playImg from '../../../assets/play.png'

import ReactPlayer from 'react-player'

import { useGlobalFunc, time2date } from "../../../global/constants.js";
import { Pagination } from "@mui/material";
import UserApi from "../../../services/UserApi.jsx";
import moment from 'moment'
import PathLocation from "../../../compontents/PathLocation";
import ArtVideoBox from "../../../compontents/ArtVideoBox";


const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search);


    const { setLoading, successToast, errorToast } = useGlobalFunc()
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
        getDataList(value)
    };

    const [dataList, setDataList] = useState(null)




    async function getDataList(page) {
        setLoading(true)
        try {
            let res = await UserApi.artVideoSearch({ page: page })
            console.log(res.data.data)
            setDataList(res.data.data)
            setTotalPage(res.data.total == 0 ? 1 : Math.ceil(res.data.total / 10))

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)

    }

    useEffect(() => {
        getDataList(1)
    }, []);


    return <div className="w-full">

        <div className=" relative pt-[40px] pb-20 bg-main9" >
            <div className="session1">
                <PathLocation color="text-white" />

                <div className="flex flex-wrap gap-4 items-end">
                    <p className="text-2xl text-white font-semibold">藝術影音</p>

                </div>

                {
                    dataList != null && dataList.length > 0 ?
                        <div onClick={() => navigate(`${dataList[0].id}`)} className="cursor-pointer px-8 flex flex-col sm:flex-row sm:items-center mt-8 mb-12 text-black9 text-base  ">
                            <div className=" pointer-events-none relative w-full sm:w-3/5 mb-4 sm:mb-0 aspect-[16/9] flex-none rounded-[10px] bg-black91">
                                <img className=" absolute w-8 h-8 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2" src={playImg} />
                                <img className="w-full h-full object-cover rounded-xl" src={dataList[0].image.logo} />
                            </div>


                            <div className="sm:px-8 flex flex-col">
                                <div className="mb-3 mr-auto bg-[#6E79A8] px-8 py-2 rounded-full text-base font-semibold text-white hidden">藝文影音</div>
                                <div className="text-white text-base ">
                                    {dataList[0].name}

                                </div>

                            </div>
                        </div>
                        : ""
                }


            </div>

        </div>
        {
            dataList != null ?
                <div className="session1 relative pt-[40px] pb-20 bg-white ">
                    <div className="sm:px-6 grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-10">

                        {
                            dataList.slice(1).map((i, index) => <ArtVideoBox key={i.id} data={i} />

                            )

                        }



                    </div>

                </div>

                : ""
        }

        {
            dataList ?
                <>
                    {
                        dataList.length == 0 ?
                            <div className="  text-center text-gray-400 text-lg w-full font-bold mb-20 ">
                                <div>暫無紀錄</div>
                            </div> : ""

                    }

                    <div className="flex w-full  mt-2 mb-10">
                        <div className="mx-auto">
                            <Pagination count={totalPage} page={page} onChange={handleChange} />
                        </div>
                    </div>
                </>
                : ""
        }




    </div >
}

export default Home