

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";

import ArtistCenterNavBar from "../compontents/ArtistCenterNavBar.js";



import { useSelector } from "react-redux";
import ApplyList from "./compontents/ApplyList";

import ShipRegister from '../../../compontents/ArtApply/ShipRegister.js'
import OrderAuction from '../../../compontents/ArtApply/OrderAuction.js'
import ConfrimApply from '../../../compontents/ArtApply/ConfrimApply.js'
import SuccessApply from '../../../compontents/ArtApply/SuccessApply.js'

import { useGlobalFunc } from "../../../global/constants.js";
import UserApi from "../../../services/UserApi.jsx";




const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const userInfo = useSelector(state => state.userInfo);


    const { setLoading, successToast, errorToast } = useGlobalFunc()

    const [step, setStep] = useState(0)

    const [successId, setSuccessId] = useState(0)

    const [isSale, setIsSale] = useState(true)



    const banner = []
    const [currBanner, setCurrBanner] = useState(0)
    const countRef = useRef()

    function backStep0() {
        setStep(0)
        window.scroll(0, 0)
    }

    function prevStep() {
        setStep(step - 1)
        window.scroll(0, 0)
    }

    function nextStep() {
        if (step == 0) {
            setData(initData)
        }
        setStep(step + 1)
        window.scroll(0, 0)
    }

    let initData = {
        name: "",
        area: "",
        phone: "",
        email: "",
        address: "",

        collectionName: "",
        artist: "",
        countryOfOrigin: "",
        material: "",
        type: "",
        creationYear: "",
        isFramed: "",
        height: "",
        width: "",
        depth: "",
        signature: "",
        damageLocation: "",
        repairDegree: "",
        purchaseSource: "",
        sourceInformation: "",
        paymentAmount: "",
        details: ""
    }


    const [data, setData] = useState()

    const updateData = (i, value) => {
        setData(prevData => ({ ...prevData, [i]: value }));
    };



    async function entrustedApply() {
        setLoading(true)
        let images = []
        if (data.img1) {
            images.push({ base64Img: data.img1 })
        }
        if (data.img2) {
            images.push({ base64Img: data.img2 })
        }
        if (data.img3) {
            images.push({ base64Img: data.img3 })
        }
        if (data.img4) {
            images.push({ base64Img: data.img4 })
        }
        if (data.img5) {
            images.push({ base64Img: data.img5 })
        }



        try {
            let temp = {
                "identityType": "artist",
                "collectionName": data.collectionName,
                "artist": data.artist,
                "countryOfOrigin": data.countryOfOrigin,
                "material": data.material,
                "creationYear": data.creationYear,
                "type": data.type,
                "isFramed": data.isFramed,
                "height": data.height,
                "width": data.width,
                "depth": data.depth,
                "signature": data.signature,
                "damageLocation": data.damageLocation,
                "repairDegree": data.repairDegree,
                "purchaseSource": data.purchaseSource,
                "sourceInformation": data.sourceInformation,
                "paymentAmount": data.paymentAmount,
                "currency": "TWD",
                "details": data.details,
                "salePrice": data.salePrice,
                "startingPrice": data.startingPrice,
                "contactInfo": {
                    "name": data.name,
                    "contactPhone": "+886" + data.phone,
                    "email": data.email,
                    "contactAddress": data.address
                },
                "images": images
            }
            let res = ""
            if (isSale) {
                res = await UserApi.entrustedPricingApply(temp)
            }
            else {
                res = await UserApi.entrustedAuctionApply(temp)
            }
            console.log(res.data.data)
            setSuccessId(String(res.data.data))
            nextStep()

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)
    }


    return <div className="w-full" >

        <ArtistCenterNavBar />
        <div className=" session1 mx-auto relative w-full  pb-14" >

            {
                step == 0 ?
                    <ApplyList prevStep={prevStep} nextStep={nextStep} />
                    : ""
            }
            {
                step == 1 ?
                    <OrderAuction type="commission" isSale={isSale} setIsSale={setIsSale} data={data} updateData={updateData} prevStep={prevStep} nextStep={nextStep} />
                    : ""
            }
            {
                step == 2 ?
                    <ShipRegister data={data} updateData={updateData} prevStep={prevStep} nextStep={nextStep} />
                    : ""
            }
            {
                step == 3 ?
                    <ConfrimApply type="commission" data={data} prevStep={prevStep} nextStep={entrustedApply} />
                    : ""
            }
            {
                step == 4 ?
                    <SuccessApply backStep0={backStep0} id={successId} />
                    : ""
            }

        </div>





    </div >
}

export default Home