

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";



import { useGlobalFunc, time2date } from "../../../global/constants.js";
import { Pagination } from "@mui/material";
import UserApi from "../../../services/UserApi.jsx";
import moment from 'moment'
import PathLocation from "../../../compontents/PathLocation.js";
import Tag from "../../../compontents/Tag.js";

const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search);


    const { setLoading, successToast, errorToast } = useGlobalFunc()

    const [data, setData] = useState(null)




    async function getData(id) {
        setLoading(true)
        try {
            let res = await UserApi.exhibitionEventGet({ id: id })
            console.log(res.data.data)
            setData(res.data.data)

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)

    }

    useEffect(() => {
        let id = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        getData(id)
    }, []);


    return <div className="w-full">

        <div className="text-black9 session1 relative pt-[40px] pb-20 " >

            <PathLocation />


            {
                data != null ?
                    <div className=" flex flex-col  mt-8 mb-12 text-black9 text-base  ">
                        <div className="relative w-full mb-2 aspect-[16/9] flex-none rounded-[10px]">

                            <img className="w-full h-full" src={data.images && data.images.banner} />


                        </div>

                        <div className="w-11/12 mx-auto  bg-main8 rounded-[20px] px-6 py-6  -translate-y-8">
                            <div className="flex flex-col sm:flex-row gap-2 sm:items-center mb-2  hidden">
                                <div className="flex  mr-auto sm:mr-0">
                                    <Tag title={t(`newsType.${data.type}`)} />
                                </div>
                                <p className="text-white "> {time2date(data.createdAt)}｜撰文者： {data.author}</p>
                            </div>
                            <div className=" text-xl  text-white font-bold">
                                {data.name}
                            </div>

                        </div>

                        <div className="flex flex-col">
                            <div className="text-base" dangerouslySetInnerHTML={{ "__html": data.content }} />
                        </div>
                    </div>
                    : ""
            }




        </div>


        {
            data ?
                <>
                    {
                        data.length == 0 ?
                            <div className="  text-center text-gray-400 text-lg w-full font-bold mb-20 ">
                                <div>暫無紀錄</div>
                            </div> : ""

                    }


                </>
                : ""
        }




    </div >
}

export default Home