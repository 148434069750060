
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";

import Line1 from '../../../compontents/Line1'
import Swiper from '../../../compontents/Swiper.js'
import TitleName from '../../../compontents/TitleName.js'
import TitleName2 from '../../../compontents/TitleName2.js'
import SeeMore from '../../../compontents/SeeMore.js'

import Btn from '../../../compontents/Btn.js'



import circle1Img from '../../../assets/artAuction/circle1.png'
import circle2Img from '../../../assets/artAuction/circle2.png'
import circle3Img from '../../../assets/artAuction/circle3.png'

import circle11Img from '../../../assets/artAuction/circle11.png'
import circle22Img from '../../../assets/artAuction/circle22.png'
import circle33Img from '../../../assets/artAuction/circle33.png'


import pic1Img from '../../../assets/1.png'
import pic2Img from '../../../assets/2.png'
import pic3Img from '../../../assets/3.png'
import pic4Img from '../../../assets/4.jpg'

import hallbgImg from '../../../assets/hallbg.png'

import moreArrowRightImg from '../../../assets/moreArrowRight.png'



import linesImg from '../../../assets/lines.png'
import lines2Img from '../../../assets/home/lines2.png'

import swipe1Img from '../../../assets/swipe1.png'
import swipe2Img from '../../../assets/swipe2.png'
import swipe3Img from '../../../assets/swipe3.jpg'
import UserApi from "../../../services/UserApi";
import { useGlobalFunc , time2date } from "../../../global/constants";
import PathLocation from "../../../compontents/PathLocation";
import StickyList from "../../../compontents/StickyList";




// import homeshadowImg from '../../../assets/homeshadow.png'
// import homescrollImg from '../../../assets/homescroll.png'

// import homepic1Img from '../../../assets/homepic1.png'
// import homepic2Img from '../../../assets/homepic2.png'


const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search);



    const { setLoading, successToast, errorToast } = useGlobalFunc()
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
        //getDataList(value)
    }


    const [data, setData] = useState(null)

    async function getArtAuction() {
        setLoading(true)
        try {
            let res = await UserApi.getArtAuction()
            console.log(res.data)
            setData(res.data.data)
        }
        catch (errors) {
            //errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)
    }



    useEffect(() => {
        getArtAuction()

    }, []);



    return <div className="session1elative pt-[40px] pb-20 bg-white" >

        <div className="mb-32 session1">
            <PathLocation />
            <TitleName title1="Art Auction" title2="藝品拍賣" />

            <img className="w-full mt-4 object-contain mb-14 hidden" src={pic4Img} alt="" />

            <div className="hidden mx-auto grid grid-cols-8 w-10/12 max-w-[400px] rounded-[3.5px] overflow-hidden">
                <div className=" bg-sec4 h-0.5"></div>
                <div className="col-span-7 bg-black9 h-0.5"></div>
            </div>
        </div>

        <div className="session1 flex ">
            <div className="hidden lg:block  flex-none w-[200px] min-h-full pr-10">
                <StickyList list={["artAuction.physicalAuction", "artAuction.onlineAuction", "artAuction.auctionNews", "artAuction.auctionService", "artAuction.auctionIntro"]} />
            </div>
            <div className="w-0 grow" id="scroll0">
                <TitleName2 title1={t("artAuction.physicalAuction")} />
                {
                    data && data.physicalAuction.length > 0?
                        <div>
                            <div className="mt-8 relative bg-sec4 rounded-2xl  mb-8">
                                <div className=" border-main border-solid border-2 w-full h-full left-0 top-0 bg-[#222968] hover:bg-[#333B9D] anim rounded-2xl transform -translate-x-2 translate-y-2 px-8 py-8 sm:px-20 sm:py-14">
                                    <p className="text-white text-2xl font-semibold mb-4">{data.physicalAuction[0].name}</p>
                                    <p className="text-white text-sm font-semibold mb-4">{data.physicalAuction[0].eventStartDate} － {data.physicalAuction[0].eventEndDate}</p>
                                    <div className="flex gap-2 items-center mb-6">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M10.0007 1.66699C12.759 1.66699 15.0007 3.88366 15.0007 6.62533C15.0007 10.342 10.0007 15.8337 10.0007 15.8337C10.0007 15.8337 5.00065 10.342 5.00065 6.62533C5.00065 3.88366 7.24232 1.66699 10.0007 1.66699ZM10.0007 5.00033C9.55862 5.00033 9.1347 5.17592 8.82214 5.48848C8.50958 5.80104 8.33398 6.22496 8.33398 6.66699C8.33398 7.10902 8.50958 7.53294 8.82214 7.8455C9.1347 8.15806 9.55862 8.33366 10.0007 8.33366C10.4427 8.33366 10.8666 8.15806 11.1792 7.8455C11.4917 7.53294 11.6673 7.10902 11.6673 6.66699C11.6673 6.22496 11.4917 5.80104 11.1792 5.48848C10.8666 5.17592 10.4427 5.00033 10.0007 5.00033ZM16.6673 15.8337C16.6673 17.6753 13.684 19.167 10.0007 19.167C6.31732 19.167 3.33398 17.6753 3.33398 15.8337C3.33398 14.7587 4.35065 13.8003 5.92565 13.192L6.45898 13.9503C5.55898 14.3253 5.00065 14.842 5.00065 15.417C5.00065 16.567 7.24232 17.5003 10.0007 17.5003C12.759 17.5003 15.0007 16.567 15.0007 15.417C15.0007 14.842 14.4423 14.3253 13.5423 13.9503L14.0756 13.192C15.6506 13.8003 16.6673 14.7587 16.6673 15.8337Z" fill="#F2F2F2" />
                                        </svg>
                                        <p className="text-white text-sm ">{data.physicalAuction[0].eventAddress}</p>
                                    </div>
                                    <div className="grid grid-cols-2 gap-5 text-h4">
                                        <Link to={`physical/catolog?id=${data.physicalAuction[0].id}`}><button className="w-full h-full py-3  text-sec2 rounded-full bg-main8 border-2 border-solid border-sec4">電子圖錄</button></Link>

                                        <Link to={`physical/intro?id=${data.physicalAuction[0].id}`}><button className="w-full h-full py-3  text-sec2 rounded-full bg-main8 border-2 border-solid border-sec4">拍賣簡介</button></Link>
                                    </div>
                                </div>
                            </div>

                            <div className=" relative bg-sec4 rounded-2xl ">
                                <div className=" border-main border-solid border-2 w-full h-full left-0 top-0 bg-[#222968] hover:bg-[#333B9D] anim rounded-2xl transform -translate-x-2 translate-y-2 px-8 py-8 sm:px-20 sm:py-14">
                                    <p className="text-sec4 text-2xl font-semibold mb-6 text-center ">拍品總覽</p>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 text-lg">
                                        {
                                            data && data.physicalAuction[0].physicalAuction.slice(0, 2).map(i =>

                                                <div onClick={()=>navigate(`/art/${i.id}?type=physical`)} className="grid grid-cols-2 gap-5 cursor-pointer">
                                                    <img src={i.images[0].image} className="w-full object-cover aspect-[1/1] rounded-xl" />
                                                    <div className=" flex  flex-col justify-between w-full">
                                                        <div>
                                                            <p className="text-gray1  font-semibold text-xs">{i.lotNumber}</p>
                                                            <p className="text-white text-h3.5 font-bold mb-2">{i.itemName}</p>
                                                            <p className="text-white text-sm font-semibold mb-2">{i.artistName}</p>
                                                        </div>
                                                        <div className="w-full py-2 text-sm font-semibold  text-white rounded-full bg-[#989DD1] text-center">2022 夏拍</div>
                                                    </div>
                                                </div>
                                            )
                                        }


                                    </div>
                                </div>
                            </div>

                        </div>
                        : ""
                }

                <div className="flex justify-end mt-8 mb-20">
                    <SeeMore to="physical" />
                </div>

                <div id="scroll1">
                    <TitleName2 title1={t("artAuction.onlineAuction")} />
                    {
                        data && data.onlineAuction.slice(0, 2).map(i => <div onClick={()=>navigate(`/artauction/online/theme?sessionsId=${i.id}`)} className=" cursor-pointer w-full  grid grid-cols-5 items-center gap-x-[50px] gap-y-[10px] pt-20 pb-10">
                            <div className="aspect-[16/9] ml-auto overflow-hidden  col-span-5 sm:col-span-3 border-solid bg-sec4 border-sec4 border-4 rounded-[20px]">
                                <img src={i.logo} className="w-full h-full object-cover anim hover:scale-105" />
                            </div>
                            <div className="col-span-5 sm:col-span-2 text-main95">
                                <p className="mb-2">{t('sessionId')}：{i.numberId}</p>
                                <p className="mb-6 text-2xl font-semibold">{i.name}</p>
                                <p className="mb-1 font-medium text-base">{i.themes.length > 0 && i.themes[0].startDate}</p>
                                <p className="mb-[11px] text-sm">{t('isOnlineGallery')}</p>
                                <p className="text-sm">Lot. {i.lotStartNumber} - {i.lotEndNumber}</p>
                            </div >
                        </div >)

                    }

                    <div className="flex justify-end mt-8 mb-20">
                        <SeeMore to="online" />
                    </div>
                </div>
                <div className="mb-20" id="scroll2">
                    <TitleName2 title1={t("artAuction.auctionNews")} />

                    {
                        data && data.auctionNews.slice(0, 2).map(i => <div onClick={()=>navigate(`/artfocus/artnews/${i.id}`)} className="group cursor-pointer px-4 sm:px-10 "><div className="sm:flex items-center gap-5 py-14">
                            <div className="flex-none sm:w-32 text-black9 font-bold text-lg">{time2date(i.createdAt)}</div>
                            <div className="group-hover:text-red-500 w-full font-bold text-base text-black9 ">{i.name}</div>

                        </div>
                            <div className="h-0.5 bg-sec2 w-full"></div>
                        </div>
                        )
                    }
                    <div className="flex justify-end mt-8 ">
                        <SeeMore to="/artfocus/artnews?type=auctionNews" />
                    </div>
                </div>
                <div className="w-full mb-12" id="scroll3">
                    <TitleName2 title1={t("artAuction.auctionService")} />
                </div>


                <div className=" relative bg-sec4 rounded-2xl  mb-20">
                    <div className=" border-main border-solid border-2 w-full h-full left-0 top-0 bg-[#222968] hover:bg-[#333B9D] anim rounded-2xl transform -translate-x-2 translate-y-2 px-8 py-8 sm:px-20 sm:py-14">
                        <div className="group flex flex-col sm:flex-row gap-4 sm:gap-10 items-center mb-10">
                            <div className="w-40 aspect-[1/1] relative flex-none">
                                <img src={circle11Img} className=" opacity-100 group-hover:opacity-0 w-full h-full object-contain absolute left-0 top-0  " />
                                <img src={circle1Img} className=" opacity-0 group-hover:opacity-100 w-full h-full  object-contain absolute left-0 top-0 " />
                            </div>
                            <div className="w-full text-center sm:text-left ">
                                <p className="group-hover:text-sec3 text-white text-xl font-semibold mb-2">{t("artAuction.inAuctionService.text1")}</p>
                                <p className="group-hover:text-sec3 text-white text-base ">{t("artAuction.inAuctionService.text2")}</p>
                            </div>
                        </div>
                        <div className="group flex flex-col sm:flex-row gap-4 sm:gap-10 items-center mb-10">
                            <div className="w-40 aspect-[1/1] relative flex-none">
                                <img src={circle22Img} className=" opacity-100 group-hover:opacity-0 w-full h-full  object-contain absolute left-0 top-0 " />
                                <img src={circle2Img} className=" opacity-0 group-hover:opacity-100 w-full h-full  object-contain absolute left-0 top-0 " />
                            </div>
                            <div className="w-full text-center sm:text-left">
                                <p className="group-hover:text-sec3 text-white text-xl font-semibold mb-2">{t("artAuction.inAuctionService.text3")}</p>
                                <p className="group-hover:text-sec3 text-white text-base ">{t("artAuction.inAuctionService.text4")}</p>
                            </div>
                        </div>
                        <div className="group flex flex-col sm:flex-row gap-4 sm:gap-10 items-center">
                            <div className="w-40 aspect-[1/1] relative flex-none">
                                <img src={circle33Img} className=" opacity-100 group-hover:opacity-0 w-full h-full  object-contain absolute left-0 top-0 " />
                                <img src={circle3Img} className=" opacity-0 group-hover:opacity-100 w-full h-full  object-contain absolute left-0 top-0 " />
                            </div>
                            <div className="w-full text-center sm:text-left">
                                <p className=" group-hover:text-sec3 text-white text-xl font-semibold mb-2">{t("artAuction.inAuctionService.text5")}</p>
                                <p className=" group-hover:text-sec3 text-white text-base ">{t("artAuction.inAuctionService.text6")}</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="w-full mb-12" id="scroll4">
                    <TitleName2 title1={t("artAuction.auctionIntro")} />
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 items-center">
                    <Btn onClick={() =>navigate('physical/rules')} title={t("artAuction.auctionIntroBtn1")} />
                    <Btn onClick={() =>navigate('online/rules')} title={t("artAuction.auctionIntroBtn2")} />

                </div>



            </div>

        </div>




    </div >
}

export default Home