

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";


import docImg from '../../assets/doc.png'
import SelectImgDialog from "../../compontents/SelectImgDialog";




// import homeshadowImg from '../../../assets/homeshadow.png'
// import homescrollImg from '../../../assets/homescroll.png'

// import homepic1Img from '../../../assets/homepic1.png'
// import homepic2Img from '../../../assets/homepic2.png'


const Home = ({ data, updateData, prevStep, nextStep, type, isSale, setIsSale }) => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);




    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');



    const handleClickOpen = (value) => {
        setSelectedValue(value)
        setOpen(true);
    };

    const handleClose = (value, type) => {
        setOpen(false);
        updateData(type, value)

    };



    useEffect(() => {

    }, []);


    return <div>

        <form onSubmit={nextStep} className="mt-8 px-6 py-6 bg-white border-solid border-4 border-main3 rounded-[20px] relative  w-full overflow-x-auto whitespace-nowrap">

            <div className="text-black9 text-xl font-bold mb-4 ">委託申請表單</div>

            <div className="text-black9 font-bold mb-6 hidden">鑑價藏品</div>
            <div className=" hidden">鑑價藏品申請訂單 ID</div>
            <div className="flex gap-2   text-black9 mb-4 hidden">

                <input className="w-full py-1 rounded-lg border border-solid border-main3" />
                <button className=" font-bold bg-main6 text-white w-1/2 rounded-[10px] py-3">取得訂單 ID</button>
            </div>
            <div className="text-black9 font-bold mb-6 mt-10">關於您的物品</div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">藝術品名稱<span className="text-red-500">*</span></div>
                <input required value={data.collectionName} onChange={(e) => updateData('collectionName', e.target.value)} className="w-full py-1 rounded-lg border border-solid border-main3" />
            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">藝術家</div>
                <input value={data.artist} onChange={(e) => updateData('artist', e.target.value)} className="w-full py-1 rounded-lg border border-solid border-main3" />
            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">原產國 / 地區</div>
                <input value={data.countryOfOrigin} onChange={(e) => updateData('countryOfOrigin', e.target.value)} className="w-full py-1 rounded-lg border border-solid border-main3" />
            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">媒材物料</div>
                <input value={data.material} onChange={(e) => updateData('material', e.target.value)} placeholder="例如：瓷器、油彩畫布" className="w-full py-1 rounded-lg border border-solid border-main3" />
            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">品項</div>
                <input value={data.type} onChange={(e) => updateData('type', e.target.value)} placeholder="版畫" className="w-full py-1 rounded-lg border border-solid border-main3" />
            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">創作年份</div>
                <div className="flex items-center gap-2">
                    <input  value={data.creationYear} onChange={(e) => updateData('creationYear', e.target.value)} placeholder="2023" maxLength={4} className="w-16 py-1 rounded-lg border border-solid border-main3" />
                    <div className="text-sm ">
                        年創作（西元）
                    </div>
                </div>
            </div>
            <div className="text-black9 font-bold mb-6 mt-10">尺寸</div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">是否已表框？</div>
                <div className="flex gap-4">
                    <div>

                        <div className="flex items-center gap-2">
                            <input checked={data.isFramed === true} onChange={(e) => updateData('isFramed', true)} type="radio" className=" py-1 rounded-lg border border-solid border-main3" />
                            <div className="text-sm ">
                                是
                            </div>
                        </div>
                    </div>
                    <div>

                        <div className="flex items-center gap-2">
                            <input checked={data.isFramed === false} onChange={(e) => updateData('isFramed', false)} type="radio" className=" py-1 rounded-lg border border-solid border-main3" />
                            <div className="text-sm ">
                                否
                            </div>
                        </div>
                    </div>


                </div>

            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">單位（cm）</div>
                <div className=" grid grid-cols-1 sm:grid-cols-3 gap-4">
                    <div>
                        <div className="text-sm text-black6 mb-1">高度<span className="text-red-500">*</span></div>
                        <div className="flex items-center gap-2">
                            <input required value={data.height} type="number" onChange={(e) => updateData('height', e.target.value)} className="w-full py-1 rounded-lg border border-solid border-main3" />
                            <div className="text-sm ">
                                cm
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="text-sm text-black6 mb-1">寬度<span className="text-red-500">*</span></div>
                        <div className="flex items-center gap-2">
                            <input required value={data.width} type="number" onChange={(e) => updateData('width', e.target.value)} className="w-full py-1 rounded-lg border border-solid border-main3" />
                            <div className="text-sm ">
                                cm
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="text-sm text-black6 mb-1">深度<span className="text-red-500">*</span></div>
                        <div className="flex items-center gap-2">
                            <input required value={data.depth} type="number" onChange={(e) => updateData('depth', e.target.value)} className="w-full py-1 rounded-lg border border-solid border-main3" />
                            <div className="text-sm ">
                                cm
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div className="text-black9 font-bold mb-6 mt-10">狀況</div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">簽名 / 標籤或標記</div>
                <input value={data.signature} onChange={(e) => updateData('signature', e.target.value)} className="w-full py-1 rounded-lg border border-solid border-main3" />
            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">損壞位置</div>
                <input value={data.damageLocation} onChange={(e) => updateData('damageLocation', e.target.value)} className="w-full py-1 rounded-lg border border-solid border-main3" />
            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">是否經過修復？如有，修復程度如何？</div>
                <input value={data.repairDegree} onChange={(e) => updateData('repairDegree', e.target.value)} className="w-full py-1 rounded-lg border border-solid border-main3" />
            </div>



            <div className="text-black9 font-bold mb-6 mt-10">來源</div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">遞藏及如何購入</div>
                <input value={data.purchaseSource} onChange={(e) => updateData('purchaseSource', e.target.value)} className="w-full py-1 rounded-lg border border-solid border-main3" />
            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">評論 / 出版文獻 / 展覽 / 專題目錄</div>
                <input value={data.sourceInformation} onChange={(e) => updateData('sourceInformation', e.target.value)} className="w-full py-1 rounded-lg border border-solid border-main3" />
            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">購入時付款金額</div>
                <input value={data.paymentAmount} type="number" onChange={(e) => updateData('paymentAmount', e.target.value)} className="w-full py-1 rounded-lg border border-solid border-main3" />
            </div>

            {
                type == "commission" &&
                <div>
                    <div className="text-black9 font-bold mb-2 mt-10">價格</div>
                    <div className="mb-2 flex">
                        <div className="flex bg-gray1 rounded-full p-1  mb-2">
                            <button type={'button'} onClick={() => setIsSale(true)} className={`${isSale ? "btn1" : "btn1 !bg-gray1"}`}>定價</button>
                            <button type={'button'} onClick={() => setIsSale(false)} className={`${!isSale ? "btn1" : "btn1 !bg-gray1"}`}>拍賣</button>
                        </div>
                    </div>
                    {
                        isSale ?
                            <div className="flex gap-2 flex-col   text-black9 mb-4">
                                <div className=" ">欲販售價格<span className="text-red-500">*</span></div>
                                <input required value={data.salePrice} onChange={(e) => updateData('salePrice', e.target.value)} className="w-full py-1 rounded-lg border border-solid border-main3" />
                            </div>
                            :
                            <div className="flex gap-2 flex-col   text-black9 mb-4">
                                <div className=" ">欲起拍價格<span className="text-red-500">*</span></div>
                                <input required value={data.startingPrice} onChange={(e) => updateData('startingPrice', e.target.value)} className="w-full py-1 rounded-lg border border-solid border-main3" />
                            </div>
                    }

                </div>
            }


            <div className="text-black9 font-bold mb-6 mt-10">註釋</div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">藝術品詳情</div>
                <textarea value={data.details} onChange={(e) => updateData('details', e.target.value)} className="w-full h-40 py-1 rounded-lg border border-solid border-main3" />
            </div>

            <div className="text-black9 font-bold mb-6 mt-10">圖片及文件</div>
            <div className="w-full  grid grid-cols-5 gap-3">
                <div onClick={() => handleClickOpen('img1')} className="relative cursor-pointer col-span-5 md:col-span-3 w-full aspect-[1/1] border border-main2 border-solid flex justify-center items-center  text-black8 text-xl font-bold text-center">
                    <div>
                        <img className="w-20 h-20 object-contain mb-4 mx-auto" src={docImg} />
                        <p className=" font-normal mb-8">點選以上載圖像</p>
                        <img className=" w-full h-full absolute left-0 top-0 object-contain" src={data.img1} />
                    </div>
                </div>
                <div className="col-span-5 md:col-span-2 grid grid-cols-2 gap-3">
                    <div onClick={() => handleClickOpen('img2')} className="relative cursor-pointer w-full aspect-[1/1]  border border-main2 border-solid flex justify-center items-center text-black8 text-xl font-bold text-center"><div>
                        <img className="w-10 h-10 object-contain mb-4 mx-auto" src={docImg} />
                        <img className=" w-full h-full absolute left-0 top-0 object-contain" src={data.img2} />

                    </div>
                    </div>
                    <div onClick={() => handleClickOpen('img3')} className="relative cursor-pointer w-full aspect-[1/1]  border border-main2 border-solid flex justify-center items-center text-black8 text-xl font-bold text-center"><div>
                        <img className="w-10 h-10 object-contain mb-4 mx-auto" src={docImg} />
                        <img className=" w-full h-full absolute left-0 top-0 object-contain" src={data.img3} />

                    </div>
                    </div>
                    <div onClick={() => handleClickOpen('img4')} className="relative cursor-pointer w-full aspect-[1/1]  border border-main2 border-solid flex justify-center items-center text-black8 text-xl font-bold text-center"><div>
                        <img className="w-10 h-10 object-contain mb-4 mx-auto" src={docImg} />
                        <img className=" w-full h-full absolute left-0 top-0 object-contain" src={data.img4} />
                    </div>
                    </div>
                    <div onClick={() => handleClickOpen('img5')} className="relative cursor-pointer w-full aspect-[1/1]  border border-main2 border-solid flex justify-center items-center text-black8 text-xl font-bold text-center"><div>
                        <img className="w-10 h-10 object-contain mb-4 mx-auto" src={docImg} />
                        <img className=" w-full h-full absolute left-0 top-0 object-contain" src={data.img5} />
                    </div>
                    </div>
                    <div className="hidden w-full aspect-[1/1]  border border-main2 border-solid flex justify-center items-center text-black8 text-xl font-bold text-center"><div>
                        <img className="w-10 h-10 object-contain mb-4 mx-auto" src={docImg} />

                    </div>
                    </div>
                    <div className="hidden w-full aspect-[1/1]  border border-main2 border-solid flex justify-center items-center text-black8 text-xl font-bold text-center"><div>
                        <img className="w-10 h-10 object-contain mb-4 mx-auto" src={docImg} />

                    </div>
                    </div>
                </div>

            </div>

            <div className="text-black6 mb-6 mt-10 text-sm">
                <div className="text-base font-bold mb-3">要求</div>
                <div className=" ">照片：JPG, JPEG, PNG</div>
                <div className=" ">最小尺寸：100px</div>
                <div className=" ">檔案大小限制：10MB</div>
            </div>

            <div className="text-black6 mb-20 mt-10 text-sm">
                <div className="text-base font-bold mb-3 ">注意</div>
                <div className=" ">請使用您所拍攝的照片，我們不能接受物品照片的翻拍照片。</div>
                <div className=" ">在可行情況下移除裝裱鏡框，以利顯示大小及比例、細節及材質。</div>
                <div className=" ">一張局部照片，除非物品過小，否則請顯示物品的25%。</div>
            </div>


            <div className="flex gap-2">
                <button onClick={() => prevStep()} className=" font-bold border-main4 border-solid border text-main4 w-full rounded-full py-3">上一步</button>
                <button type={'submit'}  className=" font-bold bg-main4 text-white w-full rounded-full py-3">下一步</button>
            </div>



        </form>


        <SelectImgDialog
            type={selectedValue}
            open={open}
            onClose={handleClose}
        />


    </div>

}

export default Home