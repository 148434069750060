

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  useNavigate,
  useSearchParams
} from "react-router-dom";

import { ReactComponent as AddImg } from '../../assets/addImg.svg'

import UserApi from "../../services/UserApi";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { isValidEmail, isValidPassword, isValidPhoneNumber } from "../../global/regex.js"

//import { auth, signInWithEmailAndPassword, signInWithGoogle } from "../../firebase";

import loginlogoImg from '../../assets/google.png'
import logintitleImg from '../../assets/google.png'



import googleloginImg from '../../assets/google.png'
import fbloginImg from '../../assets/fb.png'
import successImg from "../../assets/success.png"

import Compressor from 'compressorjs';
import picInputImg from "../../assets/doc.png"

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import axios from 'axios'
import { useGlobalFunc } from "../../global/constants";

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation()
  const dispatch = useDispatch()

  const [searchParams, setSearchParams] = useSearchParams();

  const [isShowBuyDialog, setShowBuyDialog] = useState(false);
  const closeBuyDialog = () => {
    setShowBuyDialog(false);
  }

  const { setLoading, successToast, errorToast } = useGlobalFunc()




  const [isFisrt, doneFisrt] = useState(false)

  const [step, setStep] = useState(0)

  const [sendOTP, setSendOTP] = useState(null)
  const [countdown, setCountdown] = useState(0)


  const [record, setRecord] = useState([])

  const [readPolicy, setReadPolicy] = useState(false)

  const [account, setAccount] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  const [pw, setPw] = useState("")
  const [confirmPw, setConfirmPw] = useState("")
  const [OTP, setOTP] = useState("")

  const [city, setCity] = useState("")
  const [birth, setBirth] = useState("")
  const [gender, setGender] = useState("")
  const [phone, setPhone] = useState("")
  const [area, setArea] = useState("")
  const [address, setAddress] = useState("")


  const [idNum, setIdNum] = useState("")

  const nextStep = (event) => {
    console.log('asd')
    if (step < 2) {
      event.preventDefault(); // 阻止默認的表單提交行為
    }

    // 在這裡處理你的表單邏輯，例如驗證、發送請求等
    if (step == 0) {
      if (!isValidEmail(email)) {
        errorToast('請輸入正確的電子郵件格式')
        return;
      }
      if (!isValidPassword(pw)) {
        errorToast('請輸入正確的密碼格式')
        return;
      }

      if (pw !== confirmPw) {
        errorToast('請輸入一致的密碼和確認密碼')
        return;
      }
    }
    if (step == 1) {
      if (imgBase64.current == "") {
        errorToast('請上傳正面有效身分證件')
        return
      }
      if (imgBase642.current == "") {
        errorToast('請上傳背面有效身分證件')
        return
      }

    }
    setStep(step + 1)
    window.scrollTo(0, 0);
  };



  function callInputImg(type) {
    let call
    if (type == 1) {
      call = document.querySelector('#inputimg1')
    }
    else {
      call = document.querySelector('#inputimg2')
    }
    call.click()
  }
  const handleCompressedUpload = (e, type) => {
    const image = e.target.files[0];
    new Compressor(image, {
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      convertSize: 2000000,
      success: (result) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server. 
        console.log(result)
        if (type == 1) {
          setCompressedFile(URL.createObjectURL(result))
        }
        else {
          setCompressedFile2(URL.createObjectURL(result))
        }


        var reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onloadend = function () {
          var base64data = reader.result;
          //console.log(base64data);
          //base64data = base64data.split(',')[1]

          if (type == 1) {
            imgBase64.current = base64data
          }
          else {
            imgBase642.current = base64data
          }
          console.log(base64data)

        }
      },
    });
  };


  const imgBase64 = useRef('')
  const [compressedFile, setCompressedFile] = useState(null);

  const imgBase642 = useRef('')
  const [compressedFile2, setCompressedFile2] = useState(null);


  async function sendOTPAction() {
    if (!isValidPhoneNumber("+886"+phone)) {
      errorToast('請輸入正確的電話號碼')
      return
    }

    setLoading(true)
    try {
      let res = await UserApi.getOtp({
        "phone": "+886" + phone
      })
      setSendOTP(true)
      setCountdown(60)
    }
    catch (errors) {
      errorToast(errors?.response?.data?.message || errors.toString())

    }
    setLoading(false)
  }


  async function signUpAction() {



    setLoading(true)
    try {
      let res = await UserApi.signUp({

        //"googleToken": "23",
        "email": email,
        "password": pw,
        "actualName": name,
        "birthDate": birth,
        "gender": gender,
        "city": city,
        "address": address,
        "kyc": {
          "idNumber": idNum,
          "frontImage": imgBase64.current,
          "backImage": imgBase642.current
        },
        "phone": "+886" + phone,
        "code": OTP
      })
      console.log(res.data)
      nextStep()
      //localStorage.setItem("userToken", res.data.token)
      //navigate('/membercenter')


      //window.location.reload()

    }
    catch (errors) {
      errorToast(errors?.response?.data?.message || errors.toString())
      // if (res.data.message == "User does exist") {
      //    errorToast('註冊失敗，該電子信箱已存在')
      // }
      // else if (res.data.message == "Invalid referralCode") {
      //    errorToast('註冊失敗，該邀請碼不存在')
      // }
      // else {
      //    errorToast('註冊失敗')
      // }
    }
    setLoading(false)
  }


  useEffect(() => {
    if (sendOTP && countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
        if (countdown === 1) {
          setSendOTP(false);
        }
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }

  }, [countdown]);


  useEffect(() => {



  }, []);


  return <div className="w-full " >


    {/* <div className="w-full md:w-[200px] ">
          <StakingMenu />
  
        </div> */}
    <div className="w-full  bg-main3 md:py-10 ">

      <div className="flex justify-between py-4 ">
        <p className="w-full text-center text-main9 font-bold text-3xl">會員註冊</p>

      </div>

      <div className="flex w-full h-full ">
        <div className="m-auto bg-white  w-full md:max-w-[470px] p-7 md:rounded-[20px]">

          {
            step == 0 ?
              <form onSubmit={nextStep} >
                <div className="flex items-center gap-2 mb-6">
                  <p className="text-black9 text-xl font-bold">基本資料</p>
                  <div className="bg-main3 grow h-px"></div>
                </div>

                <p className="text-black9 font-bold">電子郵件</p>
                <input required value={email} onChange={e => setEmail(e.target.value)} type="email" className="mb-6  w-full  text-lg " />

                <p className="text-black9 font-bold">密碼</p>
                <input required value={pw} onChange={e => setPw(e.target.value)} type="password" className="mb-1 w-full  text-lg" />
                <p className="text-black7 text-xs mb-6">{t("Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 number, and be at least 8 characters long")}</p>

                <p className="text-black9 font-bold">確認密碼</p>
                <input required value={confirmPw} onChange={e => setConfirmPw(e.target.value)} type="password" className="mb-6  w-full  text-lg" />

                <p className="text-black9 font-bold">真實姓名</p>
                <input required value={name} onChange={e => setName(e.target.value)} type="text" className="mb-6  w-full  text-lg" />

                <p className="text-black9 font-bold">出生年月日</p>
                <input required value={birth} onChange={e => setBirth(e.target.value)} type="date" className="mb-6  w-full  text-lg" />

                <p className="text-black9 font-bold">性別</p>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={gender}
                  label=""
                  required
                  className="w-full mb-6"
                  onChange={e => setGender(e.target.value)}
                >
                  <MenuItem value={"male"}>{t(`male`)}</MenuItem>
                  <MenuItem value={"female"}>{t(`female`)}</MenuItem>
                  <MenuItem value={"unknown"}>{t(`unknown`)}</MenuItem>
                </Select>



                <p className="text-black9 font-bold">所在地區</p>
                <input required value={city} onChange={e => setCity(e.target.value)} type="text" className="mb-6  w-full  text-lg" />

                <p className="text-black9 font-bold">地址</p>
                <input required value={address} onChange={e => setAddress(e.target.value)} type="text" className="mb-6  w-full  text-lg" />

                <button type="submit" className="btn1 w-full mb-2">下一步</button>
                <Link to="/login"><button className="btn2 w-full">返回登入</button></Link>
              </form>

              : ""
          }
          {
            step == 1 ?
              <form onSubmit={nextStep} >
                <div className="flex items-center gap-2 mb-6">
                  <p className="text-black9 text-xl font-bold">KYC 認證</p>
                  <div className="bg-main3 grow h-px"></div>
                </div>

                <p className="text-black9 font-bold">身份證字號</p>
                <input required value={idNum} onChange={e => setIdNum(e.target.value)} type="text" className="mb-6  w-full  text-lg" />

                <p className="text-black9 font-bold mb-2">身份證 / 護照 / 駕駛執照</p>
                <p className="text-black7 text-sm mb-6">使用政府簽發且帶照片的有效身分證件。<br />
                  居留證亦是有效的身份證件。</p>


                <div className="relative h-[300px] rounded-md  border-solid border border-main3 flex  shadow-md  overflow-hidden mb-6">
                  {
                    compressedFile ?
                      <img src={compressedFile} className=" relative  w-full h-full object-contain" />
                      : ""

                  }
                  {
                    !compressedFile ? <div className="m-auto ">
                      <AddImg className="mx-auto text-main9 mb-2 w-20 h-20 object-contain" />
                      <p className="text-main9 font-bold text-center text-xl">正面</p></div>
                      : ""

                  }
                  <input

                    className="hidden"
                    id="inputimg1"
                    accept="image/png, image/jpeg, image/jpg"
                    type="file"
                    onChange={(event) => handleCompressedUpload(event, 1)}
                  />

                  <div
                    type="button"
                    className="absolute top-0 left-0 w-full h-full bg-text cursor-pointer"
                    onClick={() => callInputImg(1)}
                  >
                  </div>
                </div>

                <div className="relative h-[300px] border-solid border border-main3 rounded-md   flex  shadow-md  overflow-hidden mb-6">
                  {
                    compressedFile2 ?
                      <img src={compressedFile2} className=" relative  w-full h-full object-contain" />
                      : ""

                  }
                  {
                    !compressedFile2 ? <div className="m-auto ">
                      <AddImg className="mx-auto text-main9 mb-2 w-20 h-20 object-contain" />
                      <p className="text-main9 font-bold text-center text-xl">背面</p></div>
                      : ""

                  }
                  <input

                    className="hidden"
                    id="inputimg2"
                    accept="image/png, image/jpeg, image/jpg"
                    type="file"
                    onChange={(event) => handleCompressedUpload(event, 2)}
                  />

                  <div
                    type="button"
                    className="absolute top-0 left-0 w-full h-full bg-text cursor-pointer"
                    onClick={() => callInputImg(2)}
                  >
                  </div>
                </div>



                <div className="mb-2 text-xs text-black9 flex gap-2">
                  <input required type="checkbox" onChange={() => setReadPolicy(!readPolicy)} />
                  <div>同意<a href={window.location.origin + "/onlinegallery/terms?type=0"} target="_blank" className="text-main8">條款及細則</a>。</div>
                </div>

                <button type="submit" className="w-full btn1 mb-2">下一步</button>
                <button onClick={() => setStep(step - 1)} className="w-full btn2">返回</button>

              </form>
              : ""
          }
          {
            step == 2 ?
              <div>
                <p className="mb-6  text-center text-black9 text-xl font-bold">驗證電話號碼</p>

                <p className="text-black9 font-bold">電話號碼</p>
                <div className="flex items-center text-black9 gap-2 mb-2">
                  {/* <select defaultValue={""} onChange={(e) => setArea(e.target.value)} className=" input  relative    w-20   text-lg ">
                    <option value="" disabled >區碼</option>
                    <option>86</option>
                    <option>852</option>
                    <option>853</option>
                    <option>886</option>
                  </select> */}
                  +886
                  <input maxLength={10} value={phone} onChange={e => setPhone(e.target.value)} type="number" className=" w-full  text-lg" />
                </div>

                {
                  sendOTP === true ?
                    <button disabled={true} className="w-full btn1 !bg-black6  mb-2 !rounded-[10px]">已送出驗證碼 ({countdown})</button>
                    :
                    <button onClick={() => sendOTPAction()} className="w-full btn1 mb-2 !rounded-[10px]">取得驗證碼</button>
                }


                {
                  sendOTP !== null ?
                    <div>
                      <p className="text-black9 font-bold">驗證碼</p>
                      <input value={OTP} onChange={e => setOTP(e.target.value)} type="text" className="mb-6  w-full  text-lg" />


                      <button onClick={() => signUpAction()} className="w-full btn1 mb-2">送出</button>

                    </div>
                    : ""
                }
                <button onClick={() => setStep(step - 1)} className="w-full btn2">返回</button>
              </div>

              : ""

          }
          {
            step == 3 ?
              <div>
                <p className="mb-6  text-center text-black9 text-xl font-bold">完成註冊</p>
                <img className="w-16 object-contain mb-6 mx-auto" src={successImg} />
                <p className="text-black8 text-sm text-center mb-4">
                  系統已收到您的註冊資料，將由人工審核約7個工作日
                  會員註冊開通後，會將通知信件傳至您註冊填寫的電子郵件與手機簡訊告知<br />
                  屆時即可使用天堯藝術網多項服務與功能！
                </p>
                <button onClick={() => navigate('/')} className="w-full btn2 mb-2">前往首頁</button>
              </div>

              : ""

          }



        </div>



      </div>
    </div>







  </div >

}

export default Home