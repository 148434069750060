

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";

import Line1 from '../../../compontents/Line1.js'
import Swiper from '../../../compontents/Swiper.js'
import TitleName from '../../../compontents/TitleName.js'
import TitleName2 from '../../../compontents/TitleName2.js'
import SeeMore from '../../../compontents/SeeMore.js'

import Btn from '../../../compontents/Btn.js'

import circlebgImg from '../../../assets/home/circlebg.png'
import circle11Img from '../../../assets/home/circle11.png'
import circle22Img from '../../../assets/home/circle22.png'
import circle33Img from '../../../assets/home/circle33.png'
import crossImg from '../../../assets/home/cross.png'

import pic1Img from '../../../assets/1.png'
import pic2Img from '../../../assets/2.png'
import pic3Img from '../../../assets/3.png'
import pic4Img from '../../../assets/4.jpg'

import hallbgImg from '../../../assets/hallbg.png'

import moreArrowRightImg from '../../../assets/moreArrowRight.png'



import linesImg from '../../../assets/lines.png'
import lines2Img from '../../../assets/home/lines2.png'

import swipe1Img from '../../../assets/swipe1.png'
import swipe2Img from '../../../assets/swipe2.png'
import swipe3Img from '../../../assets/swipe3.jpg'
import PathLocation from "../../../compontents/PathLocation.js";
import UserApi from "../../../services/UserApi.jsx";
import { useGlobalFunc } from "../../../global/constants.js";
import { Pagination } from "@mui/material";
import CountDownTimer from "../../../compontents/CountDownTimer.js";



// import homeshadowImg from '../../../assets/homeshadow.png'
// import homescrollImg from '../../../assets/homescroll.png'

// import homepic1Img from '../../../assets/homepic1.png'
// import homepic2Img from '../../../assets/homepic2.png'


const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search);

    const { setLoading, successToast, errorToast } = useGlobalFunc()

    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
        getDataList(value)
    };


    const [dataList, setDataList] = useState(null)
    const [pastDataList, setPastDataList] = useState(null)




    async function getDataList(page) {
        try {
            let res = await UserApi.productAuctionSessionsSearch({ page: 1, status: true })
            console.log(res.data.data)
            setDataList(res.data.data)
            //setTotalPage(res.data.total == 0 ? 1 : Math.ceil(res.data.total / 10))

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        //setLoading(false)

    }

    async function getPastDataList(page) {
        //setLoading(true)
        try {
            let res = await UserApi.productAuctionSessionsSearch({ page: page, status: false })
            console.log(res.data.data)
            setPastDataList(res.data.data)
            setTotalPage(res.data.total == 0 ? 1 : Math.ceil(res.data.total / 10))

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        //setLoading(false)

    }

    useEffect(() => {
        getDataList(1)
        getPastDataList(1)
    }, []);


    return <div className="session1 relative pt-[40px] pb-20 bg-white" >

        <PathLocation />

        <TitleName title1="Online Auction" title2="藝品線上拍賣" />

        {dataList != null && dataList.map(i => <div className="w-full  md:grid grid-cols-5  gap-x-[50px] pt-20 pb-10">
            <img src={i.logo} className="mb-4  w-full aspect-[16/9] object-cover col-span-3 border-solid bg-sec4 border-sec4 border-4 rounded-[20px]" />
            <div className="col-span-2 text-black9 min-h-full flex flex-col justify-between">
                <div>
                    <p className="mb-1">{t('sessionId')}：{i.numberId}</p>
                    <p className="mb-4 text-2xl font-semibold">{i.name}</p>
                    {
                        i.themes.length > 0 ?
                            <div className="mb-14">
                                <p className="mb-1 font-medium">{i.themes[0].startDate}</p>
                                <p >{t('isOnlineGallery')}</p>
                            </div>
                            : ""
                    }
                    <p className="mb-14 hidden">Lot. 8001-9245</p>
                    {
                        i.themes.length > 0 ?
                            <CountDownTimer endDate={i.themes[0].endDate} />
                            : ""
                    }
                    <div className="text-center mb-4 w-full py-3 font-semibold   border-sec4 border-solid border-2 text-main9 rounded-full hidden" >距離結束 6 天 23 時 58 分 59 秒</div>
                    <Link to={`theme?sessionsId=${i.id}`}>
                        <button className="mb-2 w-full py-3 font-semibold  text-main9 rounded-full" style={{ "background": "linear-gradient(90deg, #CA9849 -0.08%, #FFF4AF 85.1%)" }}>參與競拍</button>
                    </Link>
                    <div className="flex">
                        <button className=" text-sm text-black6 underline " onClick={()=>navigate('/artauction/online/rules')}>藝品線上拍賣規則</button>
                    </div>
                </div>

            </div >
        </div >
        )
        }

        <TitleName title1="Past Auctions" title2="過往拍賣" />

        {pastDataList && pastDataList.map(i => <div className="px-[10px] md:px-[60px]">
            <div className="w-full  md:grid grid-cols-5 items-center gap-x-[50px] pt-20 pb-10">
                <img src={i.logo} className=" mb-4 w-full aspect-[16/9] object-cover  col-span-3 border-solid bg-sec4 border-sec4 border-4 rounded-[20px]" />
                <div className="col-span-2 text-black9 h-full flex flex-col justify-between">
                    <div>
                        <p className="mb-1">{t('sessionId')}：{i.numberId}</p>
                        <p className="mb-4 text-2xl font-semibold"> {i.name}</p>
                        <p className=" text-sm font-semibold text-red-500">已完結</p>
                     
                    </div>
                    {/* <SeeMore to="past" /> */}
                </div >
            </div >
            <div className="relative w-full Line1 h-[2px] my-4" ></div>
        </div>
        )}

        {
            pastDataList ?
                <>
                    {
                        pastDataList.length == 0 ?
                            <div className="  text-center text-gray-400 text-lg w-full font-bold mb-20 ">
                                <div>暫無紀錄</div>
                            </div> : ""

                    }

                    <div className="flex w-full  mt-2 mb-10">
                        <div className="mx-auto">
                            <Pagination count={totalPage} page={page} onChange={handleChange} />
                        </div>
                    </div>
                </>
                : ""
        }




    </div >
}

export default Home