
import { useTranslation } from "react-i18next";


import arrowRightWLImg from '../assets/arrowRightWL.png'

const Btn = ({title, onClick }) => {
  const { t } = useTranslation();



  return (
    <button onClick={onClick} className="flex justify-between items-center px-9 w-full py-4 text-base font-semibold  text-white rounded-tr-[1.75em] rounded-bl-[1.75em] bg-[#222968] hover:bg-[#333B9D] anim ">{title}
      <img className=" w-[16px]" src={arrowRightWLImg} /></button>

  )
}
export default Btn