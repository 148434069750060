

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";



import playImg from '../../../assets/play.png'

import ReactPlayer from 'react-player'

import { useGlobalFunc, time2date } from "../../../global/constants.js";
import { Pagination } from "@mui/material";
import UserApi from "../../../services/UserApi.jsx";
import moment from 'moment'
import PathLocation from "../../../compontents/PathLocation";
import AddFavorite from "../../../compontents/AddFavorite";

const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search);


    const { setLoading, successToast, errorToast } = useGlobalFunc()

    const [data, setData] = useState(null)




    async function getData(id) {
        setLoading(true)
        try {
            let res = await UserApi.artVideoGet({ id: id })
            console.log(res.data.data)
            setData(res.data.data)

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)

    }

    useEffect(() => {
        let id = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        getData(id)
    }, []);


    return <div className="w-full">

        <div className="session1 text-black9  relative pt-[40px] pb-20 " >

            <PathLocation />



            {
                data != null ?
                    <div className=" flex flex-col gap-3 mt-8 mb-12 text-black9 text-base items-start  ">
                        <div className=" relative w-full  mb-4 aspect-[16/9] flex-none bg-black91">
                            <img className=" absolute w-14 h-14 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden" src={playImg} />

                            <ReactPlayer width="100%" height="100%" controls="true" url={data.link} />

                        </div>

                        <div className="flex flex-col">
                            <div className="flex justify-between">
                                <div className="">
                                    <div className=" text-xl  mb-1 font-bold">
                                        {data.name}
                                    </div>
                                    <div className="text-sm text-gray1 mb-6">{time2date(data.createdAt)}｜{data.author}</div>
                                </div>
                                <AddFavorite color="text-black" hoverColor="hover:text-sec6" data={{ itemId: data.id, type: "artVideo" }} />
                            </div>
                            <div className="text-base" dangerouslySetInnerHTML={{ "__html": data.content }} />
                        </div>
                    </div>
                    : ""
            }




        </div>


        {
            data ?
                <>
                    {
                        data.length == 0 ?
                            <div className="  text-center text-gray-400 text-lg w-full font-bold mb-20 ">
                                <div>暫無紀錄</div>
                            </div> : ""

                    }


                </>
                : ""
        }




    </div >
}

export default Home