

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";

import Line1 from '../../compontents/Line1.js'
import Line2 from '../../compontents/Line2.js'
import Swiper from '../../compontents/Swiper.js'
import TitleName from '../../compontents/TitleName.js'
import TitleName2 from '../../compontents/TitleName2.js'
import SeeMore from '../../compontents/SeeMore.js'

import Btn from '../../compontents/Btn.js'

import successImg from '../../assets/success.png'
import docImg from '../../assets/doc.png'


import ReactPlayer from 'react-player'

import { useGlobalFunc, time2date, separatorNumber } from "../../global/constants.js";
import { Pagination } from "@mui/material";
import UserApi from "../../services/UserApi.jsx";
import moment from 'moment'
import { useSelector } from "react-redux";
import SelectImgDialog from "../../compontents/SelectImgDialog.js";


const Home = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const userInfo = useSelector(state => state.userInfo);

    const [totalPrice, setTotalPrice] = useState(0)




    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');



    const handleClickOpen = (value) => {
        setSelectedValue(value)
        setOpen(true);
    };

    const handleClose = (value, type) => {
        setOpen(false);
        updateData(type, value)


    };

    const [itemId, setItemId] = useState('')



    const [payType, setPayType] = useState(null)
    const [sameOrderInfo, setSameOrderInfo] = useState(false)


    const [data, setData] = useState(
        {
            "ordererName": "",
            "ordererPhone": "",
            "email": "",
            "recipientName": "",
            "recipientPhone": "",
            "recipientAddress": "",
        }
    )

    const updateData = (i, value) => {
        setData(prevData => ({ ...prevData, [i]: value }));
    };



    function nextStep() {

        if (step == 0 && payType == null) {
            errorToast('請選擇付款方式')
            return
        }
        // if (step == 0 && payType === 1 && data.img5 == null) {
        //     errorToast('請上傳匯款證明圖片')
        //     return
        // }
        if (step == 1 && (data.recipientName == "" || data.recipientPhone == "" || data.recipientAddress == "")) {
            errorToast('請填寫以上資訊')
            return

        }
        if (step == 1 && sameOrderInfo) {
            setData({
                ...data, "recipientName": data.ordererName,
                "recipientPhone": data.ordererPhone
            })
        }

        setStep(step + 1)
    }

    const { setLoading, successToast, errorToast } = useGlobalFunc()

    const [step, setStep] = useState(0);


    const [dataList, setDataList] = useState(null)


    async function getDataList(id) {
        setLoading(true)
        try {
            let res = await UserApi.productAuctionGet({ id: id })
            console.log(res.data.data)
            setDataList([res.data.data.product])
            //setTotalPrice(res.data.data.totalPrice)
        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)
    }



    async function orderPay() {

        // let tempProducts = []
        // dataList.forEach(i => {
        //     tempProducts.push({ itemId: itemId })

        // });
        // console.log(tempProducts)


        setLoading(true)
        try {
            let res = await UserApi.orderPay({
                "type": "auction",
                "payMethod": payType === 1 ? "bankTransfer" : "offline",
                "contactInfo": {
                    "ordererName": data.ordererName,
                    "ordererPhone": data.ordererPhone,
                    "email": data.email,
                    "recipientName": data.recipientName,
                    "recipientPhone": data.recipientPhone,
                    "recipientAddress": data.recipientAddress,
                },
                "products": [{ itemId: Number(itemId) }]
            })
            console.log(res.data)


            setStep(step + 1)

            //document.open();
            //document.write(res.data)
            //successToast(t('removeCart Success'))
            //getDataList()
            //nextStep()
        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
            setLoading(false)
        }
        setLoading(false)

    }

    useEffect(() => {


    }, []);

    useEffect(() => {
        window.scroll(0, 0)
    }, [step]);

    const isFisrt = useRef(true)
    useEffect(() => {
        console.log(userInfo)
        if (userInfo && isFisrt.current) {

            const id = query.get('id')
            if (id != null) {
                setItemId(id)
                getDataList(id)
            }
            else {
                navigate('/404')
            }


            isFisrt.current = false
            setData({
                "ordererName": userInfo.actualName,
                "ordererPhone": userInfo.phone,
                "email": userInfo.email,
            })

        }
    }, [userInfo]);



    return <div className="session1 relative pt-[40px] pb-20 bg-white" >


        <div className="flex justify-center items-center">
            {
                ["付款方式", "填寫資料", "確認訂單", "確認訂單"].map((i, index) => <div className="flex items-center">
                    <div>

                        <div className={`${step >= index ? 'bg-main9' : ''} p-0.5 w-10 h-10 rounded-full mx-auto mb-1`}>
                            <div className={`${step >= index ? 'bg-main9' : 'bg-black5'} flex  border-solid border-white border-2 w-full h-full rounded-full`}>
                                <div className="m-auto text-white font-bold">{index + 1}</div>
                            </div>
                        </div>
                        <p className={`${step >= index ? 'text-main9' : 'text-black6'}  text-xs text-center whitespace-nowrap`}>{i}</p>
                    </div>

                    {
                        index < 3 ?
                            <div className={`${step > index ? 'bg-main9' : 'bg-black5'}  w-8 h-0.5 mb-4`}>
                            </div> : ""
                    }

                </div>)

            }

        </div>

        {
            step == 0 && <div>
                <div className="mt-10 mb-10">
                    <p className="text-xl text-black9 font-bold mb-4">得標項目</p>

                    <table className="w-full text-sm mb-4" >
                        <thead className="">

                            <tr className="  text-main6 font-semibold ">
                                <th className="">藝術品</th>
                                <th >藝術品名稱</th>
                                <th className="">價格</th>



                            </tr>
                        </thead>

                        {
                            dataList != null ?
                                <tbody>

                                    {
                                        dataList.map(i => <tr className=" text-black9 font-semibold ">
                                            <td className=""><img className="w-20 h-20 object-cover rounded-lg" src={i.images[0].image} /></td>
                                            <td>{i.collectionName}</td>
                                            <td className="">
                                                {separatorNumber(i.currentPrice)}

                                            </td>



                                        </tr>
                                        )
                                    }


                                </tbody>
                                : ""}
                    </table>
                    <div className="w-full h-0.5 bg-sec4"></div>

                </div>

                <div className="mb-10">
                    <p className="text-xl text-black9 font-bold mb-4">請選擇付款方式</p>
                    <div className="w-full h-0.5 bg-sec4"></div>
                    <div className="flex gap-4 sm:gap-6 flex-col sm:flex-row py-8 items-start">
                        <button onClick={() => setPayType(1)} className={`${payType === 1 ? "bg-main9 text-white border-transparent" : " border-black8 text-black8"} border border-solid rounded-full w-60 py-2 text-sm  font-bold flex-none`}>匯款</button>
                        <div className="text-black8 text-sm w-full">
                            <p className="font-bold">匯款帳號</p>
                            <p>臺灣銀行 北高雄分行</p>
                            <p>天堯科技文化股份有限公司</p>
                            <p className="mb-2">255001014079</p>
                            {
                                false &&
                                <div className="relative w-full">

                                    <p className="font-bold">匯款證明圖片</p>
                                    <div onClick={() => handleClickOpen('img5')} className="relative cursor-pointer w-full sm:w-60 aspect-[1/1]  border border-main2 border-solid flex justify-center items-center text-black8 text-xl font-bold text-center"><div>
                                        <img className="w-10 h-10 object-contain mb-4 mx-auto" src={docImg} />
                                        <img className=" w-full h-full absolute left-0 top-0 object-contain" src={data.img5} />
                                    </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>

                    <div className=" w-full h-0.5 bg-sec4"></div>
                    <div className=" flex gap-4 sm:gap-6 flex-col sm:flex-row py-8 items-start">
                        <button onClick={() => setPayType(2)} className={`${payType === 2 ? "bg-main9 text-white border-transparent" : " border-black8 text-black8"} border border-solid rounded-full w-60 py-2 text-sm  font-bold flex-none`}>門市</button>
                        <div className="text-black8 text-sm">
                            天堯科技文化股份有限公司門市
                        </div>
                    </div>

                    <div className="w-full h-0.5 bg-sec4"></div>
                </div>
                <div className="flex justify-end mt-8">
                    <button onClick={() => nextStep()} className="btn1 !min-w-[140px] ">前往結帳</button>
                </div>

            </div>

        }
        {
            step == 1 && <div className="py-12">
                <div className="mb-4 mt-8 flex items-center gap-4 flex-wrap">
                    <p className="text-xl text-black9 font-bold ">得標項目</p>

                </div>

                <div className="px-6 py-12 bg-white border-solid border-4 border-sec4 rounded-[20px] relative  w-full ">
                    <div className="flex text-black9 break-all w-full sm:max-w-[500px] justify-center mx-auto ">
                        <div className="w-full">
                            <div className="mb-6 w-full flex  items-center gap-2">
                                <p className="flex-none min-w-[120px]">得標人姓名</p>
                                <input className="w-full" onChange={(e) => updateData('ordererName', e.target.value)} value={data.ordererName} />
                            </div>
                            <div className="mb-6 w-full flex  items-center gap-2">
                                <p className="flex-none min-w-[120px]">聯絡電話</p>
                                <input className="w-full" onChange={(e) => updateData('ordererPhone', e.target.value)} value={data.ordererPhone} />
                            </div>
                            <div className=" w-full flex  items-center gap-2">
                                <p className="flex-none min-w-[120px]">電子郵件</p>
                                <input className="w-full" onChange={(e) => updateData('email', e.target.value)} value={data.email} />
                            </div>

                        </div>
                    </div>

                </div>

                <div className="mb-4 mt-8 flex items-center gap-4 flex-wrap">
                    <p className="text-xl text-black9 font-bold ">收件人資訊</p>
                    <button onClick={() => setSameOrderInfo(!sameOrderInfo)} className={`${sameOrderInfo ? "bg-black9 text-white" : "border-solid  border-black5 text-black5"} px-8 py-[10px] text-sm border rounded-full`}>同得標人資訊</button>
                </div>
                <div className="px-6 py-12 bg-white border-solid border-4 border-sec4 rounded-[20px] relative  w-full ">
                    <div className="flex text-black9 break-all w-full sm:max-w-[500px] justify-center mx-auto ">
                        <div className="w-full">
                            <div className="mb-6 w-full flex  items-center gap-2">
                                <p className="flex-none min-w-[120px]">收件人姓名</p>
                                {
                                    !sameOrderInfo ?
                                        <input className="w-full" onChange={(e) => updateData('recipientName', e.target.value)} value={data.recipientName} />
                                        :
                                        <div className="py-2">
                                            {data.ordererName}
                                        </div>
                                }
                            </div>
                            <div className="mb-6 w-full flex  items-center gap-2">
                                <p className="flex-none min-w-[120px]">聯絡電話</p>
                                {
                                    !sameOrderInfo ?
                                        <input className="w-full" onChange={(e) => updateData('recipientPhone', e.target.value)} value={data.recipientPhone} />
                                        :
                                        <div className="py-2">
                                            {data.ordererPhone}
                                        </div>
                                }

                            </div>
                            <div className=" w-full flex  items-center gap-2">
                                <p className="flex-none min-w-[120px]">收件地址</p>
                                <input className="w-full" onChange={(e) => updateData('recipientAddress', e.target.value)} value={data.recipientAddress} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex justify-between mt-8">
                    <button onClick={() => setStep(step - 1)} className="flex items-center text-black6 font-bold gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32" fill="none">
                            <path d="M21.957 3.70593C22.2526 4.04322 22.4004 4.44256 22.4004 4.90397C22.4004 5.36448 22.2526 5.76337 21.957 6.10066L13.2955 15.9831L21.9865 25.8993C22.2624 26.2141 22.4004 26.6076 22.4004 27.0798C22.4004 27.552 22.2526 27.9568 21.957 28.2941C21.6614 28.6314 21.3114 28.8 20.907 28.8C20.5033 28.8 20.1537 28.6314 19.8581 28.2941L9.92556 16.9275C9.80732 16.7926 9.72337 16.6465 9.6737 16.4891C9.62483 16.3317 9.60039 16.163 9.60039 15.9831C9.60039 15.8033 9.62483 15.6346 9.6737 15.4772C9.72337 15.3198 9.80732 15.1737 9.92556 15.0387L19.8877 3.6722C20.1636 3.3574 20.5034 3.2 20.907 3.2C21.3114 3.2 21.6614 3.36864 21.957 3.70593Z" fill="currentColor" stroke="currentColor" />
                        </svg>
                        返回
                    </button>
                    <button onClick={() => nextStep()} className="btn1 !min-w-[140px] ">確認資訊</button>
                </div>
            </div>
        }

        {
            step == 2 && <div className="py-12">


                <div className="mt-10 mb-10">
                    <p className="text-xl text-black9 font-bold mb-4">得標項目</p>

                    <table className="w-full text-sm mb-4" >
                        <thead className="">

                            <tr className="  text-main6 font-semibold ">
                                <th className="">藝術品</th>
                                <th className="">藝術品名稱</th>
                                <th className="">價格</th>


                            </tr>
                        </thead>

                        {
                            dataList != null ?
                                <tbody>
                                    {
                                        dataList.map(i => <tr className=" text-black9 font-semibold ">
                                            <td className=""><img className="w-20 h-20 object-cover rounded-lg" src={i.images[0].image} /></td>
                                            <td>{i.collectionName}</td>
                                            <td className="">
                                                {separatorNumber(i.currentPrice)}

                                            </td>



                                        </tr>
                                        )
                                    }
                                </tbody>
                                : ""}
                    </table>
                    <div className="w-full h-0.5 bg-sec4"></div>

                </div>
                {
                    false&&
                    <div className="mb-10">
                        <p className="text-xl text-black9 font-bold mb-4">收件資訊確認</p>
                        <div className="w-full h-0.5 bg-sec4"></div>

                        <p className="font-bold text-black7 mb-1 mt-4">匯款證明圖片</p>
                        <div className="relative  w-full sm:w-60 aspect-[1/1]  border border-main2 border-solid flex justify-center items-center text-black8 text-xl font-bold text-center"><div>
                            <img className=" w-full h-full absolute left-0 top-0 object-contain" src={data.img5} />
                        </div>
                        </div>
                    </div>
                }

                <div className="mb-10">
                    <p className="text-xl text-black9 font-bold mb-4">收件資訊確認</p>
                    <div className="w-full h-0.5 bg-sec4"></div>
                    <div className="flex text-black9 break-all w-full sm:max-w-[500px] justify-center mx-auto py-14">
                        <div className="w-full">
                            <div className="mb-6 w-full flex  items-center gap-2">
                                <p className="flex-none min-w-[120px] text-black6 font-bold">收件人姓名</p>
                                <p className="w-full">{data.recipientName}</p>
                            </div>
                            <div className="mb-6 w-full flex  items-center gap-2">
                                <p className="flex-none min-w-[120px] text-black6 font-bold">聯絡電話</p>
                                <p className="w-full">{data.recipientPhone}</p>
                            </div>
                            <div className=" w-full flex  items-center gap-2">
                                <p className="flex-none min-w-[120px] text-black6 font-bold">收件地址</p>
                                <p className="w-full">{data.recipientAddress}</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-0.5 bg-sec4"></div>
                </div>
                <div className="flex justify-between mt-8">
                    <button onClick={() => setStep(step - 1)} className="flex items-center text-black6 font-bold gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32" fill="none">
                            <path d="M21.957 3.70593C22.2526 4.04322 22.4004 4.44256 22.4004 4.90397C22.4004 5.36448 22.2526 5.76337 21.957 6.10066L13.2955 15.9831L21.9865 25.8993C22.2624 26.2141 22.4004 26.6076 22.4004 27.0798C22.4004 27.552 22.2526 27.9568 21.957 28.2941C21.6614 28.6314 21.3114 28.8 20.907 28.8C20.5033 28.8 20.1537 28.6314 19.8581 28.2941L9.92556 16.9275C9.80732 16.7926 9.72337 16.6465 9.6737 16.4891C9.62483 16.3317 9.60039 16.163 9.60039 15.9831C9.60039 15.8033 9.62483 15.6346 9.6737 15.4772C9.72337 15.3198 9.80732 15.1737 9.92556 15.0387L19.8877 3.6722C20.1636 3.3574 20.5034 3.2 20.907 3.2C21.3114 3.2 21.6614 3.36864 21.957 3.70593Z" fill="currentColor" stroke="currentColor" />
                        </svg>
                        修改收件資訊
                    </button>
                    <button onClick={() => orderPay()} className="btn1 !min-w-[140px] ">確認結帳</button>
                </div>



            </div>

        }

        {
            step == 3 && <div className="py-12">
                <div className="w-full h-0.5 bg-sec4"></div>
                <div className="py-12 w-full">

                    <img className="w-16 object-contain mb-6 mx-auto" src={successImg} />
                    <p className="mb-6  text-center text-black9 text-xl font-bold">訂單已送出，感謝您的訂購！</p>
                    <p className="text-black7 text-sm text-center mb-8">
                        您可以在「會員中心」中查看處理進度。<br />
                        如有任何疑問也歡迎隨時與我們聯繫<br />
                        客服專線： 將由專人為您服務
                    </p>
                    <div className="flex justify-center">
                        <button onClick={() => navigate('/')} className=" btn2 !min-w-[120px]">前往首頁</button>
                    </div>
                </div>
                <div className="w-full h-0.5 bg-sec4"></div>
            </div>
        }

        <SelectImgDialog
            type={selectedValue}
            open={open}
            onClose={handleClose}
        />
    </div >
}

export default Home