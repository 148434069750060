

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";
import UserApi from "../../../../services/UserApi";
import { separatorNumber, useGlobalFunc } from "../../../../global/constants";
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';

import closeImg from "../../../../assets/close.png"
import { useSelector } from "react-redux";

function UpdateBankDialog(props) {
    const { onClose, selectedValue, open, id } = props;
    const userInfo = useSelector(state => state.userInfo);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('768'));


    const [bankTitle, setBankTitle] = useState("");
    const [bankNum, setBankNum] = useState("");

    const handleClose = () => {
        onClose(selectedValue);
        setBankTitle("")
        setBankNum("")

    };

    const handleClickOpen = (value) => {
        onClose(value);
    };


    const { setLoading, successToast, errorToast, updateMe, alertDialog } = useGlobalFunc()

    function sendAction() {
        updateBank()
        handleClose()

    }

    async function updateBank() {
        setLoading(true)
        try {
            let res = await UserApi.updateMe({ depositAccountBank: bankTitle, depositAccountNumber: bankNum })
            updateMe()
            successToast('更換入款銀行帳戶成功')

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)

    }


    return (
        <Dialog maxWidth="2000" PaperProps={{ sx: { bgcolor: "transparent" } }} fullScreen={fullScreen} onClose={handleClose} open={open} >
            <div className="w-full h-full" onClick={handleClose}></div>
            <div className="md:w-[510px] text-white absolute md:relative bottom-0 left-0 w-full bg-white  p-[12px] md:p-[28px] pt-[17px] rounded-t-md">
                <div className="relative py-7">

                    <div className="text-black absolute top-0 right-0 text-base font-medium"  >
                        <img src={closeImg} className="w-6 h-6 object-contain cursor-pointer" alt="" onClick={handleClose} />
                    </div>
                    <p className="text-lg md:text-2xl text-main9 mb-[37px] font-bold text-center ">更換入款銀行帳戶</p>


                    <p className="text-black9 font-bold">入款帳戶銀行</p>
                    <input required value={bankTitle} onChange={e => setBankTitle(e.target.value)} type="text" className="mb-6  w-full  text-lg" />

                    <p className="text-black9 font-bold">入款帳戶號碼</p>
                    <input required value={bankNum} onChange={e => setBankNum(e.target.value)} type="text" className="mb-6  w-full  text-lg" />




                    <div className="mt-8 flex justify-center">
                        <button onClick={() => sendAction()} className="min-w-[140px] bg-main6 px-6 py-3 rounded-full font-semibold text-sm text-white">確定</button>
                    </div>
                </div>

            </div>
        </Dialog>
    );
}


const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const userInfo = useSelector(state => state.userInfo);

    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');

    const handleClickOpen = (value) => {
        setSelectedValue(value)
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
    };




    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // 注意：JavaScript 的月份是從 0 開始的，所以需要 +1
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);

    const yearOptions = [];

    for (let year = 2023; year <= currentYear; year++) {
        yearOptions.push(
            <option key={year} value={year}>
                {year}
            </option>
        );
    }
    yearOptions.reverse()

    const monthOptions = [];
    for (let month = 1; month <= 12; month++) {
        if (selectedYear === currentYear && month > currentMonth) {
            continue; // 如果是當前年份且月份已過，則跳過
        }
        monthOptions.push(
            <option key={month} value={month}>
                {month}
            </option>
        );
    }

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
        setSelectedMonth(1); // 當年份變化時，將月份重置為 1
        getSettlementRecord(event.target.value, 1)
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
        getSettlementRecord(selectedYear, event.target.value)
    };


    const { setLoading, successToast, errorToast } = useGlobalFunc()


    const [data, setData] = useState(null)
    const [dataList, setDataList] = useState([])


    async function getSettlementRecord(year, month) {
        setLoading(true)
        let tempMonth = month < 10 ? `0${month}` : month
        console.log(String(year) + tempMonth)
        try {
            let res = await UserApi.settlementRecord({ dateStr: String(year) + tempMonth })
            console.log(res.data.data)
            if (!(Object.keys(res.data.data).length === 0 && res.data.data.constructor === Object)) {
                console.log('asda')
                setData(res.data.data)

                let tempProduct = []
                for (let i = 0; i < res.data.data.orders.length; i++) {
                    for (let j = 0; j < res.data.data.orders[i].products.length; j++) {
                        tempProduct.push(res.data.data.orders[i].products[j])
                    }
                }
                setDataList(tempProduct)
            }
            else {
                setData(null)
                setDataList([])
            }

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)
    }

    useEffect(() => {
        console.log(selectedYear, selectedMonth)
        getSettlementRecord(selectedYear, selectedMonth)
    }, []);


    return <div className="mt-8">
        <div className="text-black9 font-bold mb-4 flex items-center gap-2">
            <p className="flex-none">選擇時間</p>
            <select className="input" id="year" value={selectedYear} onChange={handleYearChange}>
                {yearOptions}
            </select>
            <select className="input" id="month" value={selectedMonth} onChange={handleMonthChange}>
                {monthOptions}
            </select>
        </div>
        {
            true &&
            <div className="w-full">
                <div className="mt-8 px-6 py-3 bg-white border-solid border-4 border-main3 rounded-[20px] relative  w-full overflow-x-auto whitespace-nowrap">

                    <table className="w-full text-sm" >
                        <thead className="">

                            <tr className="  text-main6 font-semibold">
                                <th className="">圖片</th>
                                <th className="">名稱/藝術品ID</th>
                                <th className="">售出時間</th>
                                <th className="">定價/拍賣</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                dataList && dataList.map(i => <tr key={i.id} className=" text-black9 font-semibold  ">
                                    {
                                        i.OnlineAuctionProduct ?
                                            <>
                                                <td className=""><img src={i.images.OnlineAuctionProduct.length > 0 && i.OnlineAuctionProduct.images[0].image} className="w-10 h-10 object-cover" /></td>
                                                <td className=""><p>{i.OnlineAuctionProduct.collectionName}</p>
                                                    <p className=" text-gray1">{i.OnlineAuctionProduct.id}</p>
                                                </td>
                                                <td>NT${separatorNumber(i.OnlineAuctionProduct.winnerPrice)}</td>
                                                <td>拍賣</td>
                                            </>
                                            :
                                            <>
                                                <td className=""><img src={i.PricedProduct.images.length > 0 && i.PricedProduct.images[0].image} className="w-10 h-10 object-cover" /></td>
                                                <td className=""><p>{i.PricedProduct.collectionName}</p>
                                                    <p className=" text-gray1">{i.PricedProduct.id}</p>
                                                </td>
                                                <td>NT${separatorNumber(i.PricedProduct.salePrice)}</td>
                                                <td>定價</td>
                                            </>
                                    }

                                </tr>
                                )
                            }


                        </tbody>
                    </table>
                </div>

                <div className="mt-10 text-black9">
                    <p className="mb-4">將於每個月25號結算，並於次月10號撥款</p>
                    <div className="flex justify-between flex-wrap ">
                        <p>當月售出總額： NT${data !== null ? separatorNumber(data.totalAmount) : 0}</p>
                        {
                            data !== null &&
                            <p>{data.status ? "已撥款" : "未撥款"}</p>
                        }
                    </div>

                    <div className="w-full h-0.5 bg-main3 my-8"></div>

                    <p className="mb-4">入款帳戶銀行： {userInfo.depositAccountBank ? userInfo.depositAccountBank : "尚未新增"}</p>
                    <p>入款帳戶號碼： {userInfo.depositAccountNumber ? userInfo.depositAccountNumber : "尚未新增"}</p>

                    <div className="mt-8 flex">
                        <button onClick={() => handleClickOpen()} className="bg-main6 px-6 py-3 rounded-full font-semibold text-sm text-white">更換入款銀行帳戶</button>
                    </div>

                </div>
            </div>
        }

        {
            open != null ?
                <UpdateBankDialog
                    id={selectedValue}
                    open={open}
                    onClose={handleClose}
                /> : ""
        }


    </div>

}

export default Home