

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";


import { Pagination } from "@mui/material";
import { useGlobalFunc } from "../../../../../../global/constants";
import UserApi from "../../../../../../services/UserApi";



const Home = ({ nextStep }) => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const { setLoading, successToast, errorToast } = useGlobalFunc()

    const banner = []
    const [currBanner, setCurrBanner] = useState(0)
    const countRef = useRef()

    const [dataList, setDataList] = useState(null)

    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
        getDataList(value)
    };



    async function getDataList(page) {
        setLoading(true)
        try {
            let res = await UserApi.appraisalSearch({ page: page })
            console.log(res.data)
            setDataList(res.data.data)
            setTotalPage(res.data.total == 0 ? 1 : Math.ceil(res.data.total / 10))

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)

    }

    useEffect(() => {
        getDataList(page)
    }, []);


    return <div>

        <div className="flex justify-end">
            <button onClick={() => nextStep()} className="bg-main6 px-6 py-3 rounded-full  font-semibold text-sm text-white">我要申請</button>
        </div>
        <div className="mt-8 px-6 py-3 bg-white border-solid border-4 border-main3 rounded-[20px] relative  w-full overflow-x-auto whitespace-nowrap">
            <table className="w-full text-sm" >
                <thead className="">

                    <tr className="  text-main6 font-semibold ">
                        <th className="">圖片</th>
                        <th className="">申請時間</th>
                        <th className="">申請單ID</th>
                        <th className="">申請狀態</th>
                        <th className="hidden">查看表單內容</th>

                    </tr>
                </thead>

                {
                    dataList != null ?
                        <tbody>

                            {
                                dataList.map(i => <tr className=" text-black9 font-semibold ">
                                    <td className=""><img src={i.images.length > 0 && i.images[0].image} className="w-10 h-10 object-cover" /></td>
                                    <td className="">{i.createdAt}</td>
                                    <td className="">{i.id}</td>
                                    <td className="">{t("apply." + i.status)}</td>
                                    <td className="hidden"><button className="bg-main6 px-6 py-3 rounded-full font-semibold text-sm text-white">查看</button></td>


                                </tr>
                                )
                            }


                        </tbody>
                        : ""}
            </table>
            {
                dataList ?
                    <>
                        {
                            dataList.length == 0 ?
                                <div className="  text-center text-gray-400 text-lg w-full font-bold mb-6 ">
                                    <div>暫無紀錄</div>
                                </div> : ""

                        }

                        <div className="flex w-full  mt-2">
                            <div className="mx-auto">
                                <Pagination count={totalPage} page={page} onChange={handleChange} />
                            </div>
                        </div>
                    </>
                    : ""
            }
        </div>
    </div>

}

export default Home

