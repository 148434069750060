

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";


import AppraisalIndex from './ApplyFunctionCompontents/Appraisal/AppraisalIndex.js'
import CommissionedIndex from './ApplyFunctionCompontents/Commissioned/CommissionedIndex.js'
import ArtistRegister from './ApplyFunctionCompontents/ArtistRegister.js'


const ApplyFunction = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);


    const banner = []
    const [currBanner, setCurrBanner] = useState(0)
    const countRef = useRef()

    const applyFuncList = ['appraisalapply', 'commissionedapply',
        // 'buyBackApply'
        , 'artistregister']

    const [applyListIndex, setApplyListIndex] = useState(0)
    const [faqs, setFAQ] = useState([


    ]);




    useEffect(() => {

    }, []);


    return <div>
        < div className=" flex flex-wrap gap-3 flex-none w-full  mb-6 py-6">

            {
                applyFuncList.map((i, index) => <div className={`  font-bold text-base `} key={i}>
                    <button onClick={() => setApplyListIndex(index)} className={`${index == applyListIndex ? "bg-main4 text-white" : "bg-main1 text-main4 "} px-4 py-2 rounded-lg`}>{t(`memberCenterType.${i}`)}
                    </button>
                </div>)
            }

        </div>

        {
            applyFuncList[applyListIndex] == 'appraisalapply' ?
                <div>
                    <AppraisalIndex />
                </div>
                : ""
        }
        {
            applyFuncList[applyListIndex] == 'commissionedapply' ?
                <div>
                    <CommissionedIndex />
                </div>
                : ""
        }
        {
            applyFuncList[applyListIndex] == 'transferNFT' ?
                <></> : ""
        }
        {
            applyFuncList[applyListIndex] == 'buyBackapply' ?
                <></> : ""
        }
        {
            applyFuncList[applyListIndex] == 'artistregister' ?
                <div>
                    <ArtistRegister />
                </div>
                : ""
        }



    </div>

}

export default ApplyFunction