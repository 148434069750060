import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay"

import "./style.css";


// import required modules
import { Autoplay, Pagination } from "swiper";
import { useNavigate } from "react-router-dom";
import UserApi from "../services/UserApi";
import { useGlobalFunc } from "../global/constants";
import { useSelector } from "react-redux";


export default function App(props) {

  const navigate = useNavigate()


  const loading = useSelector(state => state.loading);

  const { setLoading, successToast, errorToast } = useGlobalFunc()

  const [dataList, setDataList] = useState(null)

  async function getDataList() {

    try {
      let res = await UserApi.productPricedSearch({ page: 1 })
      setDataList(res.data.data)

    }
    catch (errors) {
      errorToast(errors?.response?.data?.message || errors.toString())
    }

  }

  useEffect(() => {
    getDataList()
  }, []);

  const pagination = {
    "clickable": true,

  }

  return (
    <>
      {
        dataList &&
        <Swiper

          breakpoints={{
            // when window width is >= 100px
            100: {
              slidesPerView: 1.4
            },
            // when window width is >= 968px

            1024: {
              slidesPerView: 2.5,
            },
          }}
          centeredSlides={true}

          spaceBetween={20}
          loop={true}
          loopFillGroupWithBlank={true}
          grabCursor={true}
          pagination={pagination}
          autoplay={true}
          modules={[Autoplay]}
          // modules={[Pagination]}
          className="mySwiper autoplay "
        >
          {
            dataList && dataList.map((i, index) => <SwiperSlide key={index} className="rounded-md "><div onClick={() => navigate(`/art/${i.id}`)} className="relative text-main95 rounded w-full ">

              <div className="relative flex max-w-[320px] sm:max-w-[440px] h-[380px] sm:h-[440px]   mb-12 mx-auto">
                <div className="relative p-[15%] mx-auto my-1auto max-w-full  max-h-full  flex justify-center items-center border-solid border-black border-[10px] bg-white" style={{ "boxShadow": "0px 45px 25px -23px rgba(0, 0, 0, 0.3)" }}>
                  <img className="relative !object-contain w-full h-full" src={i.images[0].image} />

                </div>
              </div>

              <p className="font-semibold mb-3 text-h3.5">{i.collectionName}</p>
              <p className="font-medium text-h4">{i.artist}</p>



            </div></SwiperSlide>)
          }



        </Swiper>

      }


    </>
  );
}
