import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import { useSelector, useDispatch } from 'react-redux';


const ActionDialog = (props) => {
  //const { onClose, selectedValue, open } = props;
  const actionDialog = useSelector(state => state.actionDialog);
  const dispatch = useDispatch()



  const handleClose = () => {
    //onClose();
    dispatch({
      type: "UPDATE_ACTIONDIALOG",
      payload: { actionDialog: { isOpen: false } }
    });
  };

  //const theme = useTheme();

  return actionDialog && actionDialog.isOpen && <Dialog
    PaperProps={{
      style: {
        width: "400px",
        margin:"12px",
        backgroundColor: 'transparent'
      },
    }}
    onClose={handleClose} open={actionDialog.isOpen}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    {
      actionDialog.isOpen &&
      <div className="bg-white rounded-xl border border-main border-solid ">
        <DialogTitle className="flex justify-between items-center gap-2 border-b border-main border-solid">
          <p className='text-base font-bold'>{actionDialog.title}</p>

          <div className=' flex-none w-8 cursor-pointer p-1' onClick={handleClose}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" fill="none">
              <path d="M7.28033 4.54L15.0003 12.26L22.6803 4.58C22.85 4.39943 23.0543 4.25499 23.2812 4.15532C23.508 4.05566 23.7526 4.00283 24.0003 4C24.5308 4 25.0395 4.21071 25.4145 4.58579C25.7896 4.96086 26.0003 5.46957 26.0003 6C26.005 6.2452 25.9595 6.48877 25.8666 6.71576C25.7738 6.94275 25.6355 7.14837 25.4603 7.32L17.6803 15L25.4603 22.78C25.79 23.1025 25.9832 23.5392 26.0003 24C26.0003 24.5304 25.7896 25.0391 25.4145 25.4142C25.0395 25.7893 24.5308 26 24.0003 26C23.7454 26.0106 23.4911 25.968 23.2536 25.8751C23.016 25.7821 22.8003 25.6408 22.6203 25.46L15.0003 17.74L7.30033 25.44C7.13134 25.6145 6.92945 25.7539 6.70633 25.85C6.4832 25.9461 6.24325 25.9971 6.00033 26C5.46989 26 4.96118 25.7893 4.58611 25.4142C4.21104 25.0391 4.00033 24.5304 4.00033 24C3.99566 23.7548 4.04116 23.5112 4.13402 23.2842C4.22687 23.0572 4.36514 22.8516 4.54033 22.68L12.3203 15L4.54033 7.22C4.2107 6.89752 4.0174 6.46082 4.00033 6C4.00033 5.46957 4.21104 4.96086 4.58611 4.58579C4.96118 4.21071 5.46989 4 6.00033 4C6.48033 4.006 6.94033 4.2 7.28033 4.54Z" fill="#666666" />
            </svg>
          </div>
        </DialogTitle>
        <DialogContent >
          <div className="  pt-8 pb-10 text-base ">
            {actionDialog.content}
          </div>

          <div className='flex  justify-center gap-2'>
            {
              actionDialog.btnFunc1 == "cancel" ?
                <button onClick={handleClose} className="btn2 rounded-full w-full">{actionDialog.btnName1}</button>
                :
                <button onClick={() => actionDialog.btnFunc1()} className="btn2 rounded-full w-full">{actionDialog.btnName1}</button>
            }

            {
              actionDialog.btnFunc2 && <button onClick={() => actionDialog.btnFunc2()} className="btn1 rounded-full w-full">{actionDialog.btnName2}</button>
            }

          </div>
        </DialogContent>
      </div>
    }
  </Dialog>

}

export default ActionDialog