

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";

import Line1 from '../../compontents/Line1.js'
import Swiper from '../../compontents/Swiper.js'
import TitleName from '../../compontents/TitleName.js'
import TitleName2 from '../../compontents/TitleName2.js'
import SeeMore from '../../compontents/SeeMore.js'

import PathLocation from "../../compontents/PathLocation.js";
import StickyList from "../../compontents/StickyList.js";
import UserApi from "../../services/UserApi.jsx";
import { useGlobalFunc } from "../../global/constants.js";




const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);



    const { errorToast, setLoading } = useGlobalFunc()

    const [dataList, setDataList] = useState(null)


    const [year, setYear] = useState(new Date().getFullYear())

    async function getDataList() {
        setLoading(true)
        try {
            let res = await UserApi.memorabiliaSearch({ year: year })
            console.log(res.data.data)
            setDataList(res.data.data)

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)

    }

    useEffect(() => {
        getDataList()
    }, []);

    return <div className="session1 relative pt-[40px] pb-20 bg-white" >

        <PathLocation />
        <TitleName title1="About Us" title2="關於我們" />



        <div className="flex py-14">

            <div className="hidden lg:block  flex-none w-[200px] min-h-full pr-10">

                <StickyList list={["aboutus.page1", "aboutus.page2", "aboutus.page3", "aboutus.page4", "aboutus.page5"]} />
            </div>

            <div className="w-0 grow ">

                <div className=" w-full mb-12" id="scroll0">
                    <TitleName2 title1={t('aboutus.page1')} />
                    <p className="pl-8 mt-8  text-black9 text-base  ">天堯科技經過長時間累積的資訊資本，我們目標將「天堯拍賣」打造為臺灣第 一藝術交流平台，「天堯拍賣」提供豐富且詳細的藝術展演資訊、專題活動報 導、藝術影音等資訊可以供藝術家及閱聽人隨時瀏覽，並推出線上畫廊平台， 吸引全球電商會員，更創建唯一讓每個拍賣品都擁有專屬 NFT 憑證之藝術品 拍賣平台，後台系統更是整合了串接各項區塊鏈 API、智能合約製作與部署、 虛擬貨幣交易所與錢包連結、集合式去中心化功能、鏈上節點與事件處理等系 統功能。「天堯拍賣」網站的前台系統清晰簡便，後台系統則是將多項功能整 合，比市場現有的藝術品拍賣公司功能豐富且齊全。
                    </p>
                </div>
                <div className="mb-12" id="scroll1">
                    <TitleName2 title1={t('aboutus.page2')} />
                    <p className="pl-8 mt-8 text-black9 text-base  mb-2">我們的使命是保障所有藝術家及競拍客戶的權益，所有古董及藝術品皆能朔源 該拍賣品來源及履歷，創下古董拍賣界的首例，首度達到虛實整合的開創先 例。客戶不僅能在網站裡瀏覽藝術品熱度等資訊，藝術家也能在商品管理後台 對於拍品進行管理，也直接串接至會計系統、物流管理系統，讓拍賣更便利。
                    </p>
                </div>

                <div id="scroll2">
                    <TitleName2 title1={t('aboutus.page3')} />
                    <p className="pl-8 mt-8 text-black9 text-base  mb-2">天堯科技股份有限公司設立於 2022 年 12 月 15 日，是由一群對古董拍賣及藝術珍藏品保存、數位化充滿熱忱的有志之士共同成立，打造天堯拍賣平台，整合上下游產業，不僅在藝術產業先驅，更是拍賣平台領域首屈一指。
                    </p>
                </div>

                <div className=" w-full text-black  py-14" id="scroll3">
                    <TitleName2 title1={t('aboutus.page4')} />
                    <div className="pl-8">

                        {
                            dataList && dataList && ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map(month =>
                                <>
                                    {
                                        dataList[`${month}/${year}`] !== undefined && dataList[`${month}/${year}`].map(i => <a href={i.link} target="_blank"><div className="group  pt-8" key={i.id}>
                                            <p className="  mb-8 text-main8  text-base group-hover:text-main4 ">{month}/{year} {i.title}</p>
                                            <div className="bg-sec4 w-full h-0.5"></div>
                                        </div>
                                        </a>)
                                    }

                                </>
                            )
                        }



                    </div>



                </div>

                <div className=" w-full   py-14" id="scroll4">
                    <TitleName2 title1={t('aboutus.page5')} />
                    <div className="pl-8">
                        <p className=" mt-12 mb-8 text-main95 font-semibold text-2xl  "></p>
                        <div className="text-main95 text-base">


                            <p className=" mb-2">{t('email')}：Tianyaotaiwan20221215@gmail.com</p>
                            <p className=" mb-2">{t('phone')}：07-3313558</p>
                            <p className=" mb-2">{t('address')}：{t('realAddress')}</p>
                            <p className=" ">{t('compiled')}：89169085</p>
                        </div>

                    </div>



                </div>





            </div>

        </div>




    </div >
}

export default Home