import { separatorNumber, time2date, } from "../global/constants"
import { useTranslation } from "react-i18next";


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  useNavigate
} from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux';
import Tag from "./Tag";


const ArtNewsBox = ({ data }) => {
  const navigate = useNavigate()
  const { t } = useTranslation();

  return <div className="cursor-pointer" onClick={() => navigate(`/artfocus/artnews/${data.id}`)} >
    <div className="p-6 border-2 border-solid border-main2 bg-main1 rounded-[20px]  text-black9 text-base  ">
      <img className="w-full aspect-[16/9] object-cover rounded-[20px] mb-4" src={data.images && data.images.banner} />
      <div className=" flex flex-col">
        <div className="flex mb-4">
          <Tag  title={t(`newsType.${data.type}`)} />
        </div>
        <div className="mb-4 text-lg font-semibold text-black9">
          {data.name}
        </div>
        <div className="text-sm text-black9">
          {time2date(data.createdAt)}｜撰文者： {data.author}
        </div>
      </div>
    </div>
  </div>
}
export default ArtNewsBox