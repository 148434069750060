import { brandLink } from "../global/constants"
import { useTranslation } from "react-i18next";
// import facebookImg from '../assets/brand/facebook.png'
// import telegramImg from '../assets/brand/telegram.png'
// import twitterImg from '../assets/brand/twitter.png'
// import youtubeImg from '../assets/brand/youtube.png'
// import tiktokImg from '../assets/brand/tiktok.png'
// import discordImg from '../assets/brand/discord.png'
// import igImg from '../assets/brand/ig.png'
// import mediumImg from '../assets/brand/medium.png'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  useNavigate
} from "react-router-dom";

import { useState } from 'react'

import tianyaoImg from '../assets/brand/tianyaoWL.png'
import facebookImg from '../assets/brand/facebookWL.png'


const Footer = () => {
  const location = useLocation()
  const { t } = useTranslation();
  const navigate = useNavigate()

  const [inputEmail, setInputEmail] = useState("")

  const brand = [
  
  ]

  function handleSubmit() {
    alert('An essay was submitted: ');

  }

  return <div className="w-full bg-main95 text-white">
    <div className="flex flex-wrap   gap-y-[40px] max-w-[1000px] w-full py-[100px] mx-auto  ">
      <div className="w-full lg:w-[25%] px-4 sm:px-10 ">
        <p className=" font-bold text-2xl mb-3">{t('about')}</p>
        <div className="bg-white w-full h-px mb-8"></div>
        <button onClick={()=>navigate('/onlinegallery/terms?type=1')} className="hover:text-main3 mb-2 text-left">{t('onlinegallery.artBtn5')}</button><br />
        <button onClick={()=>navigate('/onlinegallery/terms?type=2')} className="hover:text-main3 mb-2 text-left">{t('onlinegallery.artBtn6')}</button><br />
        <button onClick={()=>navigate('/artauction/online/rules')} className="hover:text-main3 mb-2 text-left">{t('artAuction.auctionIntroBtn2')}</button><br />
        <button onClick={()=>navigate('/artauction/physical/rules')} className="hover:text-main3 text-left">{t('artAuction.auctionIntroBtn1')}</button>
      </div>

      <div className="w-full lg:w-[25%] px-4 sm:px-10">
        <p className=" font-bold text-2xl mb-3">{t('help')}</p>
        <div className="bg-white w-full h-px mb-8"></div>
        <button onClick={()=>navigate('/onlinegallery/FAQ')} className="hover:text-main3 mb-2 text-left">{t('onlinegallery.artBtn1')}</button><br />
        <button onClick={()=>navigate('/onlinegallery/QandA')} className="hover:text-main3 mb-2 text-left">{t('onlinegallery.artBtn2')}</button><br />
        <button onClick={()=>navigate('/')} className="hidden mb-2">藝術家申請</button>
        <button onClick={()=>navigate('/artauction/physical/rules')} className="hover:text-main3 hidden">拍賣競標 LINE Bot</button>
      </div>
      <div className="w-full lg:w-[25%] px-4 sm:px-10">
        <p className=" font-bold text-2xl mb-3">{t('contact')}</p>
        <div className="bg-white w-full h-px mb-8"></div>
        <p className=" mb-2">{t('email')}：Tianyaotaiwan20221215@gmail.com</p>
        <p className=" mb-2">{t('phone')}：07-3313558</p>
        <p className=" mb-2">{t('address')}：{t('realAddress')}</p>
        <p className=" ">{t('compiled')}：89169085</p>
      </div>

      <div className="w-full lg:w-[50%] px-4 sm:px-10">


        <p className=" font-bold text-2xl mb-4">{t('followEvent')}</p>
        <div className="flex gap-4">
          <a href="" target="_blank">
            <img className=" object-contain w-[50px] h-[50px] " src={tianyaoImg} alt="" />
          </a>
          <a href="" target="_blank">
            <img className=" object-contain w-[50px] h-[50px] " src={facebookImg} alt="" />
          </a>
        </div>
      </div>

    </div>

  </div>
}
export default Footer