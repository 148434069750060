

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";

import Line1 from '../../compontents/Line1.js'
import Swiper from '../../compontents/Swiper.js'
import TitleName from '../../compontents/TitleName.js'

import circlebgImg from '../../assets/home/circlebg.png'
import circle1Img from '../../assets/home/circle1.png'
import circle2Img from '../../assets/home/circle2.png'
import circle3Img from '../../assets/home/circle3.png'
import crossImg from '../../assets/home/cross.png'

import pic1Img from '../../assets/1.png'
import pic2Img from '../../assets/2.png'
import pic3Img from '../../assets/3.png'

import hallbgImg from '../../assets/hallbg.png'

import moreArrowRightImg from '../../assets/moreArrowRight.png'


import seeMoreImg from '../../assets/seeMore.svg'

import linesImg from '../../assets/lines.png'
import lines2Img from '../../assets/home/lines2.png'

import swipe1Img from '../../assets/swipe1.png'
import swipe2Img from '../../assets/swipe2.png'
import swipe3Img from '../../assets/swipe3.jpg'
import { useGlobalFunc } from "../../global/constants.js";
import UserApi from "../../services/UserApi.jsx";
import Line2 from "../../compontents/Line2.js";
import ArtGrid from "../../compontents/ArtBox.js";
import AddFavorite from "../../compontents/AddFavorite.js";



// import homeshadowImg from '../../assets/homeshadow.png'
// import homescrollImg from '../../assets/homescroll.png'

// import homepic1Img from '../../assets/homepic1.png'
// import homepic2Img from '../../assets/homepic2.png'


const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);


    const [itemId, setItemId] = useState(0)

    const { setLoading, successToast, errorToast } = useGlobalFunc()

    const [data, setData] = useState(null)
    const [artDataList, setArtDataList] = useState(null)

    const [selIndex, setSelIndex] = useState(0)


    async function getData(id) {
        setLoading(true)
        try {
            let res = await UserApi.artistGet({ id: id })
            console.log(res.data.data)
            setData(res.data.data)

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)
    }


    async function getDataList(id) {
        setLoading(true)
        try {
            let res = await UserApi.productPricedGet({ id: id })
            console.log(res.data.data)
            setData(res.data.data)

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)
    }

    async function addCart() {
        setLoading(true)
        try {
            let res = await UserApi.cartAdd({ "id": itemId })
            console.log(res.data.data)
            successToast(t('addCart Success'))
        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)
    }



    useEffect(() => {
        let id = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        setItemId(id)
        getData(id)
    }, []);

    return <div className=" bg-white " >
        {
            data ?
                <div>
                    <div className="relative w-full gradiBorder p-[6px] ">

                        <div className="w-full bg-black4">
                            <div className="session1  flex items-center gap-10 py-28">
                                <div className="w-60 aspect-[1/1] gradiBorder p-[4px] rounded-full " >
                                    <img src={data.avatar && data.avatar} className=" bg-white w-full h-full object-cover rounded-full " />
                                </div>
                                <div className="text-black9 w-full">
                                    <div className="flex justify-between">
                                        <div>
                                            <p className="font-bold text-xl ">{data.name}</p>
                                            <p className="text-sm hidden">KANG,YU-HSUAN</p>
                                        </div>

                                        <AddFavorite color="text-black" hoverColor="hover:text-sec6" data={{ itemId: data.userId, type: "artist" }} />
                                    </div>

                                    <div className=" brightness-0">
                                        <Line2 />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className=" absolute  bottom-0 left-0 flex justify-center w-full  ">
                            {
                                ["藝術品", "關於", "履歷"].map((i, index) => <div onClick={() => setSelIndex(index)} className={`${selIndex == index ? 'bg-white rounded-t-[30px] text-black' : "text-black6"}  px-10 py-4  text-sm font-bold cursor-pointer `}>{i}</div>)
                            }

                        </div>

                    </div>

                    <div className="session1 mx-auto py-14">


                        {
                            selIndex == 0 ?
                                <div></div>
                                : ""
                        }
                        {
                            selIndex == 1 ?
                                <div className="sm:w-2/3 mx-auto text-black9 text-sm">
                                    一個風風火火的牡羊座，創作內容及題材多變，無論是生活周遭的瑣事，還是自己的內新體悟，都是靈感來源。不單一侷限於某種材料的使用，壓克力、油畫、色鉛筆、水墨、電繪…等都是常使用的媒材。試圖以繪畫來表達自己內心的聲音及對創作的熱情，將自己融入畫面中的角色，用繪畫，說故事給觀賞者聽，對我而言，繪畫不僅只是向外界溝通的方式之一，它也是一把梳子，用來梳理自己的內心，繪畫與我的生命緊密相連，讓我打開第六感，去注意到這世界上每樣事物所散發出的獨特靈光。
                                </div>
                                : ""
                        }
                        {
                            selIndex == 2 ?
                                <div className="sm:w-2/3 mx-auto text-black9 text-sm">
                                    得獎經歷 :<br />
                                    2020 第8屆彩繪希望繪畫比賽_佳作<br />
                                    2020 臺灣國際學生創意設計大賽_入選<br />
                                    2020 中崙圖書館第21屆漫筆獎大專社會組_第二名<br />
                                    2019 關渡宮大專西畫新秀獎_佳作<br />
                                    2019 中崙圖書館第20屆漫筆獎大專社會組_佳作<br />
                                    2019 QWQ校園設計徵稿比賽_第二名<br />
                                    2018 台灣國際海報新星獎_入選<br />
                                    2018 107年資訊月全國海報_入圍<br />
                                    2017 COPIC第二屆台灣ACG原創插畫大賽大專社會組_入選<br />
                                    2017 第二屆鄭豐喜藝術獎青少組_佳帆獎<br />
                                    2017 中崙圖書館第18屆漫筆獎大專社會組_第三名<br />
                                    2016 COPIC第一屆台灣ACG原創插畫大賽高中組_<br /><br /><br />

                                    入選展覽經歷 :<br />
                                    2022 紅玥緬因貓藝廊個展之有沒有貓膩<br />
                                    2022 國家圖書館詩篇咖啡廳聯展<br />
                                    2022 插畫家王子麵法國巴黎師生聯展<br />
                                    2021 信義遠百CYRANO席哈諾 x 藝拍聯展<br />
                                    2021 插畫家王子麵植畫x植說師生聯展<br />
                                    2020 插畫家王子麵師生聯展

                                </div>
                                : ""
                        }
                        {
                            selIndex == 3 ?
                                <div></div>
                                : ""
                        }


                    </div>

                </div>

                : ""
        }
    </div >
}

export default Home