import React, { Component, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app



const ArtLightBox = ({ images = [], currentIndex = 0 }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [selIndex, setSelIndex] = useState(1);

  function openImg(index) {
    setSelIndex(index)
    setIsOpen(true)
  }

  return <div>

    <img onClick={() => openImg(0)} src={images[0].image} className="w-full object-cover aspect-[1/1] rounded-xl  mb-4 cursor-pointer" />
    <div className='w-full mx-auto grid grid-cols-4 gap-7 md:mb-14'>
      {
        images.slice(1).map((i,index) => <div>
          {
            images.length > index ?
              <img onClick={() => openImg(index + 1)} src={i.image} className="w-full object-cover aspect-[1/1] rounded-xl  mb-14 cursor-pointer" />
              :
              <div className='bg-gray-200 w-full aspect-[1/1] rounded-xl'></div>
          }

        </div>)
      }

    </div>

    {isOpen && (
      <Lightbox
        mainSrc={images[selIndex].image}
        nextSrc={images[(selIndex + 1) % images.length].image}
        prevSrc={images[(selIndex + images.length - 1) % images.length].image}
        onCloseRequest={() => setIsOpen(false)}
        onMovePrevRequest={() =>
          setSelIndex((selIndex + images.length - 1) % images.length)
        }
        onMoveNextRequest={() =>
          setSelIndex((selIndex + 1) % images.length)
        }
      />
    )}
  </div>
}
export default ArtLightBox