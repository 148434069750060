

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";


const Home = ({ id,backStep0 }) => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);


    const banner = []
    const [currBanner, setCurrBanner] = useState(0)
    const countRef = useRef()



    const [faqs, setFAQ] = useState([


    ]);



    const [FAQType, setFAQType] = useState(0);




    useEffect(() => {

    }, []);


    return <div>
        <div className="mt-8 px-6 py-8 bg-white border-solid border-4 border-main3 rounded-[20px] relative  w-full ">

            <div className="text-black9 text-center text-xl font-bold mb-12">您已申請成功</div>

            <div className="flex justify-center text-black9 break-all w-full sm:max-w-[500px] mx-auto ">
                <div className="text-center hidden">


                    申請訂單ID：<br/>{id}

                </div>

            </div>
            <div className="mt-8 flex justify-center">
                <button onClick={()=>backStep0()} className="border-main6 border-solid border text-main6 px-6 py-3 rounded-full font-semibold text-sm ">返回申請列表</button>
            </div>


        </div>

    </div>

}

export default Home