

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";

import SeeMore from '../../compontents/SeeMore.js'
import Line1 from '../../compontents/Line1.js'
import Swiper from '../../compontents/Swiper.js'
import TitleName from '../../compontents/TitleName.js'

import circlebgImg from '../../assets/home/circlebg.png'
import circle1Img from '../../assets/home/circle1.png'
import circle2Img from '../../assets/home/circle2.png'
import circle3Img from '../../assets/home/circle3.png'
import crossImg from '../../assets/home/cross.png'

import pic1Img from '../../assets/1.png'
import pic2Img from '../../assets/2.png'
import pic3Img from '../../assets/3.png'

import homebannerImg from '../../assets/homebanner.jpg'


import linesImg from '../../assets/lines.png'
import lines2Img from '../../assets/home/lines2.png'

import swipe1Img from '../../assets/swipe1.png'
import swipe2Img from '../../assets/swipe2.png'
import swipe3Img from '../../assets/swipe3.jpg'

import seeMoreImg from '../../assets/seeMore.png'
import UserApi from "../../services/UserApi.jsx";
import { useGlobalFunc } from "../../global/constants.js";
import CircleSession from "./compontents/CircleSession.js";

// import homeshadowImg from '../../assets/homeshadow.png'
// import homescrollImg from '../../assets/homescroll.png'

// import homepic1Img from '../../assets/homepic1.png'
// import homepic2Img from '../../assets/homepic2.png'


const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search);

    const { setLoading, successToast, errorToast } = useGlobalFunc()


    const [data, setData] = useState(null)


    async function getHomeIndex() {
        setLoading(true)
        try {
            let res = await UserApi.homeIndexGet()
            console.log(res.data)
            setData(res.data.data)

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)
    }


    const [FAQType, setFAQType] = useState(0);

    const galleryList = [
        {
            img: swipe1Img,
            title: "斜陽佳色",
            name: "連雅綺"
        },
        {
            img: swipe2Img,
            title: "世界的末日又多一天",
            name: "黃柔閩"
        },
        {
            img: swipe3Img,
            title: "Water and Fire",
            name: "葛拉娜"
        },

    ]



    useEffect(() => {
        getHomeIndex()

    }, []);


    return <div className="bg-white" >
        {
            data != null ?
                <div>

                    <div className="session1 mt-10 mb-8">
                        <TitleName title1="Online Gallery" title2="線上藝廊" />
                    </div>
                    <div className="hidden lg:hidden">
                        <Line1 />
                    </div>
                    <Swiper serviceList={galleryList} ></Swiper>
                    <div className="flex justify-center mt-14 relative ">
                        <SeeMore to="/onlinegallery" />
                    </div>
                    <div className="w-full aspect-[16/6] relative pointer-events-none ">
                        <img src={linesImg} className=" absolute left-0 top-0 w-full object-contain transform -translate-y-1/3" />
                    </div>
                    {/* <div className="relative w-full aspect-[16/9] lg:aspect-auto lg:h-screen mb-[132px]">
                        <img className="w-full h-full  object-cover" src={homebannerImg} />

                        <div className="absolute boxShadow bg-white px-[16px] py-[12px] sm:px-[36px] sm:py-[32px]  w-11/12 lg:w-1/2 -bottom-[45px] lg:-bottom-[105px] right-[4.5%] lg:right-[90px] text-main95 border-solid border-sec4 border-4 rounded-[20px] overflow-hidden">
                            <p className="text-main95 text-base lg:text-xl font-semibold pb-6">沉睡在荒野中的一千零一夜 —— 藝術家LUPA的「人間勿語」</p>
                            <p className="text-sm lg:text-base">2022-12-05｜撰文者：張慧慧 / hiro hiro art space</p>
                        </div>
                        
                    </div> */}
                    <div className="session1 mb-[80px]">
                        <div className="mb-32 mx-auto grid grid-cols-8 w-10/12 max-w-[400px] rounded-[3.5px] overflow-hidden">
                            <div className=" bg-sec4 h-0.5"></div>
                            <div className="col-span-7 bg-black9 h-0.5"></div>
                        </div>
                        <TitleName title1="Art Auction" title2="藝品拍賣" />
                        <Line1 />
                        {
                            data.auctionProduct.slice(0, 1).map(i => <div key={i.id}>

                                <div onClick={()=>navigate(`/artauction/online/theme?sessionsId=${i.id}`)} className=" cursor-pointer px-4 lg:px-20 py-8 lg:py-[120px] grid grid-cols-3 items-center gap-x-[60px] gap-y-6 ">

                                    <div className="w-full aspect-[16/9] col-span-3 lg:col-span-2 border-solid  bg-sec4 border-sec4 border-4 rounded-[20px] overflow-hidden">
                                        <img src={i.logo} className="w-full h-full object-cover hover:scale-105 anim  " />
                                    </div>
                                    <div className="col-span-3 lg:col-span-1 text-main95">
                                        <p className="mb-3">{t('sessionId')}：{i.numberId}</p>
                                        <p className="mb-6 text-3xl font-semibold">{i.themes[0].name}</p>
                                        <p className="mb-[21px] font-medium">{i.themes[0].startDate}</p>
                                        <p className="mb-[11px]">{t('isOnlineGallery')}</p>
                                        <p>Lot. {i.lotStartNumber} - {i.lotEndNumber}</p>
                                    </div >
                                </div >
                                <Line1 />
                            </div>
                            )
                        }

                        {
                            data.auctionProduct.slice(1, 2).map(i => <div key={i.id}>

                                <div onClick={()=>navigate(`/artauction/online/theme?sessionsId=${i.id}`)} className="w-full  cursor-pointer px-5 lg:px-20 py-8 lg:py-[120px] grid grid-cols-3 items-center gap-x-[60px] gap-y-6 ">
                                    <div className="w-full aspect-[16/9] col-span-3 lg:col-span-2 border-solid  bg-sec4 border-sec4 border-4 rounded-[20px] overflow-hidden">
                                        <img src={i.logo} className="w-full h-full object-cover hover:scale-105 anim  " />
                                    </div>
                                    <div className="col-span-3 lg:col-span-1 text-main95">
                                        <p className="mb-3">{t('sessionId')}：{i.numberId}</p>
                                        <p className="mb-6 text-3xl font-semibold"> {i.themes[0].name}</p>
                                        <p className="mb-[21px] font-medium">{i.themes[0].startDate}</p>
                                        <p className="mb-[11px]">{t('isOnlineGallery')}</p>
                                        <p>Lot. {i.lotStartNumber} - {i.lotEndNumber}</p>
                                    </div >
                                </div >
                                <Line1 />
                            </div>
                            )
                        }

                        <div className="flex justify-end mt-[80px] mb-[186px] ">
                            <SeeMore to="/artauction" />
                        </div>


                    </div >




                    <div className="session1 mb-[80px]">
                        <TitleName title1="Art News" title2="最新消息" />
                        <Line1 />
                        <div className="my-[80px]">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px] ">
                                {
                                    data.artArticle.slice(0, 2).map(i => <div onClick={() => navigate(`/artfocus/artnews/${i.id}`)} className=" cursor-pointer">
                                        <div className="rounded-[10px] w-full  mb-3 aspect-[16/9] overflow-hidden">
                                            <img src={i.images && i.images.banner} className="w-full h-full object-cover hover:scale-105 anim  " />
                                        </div>
                                        <div className="text-main95 text-h3.5 textline2">
                                            {i.name}
                                        </div>
                                        <div className="text-gray1 text-sm">
                                            {i.createdAt}
                                        </div>
                                    </div>)

                                }

                            </div>

                        </div>

                        <Line1 />
                        <div className="my-[80px]">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px] ">
                                {
                                    data.artArticle.slice(2, 4).map(i => <div onClick={() => navigate(`/artfocus/artnews/${i.id}`)} className="cursor-pointer">
                                        <div className="rounded-[10px] w-full  mb-3 aspect-[16/9] overflow-hidden">
                                            <img src={i.images !== null && i.images.banner} className="w-full h-full object-cover hover:scale-105 anim  " />
                                        </div>
                                        <div className=" text-main95">
                                            <div className="text-main95 text-h3.5 textline2">
                                                {i.name}
                                            </div>
                                            <div className="text-gray1 text-sm">
                                                {i.createdAt}
                                            </div>

                                        </div>
                                    </div>)

                                }

                            </div>

                        </div>

                        <Line1 />

                        <div className="flex justify-end mt-[70px] mb-20 lg:mb-[186px]">
                            <SeeMore to="/artfocus/artnews" />
                        </div>



                        <TitleName title1="Customer Service" title2="客戶服務" />
                        <CircleSession />
                    </div>
                </div>
                : ""
        }
        <img src={lines2Img} className="w-full object-contain " />



    </div >
}

export default Home