

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";

import ArtistCenterNavBar from "../compontents/ArtistCenterNavBar.js";



import { useSelector } from "react-redux";
import ArtManage from "./compontents/ArtManage";

import ShipRegister from '../../../compontents/ArtApply/ShipRegister.js'
import OrderAuction from '../../../compontents/ArtApply/OrderAuction.js'
import ConfrimApply from '../../../compontents/ArtApply/ConfrimApply.js'
import SuccessApply from '../../../compontents/ArtApply/SuccessApply.js'

import { useGlobalFunc } from "../../../global/constants.js";
import UserApi from "../../../services/UserApi.jsx";




const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const userInfo = useSelector(state => state.userInfo);


    const { setLoading, successToast, errorToast } = useGlobalFunc()



    return <div className="w-full" >

        <ArtistCenterNavBar />
        <div className=" session1 mx-auto relative w-full  pb-14" >


            <ArtManage />



        </div>





    </div >
}

export default Home