

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";

import Line1 from '../../compontents/Line1.js'
import Swiper from '../../compontents/Swiper.js'
import TitleName from '../../compontents/TitleName.js'

import circlebgImg from '../../assets/home/circlebg.png'
import circle1Img from '../../assets/home/circle1.png'
import circle2Img from '../../assets/home/circle2.png'
import circle3Img from '../../assets/home/circle3.png'
import crossImg from '../../assets/home/cross.png'

import pic1Img from '../../assets/1.png'
import pic2Img from '../../assets/2.png'
import pic3Img from '../../assets/3.png'

import hallbgImg from '../../assets/hallbg.png'

import moreArrowRightImg from '../../assets/moreArrowRight.png'


import seeMoreImg from '../../assets/seeMore.svg'

import linesImg from '../../assets/lines.png'
import lines2Img from '../../assets/home/lines2.png'

import swipe1Img from '../../assets/swipe1.png'
import swipe2Img from '../../assets/swipe2.png'
import swipe3Img from '../../assets/swipe3.jpg'
import { separatorNumber, useGlobalFunc } from "../../global/constants.js";
import UserApi from "../../services/UserApi.jsx";
import SeeMore from "../../compontents/SeeMore.js";
import ArtLightBox from "../../compontents/ArtLightBox.js";
import PathLocation from "../../compontents/PathLocation.js";
import ArtBox from "../../compontents/ArtBox.js";



// import homeshadowImg from '../../assets/homeshadow.png'
// import homescrollImg from '../../assets/homescroll.png'

// import homepic1Img from '../../assets/homepic1.png'
// import homepic2Img from '../../assets/homepic2.png'


const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);


    const [itemId, setItemId] = useState(0)
    const [isPhysical, setIsPhysical] = useState(false)

    const { setLoading, successToast, errorToast } = useGlobalFunc()

    const [data, setData] = useState(null)
    const [dataList, setDataList] = useState(null)



    async function getData(id) {
        setLoading(true)
        try {
            let res = await UserApi.productPricedGet({ id: id })
            console.log(res.data.data)
            setData(res.data.data)

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)
    }

    async function getPhysicalData(id) {
        setLoading(true)
        try {
            let res = await UserApi.physicalAuctionGet({ id: id })
            console.log(res.data.data)
            setData(res.data.data)

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)
    }

    async function getOnlineData(id) {
        setLoading(true)
        try {
            let res = await UserApi.productAuctionGet({ id: id })
            console.log(res.data.data)
            setData(res.data.data.product)

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)
    }

    async function addCart() {
        setLoading(true)
        try {
            let res = await UserApi.cartAdd({ "id": itemId })
            console.log(res.data.data)
            successToast(t('addCart Success'))
        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)
    }


    async function getDataList(page) {
        setLoading(true)
        try {
            let res = await UserApi.productPricedSearch({ page: page })
            console.log(res.data)
            setDataList(res.data.data)

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)

    }


    useEffect(() => {
        let id = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        setItemId(id)

        const typeValue = query.get('type');
        console.log(typeValue)
        if (typeValue == 'physical') {
            setIsPhysical(true)
            getPhysicalData(id)
        }
        else {
            getData(id)
            getDataList({ page: 1 })
        }

    }, []);

    return <div className="session1 bg-white pt-10  pb-20" >
        <PathLocation />


        {
            data != null ?
                <div className="mt-12">
                    <div className="w-full flex flex-wrap ">
                        <div className="w-full md:w-[55%]  md:pr-14 mb-4 md:mb-0">
                            <ArtLightBox images={data.images} currentIndex={0} />


                        </div>

                        <div className="w-full md:w-[45%]    text-black6 text-base flex  ">
                            <div className="hidden md:block flex-none w-0.5 h-full bg-gradient-to-b from-[#CA9849] to-[#FFF4AF] mr-14"></div>
                            <div className="pt-[6px] pb-28">


                                {
                                    data.lotNumber && <p className="text-2xl text-black8 font-semibold">Lot .{data.lotNumber}</p>
                                }
                                <p className="text-black9 font-semibold text-3xl mt-8 mb-4">{data.collectionName}</p>
                                <p>{data.artistName}</p>
                                <p className="mb-8"></p>
                                {
                                    data.salePrice && <p>NT${separatorNumber(data.salePrice)}</p>
                                }


                                <div className="">
                                    <p className="font-semibold text-lg mt-14">媒材／尺寸</p>
                                    <p>{data.material}, {data.height}x{data.width}x{data.depth}cm</p>

                                    <p className="font-semibold text-lg mt-8">品項</p>
                                    <p>{data.type}</p>

                                    <p className="font-semibold text-lg mt-8">創作年份</p>
                                    <p>{data.creationYear}</p>

                                    <p className="font-semibold text-lg mt-8">出版／備註</p>
                                    <p>{data.sourceInformation}</p>
                                </div>
                                {
                                    !isPhysical &&
                                    <button onClick={() => addCart()} className=" mt-4 w-full py-2 text-sm font-semibold  text-white rounded-full bg-main9 ">加入購物車</button>
                                }

                            </div>


                        </div>


                    </div>
                    <div className="flex-none w-full h-0.5 bg-gradient-to-r from-[#CA9849] to-[#FFF4AF] "></div>

                    <div className="text-black9 py-10">
                        <p className="font-semibold text-lg mb-2">藝術品詳情</p>
                        <p dangerouslySetInnerHTML={{ "__html": data.details }} />
                    </div>

                    <div className="flex-none w-full h-0.5 bg-gradient-to-r from-[#CA9849] to-[#FFF4AF]  mb-20"></div>
                </div>
                : ""
        }

        <div className="flex justify-between mb-8  ">
            <div className="text-3xl font-semibold text-black9">更多拍品</div>
            <SeeMore to="/artlist" />
        </div>

        <div className="w-full grid grid-cols-4 gap-10 ">

            {
                dataList && dataList.length > 1 && dataList.filter(item => item.id != data.id).slice(0, 4).map((i) => <ArtBox key={i.id} data={i} />)
            }



        </div>



    </div >
}

export default Home