

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";


import TitleName from '../../../compontents/TitleName.js'



import seeMoreImg from '../../../assets/seeMore.png'
import PathLocation from "../../../compontents/PathLocation.js";

// import homeshadowImg from '../../assets/homeshadow.png'
// import homescrollImg from '../../assets/homescroll.png'

// import homepic1Img from '../../assets/homepic1.png'
// import homepic2Img from '../../assets/homepic2.png'


const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);


    // useEffect(() => {
    //     let id = query.get('id')
    //     if (id == null) {
    //         navigate('../')
    //     }
    //     else {
    //         setSelId(id)
    //         getDataList(page, id)
    //     }
    // }, []);


    return <div className="session1 relative pt-[40px] pb-20" >

        <PathLocation />
        <TitleName title1="Auction Introduction" title2="拍賣簡介" />



        <div className="w-full   mt-20">



            <p className="text-black9 font-semibold mb-2">茲請求天堯科技文化股份有限公司依照下列競投慣例，在指定範圍內參與下列拍賣品的競投。<br />
                本人了解天堯科技文化股份有限公司為方便客戶提供代為標的的服務，不會因未能成功投標而負責任。 天堯科技文化股份有限公司如果收到相同競價的委托，則以最先收到的為優先處理。<br />本人了解，如果競標成功，應支付的購買款項為最後的拍賣價格加上服務費 (含後拍定價和服務費之營業稅)。<br />服務費是根據最後的拍賣價格乘以服務費率計算的。 <br />本人也已詳閱買家拍賣規則。<br />
                (1) 每件拍賣品服務費率為拍定價之 15%。<br />(2) 如買家自行取件或運送，則無需支付拍賣品的運送費用。但本公司保留調整每件拍賣品服務費率之權利。<br />
                為確保所有投標均能接受，以及拍賣品運送不延誤，買家應向天堯科技文化股份有限公司提供往來銀行或其他付款資料，並授權該公司向銀行查證。這些資料必須即時提供，以便在拍賣前處理。<br /><br />投標牌號保證金為新台幣貳拾萬元整，而特殊競標牌號的保證金則為新台幣壹仟萬元整。如果競買人成功得標成為買受人，該保證金會自動轉為已成交拍賣品的部分購買價款、訂金或部分訂金。若競買人未能購得拍賣品，則保證金全額無息退還。<br />
                <br /><br />
                我已閱讀並了解拍賣公司的拍賣規則，明白不同拍賣公司可能會有不同的條款，同意遵守拍賣規則之條款。</p>


        </div>



    </div >
}

export default Home