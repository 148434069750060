import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import App from './App';
//import reportWebVitals from './reportWebVitals';
import Header from './compontents/Header'
import Footer from './compontents/Footer'

import Loading from './compontents/Loading'

import Login from "./pages/Login";

import Home from "./pages/Home";
import PhysicalArtAuction from "./pages/ArtAuctionPages/PhysicalArtAuction";
import PhysicalArtAuctionDetail from "./pages/ArtAuctionPages/PhysicalArtAuctionDetail";
import ArtAuction from "./pages/ArtAuctionPages/ArtAuction";
import ArtList from "./pages/ArtList";
import OnlineArtDetail from "./pages/ArtAuctionPages/OnlineArtDetail";
import PhysicalArtDetail from "./pages/PhysicalArtDetail";
import ArtDetail from "./pages/ArtDetail";

import ElectronicCatolog from "./pages/ArtAuctionPages/ElectronicCatolog";
import AuctionIntro from "./pages/ArtAuctionPages/AuctionIntro";
import OnlineArtAuction from "./pages/ArtAuctionPages/OnlineArtAuction";
import OnlineArtAuctionTheme from "./pages/ArtAuctionPages/OnlineArtAuctionTheme";
import OnlineArtAuctionThemeDetail from "./pages/ArtAuctionPages/OnlineArtAuctionThemeDetail";
import PastArtAuction from "./pages/ArtAuctionPages/PastArtAuction";
import PastArtAuctionDetail from "./pages/ArtAuctionPages/PastArtAuctionDetail";

import OnlineAuctionRules from "./pages/ArtAuctionPages/OnlineAuctionRules";
import PhysicalAuctionRules from "./pages/ArtAuctionPages/PhysicalAuctionRules";

import Cart from "./pages/Cart";

import Artist from "./pages/ArtistDetail";
import ArtistList from "./pages/ArtistList";

import OnlineGallery from "./pages/OnlineGallery";
import CustomerService from "./pages/CustomerService";
import AboutUs from "./pages/AboutUs";
import ArtFocus from "./pages/ArtFocusPages/ArtFocus";
import ArtNews from "./pages/ArtFocusPages/ArtNews";
import ArtNewsDetail from "./pages/ArtFocusPages/ArtNewsDetail";
import Exhibition from "./pages/ArtFocusPages/Exhibition";
import ExhibitionDetail from "./pages/ArtFocusPages/ExhibitionDetail";
import ArtPlace from "./pages/ArtFocusPages/ArtPlace";
import ArtPlaceDetail from "./pages/ArtFocusPages/ArtPlaceDetail";
import ArtVideo from "./pages/ArtFocusPages/ArtVideo";
import ArtVideoDetail from "./pages/ArtFocusPages/ArtVideoDetail";






import PaySuccess from "./pages/PaySuccess";
import Register from "./pages/Register";

import FAQ from "./pages/OnlineGalleryIntroPage/FAQ";
import QandA from "./pages/OnlineGalleryIntroPage/QandA";
import ArtMainTenance from "./pages/OnlineGalleryIntroPage/ArtMaintenance";
import Terms from "./pages/OnlineGalleryIntroPage/Terms";

import Page404 from "./pages/Page404";

import BidPayment from "./pages/BidPayment";

import { Provider } from "react-redux";
import { store } from "./model/store.js";


import "./i18n"

import { useTranslation } from "react-i18next";
import { t } from "react-i18next";



import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import ScrollToTop from './compontents/ScrollToTop';
import './firebase.js';


import MemberCenter from "./pages/MemberCenterPage/MemberCenter";
import OrderRecord from "./pages/MemberCenterPage/OrderRecord";
import RegisterPlace from "./pages/MemberCenterPage/JoinSession";
import SuccessOrder from "./pages/MemberCenterPage/BidProduct";
import ArtCert from "./pages/MemberCenterPage/ArtCertificate";
import ApplyFunction from "./pages/MemberCenterPage/ApplyFunction";
import MonthlySystem from "./pages/MemberCenterPage/MonthlySystem";
import MyFavor from "./pages/MemberCenterPage/MyFavorite";
import PayRecord from "./pages/MemberCenterPage/PayRecord";
import Setting from "./pages/MemberCenterPage/Setting";
import ArtManage from "./pages/MemberCenterPage/ArtManage";



import ArtistArtManage from "./pages/ArtistCenterPage/ArtManage";
import ApplyList from "./pages/ArtistCenterPage/ApplyList";
import ArtistIntro from "./pages/ArtistCenterPage/ArtistIntro";
import ExhibitionExperience from "./pages/ArtistCenterPage/ExhibitionExperience";
import ArtistSetting from "./pages/ArtistCenterPage/ArtistSetting";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter >
      <div className=' relative w-full h-full min-h-screen bg1 text-white bg-[#FDFCF9]' >
        <div className="relative min-h-screen flex flex-col">
          <ScrollToTop />
          <Loading />
          <Header />
          <div className='min-h-full grow'>
            <Routes>
              <Route path="/artauction" >
                <Route path="" element={<ArtAuction />}></Route>
                <Route path="physical" >
                  <Route path="" element={<PhysicalArtAuction />}></Route>
                  <Route path=":id" element={<PhysicalArtAuctionDetail />}></Route>
                  <Route path="art/:id" element={<PhysicalArtDetail />}></Route>
                  <Route path="intro" element={<AuctionIntro />}></Route>
                  <Route path="catolog" element={<ElectronicCatolog />}></Route>
                  <Route path="rules" element={<PhysicalAuctionRules />}></Route>
                </Route>

                <Route path="online" >
                  <Route path="" element={<OnlineArtAuction />}></Route>
                  <Route path="art/detail" element={<OnlineArtDetail />}></Route>

                  <Route path="theme" element={<OnlineArtAuctionTheme />}></Route>
                  <Route path="theme/:id" element={<OnlineArtAuctionThemeDetail />}></Route>
                  {/* <Route path="past" element={<PastArtAuction />}></Route>
                  <Route path="past/:id" element={<PastArtAuctionDetail />}></Route> */}

                  <Route path="rules" element={<OnlineAuctionRules />}></Route>


                </Route>
              </Route>

              <Route path="/artistlist" element={<ArtistList />}></Route>
              <Route path="/artist/:id" element={<Artist />}></Route>


              <Route path="/cart" element={<Cart />}></Route>
              <Route path="/artlist" element={<ArtList />}></Route>

              <Route path="/art/:id" element={<ArtDetail />}></Route>



              <Route path="/onlinegallery" >
                <Route path="" element={<OnlineGallery />}></Route>

                <Route path="faq" element={<FAQ />}></Route>
                <Route path="QandA" element={<QandA />}></Route>
                <Route path="artmaintenance" element={<ArtMainTenance />}></Route>
                <Route path="terms" element={<Terms />}></Route>

              </Route>


              <Route path="/customerservice" element={<CustomerService />}></Route>
              <Route path="/aboutus" element={<AboutUs />}></Route>


              <Route path="/artfocus" >
                <Route path="" element={<ArtFocus />}></Route>
                <Route path="artnews" element={<ArtNews />}></Route>
                <Route path="exhibition" element={<Exhibition />}></Route>
                <Route path="artplace" element={<ArtPlace />}></Route>
                <Route path="artvideo" element={<ArtVideo />}></Route>

                <Route path="artnews/:id" element={<ArtNewsDetail />}></Route>
                <Route path="exhibition/:id" element={<ExhibitionDetail />}></Route>
                <Route path="artplace/:id" element={<ArtPlaceDetail />}></Route>
                <Route path="artvideo/:id" element={<ArtVideoDetail />}></Route>
              </Route>

              <Route path="/membercenter" >
                <Route path="" element={<OrderRecord />}></Route>
                <Route path="orderrecord" element={<OrderRecord />}></Route>
                <Route path="registerplace" element={<RegisterPlace />}></Route>
                <Route path="successorder" element={<SuccessOrder />}></Route>
                <Route path="artcert" element={<ArtCert />}></Route>
                <Route path="artmanage" element={<ArtManage />}></Route>
                <Route path="applyfunction" element={<ApplyFunction />}></Route>

                <Route path="monthlySystem" element={<MonthlySystem />}></Route>
                <Route path="myfavorite" element={<MyFavor />}></Route>
                <Route path="payrecord" element={<PayRecord />}></Route>
                <Route path="setting" element={<Setting />}></Route>
              </Route>



              <Route path="/artistcenter" >
                <Route path="" element={<ArtistArtManage />}></Route>
                <Route path="artmanage" element={<ArtistArtManage />}></Route>
                <Route path="commissionedapply" element={<ApplyList />}></Route>
                <Route path="intro" element={<ArtistIntro />}></Route>
                <Route path="exhibitionexperience" element={<ExhibitionExperience />}></Route>
                <Route path="artistInfo" element={<ArtistSetting />}></Route>

              </Route>


              <Route path="/login" element={<Login />}></Route>
              <Route path="/register" element={<Register />}></Route>

              <Route path="/404" element={<Page404 />}></Route>

              <Route path="/paysuccess" element={<PaySuccess />}></Route>

              <Route path="/bidpayment" element={<BidPayment />}></Route>



              <Route path="/" element={<Home />}></Route>

              <Route path='*' element={<Navigate to='/' replace />} />

            </Routes>

          </div>


          <Footer />


        </div>
      </div>

    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
