

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";
import { useSelector } from "react-redux";

import UserApi from "../../../../services/UserApi.jsx";
import { useGlobalFunc } from "../../../../global/constants.js";






// import homeshadowImg from '../../../assets/homeshadow.png'
// import homescrollImg from '../../../assets/homescroll.png'

// import homepic1Img from '../../../assets/homepic1.png'
// import homepic2Img from '../../../assets/homepic2.png'


const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const userInfo = useSelector(state => state.userInfo);

    const [inputValue, setInputValue] = useState('')

    const { setLoading, successToast, errorToast, updateMe } = useGlobalFunc()


    async function sendUpdateMe() {
        setLoading(true)
        try {
            let res = await UserApi.updateMe({ about: inputValue })
            console.log(res)
            successToast('succuess')
            updateMe()
        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)
    }


    useEffect(() => {
        if (userInfo && userInfo.artistProfile) {
            setInputValue(userInfo.artistProfile.about)
        }


    }, [userInfo]);


    return <div className="mt-8 px-6 py-6 bg-white border-solid border-4 border-main3 rounded-[20px] relative  w-full overflow-x-auto whitespace-nowrap">
        <p className="text-black9 mb-4 font-bold">關於藝術家</p>
        <textarea value={inputValue} onChange={(e) => setInputValue(e.target.value)} className="text-black9 bg-black2 rounded-[10px] w-full h-64 p-4 mb-8" />

        <div className="flex justify-center gap-4">
            <button className="border-main6 border-solid border w-40 px-6 py-3 rounded-full font-semibold text-sm text-main6">取消</button>
            <button onClick={() => sendUpdateMe()} className="bg-main6 px-6 py-3 rounded-full w-40 font-semibold text-sm text-white mb-1">儲存</button>

        </div>
    </div>

}

export default Home