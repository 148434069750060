

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";

import Line1 from '../../../compontents/Line1.js'
import Line2 from '../../../compontents/Line2.js'
import Swiper from '../../../compontents/Swiper.js'
import TitleName from '../../../compontents/TitleName.js'
import TitleName2 from '../../../compontents/TitleName2.js'
import SeeMore from '../../../compontents/SeeMore.js'


import ReactPlayer from 'react-player'

import { useGlobalFunc, time2date } from "../../../global/constants.js";
import { Pagination } from "@mui/material";
import UserApi from "../../../services/UserApi.jsx";
import moment from 'moment'
import PathLocation from "../../../compontents/PathLocation.js";
import StickyList from "../../../compontents/StickyList.js";
import ArtPlaceBox from "../../../compontents/ArtPlaceBox.js";
import ArtVideoBox from "../../../compontents/ArtVideoBox.js";
import Tag from "../../../compontents/Tag.js";


const Home = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const { setLoading, successToast, errorToast } = useGlobalFunc()


    const [newsDataList, setNewsDataList] = useState([])
    const [eventDataList, setEventDataList] = useState([])
    const [placeDataList, setPlaceDataList] = useState([])
    const [videoDataList, setVideoDataList] = useState([])

    function checkEventStatus(start, end) {
        let toStart = moment(start).valueOf() - moment().valueOf()
        let toEnd = moment(end).valueOf() - moment().valueOf()

        if (toStart > 0) {
            return "即將開始"
            return `${Math.floor(toStart / 86400000)}天後開始`
        }
        else if (toEnd > 0) {
            return `最後${Math.floor(toEnd / 86400000)}天`
        }
        else {
            return `已完結`
        }
        //return moment(end).valueOf()

    }

    async function getNewsDataList() {
        setLoading(true)
        try {
            let res = await UserApi.artArticleSearch({ page: 1, type: 'all' })
            console.log(res.data.data)
            setNewsDataList(res.data.data)

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)
    }


    async function getEventDataList() {
        setLoading(true)
        try {
            let res = await UserApi.exhibitionEventSearch({ page: 1 })
            console.log(res.data.data)
            setEventDataList(res.data.data)

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)

    }

    async function getPlaceDataList() {
        setLoading(true)
        try {
            let res = await UserApi.artGatheringSearch({ page: 1 })
            console.log(res.data.data)
            setPlaceDataList(res.data.data)

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)

    }

    async function getVideoDataList() {
        setLoading(true)
        try {
            let res = await UserApi.artVideoSearch({ page: 1 })
            console.log(res.data.data)
            setVideoDataList(res.data.data)

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)

    }

    async function getFocusIndex() {
        setLoading(true)
        try {
            let res = await UserApi.artFocusIndexGet()
            console.log(res.data)

            setNewsDataList(res.data.data.artArticle)
            setEventDataList(res.data.data.exhibitionEvent)
            setPlaceDataList(res.data.data.artGathering)
            setVideoDataList(res.data.data.artVideo)

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)
    }


    useEffect(() => {
        getFocusIndex()

        //getNewsDataList()
        //getEventDataList()
        //getPlaceDataList()
        //getVideoDataList()

    }, []);


    return <div className="session1 relative pt-[40px] pb-20 bg-white" >

        <PathLocation />
        <TitleName title1="Art Focus" title2="藝術焦點" />



        <div className="flex pt-14">
            <div className="hidden lg:block  flex-none w-[200px] min-h-full pr-10">
                <StickyList list={["artFocus.news", "artFocus.exhibition", "artFocus.place", "artFocus.video"]} />
            </div>
            <div className="w-0 grow ">

                <div className=" w-full   pb-14" id="scroll0">
                    <TitleName2 title1={t('artFocus.news')} />
                    {
                        newsDataList.slice(0, 2).map(i => <div onClick={() => navigate(`artnews/${i.id}`)} className="cursor-pointer">
                            <div className="flex flex-col gap-4 sm:gap-10 sm:flex-row sm:items-center mt-8 mb-12 text-black9 text-base  ">
                                <img className=" rounded-[10px] w-full sm:w-1/3 aspect-[16/9] object-cover flex-none mb-4 sm:mb-0" src={i.images != null ? i.images.banner : ""} />
                                <div className="px-4 flex flex-col ">
                                    <div className="flex mb-4">
                                        <Tag  title={t(`newsType.${i.type}`)} />
                                    </div>

                                    <div className="textline2 font-bold">
                                        {i.name}
                                    </div>
                                    <div className=" text-sm">
                                        {time2date(i.createdAt)}｜撰文者： {i.author}
                                    </div>
                                </div>
                            </div>
                            <Line2 />
                        </div>)
                    }

                    <div className="flex justify-end mt-8 ">
                        <SeeMore to="artnews" />
                    </div>
                </div>
                <div className=" w-full   py-14" id="scroll1">

                    <TitleName2 title1={t('artFocus.exhibition')} />
                    <div className="mb-4"></div>
                    {
                        eventDataList.slice(0, 2).map(i => <div onClick={() => navigate(`exhibition/${i.id}`)} className=" ">
                            <div className=" flex gap-4 justify-between items-center  text-black9 text-base  py-8 group cursor-pointer">
                                <p className="flex-none">{time2date(i.startTime)}</p>
                                <p className="flex-none text-[#EB5757] font-semibold">{checkEventStatus(i.startTime, i.endTime)}</p>
                                <p className="textline2 grow  group-hover:text-red-500">{i.name}</p>
                            </div>
                            <Line2 />
                        </div>)
                    }


                    <div className="flex justify-end mt-8 ">
                        <SeeMore to="exhibition" />
                    </div>
                </div>

                <div className=" w-full  py-14" id="scroll2">
                    <TitleName2 title1={t('artFocus.place')} />
                    <div className=" flex flex-col sm:flex-row gap-8 justify-center items-center  mt-8">

                        {
                            placeDataList.slice(0, 2).map(i => <ArtPlaceBox key={i.id} data={i} />
                            )
                        }



                    </div>
                    <div className="flex justify-end mt-8 ">
                        <SeeMore to="artplace" />
                    </div>

                </div>

                <div className=" w-full   py-14" id="scroll3">
                    <TitleName2 title1={t('artFocus.video')} />
                    <div className=" grid grid-cols-2 gap-3 sm:gap-8 justify-center  mt-8">

                        {
                            videoDataList.slice(0, 2).map(i => <ArtVideoBox key={i.id} data={i} />
                            )
                        }


                    </div>
                    <div className="flex justify-end mt-8 mb-14">
                        <SeeMore to="artvideo" />
                    </div>



                </div>





            </div>

        </div>




    </div >
}

export default Home