

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";

import Line1 from '../../compontents/Line1.js'
import Line2 from '../../compontents/Line2.js'
import Swiper from '../../compontents/Swiper.js'
import TitleName from '../../compontents/TitleName.js'
import TitleName2 from '../../compontents/TitleName2.js'
import SeeMore from '../../compontents/SeeMore.js'

import Btn from '../../compontents/Btn.js'

import successImg from '../../assets/success.png'
import docImg from '../../assets/doc.png'


import ReactPlayer from 'react-player'

import { useGlobalFunc, time2date, separatorNumber } from "../../global/constants.js";
import { Pagination } from "@mui/material";
import UserApi from "../../services/UserApi.jsx";
import moment from 'moment'
import { useSelector } from "react-redux";
import SelectImgDialog from "../../compontents/SelectImgDialog.js";


const Home = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const userInfo = useSelector(state => state.userInfo);

    const [totalPrice, setTotalPrice] = useState(0)
    const [productPrice, setProductPrice] = useState(0)
    const [handlingPrice, setHandlingPrice] = useState(0)


    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');



    const handleClickOpen = (value) => {
        setSelectedValue(value)
        setOpen(true);
    };

    const handleClose = (value, type) => {
        setOpen(false);
        updateData(type, value)


    };



    const [payType, setPayType] = useState(null)
    const [sameOrderInfo, setSameOrderInfo] = useState(false)

    const [data, setData] = useState(
        {
            "ordererName": "",
            "ordererPhone": "",
            "email": "",
            "recipientName": "",
            "recipientPhone": "",
            "recipientAddress": "",
        }
    )

    const updateData = (i, value) => {
        setData(prevData => ({ ...prevData, [i]: value }));
    };


    function nextStep() {
        if (step == 0 && payType == null) {
            errorToast('請選擇付款方式')
            return
        }
        if (step == 0 && payType === 0 && totalPrice > 100000) {
            errorToast('價格總計超過NT$100,000 不能使用信用卡刷卡')
            return
        }
        // if (step == 0 && payType === 1 && data.img5 == null) {
        //     errorToast('請上傳匯款證明圖片')
        //     return
        // }
        if (step == 1 && sameOrderInfo) {
            setData({
                ...data, "recipientName": data.ordererName,
                "recipientPhone": data.ordererPhone
            })
        }

        setStep(step + 1)
    }

    const { setLoading, successToast, errorToast } = useGlobalFunc()

    const [step, setStep] = useState(0);


    const [dataList, setDataList] = useState(null)


    async function getDataList() {
        setLoading(true)
        try {
            let res = await UserApi.cartGet({})
            console.log(res.data.data)
            setDataList(res.data.data.products)
            setTotalPrice(res.data.data.totalPrice)
            setHandlingPrice(res.data.data.handlingPrice)
            setProductPrice(res.data.data.productPrice + res.data.data.servicePrice)
        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)
    }


    async function removeCart(id) {
        setLoading(true)
        try {
            let res = await UserApi.cartDelete({ "id": id })
            console.log(res.data.data)
            successToast(t('removeCart Success'))
            getDataList()

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
            setLoading(false)
        }

    }

    async function orderPay() {

        let tempProducts = []
        dataList.forEach(i => {
            tempProducts.push({ itemId: i.id })

        });
        console.log(tempProducts)

        let payMethod = ""
        if (payType == 0) {
            payMethod = "creditCard"
        }
        else if (payType == 1) {
            payMethod = "bankTransfer"
        }
        else if (payType == 2) {
            payMethod = "offline"
        }

        setLoading(true)
        try {
            let res = await UserApi.orderPay({
                "type": "priced",
                "payMethod": payMethod,
                "contactInfo": {
                    "ordererName": data.ordererName,
                    "ordererPhone": data.ordererPhone,
                    "email": data.email,
                    "recipientName": data.recipientName,
                    "recipientPhone": data.recipientPhone,
                    "recipientAddress": data.recipientAddress,
                },
                "products": tempProducts
            })
            //console.log(res.data)

            //let orderCoinToken = await axios.post(api_url, json, { headers: headers })

            //let docData = await axios.post(api_pay_url, { payload: orderCoinToken.data }, { headers: headers })
            //console.log(docData)
            if (payType > 0) {
                setStep(step + 1)

            }
            else {
                document.open();
                document.write(res.data)
            }
            //successToast(t('removeCart Success'))
            //getDataList()
            //nextStep()
        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
            setLoading(false)
        }
        setLoading(false)

    }

    useEffect(() => {
        getDataList()
    }, []);

    useEffect(() => {
        window.scroll(0, 0)
    }, [step]);

    const isFisrt = useRef(true)
    useEffect(() => {
        console.log(userInfo)
        if (userInfo && isFisrt.current) {
            isFisrt.current = false
            setData({
                "ordererName": userInfo.actualName,
                "ordererPhone": userInfo.phone,
                "email": userInfo.email,
            })

        }
    }, [userInfo]);



    return <div className="session1 relative pt-[40px] pb-20 bg-white" >


        <div className="flex justify-center items-center">
            {
                ["付款方式", "填寫資料", "確認訂單", "確認訂單"].map((i, index) => <div className="flex items-center">
                    <div>

                        <div className={`${step >= index ? 'bg-main9' : ''} p-0.5 w-10 h-10 rounded-full mx-auto mb-1`}>
                            <div className={`${step >= index ? 'bg-main9' : 'bg-black5'} flex  border-solid border-white border-2 w-full h-full rounded-full`}>
                                <div className="m-auto text-white font-bold">{index + 1}</div>
                            </div>
                        </div>
                        <p className={`${step >= index ? 'text-main9' : 'text-black6'}  text-xs text-center whitespace-nowrap`}>{i}</p>
                    </div>

                    {
                        index < 3 ?
                            <div className={`${step > index ? 'bg-main9' : 'bg-black5'}  w-8 h-0.5 mb-4`}>
                            </div> : ""
                    }

                </div>)

            }

        </div>

        {
            step == 0 && <div>
                <div className="mt-10 mb-10">
                    <p className="text-xl text-black9 font-bold mb-4">購物清單</p>

                    <table className="w-full text-sm mb-4" >
                        <thead className="">

                            <tr className="  text-main6 font-semibold ">
                                <th className="">藝術品</th>
                                <th >藝術品名稱</th>
                                <th className="">價格 / 服務費</th>
                                <th className="">小計</th>
                                <th className=""></th>

                            </tr>
                        </thead>

                        {
                            dataList != null ?
                                <tbody>

                                    {
                                        dataList.map(i => <tr className=" text-black9 font-semibold ">
                                            <td className=""><img className="w-20 h-20 object-cover rounded-lg" src={i.images[0].image} /></td>
                                            <td >{i.collectionName}</td>
                                            <td className="">NT${separatorNumber(i.salePrice)}
                                                <p className="text-black8 text-xs">服務費+NT${separatorNumber(i.serviceFee)}</p>
                                            </td>
                                            <td>NT${separatorNumber(i.salePrice + i.serviceFee)}</td>
                                            <td className=""><button onClick={() => removeCart(i.id)} className=" rounded-full font-semibold text-xl text-gray1">X</button></td>


                                        </tr>
                                        )
                                    }


                                </tbody>
                                : ""}
                    </table>
                    <div className="w-full h-0.5 bg-sec4"></div>

                    <div className="flex justify-between mt-8">
                        <Link to="/artlist"><button className="text-black6 flex items-center gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32" fill="none">
                                <path d="M21.957 3.70593C22.2526 4.04322 22.4004 4.44256 22.4004 4.90397C22.4004 5.36448 22.2526 5.76337 21.957 6.10066L13.2955 15.9831L21.9865 25.8993C22.2624 26.2141 22.4004 26.6076 22.4004 27.0798C22.4004 27.552 22.2526 27.9568 21.957 28.2941C21.6614 28.6314 21.3114 28.8 20.907 28.8C20.5033 28.8 20.1537 28.6314 19.8581 28.2941L9.92556 16.9275C9.80732 16.7926 9.72337 16.6465 9.6737 16.4891C9.62483 16.3317 9.60039 16.163 9.60039 15.9831C9.60039 15.8033 9.62483 15.6346 9.6737 15.4772C9.72337 15.3198 9.80732 15.1737 9.92556 15.0387L19.8877 3.6722C20.1636 3.3574 20.5034 3.2 20.907 3.2C21.3114 3.2 21.6614 3.36864 21.957 3.70593Z" fill="currentColor" stroke="currentColor" />
                            </svg>
                            繼續購物</button></Link>
                        <div>
                            <p className="text-black7 text-right text-xs font-bold">價格及服務費 NT${separatorNumber(productPrice)}</p>
                            <p className="text-black7  text-right text-xs font-bold mb-4">手續費 NT${separatorNumber(handlingPrice)}</p>

                            <p className="text-black9  text-right font-bold">總計 NT${separatorNumber(totalPrice)}</p>
                        </div>
                    </div>
                </div>

                <div className="mb-10">
                    <p className="text-xl text-black9 font-bold mb-4">請選擇付款方式</p>
                    <div className="w-full h-0.5 bg-sec4"></div>
                    <div className="flex gap-4 sm:gap-6 flex-col sm:flex-row py-8 items-start">
                        <button onClick={() => setPayType(0)} className={`${payType === 0 ? "bg-main9 text-white border-transparent" : " border-black8 text-black8"} border border-solid rounded-full w-60 py-2 text-sm  font-bold flex-none`}>信用卡刷卡 (一次付清)</button>
                        <div className="text-black8 text-sm">
                            在您輸入卡號與授權碼後， 會連結至輸入「3D驗證」密碼的頁面，如您尚未取得密碼，請您至發卡行網站取得密碼，唯發卡行與您知道此密碼，本平台亦不會記錄。
                        </div>
                    </div>

                    <div className="w-full h-0.5 bg-sec4"></div>
                    <div className="flex gap-4 sm:gap-6 flex-col sm:flex-row py-8 items-start">
                        <button onClick={() => setPayType(1)} className={`${payType === 1 ? "bg-main9 text-white border-transparent" : " border-black8 text-black8"} border border-solid rounded-full w-60 py-2 text-sm  font-bold flex-none`}>匯款</button>
                        <div className="text-black8 text-sm w-full">
                            <p className="font-bold">匯款帳號</p>
                            <p>臺灣銀行 北高雄分行</p>
                            <p>天堯科技文化股份有限公司</p>
                            <p className="mb-2">255001014079</p>
                            {
                                false &&
                                <div className="relative w-full">

                                    <p className="font-bold">匯款證明圖片</p>
                                    <div onClick={() => handleClickOpen('img5')} className="relative cursor-pointer w-full sm:w-60 aspect-[1/1]  border border-main2 border-solid flex justify-center items-center text-black8 text-xl font-bold text-center"><div>
                                        <img className="w-10 h-10 object-contain mb-4 mx-auto" src={docImg} />
                                        <img className=" w-full h-full absolute left-0 top-0 object-contain" src={data.img5} />
                                    </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>

                    <div className=" w-full h-0.5 bg-sec4"></div>
                    <div className=" flex gap-4 sm:gap-6 flex-col sm:flex-row py-8 items-start">
                        <button onClick={() => setPayType(2)} className={`${payType === 2 ? "bg-main9 text-white border-transparent" : " border-black8 text-black8"} border border-solid rounded-full w-60 py-2 text-sm  font-bold flex-none`}>門市</button>
                        <div className="text-black8 text-sm">
                            天堯科技文化股份有限公司門市
                        </div>
                    </div>

                    <div className="hidden w-full h-0.5 bg-sec4"></div>
                    <div className="hidden flex gap-4 sm:gap-6 flex-col sm:flex-row py-8 items-start">
                        <button className=" border border-solid border-black8 rounded-full w-60 py-2 text-sm text-black8 font-bold flex-none">信用卡刷卡 (分期付款)</button>
                        <div className="text-black8 text-sm">
                            僅限玉山銀行(3期)<br /><br />
                            在您輸入卡號與授權碼後， 會連結至輸入「3D驗證」密碼的頁面，如您尚未取得密碼，請您至發卡行網站取得密碼，唯發卡行與您知道此密碼，本平台亦不會記錄。
                        </div>
                    </div>
                    <div className="hidden w-full h-0.5 bg-sec4"></div>

                    <div className="hidden flex gap-4 sm:gap-6 flex-col sm:flex-row py-8 items-start">
                        <button className=" border border-solid border-black8 rounded-full w-60 py-2 text-sm text-black8 font-bold flex-none">ATM 匯款 / 臨櫃轉帳</button>
                        <div className="text-black8 text-sm">
                            選擇ATM匯款／臨櫃轉帳，請於匯款完成後至「會員中心」{">"}「藝術品訂購紀錄」中，填寫本筆訂單「轉出帳戶後五碼」，以方便我們為您快速核帳，謝謝。
                        </div>
                    </div>
                    <div className="w-full h-0.5 bg-sec4"></div>
                </div>
                <div className="flex justify-end mt-8">
                    <button onClick={() => nextStep()} className="btn1 !min-w-[140px] ">前往結帳</button>
                </div>

            </div>

        }
        {
            step == 1 && <div className="py-12">
                <div className="mb-4 mt-8 flex items-center gap-4 flex-wrap">
                    <p className="text-xl text-black9 font-bold ">訂購人資訊</p>

                </div>

                <div className="px-6 py-12 bg-white border-solid border-4 border-sec4 rounded-[20px] relative  w-full ">
                    <div className="flex text-black9 break-all w-full sm:max-w-[500px] justify-center mx-auto ">
                        <div className="w-full">
                            <div className="mb-6 w-full flex  items-center gap-2">
                                <p className="flex-none min-w-[120px]">訂購人姓名</p>
                                <input className="w-full" onChange={(e) => updateData('ordererName', e.target.value)} value={data.ordererName} />
                            </div>
                            <div className="mb-6 w-full flex  items-center gap-2">
                                <p className="flex-none min-w-[120px]">聯絡電話</p>
                                <input className="w-full" onChange={(e) => updateData('ordererPhone', e.target.value)} value={data.ordererPhone} />
                            </div>
                            <div className=" w-full flex  items-center gap-2">
                                <p className="flex-none min-w-[120px]">電子郵件</p>
                                <input className="w-full" onChange={(e) => updateData('email', e.target.value)} value={data.email} />
                            </div>

                        </div>
                    </div>

                </div>

                <div className="mb-4 mt-8 flex items-center gap-4 flex-wrap">
                    <p className="text-xl text-black9 font-bold ">收件人資訊</p>
                    <button onClick={() => setSameOrderInfo(!sameOrderInfo)} className={`${sameOrderInfo ? "bg-black9 text-white" : "border-solid  border-black5 text-black5"} px-8 py-[10px] text-sm border rounded-full`}>同訂購人資訊</button>
                </div>
                <div className="px-6 py-12 bg-white border-solid border-4 border-sec4 rounded-[20px] relative  w-full ">
                    <div className="flex text-black9 break-all w-full sm:max-w-[500px] justify-center mx-auto ">
                        <div className="w-full">
                            <div className="mb-6 w-full flex  items-center gap-2">
                                <p className="flex-none min-w-[120px]">收件人姓名</p>
                                {
                                    !sameOrderInfo ?
                                        <input className="w-full" onChange={(e) => updateData('recipientName', e.target.value)} value={data.recipientName} />
                                        :
                                        <div className="py-2">
                                            {data.ordererName}
                                        </div>
                                }
                            </div>
                            <div className="mb-6 w-full flex  items-center gap-2">
                                <p className="flex-none min-w-[120px]">聯絡電話</p>
                                {
                                    !sameOrderInfo ?
                                        <input className="w-full" onChange={(e) => updateData('recipientPhone', e.target.value)} value={data.recipientPhone} />
                                        :
                                        <div className="py-2">
                                            {data.ordererPhone}
                                        </div>
                                }

                            </div>
                            <div className=" w-full flex  items-center gap-2">
                                <p className="flex-none min-w-[120px]">收件地址</p>
                                <input className="w-full" onChange={(e) => updateData('recipientAddress', e.target.value)} value={data.recipientAddress} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex justify-between mt-8">
                    <button onClick={() => setStep(step - 1)} className="flex items-center text-black6 font-bold gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32" fill="none">
                            <path d="M21.957 3.70593C22.2526 4.04322 22.4004 4.44256 22.4004 4.90397C22.4004 5.36448 22.2526 5.76337 21.957 6.10066L13.2955 15.9831L21.9865 25.8993C22.2624 26.2141 22.4004 26.6076 22.4004 27.0798C22.4004 27.552 22.2526 27.9568 21.957 28.2941C21.6614 28.6314 21.3114 28.8 20.907 28.8C20.5033 28.8 20.1537 28.6314 19.8581 28.2941L9.92556 16.9275C9.80732 16.7926 9.72337 16.6465 9.6737 16.4891C9.62483 16.3317 9.60039 16.163 9.60039 15.9831C9.60039 15.8033 9.62483 15.6346 9.6737 15.4772C9.72337 15.3198 9.80732 15.1737 9.92556 15.0387L19.8877 3.6722C20.1636 3.3574 20.5034 3.2 20.907 3.2C21.3114 3.2 21.6614 3.36864 21.957 3.70593Z" fill="currentColor" stroke="currentColor" />
                        </svg>
                        回購物車
                    </button>
                    <button onClick={() => nextStep()} className="btn1 !min-w-[140px] ">確認資訊</button>
                </div>
            </div>
        }

        {
            step == 2 && <div className="py-12">
                <p className="text-xl text-black9 font-bold mb-4">訂購人資訊</p>

                <div className="mt-10 mb-10">
                    <p className="text-xl text-black9 font-bold mb-4">購物清單</p>

                    <table className="w-full text-sm mb-4" >
                        <thead className="">

                            <tr className="  text-main6 font-semibold ">
                                <th className="">藝術品</th>
                                <th className="">藝術品名稱</th>
                                <th className="">價格 / 服務費</th>
                                <th className="">小計</th>
                                <th className=""></th>

                            </tr>
                        </thead>

                        {
                            dataList != null ?
                                <tbody>
                                    {
                                        dataList.map(i => <tr className=" text-black9 font-semibold ">
                                            <td className=""><img className="w-20 h-20 object-cover rounded-lg" src={i.images[0].image} /></td>
                                            <td className="">{(i.collectionName)}</td>
                                            <td className="">NT${separatorNumber(i.salePrice)}
                                                <p className="text-black8 text-xs">服務費+NT${separatorNumber(i.serviceFee)}</p>
                                            </td>
                                            <td>NT${separatorNumber(i.salePrice)}</td>

                                        </tr>
                                        )
                                    }
                                </tbody>
                                : ""}
                    </table>
                    <div className="w-full h-0.5 bg-sec4"></div>

                    <div className="flex justify-end mt-8">
                        <div>
                            <p className="text-black7 text-right text-xs font-bold">價格及服務費 NT${separatorNumber(productPrice)}</p>
                            <p className="text-black7  text-right text-xs font-bold mb-4">手續費 NT${separatorNumber(handlingPrice)}</p>

                            <p className="text-black9  text-right font-bold">總計 NT${separatorNumber(totalPrice)}</p>
                        </div>
                    </div>
                </div>

                {
                    false &&
                    <div className="mb-10">
                        <p className="text-xl text-black9 font-bold mb-4">收件資訊確認</p>
                        <div className="w-full h-0.5 bg-sec4"></div>

                        <p className="font-bold text-black7 mb-1 mt-4">匯款證明圖片</p>
                        <div className="relative  w-full sm:w-60 aspect-[1/1]  border border-main2 border-solid flex justify-center items-center text-black8 text-xl font-bold text-center"><div>
                            <img className=" w-full h-full absolute left-0 top-0 object-contain" src={data.img5} />
                        </div>
                        </div>
                    </div>
                }

                <div className="mb-10">
                    <p className="text-xl text-black9 font-bold mb-4">訂購資訊確認</p>
                    <div className="w-full h-0.5 bg-sec4"></div>
                    <div className="flex text-black9 break-all w-full sm:max-w-[500px] justify-center mx-auto py-14">
                        <div className="w-full">
                            <div className="mb-6 w-full flex  items-center gap-2">
                                <p className="flex-none min-w-[120px] text-black6 font-bold">收件人姓名</p>
                                <p className="w-full">{data.recipientName}</p>
                            </div>
                            <div className="mb-6 w-full flex  items-center gap-2">
                                <p className="flex-none min-w-[120px] text-black6 font-bold">聯絡電話</p>
                                <p className="w-full">{data.recipientPhone}</p>
                            </div>
                            <div className=" w-full flex  items-center gap-2">
                                <p className="flex-none min-w-[120px] text-black6 font-bold">收件地址</p>
                                <p className="w-full">{data.recipientAddress}</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-0.5 bg-sec4"></div>
                </div>
                <div className="flex justify-between mt-8">
                    <button onClick={() => setStep(step - 1)} className="flex items-center text-black6 font-bold gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32" fill="none">
                            <path d="M21.957 3.70593C22.2526 4.04322 22.4004 4.44256 22.4004 4.90397C22.4004 5.36448 22.2526 5.76337 21.957 6.10066L13.2955 15.9831L21.9865 25.8993C22.2624 26.2141 22.4004 26.6076 22.4004 27.0798C22.4004 27.552 22.2526 27.9568 21.957 28.2941C21.6614 28.6314 21.3114 28.8 20.907 28.8C20.5033 28.8 20.1537 28.6314 19.8581 28.2941L9.92556 16.9275C9.80732 16.7926 9.72337 16.6465 9.6737 16.4891C9.62483 16.3317 9.60039 16.163 9.60039 15.9831C9.60039 15.8033 9.62483 15.6346 9.6737 15.4772C9.72337 15.3198 9.80732 15.1737 9.92556 15.0387L19.8877 3.6722C20.1636 3.3574 20.5034 3.2 20.907 3.2C21.3114 3.2 21.6614 3.36864 21.957 3.70593Z" fill="currentColor" stroke="currentColor" />
                        </svg>
                        修改訂購資訊
                    </button>
                    <button onClick={() => orderPay()} className="btn1 !min-w-[140px] ">確認結帳</button>
                </div>



            </div>

        }

        {
            step == 3 && <div className="py-12">
                <div className="w-full h-0.5 bg-sec4"></div>
                <div className="py-12 w-full">

                    <img className="w-16 object-contain mb-6 mx-auto" src={successImg} />
                    <p className="mb-6  text-center text-black9 text-xl font-bold">訂單已送出，感謝您的訂購！</p>
                    <p className="text-black7 text-sm text-center mb-8">
                        您可以在「會員中心」中查看處理進度。<br />
                        如有任何疑問也歡迎隨時與我們聯繫<br />
                        客服專線： 將由專人為您服務
                    </p>
                    <div className="flex justify-center">
                        <button onClick={() => navigate('/')} className=" btn2 !min-w-[120px]">前往首頁</button>
                    </div>
                </div>
                <div className="w-full h-0.5 bg-sec4"></div>
            </div>
        }

        <SelectImgDialog
            type={selectedValue}
            open={open}
            onClose={handleClose}
        />

    </div >

}

export default Home