
export const en = {
    "register success": "註冊成功，請等待審核通過。",



    days: "D",
    hours: "H",
    minutes: "M",
    seconds: "S",

    header: {
        artAuction: "Art Auction",
        onlineGallery: "Online Gallery",
        artFocus: "Art Focus",
        customerService: "Customer Service",
        aboutUs: "About Us",


    },

    male: "Male",
    female: "Female",
    unknown: "unknown",

    author: 'Author',
    sessionId: "Session ID",
    session: "Session",
    isOnlineGallery: 'Online Gallery',
    'about': 'About',
    'help': 'Help',
    'contact': 'Contact',
    'email': 'Email',
    'phone': 'Phone',
    'address': 'Address',
    'compiled': 'Compiled',
    followEvent: "Follow Event",
    realAddress: "24F-5, No. 91, Zhongshan 2nd Rd., Qianzhen Dist., Kaohsiung City 806612, Taiwan (R.O.C.)",
    login: "Login",
    logout: "Logout",

    newsType: {
        all: "All",
        newsFocus: "Focus",
        internationalNews: "International",
        artisticCrossing: "Crossing",
        artCritique: "Critique",
        artIndustry: "Industry",
        specialProject: "Project",
        auctionNews: "Auction",
        solicitation: "Solicitation",
    },
    memberCenterType: {
        orderrecord: "Order Record",
        registerplace: "Register Place",
        successorder: "Success Order",
        artcert: "Art Cert",
        applyfunction: "Apply Function",
        monthlySystem: "Monthly System",
        myfavorite: "Favorite",
        setting: "Setting",
        appraisalapply: "Appraisal Apply",
        commissionedapply: "Commissioned Apply",
        transferNFT: "Transfer NFT",
        buybackapply: "Buyback Apply",
        artistregister: "Artist Register",

        payrecord: "Pay Record",


        artManage: "Art Manage",
        intro: "Intro",
        exhibitionExperience: "Exhibition Experience",
        artistInfo: "Artist Info"
    },
    favoriteType: {
        'artArticle': "Art Article",
        'artVideo': "Art Video",
        'artGathering': "Art Gathering",
        'artist': "Artist",
        'product': "Art"

    },
    artAuction: {
        physicalAuction: "Physical Auction",
        onlineAuction: "Online Auction",
        auctionNews: "Auction News",

        auctionService: "Auction Service",
        "inAuctionService": {
            "text1": "Collecting Artwork for Auctions",
            "text2": "We are currently collecting artwork, paintings, and antique collectibles for auction. If you have any art or antiques with collectible value, please submit an application and participate in our collection event. We will provide professional appraisal and high-quality display services to ensure your collection receives the best market value.",
            "text3": "Online Auction Consignment",
            "text4": "We offer professional consignment services for online auctions. Simply submit the details and photos of your collection, and we will provide you with an objective appraisal, high-quality presentation, and marketing solutions to ensure your collection receives the best market value. We are dedicated to providing you with the best service and experience. Feel free to participate in our online auction events.",
            "text5": "Customer Service and Locations",
            "text6": "We provide quality customer service and locations for communication via email, phone, or fax. Our customer service team is ready to offer professional advice and assistance to ensure your needs are promptly met. Please don't hesitate to contact us; we look forward to serving you."
        },


        auctionIntro: "Auction Intro",
        "auctionIntroBtn1": "Rules for Physical Auctions",
        "auctionIntroBtn2": "Rules for Online Auctions"

    },
    "onlinegallery": {
        "artBtn1": "Online Galleries Questions",
        "artBtn2": "Artist FAQs",
        "artBtn3": "Maintenance of Artwork",
        "artBtn4": "Terms of Member",
        "artBtn5": "Privacy Policy",
        "artBtn6": "Disclaimer"
    },
    "artFocus": {
        "news": "Art News",
        "exhibition": "Exhibition Events",
        "place": "Art Hubs",
        "video": "Arts and Culture Videos"
    },
    "customerService": {
        "page1": "Appraisal of Collections",
        "inPage1": {
            "text0": "Appraisal of Collections",
            "text1": "Appraisal Subjects:",
            "text2": "We accept various types of collections for appraisal, including but not limited to art, antiques, jewelry, collectibles, coins, stamps, and more.",
            "text3": "Appraisal Method:",
            "text4": "Members can submit appraisal requests through the platform's 'Collection Appraisal' feature. Our professional appraisal team will assess the collections based on the provided information.",
            "text5": "Appraisal Fee:",
            "text6": "The appraisal fee is charged at a fixed rate. The specific charges will be communicated to the applicant by customer service after the application. Applicants must pay the fee to proceed with the appraisal.",
            "text7": "Appraisal Report:",
            "text8": "We will conduct the appraisal as soon as we receive the appraisal fee. The report will include detailed information about the collection and a recommended price range. If physical authentication is needed, we will arrange an appointment with the member.",
            "text9": "Disclaimer:",
            "text10": "Our appraisal reports are for reference only and do not constitute any trading advice or guarantee. Members should assume the risks of buying and selling on their own, and we are not responsible for any trading outcomes.",

            "text100": "Appraisal of Collections Process",
            "text101": "Members fill out the appraisal application form, providing basic information and pictures of the collection, and pay the fixed appraisal fee.",
            "text102": "After receiving the application, we arrange for a preliminary phone information confirmation and proceed with professional appraisers' evaluation after confirming receipt of the appraisal fee.",
            "text103": "Appraisers evaluate based on factors such as the condition, rarity, market demand, and provide a suggested appraisal range.",
            "text104": "We record the appraisal results in the appraisal report, detailing the appraisal process, criteria, and conclusion. The report is provided to the member in electronic format.",

            "text200": "Important Notes",
            "text201": "Collection appraisal is based on the information provided by the member and does not involve physically seeing the collection, which may lead to some margin of error. It is for reference only.",
            "text202": "For the buyback service, consumers should provide genuine photos of the collection to allow for inspection.",
            "text203": "Incomplete or incorrect information provided by the member may result in inaccuracies in the appraisal report. Detailed collection information is recommended.",
            "text204": "The platform reserves the right to decide whether to accept the member's collection for auction based on the appraisal report to ensure the platform's quality and reputation.",

            "text301": "Please note that the appraisal result is for reference only and does not represent the actual sale price of the collection. Market fluctuations can affect the value of the collection. Additionally, accurate collection information and photos must be provided, as providing false data may impact the appraisal result or result in the disqualification of appraisal.",

        },

        "page2": "Collection Buyback",
        "inPage2": {
            "text0": "Collection Buyback",
            "text1": "Buyback Application:",
            "text2": "Consumers can apply for the buyback service on our website. When applying, consumers need to provide detailed information about the collection, including name, number, purchase date, purchase price, etc.",
            "text3": "Buyback Price:",
            "text4": "We will determine the buyback price of the collection based on market conditions, the condition of the auction item, and the price at which the consumer purchased it. The buyback price will be proposed to the consumer and negotiated.",
            "text5": "Collection Inspection:",
            "text6": "We will inspect the collection to confirm if it meets the buyback requirements. The auction item must have been purchased on our website, be well-preserved, and free from significant defects. If the collection does not meet the buyback requirements, we have the right to refuse the buyback.",
            "text7": "Payment Methods:",
            "text8": "We will make payments to the consumer through methods such as bank transfers.",
            "text9": "Service Fee:",
            "text10": "We will charge a certain service fee for managing and handling buyback-related matters. The fee amount will be deducted from the buyback price.",
            "text11": "Reserve Rights:",
            "text12": "We reserve the right to modify and adjust the buyback rules based on the actual situation.",

            "text100": "Collection Buyback Process",
            "text101": "Consumers submit a collection buyback application on the website, providing detailed information about the collection, including name, number, purchase date, purchase price, etc.",
            "text102": "After receiving the application, the website will inspect the collection to confirm if it meets the buyback requirements. The auction item must have been purchased on the website, be well-preserved, and free from significant defects.",
            "text103": "If the collection meets the buyback requirements, the website will propose the buyback price to the consumer and negotiate. The buyback price is determined based on market conditions, the condition of the collection, and the consumer's purchase price. Once the buyback price is agreed upon, it becomes the final price, and the consumer cannot negotiate or make other requests.",
            "text104": "If the collection does not meet the buyback requirements, the website has the right to reject the buyback application. If the auction item has defects or damage during the transaction, the website has the right to reject the buyback application or adjust the price.",
            "text105": "The consumer must send the collection within the specified timeframe; otherwise, the buyback application will be considered invalid. After the collection arrives at the website, the website will conduct a final inspection to confirm whether the collection meets the buyback requirements.",
            "text106": "After confirming that the collection meets the buyback requirements, the website will make the buyback payment to the consumer. The payment will be made through methods such as bank transfers, and the service fee amount will be deducted from the buyback price.",

            "text200": "Important Notes",
            "text201": "The collection buyback service applies only to collections purchased on our website. If you purchased the collection elsewhere, we cannot provide the buyback service.",
            "text202": "Consumers should ensure that the collection information provided is accurate, including name, number, purchase date, purchase price, etc. Providing inaccurate information may affect the determination of the buyback price and the inspection of the collection.",
            "text203": "When applying for the collection buyback service, consumers should provide genuine photos of the collection for inspection.",
            "text204": "During the collection buyback service, consumers need to pay a certain service fee. Consumers should be aware of the fee amount to better understand how the buyback price is calculated.",
            "text205": "When sending the collection to the seller, consumers should choose a suitable logistics company to ensure the safe delivery of the collection to our facility.",
            "text206": "The platform reserves the right to modify and adjust the buyback rules based on the actual situation.",
            "text207": "If the collectible item exhibits defects or damage during the transaction process, we have the right to reject the buyback application or make price adjustments. Upon receiving the collectible, we will conduct a detailed inspection. If we find that the condition of the collectible does not meet the buyback requirements, we will negotiate with the consumer on how to proceed. If the buyback application is rejected, we will return the collectible and arrange for its return to the buyer.",

        },
        "page3": "Auction Consignment Application",
        "inPage3": {
            "text0": "Auction Consignment Application",
            "text1": "This platform provides online auctions, allowing members and artists to conduct online auctions within a fixed time period, attracting more buyers to participate in bidding.",

            "text100": "Auction Consignment Application Process",
            "text101": "You must be a member of our platform to apply for consignment online auctions.",
            "text102": "When filling out the application form in the backend, provide information about the auctioned artwork, including pictures, descriptions, and starting prices.",
            "text103": "After our review and confirmation that the data complies with the standards, the artwork will be listed in the next online auction.",
            "text104": "If the artwork receives bids and is sold, please send the artwork to the platform's designated receiving address within the specified time frame. The platform will assist in shipping to the buyer.",
            "text105": "Once your artwork is successfully sold, we will deduct a certain percentage of the transaction fee and transfer the funds to your account within a specified time. If your collection is not sold, we will notify you and make relevant arrangements.",
        },

        "page4": "Pricing Consignment Application",
        "inPage4": {
            "text0": "Pricing Consignment Application",
            "text1": "This platform provides a service for members and artists to consign priced sales. We will assist in showcasing the artwork and use diversified online marketing to ensure better exposure and sales performance.",

            "text100": "Pricing Consignment Application Process",
            "text101": "You must be a member of our platform to apply for pricing consignment auctions.",
            "text102": "When filling out the application form in the backend, provide information about the artwork, including pictures, descriptions, and the desired selling price.",
            "text103": "After your artwork is sold, please send the artwork to the platform's designated receiving address within the specified time frame. The platform will assist in shipping to the buyer.",
            "text104": "After our review and confirmation that the data complies with the standards, the artwork will be sold in the platform's online gallery.",
            "text105": "Once your artwork is successfully sold, we will deduct a certain percentage of the transaction fee and transfer the funds to your account within a specified time.",

            "text200": "Pricing Consignment Rules",
            "text201": "Application Process:",
            "text202": "Applicants need to fill out an application form in the backend, submitting information about the artwork and the selling price. Once the platform approves, the artwork can be listed.",
            "text203": "Selling Price:",
            "text204": "The selling price is determined by the applicant, and artists should consider the value of the collection, market demand, and their own needs when pricing.",
            "text205": "Transaction Fee:",
            "text206": "The platform will deduct a certain transaction fee, and the specific fee rate can be found in the platform's announcements.",
            "text207": "Other Notes:",
            "text208": "Artists must ensure that collection information is truthful and accurate, without false data or descriptions. Otherwise, the platform has the right to delist the artwork. Artists must not engage in any activities that violate laws and regulations on this platform; otherwise, the platform has the right to terminate cooperation and pursue legal responsibilities."
        },
        "page5": "Artist Application",
        "inPage5": {
            "text0": "Artist Application",
            "text1": "We provide a convenient feature that allows users to become artist accounts and further auction and price their collectibles. Once you become a member, you can apply to become an artist account. After the application review, you can start showcasing, auctioning, and selling your collectibles on our website. Through this feature, you can more easily manage your collectibles and get more exposure opportunities.",
            "text100": "Artist Application Process",
            "text101": "Log in as a member and click on \"Apply to Become an Artist.\"",
            "text102": "Fill out the application form, including personal information, work experience, and relevant supporting documents.",
            "text103": "Submit the application form, and the system will review it.",
            "text104": "After the review is approved, the system will send a confirmation email to the email address you provided.",
            "text105": "Once you successfully become an artist, you can list your collectibles in the backend and start auctioning or pricing them for sale.",
            "text200": "Important Notes",
            "text201": "The personal information and work information in the application form must be accurate and reliable.",
            "text202": "Artists must comply with relevant laws and regulations, protect copyrights and intellectual property rights.",
            "text203": "We will rigorously review the submitted application forms and related supporting documents. If false information is found, we reserve the right to cancel the application and may pursue legal action.",
            "text204": "If you have any questions during the application process, please feel free to contact our customer service."
        },
        "page6": "Auction Artwork Submission",
        "inPage6": {
            "text0": "Submission Period",
            "text1": "Collectibles are accepted year-round.",
            "text100": "Submission Categories",
            "text101": "Antiques and Collectibles",
            "text102": "Artists need to fill out an application form in the backend, providing details about their items and the desired selling price. Once approved by the platform, the items can be listed.",
            "text103": "Modern and Contemporary Paintings and Calligraphy",
            "text104": "We welcome works by renowned artists like Zhang Daqian, Wu Hu Fan, Qi Baishi, Hu Shi, Lin Yutang, Liang Shiqiu, Li Hongzhang, Zuo Zongtang, Zheng Banqiao, Xu Beihong, and other famous painters and calligraphers.",
            "text105": "Ancient Paintings and Calligraphy",
            "text106": "This includes artworks from different historical periods such as Song, Yuan, Ming, and Qing dynasties.",
            "text107": "Modern and Contemporary Art",
            "text108": "We accept works from well-known artists like Cai Guoqiang, Ai Weiwei, Xiao Qin, Liu Guosong, Takashi Murakami, Yoshitomo Nara, Yayoi Kusama, Damien Hirst, Jeff Koons, Banksy, and more.",
            "text200": "How to Submit",
            "text201": "Contact Customer Service",
            "text202": "On the Tianyao Auction platform, you can provide photos of the item, its era, category, dimensions, price, and any additional information.",
            "text203": "Professional Evaluation",
            "text204": "Our team of experts will assess and authenticate your items. If necessary, we will contact you to arrange a physical examination. Once approved, your items can be listed on the auction platform.",
            "text205": "Auction Events",
            "text206": "If your items pass the evaluation, please keep them until the auction ends."
        },

        "page7": "Customer Service and Service Locations",
        "inPage7": {
            "text0": "Customer Service and Service Locations",
            "text1": "Fax",
            "text2": "Phone"
        },
        "page8": "Artwork Auction Bidding - Real-time Notification Bot",
        "inPage8": {
            "text0": "Auction Bidding Bot - Line Bot",
            "text1": "Our auction bidding bot is a very convenient tool to help you participate in auctions more smoothly. Through our auction bidding bot, you can stay up to date with the latest status of auctions, such as whether your bid has been surpassed or if the auction is ending soon. Additionally, we provide many useful features like bidding, viewing current auctions, checking items you've bid on, and receiving winning bid notifications, making it easier for you to engage in auctions and get real-time updates. If you want to participate in auctions more effortlessly, feel free to use our auction bidding bot.",
            "text100": "How It Works",
            "text101": "Open the LINE application and click on the 'Add Friends' button in the upper right corner.",
            "text102": "Select the 'QR Code' option and scan the QR code below.",
            "text103": "Once the scan is complete, click the 'Add Friends' button.",
            "text104": "The system will confirm your successful addition to our LINE BOT, and you can start using related features."
        },

        cue1: "To apply for appraisal of collections, you must first login.",
        login: "Login"

    },
    aboutus: {
        page1: "Our Story",
        page2: "Our Mission",
        page3: "Our Information",
        page4: "Events",
        page5: "Contact Us",

    },
    path: {
        artauction: "Art Auction",
        physical: "Physical",
        intro: "Intro",
        catolog: "Catolog",
        online: "Online",
        theme: "Theme",
        past: "Past",

        artistlist: "Artist",
        artist: "Artist",

        cart: "Cart",
        artlist: "Art List",
        art: "Art",


        onlinegallery: "Online Gallery",


        customerservice: "Customer Service",
        aboutus: "About Us",


        artfocus: "Art Focus",

        artnews: "Art News",
        exhibition: "Exhibition",
        artplace: "Art Place",
        artvideo: "Art Video",

        FAQ: "FAQ",
        QandA: "Q&A",
        artmaintenance: "Art Maintenance",
        terms: "Terms",
        rules: "Rules",



        membercenter: "Member Center",
        artistcenter: "Artist Center",

        login: "Login",
        register: "Register",
        "": "Home",



    }



}