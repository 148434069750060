

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";

import PathLocation from "../../../compontents/PathLocation.js";



const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);


    const handleDataList = (selGroupIndex, selIndex) => {
        const temp = dataList.map((item, index) => {
            console.log(item)
            if (index === selGroupIndex) {
                item.groupList[selIndex].isOpen = !item.groupList[selIndex].isOpen
                console.log(item)
                return { ...item };
            }
            return item;
        });
        setDataList(temp);
    };


    const [dataList, setDataList] = useState(
        [
            {
                groupTitle: "成為藝術家",
                groupList: [
                    {
                        isOpen: false,
                        title: "我該如何在線上藝廊刊登我的個人介紹及作品?",
                        content: "當您完成註冊本網站的會員，您可以到「會員中心」的「申請功能」，並點選「藝術家申請」，完成所需資料後待平台審核通過即可。"
                    },
                    {
                        isOpen: false,
                        title: "我想了解藝術家後台的收費模式?",
                        content: "本平台提供讓新銳藝術家可以在無需支付後台上架費用的情況下，透過自主管理與銷售作品的平台，展示自己的創作才華。我們提供了多項功能， 例如個人網頁版面、無限制件數的作品上傳、個人展經歷資料庫、 Facebook 等，協助藝術家更精準地行銷。同時，我們致力於數位行銷， 吸引更多訪客來到本平台，並將收藏家名單轉化為銷售量，減少藝術家參加實體展覽的昂貴行銷費用。藝術家的作品也能在平台上得到良好的曝光機會，讓收藏家隨時能夠欣賞和購買潛力藝術家的作品。我們針對交易金額從中收取 25%的交易分潤，銷售款項以月結制，以協力推廣藝術家作品銷售。"
                    },
                    {
                        isOpen: false,
                        title: "假如我上傳作品到非池中線上藝廊後，可以自行販售作品或是辦展嗎?",
                        content: "我們非常歡迎藝術家利用「線上藝廊」平台來拓展其潛在客戶群，並讓更多人欣賞到他們的作品。藝術家所上架的作品，售出後必須盡快在後台上 更新標示為「已售出」，以避免買家重複下標。 所有出現在我們網站上的藝術品資訊，均由藝術家或提供廠商、組織負責 其內容。我們會盡力確保資訊的正確性，但對於其可能造成的任何後果， 本公司概不負責任何法律責任。"
                    },
                    {
                        isOpen: false,
                        title: "我提供的作品，是否需要裱框?",
                        content: "我們沒有限制作品一定要裱框，但我們建議藝術家在寄送作品時使用簡單的裝裱方式，以保護作品的完整性。對於紙本水彩作品，請使用不易變形、有厚度的載體(例如硬紙板或捲筒)進行包裝。如果作品已經裝框，藝術家可以在後台上傳作品時選擇「含框」選項，方便有興趣購買裝框作品的買家進行挑選。我們希望能提供最好的保護和展示方式，讓藝術品在寄送過程中保持完好無損，讓收到作品的買家可以感受到最好的藝術品質。"
                    },
                    {
                        isOpen: false,
                        title: "提供作品是否有建議事項?",
                        content: "為了呈現作品最真實的外觀，提供高品質的照片是很重要的(長寬單邊儘量在 2000 像素以上，分辨率為 300dpi，RGB 色彩模式)，以供製作文宣品和觀賞。此外，建議在後台上傳作品的細節圖片，例如提供 3-4 張不同角度(特別是立體作品)或近拍照片。 此外，完整的作品理念敘述和作品資訊，有助於收藏家進行參考、了解作品的背景和意義。因此，請在作品上傳時，一併提供作品的故事、創作理念、使用的材料、尺寸等詳細資訊，讓收藏家更深入地認識您的作品。"
                    },
                    {
                        isOpen: false,
                        title: "作品的訂價是由誰決定?",
                        content: "原創的藝術品是由藝術家自行決定，平台不會干預價格。"
                    },
                    {
                        isOpen: false,
                        title: "是否可以調整作品的價格?",
                        content: "若需要調整價格，可以自行於後台系統修改。"
                    },
                    {
                        isOpen: false,
                        title: "若作品售出時，要如何寄送?",
                        content: "當買家完成訂單並支付費用後，我們會通知藝術家進行出貨準備，藝術家需自行支付單程運費，將作品寄送至高雄市前鎮區中山二路 91 號 24 樓之 5 天堯科技收。如果買家在收到作品後七天內決定退貨，我們將承擔退貨運費，並確認作品狀況後為買家辦理退款手續。請注意，稍早藝術家支付的單趟運費不會退款。"
                    },
                    {
                        isOpen: false,
                        title: "作品售出後，要如何處理作品保證書、發票、包裝事宜?",
                        content: "當藝術家在線上售出作品後，藝術家需自行負責將作品運送至我們的收貨地址，收到您的作品後，我們的工作人員會重新包裝作品，並安排運送給買方，同時開立發票與保證書。為確保作品在運輸過程中不受損，建議藝術家在第一次寄送時使用具有防護功能的包裝材料，如氣泡布、泡棉或紙箱等。我們會透過手機簡訊和電子郵件通知藝術家寄送作品的進度和相關訊息，藝術家可隨時留意。如果買方在 7 天鑑賞期內退貨，我們會負責運送作品回到藝術家手中，但之前藝術家所負擔的運費恕不予退款。"
                    },

                ],
            },
          

        ]
    )


    useEffect(() => {

    }, []);


    return <div className="session1 relative pt-[40px] pb-20 bg-white" >

        <PathLocation />
        <p className="text-2xl font-bold text-black9">藝術家問答集</p>

        <div className="py-14">
            {
                dataList.map((group, groupIndex) =>
                    <div className=" mb-5 w-full border border-solid border-main2 rounded-[40px] bg-white px-[20px] sm:px-[56px] py-[40px]">
                        <p className="mb-12 text-base font-bold text-main7">{group.groupTitle}</p>

                        <div className="flex flex-col gap-6">
                            {
                                group.groupList.map((i, index) => <div className="cursor-pointer" onClick={() => handleDataList(groupIndex, index)}>
                                    <p className={`text-lg font-bold hover:text-main4 anim ${i.isOpen ? 'text-main4' : 'text-main9'}`}>Q{index + 1}. {i.title}</p>
                                    {
                                        <p className={`text-sm font-medium text-black8  overflow-hidden ${i.isOpen ? ' opacity-100 anim mt-4 mb-6' : 'opacity-0 h-0'}`}>{i.content}</p>
                                    }

                                </div>)
                            }
                        </div>

                    </div>

                )
            }

        </div>




    </div >
}

export default Home