



import { useTranslation } from "react-i18next";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";


import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';


import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Compressor from 'compressorjs';
import { ReactComponent as AddImg } from '../assets/addImg.svg'


import closeImg from '../assets/close.png'
import { useSelector } from "react-redux";
import { useGlobalFunc } from "../global/constants";
import UserApi from "../services/UserApi.jsx";






const SelectImgDialog = (props) => {
    const { onClose, selectedValue, open, type } = props;

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('768'));

    const inputFileRef = useRef(null);

    const handleClose = () => {
        onClose("")
        if (inputFileRef.current) {
            inputFileRef.current.value = null;
        }

        imgBase64.current = null
        setCompressedFile(null)

    };

    const handleClickOpen = (value) => {
        onClose(value);
    };


    const handleClickSelectImg = () => {
        onClose(imgBase64.current,type);
        handleClose()
    };

    const { setLoading, successToast, errorToast, updateMe } = useGlobalFunc()


    function callInputImg(type) {
        let call
        if (type == 1) {
            call = document.querySelector('#inputimg1')
        }
        call.click()
    }
    const handleCompressedUpload = (e, type) => {
        const image = e.target.files[0];
        new Compressor(image, {
            quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
            convertSize: 2000000,
            success: (result) => {
                // compressedResult has the compressed file.
                // Use the compressed file to upload the images to your server. 
                console.log(result)
                if (type == 1) {
                    setCompressedFile(URL.createObjectURL(result))
                }

                var reader = new FileReader();
                reader.readAsDataURL(result);
                reader.onloadend = function () {
                    var base64data = reader.result;
                    //console.log(base64data);
                    //base64data = base64data.split(',')[1]
                    if (type == 1) {
                        imgBase64.current = base64data
                    }

                    console.log(base64data)
                }
            },
        });
    };

    const imgBase64 = useRef('')
    const [compressedFile, setCompressedFile] = useState(null);

    return (
        <Dialog maxWidth="2000" PaperProps={{ sx: { bgcolor: "transparent" } }} fullScreen={fullScreen} onClose={handleClose} open={open} >
            <div className="w-full h-full" onClick={handleClose}></div>
            <div className="md:w-[510px] text-white absolute md:relative bottom-0 left-0 w-full bg-white  p-[12px] md:p-[28px] pt-[17px] rounded-t-md">
                <div className="relative py-7">

                    <div className="text-black absolute top-0 right-0 text-base font-medium"  >
                        <img src={closeImg} className="w-6 h-6 object-contain cursor-pointer" alt="" onClick={handleClose} />
                    </div>
                    <p className="text-lg md:text-2xl text-main9 mb-[37px] font-bold text-center "></p>

                    <div className="relative aspect-[16/9] rounded-md  border-solid border border-main3 flex  shadow-md  overflow-hidden mb-6">
                        {
                            compressedFile ?
                                <img src={compressedFile} className=" relative  w-full h-full object-contain" />
                                : ""
                        }
                        {
                            !compressedFile ? <div className="m-auto ">
                                <AddImg className="mx-auto text-main9 mb-2 w-20 h-20 object-contain" />
                            </div>
                                : ""

                        }
                        <input
                            className="hidden"
                            id="inputimg1"
                            accept="image/png, image/jpeg, image/jpg"
                            type="file"
                            ref={inputFileRef}
                            onChange={(event) => handleCompressedUpload(event, 1)}
                        />

                        <div
                            type="button"
                            className="absolute top-0 left-0 w-full h-full bg-text cursor-pointer"
                            onClick={() => callInputImg(1)}
                        >
                        </div>
                    </div>

                    <div className="mt-8 flex justify-center">
                        <button onClick={() => handleClickSelectImg()} className="min-w-[140px] bg-main6 px-6 py-3 rounded-full font-semibold text-sm text-white">確定</button>
                    </div>
                </div>

            </div>
        </Dialog>
    );
}


export default SelectImgDialog