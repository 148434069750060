
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, Link, useNavigate } from "react-router-dom";


import arrowRightWLImg from '../assets/arrowRightWL.png'

const StickyList = ({ list = [] }) => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const location = useLocation()


  const [currentIndex, setCurrentIndex] = useState(0)


  function handleScroll() {
    for (let i = list.length - 1; i >= 0; i--) {
      let temp = document.querySelector(`#scroll${i}`)
      if (temp.getBoundingClientRect().top <= window.innerHeight / 4) {
        setCurrentIndex(i)
        return
      }
    };
  }

  function scrollToId(id){
    let temp = document.querySelector(id).getBoundingClientRect().top + window.pageYOffset - window.innerHeight / 4 + 5;
    console.log(temp)
    window.scrollTo(0, temp);
  }

  useEffect(() => {

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="sticky top-40 text-lg font-bold">
      {
        list.map((i, index) => <div className={`${index == currentIndex ? "text-main8" : "text-main3"}  mb-6 `} key={i}>
          <span onClick={()=>scrollToId(`#scroll${index}`)} className=" cursor-pointer" >{t(i)}</span>
        </div>)
      }
    </div>
  )
}
export default StickyList