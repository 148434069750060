

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";


import successImg from '../../assets/success.png'
import UserApi from "../../services/UserApi.jsx";
import { useGlobalFunc } from "../../global/constants.js";

// import homeshadowImg from '../../assets/homeshadow.png'
// import homescrollImg from '../../assets/homescroll.png'

// import homepic1Img from '../../assets/homepic1.png'
// import homepic2Img from '../../assets/homepic2.png'


const PaySuccess = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search);

    const { setLoading, successToast, errorToast } = useGlobalFunc()



    useEffect(() => {


    }, []);


    return <div className="session1 relative pt-[40px] pb-20 bg-white" >



        {
            true && <div className="py-12">
                <div className="w-full h-0.5 bg-sec4"></div>
                <div className="py-12 w-full">

                    <img className="w-16 object-contain mb-6 mx-auto" src={successImg} />
                    <p className="mb-6  text-center text-black9 text-xl font-bold">訂單已送出，感謝您的訂購！</p>
                    <p className="text-black7 text-sm text-center mb-8">
                        您可以在「會員中心」的「消費紀錄」中查看訂單處理進度。<br />
                        如有任何疑問也歡迎隨時與我們聯繫<br />
                        客服專線： 將由專人為您服務
                    </p>
                    <div className="flex justify-center">
                        <button onClick={() => navigate('/')} className=" btn2 !min-w-[120px]">前往首頁</button>
                    </div>
                </div>
                <div className="w-full h-0.5 bg-sec4"></div>
            </div>
        }
    </div >
}



export default PaySuccess