

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";


import pic3Img from '../../../assets/3.png'

import pic5Img from '../../../assets/5.png'


import { useGlobalFunc, time2date } from "../../../global/constants.js";
import { Pagination } from "@mui/material";
import UserApi from "../../../services/UserApi.jsx";
import moment from 'moment'
import PathLocation from "../../../compontents/PathLocation";
import ArtPlaceBox from "../../../compontents/ArtPlaceBox";

const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search);


    const { setLoading, successToast, errorToast } = useGlobalFunc()
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
        getDataList(value)
    };

    const [dataList, setDataList] = useState(null)




    async function getDataList(page) {
        setLoading(true)
        try {
            let res = await UserApi.artGatheringSearch({ page: page })
            console.log(res.data.data)
            setDataList(res.data.data)
            setTotalPage(res.data.total == 0 ? 1 : Math.ceil(res.data.total / 10))

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)

    }

    useEffect(() => {
        getDataList(1)
    }, []);


    return <div className="w-full">

        <div className=" relative pt-[40px] pb-20 bg-main9" >
            <div className="session1">

                <PathLocation color="white" />

                <div className="flex flex-wrap gap-4 items-end">
                    <p className="text-2xl text-white font-semibold">藝術聚點</p>
                    {/* {
                        ["畫廊總覽", "藝文單位", "藝術家", "藝術品"].map((i, index) => <div className={`${index == 0 ? "text-white" : "text-[#47517C]"}  font-semibold text-base `} key={i}>
                            <button>{i}</button>
                        </div>)
                    } */}
                </div>

                <div className=" grid grid-cols-1 sm:grid-cols-2 gap-8 justify-center  mt-8">
                    {
                        dataList && dataList.slice(0, 2).map(i => <div onClick={()=>navigate(`${i.id}`)} key={i.id} className=" cursor-pointer text-black9 text-base  ">
                            <img className="w-full aspect-[288/182] object-cover rounded-[10px]" src={i.images && i.images.banner} />
                            <div className="flex items-center gap-6 mt-6">
                                <img className="w-[15%] rounded-[10px] flex-none object-contain" src={i.images.logo} />
                                <div className="w-full "  >
                                    <div className=" truncate text-white font-semibold text-sm mb-2">{i.name}</div>
                                    <div className=" truncate text-white font-semibold text-xs">{i.address}</div>

                                </div>
                            </div>
                        </div>
                        )
                    }
            

                </div>

            </div>

        </div>
        <div className="session1 relative pt-[40px] pb-20 bg-white ">

            {
                dataList != null ?
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-10">

                        {
                            dataList.slice(2).map(i => <ArtPlaceBox key={i.id} data={i} />
                            )
                        }

                    </div>
                    : ""
            }

            {
                dataList != null ?
                    <>
                        {
                            dataList.length == 0 ?
                                <div className="  text-center text-gray-400 text-lg w-full font-bold mb-20 ">
                                    <div>暫無紀錄</div>
                                </div> : ""

                        }

                        <div className="flex w-full  mt-2 mb-10">
                            <div className="mx-auto">
                                <Pagination count={totalPage} page={page} onChange={handleChange} />
                            </div>
                        </div>
                    </>
                    : ""
            }

        </div>




    </div >
}

export default Home