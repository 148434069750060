

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";

import PathLocation from "../../../compontents/PathLocation.js";



const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);


    const handleDataList = (selGroupIndex, selIndex) => {
        const temp = dataList.map((item, index) => {
            console.log(item)
            if (index === selGroupIndex) {
                item.groupList[selIndex].isOpen = !item.groupList[selIndex].isOpen
                console.log(item)
                return { ...item };
            }
            return item;
        });
        setDataList(temp);
    };


    const [dataList, setDataList] = useState(
        [
            {
                groupTitle: "作品保護問答",
                groupList: [
                    {
                        isOpen: false,
                        title: "如何避免藝術品退色或褪色?",
                        content: "當您完成註冊本網站的會員，您可以到「會員中心」的「申請功能」，並點選「藝術家申請」，完成所需資料後待平台審核通過即可。"
                    },
                    {
                        isOpen: false,
                        title: "如何清潔藝術品表面污漬?",
                        content: "清潔藝術品表面污漬需要謹慎處理，應該先詢問專業人士的建議。在清潔前，需要了解作品的材料、年代、現狀等信息，以選擇合適的清潔方法和工具。一般來說，最好使用純棉布或羊毛刷等非磨損性工具清潔表面污漬。但是，對於高價值的藝術品，最好由專業人員進行清潔或歡迎來電詢問。"
                    },
                    {
                        isOpen: false,
                        title: "購買油畫作品，有沒有須要注意的維護事項?",
                        content: "(1) 避免油畫長時間覆蓋氣泡布，以免濕氣或霉菌危害作品。建議定期更換懸掛位置，且保持室內通風。\n\n(2) 確保適當的溫度和濕度，避免高濕度、溫度變化對畫作造成損害。油畫不適合懸掛在廚房、浴室等濕度高的場所。掛畫時，需要與牆面形成一 定角度，以維持通風並降低吸濕程度。 \n\n(3) 避免油畫與水接觸，請不要使用水清洗畫作。 \n\n(4) 適當懸掛畫作，避免掛在人們經常走動的位置，以避免碰撞和損壞。\n\n(5) 儲存畫作時須保持一定距離，避免畫作重疊造成損害。\n\n(6) 若油畫有灰塵時，請使用較為柔軟的刷子輕刷表面。\n\n(7) 室內掛畫時，建議避免吸煙，以免對畫作造成損害。\n\n(8) 請不要使用手直接接觸藝術品，因為手上的汗水、污垢、皮屑等都可能對畫作造成損害。\n\n(9) 避免陽光直接照射畫作，以避免褪色和變暗。"
                    },
                    {
                        isOpen: false,
                        title: "裱框的作品，有沒有須要注意的維護事項?",
                        content: "當作品被裱框後，會有一層玻璃或壓克力遮蔽作品，以保護其品質。但需要注意的是，這些材料不能直接接觸到作品，以免導致受損。此外，玻璃也不能完全防潮，因此需要將作品保存在適當的溫度和濕度下，以確保其長期保存的完好。"
                    },

                ],
            },
          

        ]
    )


    useEffect(() => {

    }, []);


    return <div className="session1 relative pt-[40px] pb-20 bg-white" >

        <PathLocation />
        <p className="text-2xl font-bold text-black9">作品裱框與維護</p>

        <div className="py-14">
            {
                dataList.map((group, groupIndex) =>
                    <div className=" mb-5 w-full border border-solid border-main2 rounded-[40px] bg-white px-[20px] sm:px-[56px] py-[40px]">
                        <p className="mb-12 text-base font-bold text-main7">{group.groupTitle}</p>

                        <div className="flex flex-col gap-6">
                            {
                                group.groupList.map((i, index) => <div className="cursor-pointer" onClick={() => handleDataList(groupIndex, index)}>
                                    <p className={`text-lg font-bold hover:text-main4 anim ${i.isOpen ? 'text-main4' : 'text-main9'}`}>Q{index + 1}. {i.title}</p>
                                    {
                                        <p className={`text-sm font-medium text-black8  overflow-hidden ${i.isOpen ? ' opacity-100 anim mt-4 mb-6' : 'opacity-0 h-0'}`}>{i.content}</p>
                                    }

                                </div>)
                            }
                        </div>

                    </div>

                )
            }

        </div>




    </div >
}

export default Home