import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGlobalFunc } from "../global/constants"
import UserApi from "../services/UserApi"


const AddFavorite = ({ color = "text-[#ECECEC]", hoverColor = "hover:text-sec4", data }) => {

  const navigate = useNavigate()
  const userInfo = useSelector(state => state.userInfo);

  const { setLoading, successToast, errorToast } = useGlobalFunc()

  async function addFavorAction() {
    if (userInfo == null) {
      errorToast('請先登入')
      //navigate('/login')
      return
    }

    try {
      let res = await UserApi.favoriteAdd({
        "itemId": data.itemId,
        "type": data.type
      })
      console.log(res.data)
      successToast('已加入我的最愛')
    }
    catch (errors) {
      //errorToast(errors?.response?.data?.message || errors.toString())
      if (errors?.response?.data?.message == "This favorite already exists") {
        successToast("已加入我的最愛")
      }

    }

  }

  return <div onClick={() => addFavorAction()} className={` cursor-pointer w-12 object-contain ${color} ${hoverColor} `}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none">
      <path d="M0 24C0 30.3652 2.52856 36.4697 7.02944 40.9706C11.5303 45.4714 17.6348 48 24 48C30.3652 48 36.4697 45.4714 40.9706 40.9706C45.4714 36.4697 48 30.3652 48 24C48 17.6348 45.4714 11.5303 40.9706 7.02944C36.4697 2.52856 30.3652 0 24 0C17.6348 0 11.5303 2.52856 7.02944 7.02944C2.52856 11.5303 0 17.6348 0 24ZM22.8 16.845L23.97 18L25.17 16.821C26.3594 15.6726 27.9492 15.0326 29.6025 15.0365C31.2558 15.0404 32.8426 15.6879 34.0266 16.8418C35.2106 17.9958 35.8987 19.5654 35.9451 21.2181C35.9914 22.8707 35.3924 24.4765 34.275 25.695L25.095 35.517C24.9547 35.6669 24.7851 35.7863 24.5968 35.868C24.4084 35.9497 24.2053 35.9918 24 35.9918C23.7947 35.9918 23.5916 35.9497 23.4032 35.868C23.2149 35.7863 23.0453 35.6669 22.905 35.517L13.71 25.683C13.1297 25.0861 12.6726 24.3808 12.3649 23.6073C12.0572 22.8338 11.9048 22.0073 11.9165 21.1749C11.9401 19.4938 12.6306 17.891 13.836 16.719C15.0414 15.547 16.663 14.9019 18.3441 14.9255C20.0252 14.9491 21.628 15.6396 22.8 16.845Z" fill="currentColor" />
    </svg>
  </div>
}
export default AddFavorite