

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";



import AppraisalList from '../Appraisal/AppraisalList.js'
import ShipRegister from '../../../../../../compontents/ArtApply/ShipRegister.js'
import OrderAuction from '../../../../../../compontents/ArtApply/OrderAuction.js'
import ConfrimApply from '../../../../../../compontents/ArtApply/ConfrimApply.js'
import SuccessApply from '../../../../../../compontents/ArtApply/SuccessApply.js'
import UserApi from "../../../../../../services/UserApi.jsx";
import { useGlobalFunc } from "../../../../../../global/constants.js";
import { isValidEmail, isValidPhoneNumber } from "../../../../../../global/regex.js";



const CommissionedApply = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);


    const { setLoading, successToast, errorToast, alertDialog } = useGlobalFunc()

    const [step, setStep] = useState(0)

    const [successId, setSuccessId] = useState(0)

    const banner = []
    const [currBanner, setCurrBanner] = useState(0)
    const countRef = useRef()

    function backStep0() {
        setStep(0)
        window.scroll(0, 0)
    }

    function prevStep() {
        setStep(step - 1)
        window.scroll(0, 0)
    }

    const nextStep = (event) => {
        if (step == 0) {
            setData(initData)
        }

        if (step == 1 || step == 2) {
            event.preventDefault();
        }
        if (step == 1) {
            if (!(data.img1 || data.img2 || data.img3 || data.img4 || data.img5)) {
                errorToast('必須上傳最少一張圖片')
                return
            }
        }
        if (step == 2) {
            if (!isValidEmail(data.email)) {
                errorToast('請輸入正確的電子郵件格式')
                return;
            }
            if (!isValidPhoneNumber("+886"+data.phone)) {
                errorToast('請輸入正確的電話號碼')
                return
            }
        }

        setStep(step + 1)
        window.scroll(0, 0)
    }

    const applyFuncList = ['appraisalApply', 'commissionedApply', 'transferNFT', 'buyBackApply', 'artistRegister']


    let initData = {
        name: "",
        area: "",
        phone: "",
        email: "",
        address: "",

        collectionName: "",
        artist: "",
        countryOfOrigin: "",
        material: "",
        type: "",
        creationYear: "",
        isFramed: "",
        height: "",
        width: "",
        depth: "",
        signature: "",
        damageLocation: "",
        repairDegree: "",
        purchaseSource: "",
        sourceInformation: "",
        paymentAmount: "",
        details: ""
    }

    const [data, setData] = useState(

    )

    const updateData = (i, value) => {
        setData(prevData => ({ ...prevData, [i]: value }));
    };



    async function sendAppraisalApply() {
        setLoading(true)
        try {
            let images = []
            if (data.img1) {
                images.push({ base64Img: data.img1 })
            }
            if (data.img2) {
                images.push({ base64Img: data.img2 })
            }
            if (data.img3) {
                images.push({ base64Img: data.img3 })
            }
            if (data.img4) {
                images.push({ base64Img: data.img4 })
            }
            if (data.img5) {
                images.push({ base64Img: data.img5 })
            }
            let res = await UserApi.appraisalApply({
                "identityType": "user",
                "collectionName": data.collectionName,
                "artist": data.artist,
                "countryOfOrigin": data.countryOfOrigin,
                "material": data.material,
                "creationYear": data.creationYear,
                "type": data.type,
                "isFramed": data.isFramed,
                "height": data.height,
                "width": data.width,
                "depth": data.depth,
                "signature": data.signature,
                "damageLocation": data.damageLocation,
                "repairDegree": data.repairDegree,
                "purchaseSource": data.purchaseSource,
                "sourceInformation": data.sourceInformation,
                "paymentAmount": data.paymentAmount,
                "currency": "TWD",
                "details": data.details,
                "contactInfo": {
                    "name": data.name,
                    "contactPhone": "+886" + data.phone,
                    "email": data.email,
                    "contactAddress": data.address
                },
                "images": images
            })
            console.log(res.data.data)
            setSuccessId(String(res.data.data))
            nextStep()
            alertDialog('您已申請成功')

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)
    }

    useEffect(() => {

    }, []);


    return <div>

        {
            step == 0 ?
                <AppraisalList prevStep={prevStep} nextStep={nextStep} />
                : ""
        }
        {
            step == 1 ?
                <OrderAuction data={data} updateData={updateData} prevStep={prevStep} nextStep={nextStep} />
                : ""
        }
        {
            step == 2 ?
                <ShipRegister data={data} updateData={updateData} prevStep={prevStep} nextStep={nextStep} />
                : ""
        }
        {
            step == 3 ?
                <ConfrimApply data={data} prevStep={prevStep} nextStep={sendAppraisalApply} />
                : ""
        }
        {
            step == 4 ?
                <SuccessApply backStep0={backStep0} id={successId} />
                : ""
        }



    </div>

}

export default CommissionedApply