

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";

import Line1 from '../../../compontents/Line1.js'
import Swiper from '../../../compontents/Swiper.js'
import TitleName from '../../../compontents/TitleName.js'
import TitleName2 from '../../../compontents/TitleName2.js'
import SeeMore from '../../../compontents/SeeMore.js'


import linesImg from '../../../assets/lines.png'
import lines2Img from '../../../assets/home/lines2.png'


import PathLocation from "../../../compontents/PathLocation.js";
import UserApi from "../../../services/UserApi.jsx";
import { useGlobalFunc } from "../../../global/constants.js";
import CountDownTimer from "../../../compontents/CountDownTimer.js";



// import homeshadowImg from '../../../assets/homeshadow.png'
// import homescrollImg from '../../../assets/homescroll.png'

// import homepic1Img from '../../../assets/homepic1.png'
// import homepic2Img from '../../../assets/homepic2.png'


const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search);

    const [sessionsId, setSessionsId] = useState('');


    const { setLoading, successToast, errorToast } = useGlobalFunc()

    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
        getDataList(value)
    };

    const [data, setData] = useState(null)


    async function getDataList(sessionsId) {
        setLoading(true)
        try {
            let res = await UserApi.productAuctionSessionsThemeGet({ sessionsId: sessionsId })
            console.log(res.data.data)
            setData(res.data.data)
            //setTotalPage(res.data.total == 0 ? 1 : Math.ceil(res.data.total / 10))

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)

    }

    useEffect(() => {
        //let sessionsId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        let sessionsId = query.get('sessionsId')
        console.log(sessionsId)
        if (sessionsId == null) {
            navigate('../')
        }
        else {
            getDataList(sessionsId)
            setSessionsId(sessionsId)

        }

    }, []);


    return <div className="session1 relative pt-[40px] pb-20 bg-white" >

        <PathLocation />



        {data && data.themes.map(i => <div className="">

            <div className="flex justify-between items-end text-black8 mb-4">
                <div className="font-bold text-xl">{i.name} </div>
                <div className="text-sm">{data.name}</div>
            </div>

            <div className="w-full   ">
                <img src={i.logo} className="w-full aspect-[16/9] object-cover  rounded-[20px] mb-4" />
                <div className="flex items-center gap-5 text-black9 min-h-full   justify-between">

                    {
                        data.themes.length > 0 ?
                            <CountDownTimer endDate={data.themes[0].endDate} />
                            : ""
                    }

                    <div className="flex-none">
                        <SeeMore to={String(i.id) + `?sessions=${sessionsId}`} />
                    </div>


                </div >
            </div >
            <div className="relative w-full Line1 h-[2px] my-20" ></div>
        </div>
        )}




    </div >
}

export default Home