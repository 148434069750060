import { separatorNumber, time2date, } from "../global/constants"
import { useTranslation } from "react-i18next";


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  useNavigate
} from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux';

import playImg from '../assets/play.png'

import ReactPlayer from 'react-player'


const ArtVideoBox = ({ data }) => {
  const navigate = useNavigate()
  const { t } = useTranslation();

  return <div onClick={() => navigate(`/artfocus/artvideo/${data.id}`)} className={` cursor-pointer rounded-[20px]  text-black9 text-base `}>
    <div className="pointer-events-none relative w-full aspect-[16/9] flex-none rounded-[10px] bg-black91 mb-4">
      <img className=" absolute w-8 h-8 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2" src={playImg} />
      <img className="w-full h-full object-cover rounded-xl" src={data.images && data.image.logo} />
    </div>
    <div className=" flex flex-col">
      <div className=" text-base font-semibold text-black9">
        {data.name}

      </div>

    </div>
  </div>
}

export default ArtVideoBox
