import { separatorNumber, time2date, } from "../global/constants"
import { useTranslation } from "react-i18next";


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  useNavigate
} from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux';


const ArtPlaceBox = ({ data }) => {
  const navigate = useNavigate()
  const { t } = useTranslation();

  return <div onClick={() => navigate(`/artfocus/artplace/${data.id}`)} className=" cursor-pointer p-5 min-w-[250px] w-3/5 sm:w-2/5  rounded-[20px] bg-black">
    <img className="w-full aspect-[288/182] object-cover rounded-[10px]" src={data.images && data.images.banner} />
    <div className="flex  gap-6 mt-6">
      <img className="w-[15%] aspect-[1/1] rounded-[10px] flex-none object-cover" src={data.images && data.images.logo} />
      <div className="w-full "  >
        <div className=" truncate text-white font-semibold text-sm mb-2">{data.name}</div>
        <div className=" truncate text-white font-semibold text-xs">台灣 · 台北市</div>

      </div>
    </div>
  </div>
}

export default ArtPlaceBox
