

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";


import UserApi from "../../../../services/UserApi.jsx";
import { separatorNumber, useGlobalFunc } from "../../../../global/constants.js";
import { Pagination } from "@mui/material";




const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const { setLoading, successToast, errorToast } = useGlobalFunc()
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
        getDataList(value)
    };

    const [dataList, setDataList] = useState(null)


    async function getDataList(page) {
        setLoading(true)
        try {
            let res = await UserApi.nftRecordSearch({ page: page })
            console.log(res.data.data)
            setDataList(res.data.data)
            setTotalPage(res.data.total == 0 ? 1 : Math.ceil(res.data.total / 10))

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)

    }

    useEffect(() => {
        getDataList(1)
    }, []);

    return <div className="mt-8   relative  w-full px-6 py-3 bg-white border-solid border-4 border-main3 rounded-[20px]">
        <div className="   whitespace-nowrap w-full  overflow-x-auto pb-10">
            <table className="w-full text-sm" >
                <thead className="">

                    <tr className="  text-main6 font-semibold ">
                        <th className="">NFT編號</th>
                        <th className="">藝品名稱</th>
                        <th className="">鏈上 NFT 憑證</th>
                        <th>拍賣/定價</th>

                    </tr>
                </thead>

                <tbody>
                    {
                        dataList && dataList.map(i => <tr className="align-top text-black9 font-semibold ">
                            {
                                i.OnlineAuctionProduct ?
                                    <>
                                        <td> {i.nftTokenId}</td>

                                        <td className=""><p>{i.OnlineAuctionProduct.collectionName}</p></td>

                                        <td>{(i.nftAddress)}</td>
                                        <td>拍賣</td>
                                    </>
                                    :
                                    <>
                                        <td> {i.nftTokenId}</td>
                                        <td className=""><p>{i.PricedProduct.collectionName}</p></td>
                                        <td>{(i.nftAddress)}</td>
                                        <td>定價</td>
                                    </>
                            }
                        </tr>



                        )
                    }

                </tbody>
            </table>

        </div>
        {
            dataList != null ?
                <>
                    {
                        dataList.length == 0 ?
                            <div className="  text-center text-gray-400 text-lg w-full font-bold mb-16 ">
                                <div>暫無紀錄</div>
                            </div> : ""

                    }

                    <div className="flex w-full  mt-4 mb-2">
                        <div className="mx-auto">
                            <Pagination count={totalPage} page={page} onChange={handleChange} />
                        </div>
                    </div>
                </>
                : ""
        }
    </div >

}


export default Home

