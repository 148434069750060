

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";

import Line1 from '../../../compontents/Line1.js'
import Swiper from '../../../compontents/Swiper.js'
import TitleName from '../../../compontents/TitleName.js'

import seeMoreImg from '../../../assets/seeMore.png'
import UserApi from "../../../services/UserApi.jsx";
import { useGlobalFunc } from "../../../global/constants.js";
import PathLocation from "../../../compontents/PathLocation.js";

// import homeshadowImg from '../../assets/homeshadow.png'
// import homescrollImg from '../../assets/homescroll.png'

// import homepic1Img from '../../assets/homepic1.png'
// import homepic2Img from '../../assets/homepic2.png'


const Home = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const [selId, setSelId] = useState(0);


    const { setLoading, successToast, errorToast } = useGlobalFunc()
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
        getDataList(value, selId)
    }

    const [dataList, setDataList] = useState(null)


    async function getDataList(page, id) {
        setLoading(true)
        try {
            let res = await UserApi.physicalAuctionCatalogSearch({ id: id, page: page })
            console.log(res.data)
            setDataList(res.data.data)
            setTotalPage(res.data.total == 0 ? 1 : Math.ceil(res.data.total / 10))

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)

    }

    useEffect(() => {
        let id = query.get('id')
        if (id == null) {
            navigate('../')
        }
        else {
            setSelId(id)
            getDataList(page, id)
        }
    }, []);


    return <div className="session1 relative pt-[40px] pb-20" >

        <PathLocation />

        <TitleName title1="Electronic Catolog" title2="電子圖錄" />



        {dataList != null ?

            <div className="w-full grid grid-cols-2 md:grid-cols-4 gap-10  mt-20">
                {
                    dataList.map((i) => <a href={i.link} target="_blank"><div>

                        <div className="relative w-full aspect-[285/364] mb-4">
                            <div className="rounded-xl w-11/12 h-[91%] transform translate-y-[20px] border border-main2 absolute  left-0 top-0"></div>
                            <img src={i.image.logo} className="w-11/12 h-[91%] object-cover transform translate-x-[20px]  rounded-xl  mb-6" />
                        </div>

                        <p className="text-black9 font-semibold mb-2">{i.name}</p>

                        <p className="text-main4 font-semibold text-sm">2022 秋拍</p>

                    </div>
                    </a>)
                }



            </div>

            : ""
        }



    </div >
}

export default Home