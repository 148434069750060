

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
   BrowserRouter as Router,
   Switch,
   Route,
   Link,
   useLocation
} from "react-router-dom";







const ShipRegister = ({ data, updateData, prevStep, nextStep }) => {
   const { t, i18n } = useTranslation();
   const location = useLocation();
   const query = new URLSearchParams(location.search);



   useEffect(() => {

   }, []);


   return <form onSubmit={nextStep} className="mt-8 px-6 py-6 bg-white border-solid border-4 border-main3 rounded-[20px] relative  w-full overflow-x-auto whitespace-nowrap">

      <div className="text-black9 text-xl font-bold mb-4">鑑價藏品申請表單</div>
      <div className="text-black9 font-bold mb-6">聯絡及運送資料</div>
      <div className="flex items-center gap-3   text-black9 mb-4">
         <div className="w-32 flex-none ">姓名<span className="text-red-500">*</span></div>
         <input required value={data.name} onChange={(e) => updateData('name', e.target.value)} className="w-0 grow py-1 rounded-lg border border-solid border-main3" />
      </div>
      <div className="flex items-center gap-3   text-black9 mb-4">
         <div className="w-32 flex-none ">電話號碼<span className="text-red-500">*</span></div>
         <div className="flex items-center gap-2 grow">
            <select defaultValue={""} onChange={(e) => updateData('area', e.target.value)} className="hidden input !py-1  relative    w-20   ">
               <option value="" disabled >區碼</option>
               <option>86</option>
               <option>852</option>
               <option>853</option>
               <option>886</option>
            </select>
            +886 
            <input required value={data.phone} onChange={(e) => updateData('phone', e.target.value)} className="w-full grow py-1 rounded-lg border border-solid border-main3" />
         </div>
      </div>
      <div className="flex items-center gap-3   text-black9 mb-4">
         <div className="w-32 flex-none ">電子信箱<span className="text-red-500">*</span></div>
         <input required value={data.email} onChange={(e) => updateData('email', e.target.value)} className="w-0 grow py-1 rounded-lg border border-solid border-main3" />
      </div>
      <div className="flex items-center gap-3   text-black9 mb-6">
         <div className="w-32 flex-none ">地址<span className="text-red-500">*</span></div>
         <input required value={data.address} onChange={(e) => updateData('address', e.target.value)} className="w-0 grow py-1 rounded-lg border border-solid border-main3" />
      </div>

      <div className="flex gap-2">
         <button onClick={() => prevStep()} className=" font-bold border-main4 border-solid border text-main4 w-full rounded-full py-3">上一步</button>
         <button type={'submit'} className=" font-bold bg-main4 text-white w-full rounded-full py-3">下一步</button>
      </div>



   </form>

}

export default ShipRegister