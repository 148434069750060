import {
  BrowserRouter as Router,
  useLocation,
  useNavigate,
  useSearchParams

} from "react-router-dom";

import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useGlobalFunc } from "../../../../global/constants";
import UserApi from "../../../../services/UserApi";
import { useSelector } from "react-redux";

import { getDatabase, ref, get } from "firebase/database";

const SubscribeBtn = ({ sessionsId, themeId, itemId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [searchParams, setSearchParams] = useSearchParams();

  const userInfo = useSelector(state => state.userInfo);

  const db = getDatabase();
  const { updateMe, setLoading, successToast, errorToast } = useGlobalFunc()


  const clientId = "JLU9Yk8ZK4KmZUBUNmkklZ"
  const weburl = useRef("")
  const line_redirect_uri = useRef("")

  const [isFollower, setIsFollower] = useState(null)

  const [oauthURL, setoauthURL] = useState("")

  const [lineURI, setLineURI] = useState("")

  const [sendData, setSendData] = useState([
    {
      title: "帳號",
      content: "",
      placeholder: "帳號"
    },
    {
      title: "密碼",
      content: "",
      placeholder: "密碼"
    },
  ]);
  function sendDataChange(index, value) {

    sendData[index].content = value
    setSendData([...sendData])
  }

  function getlineoauth() {


    // Line Notify OAuth2 授權頁面的 URI
    const authorizeUrl = 'https://notify-bot.line.me/oauth/authorize';

    // 您的應用程式的 Client ID

    // 要求使用者授權存取的範圍
    const scope = 'notify'; // 這表示您要求存取 Line Notify 服務

    // 要求的參數
    const queryParams = new URLSearchParams({
      response_type: 'code',
      client_id: clientId,
      scope: scope,
      redirect_uri: weburl.current, // 請替換成您的重導向 URI
      state: 'asdaszx', // 可選，用於 CSRF 防護等
    });

    // 組合最終的授權頁面網址
    const fullAuthorizeUrl = `${authorizeUrl}?${queryParams.toString()}`;
    setoauthURL(fullAuthorizeUrl)
    // 將使用者導向授權頁面
  }


  async function getLineID(id_token) {

    setLoading(true)
    try {
      let res = await UserApi.lineNotifyToken({
        "code": id_token,
        "redirect_uri": weburl.current
      })
      console.log(res.data.data)
      successToast('追蹤成功')
      await productAuctionSubscribe()
      await updateMe()
      checkIsFollower()

    }
    catch (errors) {
      let error = errors?.response?.data?.message || errors.toString()
      errorToast(error)

    }
    setLoading(false)
  }



  async function productAuctionSubscribe() {
    setLoading(true)
    try {
      let res = await UserApi.productAuctionSubscribe({ auctionProductId: itemId })
      console.log(res)
      successToast('succuess')
      checkIsFollower()
      //handleClose()
    }
    catch (errors) {
      errorToast(errors?.response?.data?.message || errors.toString())
    }
    setLoading(false)
  }

  async function checkIsFollower() {
    const dbRef = ref(db, `/auction/sessions/${sessionsId}/themes/${themeId}/products/${itemId}`);

    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const obj = snapshot.val();
      if (obj.follower) {
        const followerArray = Object.values(obj.follower);
        const existsInArray = followerArray.some(obj => obj.id === userInfo.lineNotifyToken);
        console.log(followerArray)
        console.log(existsInArray , userInfo.lineNotifyToken)
        if (existsInArray) {
          setIsFollower(true)
          return
        }
      }
    }
    setIsFollower(false)
  }


  function subscribeAction() {
    console.log(userInfo.lineUserId)
    productAuctionSubscribe()

  }


  useEffect(() => {
    console.log('effect')


    let port = window.location.port == "" ? "" : ":" + window.location.port;

    weburl.current =
      window.location.protocol +
      "//" +
      window.location.hostname +
      port +
      window.location.pathname + `?id=${itemId}`;

    line_redirect_uri.value =
      `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clientId}&redirect_uri=` +
      weburl.current +
      `&state=12345abcde&scope=profile%20openid&nonce=09876xyz`;

    setLineURI(line_redirect_uri.value)


    console.log(searchParams.get('code'));

    checkIsFollower()

    if (searchParams.get('code') != undefined) {
      getLineID(searchParams.get('code'))
      //getLineIDToken();
    }
    getlineoauth()

  }, [])


  return <div>
    {
      isFollower !== null && isFollower === false &&
      <div>
        {
          userInfo && userInfo.lineNotifyToken !== null ?
            < button className="btn2" onClick={() => subscribeAction()} >追蹤價位</button>
            :
            <a href={oauthURL}>
              <button className="btn2" >追蹤價位</button>
            </a>
        }
      </div>

    }


  </div >
}

export default SubscribeBtn