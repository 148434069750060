


const Line1 = (props) => {


  return (

    <div className="relative w-full Line1 h-[2px] my-4" >
      <div className="absolute left-0 top-1/2 w-2 h-2 bg-[#D49F4A] transform -translate-y-1/2 rotate-45"> </div>
      <div className="absolute right-0 top-1/2 w-2 h-2 bg-[#FFF4AF] transform -translate-y-1/2 rotate-45"> </div>

    </div>


  )
}
export default Line1