import { useTranslation } from "react-i18next";


import circle1Img from '../../../assets/home/circle1.png'
import circle2Img from '../../../assets/home/circle2.png'
import circle3Img from '../../../assets/home/circle3.png'

import circle11Img from '../../../assets/home/circle11.png'
import circle22Img from '../../../assets/home/circle22.png'
import circle33Img from '../../../assets/home/circle33.png'

import crossImg from '../../../assets/home/cross.png'


const CircleSession = ({ prevStep, nextStep }) => {
    const { t } = useTranslation();



    return <div className="grid grid-cols-3 gap-x-[0px] mt-[80px]">
        <div className="group transform translate-x-2 sm:translate-x-8">
            <div className="w-full aspect-[1/1] relative mb-14">
                <div className=" opacity-100 group-hover:opacity-0 absolute left-0 top-0 w-full h-full">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 183 183" fill="none">
                        <path d="M1.50001 91.5C1.5 41.7944 41.7944 1.5 91.5 1.49999C141.206 1.49999 181.5 41.7944 181.5 91.5C181.5 141.206 141.206 181.5 91.5 181.5C41.7944 181.5 1.50001 141.206 1.50001 91.5Z" stroke="url(#paint0_linear_2641_27827)" stroke-width="3" />
                        <defs>
                            <linearGradient id="paint0_linear_2641_27827" x1="91.5" y1="183" x2="101.748" y2="-46.36" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#000533" />
                                <stop offset="0.755208" stop-color="#9DA7FC" />
                                <stop offset="1" stop-color="#B5BCFF" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
                <div className=" absolute opacity-0 group-hover:!opacity-100 left-0 top-0 w-full h-full">
                    <svg viewBox="0 0 375 375" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.50001 187.5C2.50001 85.3273 85.3273 2.49999 187.5 2.49999C289.673 2.49998 372.5 85.3273 372.5 187.5C372.5 289.673 289.673 372.5 187.5 372.5C85.3273 372.5 2.50002 289.673 2.50001 187.5Z" stroke="#D5AD32" stroke-width="5" />
                    </svg>
                </div>
                <div className="  opacity-100 group-hover:opacity-0 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute w-1/2 h-1/2  object-contain">
                    <img src={circle1Img} className="w-full h-full object-contain " />
                </div>

                <div className=" opacity-0 group-hover:!opacity-100 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute w-1/2 h-1/2  object-contain">
                    <img src={circle11Img} className="w-full h-full object-contain " />
                </div>

            </div>
            <p className="group-hover:text-sec6 text-main95 text-center text-sm sm:text-xl mb-6">鑑價藏品</p>
            <div className="group-hover:bg-sec6 bg-main95 w-[55px] h-[3px] mx-auto rounded-full"></div>
        </div>
        <div className="group">
            <div className="w-full aspect-[1/1] relative mb-14">
                <div className=" opacity-100 group-hover:opacity-0 absolute left-0 top-0 w-full h-full">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 183 183" fill="none">
                        <path d="M1.50001 91.5C1.5 41.7944 41.7944 1.5 91.5 1.49999C141.206 1.49999 181.5 41.7944 181.5 91.5C181.5 141.206 141.206 181.5 91.5 181.5C41.7944 181.5 1.50001 141.206 1.50001 91.5Z" stroke="url(#paint0_linear_2641_27827)" stroke-width="3" />
                        <defs>
                            <linearGradient id="paint0_linear_2641_27827" x1="91.5" y1="183" x2="101.748" y2="-46.36" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#000533" />
                                <stop offset="0.755208" stop-color="#9DA7FC" />
                                <stop offset="1" stop-color="#B5BCFF" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>

                <div className=" absolute opacity-0 group-hover:!opacity-100 left-0 top-0 w-full h-full">
                    <svg viewBox="0 0 375 375" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.50001 187.5C2.50001 85.3273 85.3273 2.49999 187.5 2.49999C289.673 2.49998 372.5 85.3273 372.5 187.5C372.5 289.673 289.673 372.5 187.5 372.5C85.3273 372.5 2.50002 289.673 2.50001 187.5Z" stroke="#D5AD32" stroke-width="5" />
                    </svg>
                </div>
                <div className="  opacity-100 group-hover:opacity-0 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute w-1/2 h-1/2  object-contain">
                    <img src={circle2Img} className="w-full h-full object-contain " />
                </div>

                <div className=" opacity-0 group-hover:!opacity-100 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute w-1/2 h-1/2  object-contain">
                    <img src={circle22Img} className="w-full h-full object-contain " />
                </div>

            </div>
            <div className="relative mb-6 ">
                <p className="group-hover:text-sec6 text-main95 text-center text-sm sm:text-xl ">委託拍賣</p>
                <img src={crossImg} className="absolute top-1/2 left-0 transform -translate-y-1/2 w-3 sm:w-4 object-contain " />
                <img src={crossImg} className="absolute top-1/2 right-0 transform -translate-y-1/2 w-3 sm:w-4 object-contain " />
            </div>
            <div className="group-hover:bg-sec6 bg-[#222968] w-[55px] h-[3px] mx-auto rounded-full"></div>
        </div>
        <div className=" group transform -translate-x-2 sm:-translate-x-8">
            <div className="w-full aspect-[1/1] relative mb-14">
                <div className=" opacity-100 group-hover:opacity-0 absolute left-0 top-0 w-full h-full">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 183 183" fill="none">
                        <path d="M1.50001 91.5C1.5 41.7944 41.7944 1.5 91.5 1.49999C141.206 1.49999 181.5 41.7944 181.5 91.5C181.5 141.206 141.206 181.5 91.5 181.5C41.7944 181.5 1.50001 141.206 1.50001 91.5Z" stroke="url(#paint0_linear_2641_27827)" stroke-width="3" />
                        <defs>
                            <linearGradient id="paint0_linear_2641_27827" x1="91.5" y1="183" x2="101.748" y2="-46.36" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#000533" />
                                <stop offset="0.755208" stop-color="#9DA7FC" />
                                <stop offset="1" stop-color="#B5BCFF" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
                <div className=" absolute opacity-0 group-hover:!opacity-100 left-0 top-0 w-full h-full">
                    <svg viewBox="0 0 375 375" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.50001 187.5C2.50001 85.3273 85.3273 2.49999 187.5 2.49999C289.673 2.49998 372.5 85.3273 372.5 187.5C372.5 289.673 289.673 372.5 187.5 372.5C85.3273 372.5 2.50002 289.673 2.50001 187.5Z" stroke="#D5AD32" stroke-width="5" />
                    </svg>
                </div>
                <div className="  opacity-100 group-hover:opacity-0 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute w-1/2 h-1/2  object-contain">
                    <img src={circle3Img} className="w-full h-full object-contain " />
                </div>

                <div className=" opacity-0 group-hover:!opacity-100 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute w-1/2 h-1/2  object-contain">
                    <img src={circle33Img} className="w-full h-full object-contain " />
                </div>

            </div>
            <p className="group-hover:text-sec6 text-main95 text-center text-sm sm:text-xl mb-6">藝品徵件</p>
            <div className="group-hover:bg-sec6 bg-main95 w-[55px] h-[3px] mx-auto rounded-full"></div>
        </div>


    </div>

}

export default CircleSession