

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
   BrowserRouter as Router,
   Switch,
   Route,
   Link,
   useLocation,
   useNavigate
} from "react-router-dom";


import UserApi from "../../../../../services/UserApi.jsx";
import { useGlobalFunc } from "../../../../../global/constants.js";
import { useSelector } from "react-redux";






// import homeshadowImg from '../../../assets/homeshadow.png'
// import homescrollImg from '../../../assets/homescroll.png'

// import homepic1Img from '../../../assets/homepic1.png'
// import homepic2Img from '../../../assets/homepic2.png'


const Home = () => {
   const { t, i18n } = useTranslation();
   const location = useLocation();
   const navigate = useNavigate()
   const query = new URLSearchParams(location.search);
   const userInfo = useSelector(state => state.userInfo);

   const { setLoading, successToast, errorToast, updateMe } = useGlobalFunc()

   const banner = []
   const [currBanner, setCurrBanner] = useState(0)
   const countRef = useRef()

   const [step, setStep] = useState(0)

   const [data, setData] = useState(
      {
         "name": "",
         "artLink": "",
         "introduction": "",
         "facebook": "",
         "twitter": "",
         "instagram": "",
         "pinterest": "",
         "behance": ""
      }
   )

   const updateData = (i, value) => {
      setData(prevData => ({ ...prevData, [i]: value }));
   };

   async function artistApply(event) {
      event.preventDefault(); // 阻止默認的表單提交行為
      window.scroll(0,0)
      
      setLoading(true)
      try {
         let res = await UserApi.artistApply(
            {
               "name": data.name,
               "artLink": data.artLink,
               "introduction": data.introduction,
               "facebook": data.facebook,
               "twitter": data.twitter,
               "instagram": data.instagram,
               "pinterest": data.pinterest,
               "behance": data.behance
            }
         )
         console.log(res.data.data)
         setStep(2)

      }
      catch (errors) {
         errorToast(errors?.response?.data?.message || errors.toString())
      }
      setLoading(false)

   }

   useEffect(() => {
      if (userInfo != null) {
         console.log(userInfo)
         if (userInfo.artistProfile != null) {
            if (userInfo.artistProfile.status == 'approved') {
               setStep(3)  // 通過
            }
            else if (userInfo.artistProfile.status == 'pending') {
               setStep(2)
            }

         }


      }

   }, [userInfo]);


   return <div>
      {
         step == 0 ?
            <div className="mt-8 px-6 py-6 bg-white border-solid border-4 border-main3 rounded-[20px] relative  w-full ">

               <div className="text-black9 text-xl font-bold mb-4 text-center">暫無申請成藝術家</div>

               <button className=" font-bold bg-main4 text-white w-full rounded-full py-3" onClick={() => setStep(1)}>申請成藝術家</button>

            </div>
            : ""
      }


      {
         step == 1 ?
            <form onSubmit={(e)=>artistApply(e)} className="mt-8 px-6 py-6 bg-white border-solid border-4 border-main3 rounded-[20px] relative  w-full ">

               <div className="text-black9 text-xl font-bold mb-4 ">藝術家申請表單</div>

               <div className="text-black9 font-bold mb-6">藝術家資料</div>
               <div className="flex gap-2 flex-col   text-black9 mb-4">
                  <div className=" ">藝術家名<span className="text-red-500">*</span></div>
                  <input required className="w-full py-1 rounded-lg border border-solid border-main3" value={data.name} onChange={(e) => updateData('name', e.target.value)} />
               </div>
               <div className="flex gap-2 flex-col   text-black9 mb-4">
                  <div className=" ">個人作品參考網址</div>
                  <input className="w-full py-1 rounded-lg border border-solid border-main3" value={data.artLink} onChange={(e) => updateData('artLink', e.target.value)} />
               </div>
               <div className="flex gap-2 flex-col   text-black9 mb-4">
                  <div className=" ">藝術家簡介</div>
                  <input className="w-full py-1 rounded-lg border border-solid border-main3" value={data.introduction} onChange={(e) => updateData('introduction', e.target.value)} />
               </div>
               <div className="text-black9 font-bold mb-6">以下為選填資料</div>
               <div className="flex gap-2 flex-col   text-black9 mb-4">
                  <div className=" ">Facebook</div>
                  <input className="w-full py-1 rounded-lg border border-solid border-main3" value={data.facebook} onChange={(e) => updateData('facebook', e.target.value)} />
               </div>
               <div className="flex gap-2 flex-col   text-black9 mb-4">
                  <div className=" ">Instagram</div>
                  <input className="w-full py-1 rounded-lg border border-solid border-main3" value={data.instagram} onChange={(e) => updateData('instagram', e.target.value)} />
               </div>
               <div className="flex gap-2 flex-col   text-black9 mb-4">
                  <div className=" ">Twitter</div>
                  <input className="w-full py-1 rounded-lg border border-solid border-main3" value={data.twitter} onChange={(e) => updateData('twitter', e.target.value)} />
               </div>
               <div className="flex gap-2 flex-col   text-black9 mb-4">
                  <div className=" ">Behance</div>
                  <input className="w-full py-1 rounded-lg border border-solid border-main3" value={data.behance} onChange={(e) => updateData('behance', e.target.value)} />
               </div>
               <div className="flex gap-2 flex-col   text-black9 mb-4">
                  <div className=" ">Pinterest</div>
                  <input className="w-full py-1 rounded-lg border border-solid border-main3" value={data.pinterest} onChange={(e) => updateData('pinterest', e.target.value)} />
               </div>

               <button type={"submit"} className=" font-bold bg-main4 text-white w-full rounded-full py-3">送出</button>


            </form>
            : ""
      }


      {step == 2123123 ?

         <div className="mt-8 px-6 py-6 bg-white border-solid border-4 border-main3 rounded-[20px] relative  w-full ">

            <div className="text-black9 text-xl font-bold mb-4 sm:flex justify-between">
               <div>藝術家申請表單</div>
               <div className="text-right text-sm font-normal text-black6">
                  申請時間：2022年12月12日<br />
                  申請單ID：1111220002
               </div>
            </div>

            <div className="text-black9 font-bold mb-6">藝術家資料</div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
               <div className=" ">藝術家名：</div>
               Amber Liu
            </div>
            <div className="relative flex gap-2 flex-col   text-black9 mb-4 break-all ">
               <div className=" ">個人作品參考網址：</div>
               <p >https://drive.google.com/drive/folders/0B3CDg-0mK-1234567890ps6666/drive.google.com/drive/folders/0B3CDg-0mK-rYZGprNldwMmhm666OG8?dhczMZ9iGcBYvH544EmV1A&usp=sharingrYZGprNldwMmhmO66666666G8?resourcekey=0-dhczMZ9iGcBYvH544EmV1</p>
            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
               <div className=" ">藝術家簡介：</div>
               會員申請的時候這一欄可以填入500個字，會員申請的時候這一欄可以填入150個字，會員申請的時候這一欄可以填入500個字，會員申請的時候這一欄可以填入150個字，會員申請的時候這一欄可以填入500個字，會員申請的時候這一欄可以填入500個字，會員申請的時候這一欄可以填入500個字。
            </div>

            <div className="flex gap-2 flex-col   text-black9 mb-4  break-all">
               <div className="">Facebook：</div>
               https://drive.google.com/drive/folders/0B3CDg-0mK-1234567890ps6666/drive.google.com/drive/folders/0B3CDg-0mK-rYZGprNldwMmhm666OG8?dhczMZ9iGcBYvH544EmV1A&usp=sharingrYZGprNldwMmhmO66666666G8?resourcekey=0-dhczMZ9iGcBYvH544EmV1A&usp=sharing
            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
               <div className=" ">Instagram：</div>

            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
               <div className=" ">Twitter：</div>

            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
               <div className=" ">Behance：</div>

            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
               <div className=" ">Pinterest：</div>

            </div>

            <button className=" font-bold border-main4 border-solid border text-main4 w-full rounded-full py-3">編輯</button>


         </div>
         : ""
      }

      {
         step == 2 ?
            <div className="mt-8 px-6 py-8 bg-white border-solid border-4 border-main3 rounded-[20px] relative  w-full ">

               <div className="text-black9 text-center text-xl font-bold ">申請審核中</div>


         


            </div>
            : ""

      }

      {
         step == 3 ?
            <div className="mt-8 px-6 py-8 bg-white border-solid border-4 border-main3 rounded-[20px] relative  w-full ">

               <div className="text-black9 text-center text-xl font-bold mb-4">您已成為藝術家</div>


               <div className="mt-8 flex justify-center">
                  <button onClick={() => navigate("/artistcenter")} className="border-main6 border-solid border text-main6 px-6 py-3 rounded-full font-semibold text-sm ">前往藝術家中心</button>
               </div>


            </div>
            : ""

      }
   </div>

}



export default Home