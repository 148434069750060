

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";


import errorImg from '../../assets/404Pic.png'

import { useGlobalFunc } from "../../global/constants.js";
import CircleSession from "./compontents/CircleSession.js";

// import homeshadowImg from '../../assets/homeshadow.png'
// import homescrollImg from '../../assets/homescroll.png'

// import homepic1Img from '../../assets/homepic1.png'
// import homepic2Img from '../../assets/homepic2.png'


const Page404 = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search);

    const { setLoading, successToast, errorToast } = useGlobalFunc()



    useEffect(() => {


    }, []);


    return <div className="bg-white w-full min-h-[80vh] pt-10 pb-20 px-4 flex justify-center items-center" >
        <div className="relative font-bold text-main7 text-3xl sm:text-8xl text-center">
            404
            <p className="mt-2 mb-10 text-lg sm:text-xl ">
                找不到該頁面
            </p>
            <img src={errorImg} className="w-full max-w-[400px] object-contain mb-4" />
            <div className="w-full flex">
                <button onClick={()=>navigate('/')} className="w-full btn2 !text-main7 !border-main7">返回首頁</button>
            </div>

        </div>



    </div >
}

export default Page404