

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";

import { Pagination } from "@mui/material";

import Line1 from '../../../compontents/Line1.js'
import Line2 from '../../../compontents/Line2.js'

import UserApi from "../../../services/UserApi";
import { time2date, useGlobalFunc } from "../../../global/constants.js";
import PathLocation from "../../../compontents/PathLocation.js";
import ArtNewsBox from "../../../compontents/ArtNewsBox.js";
import Tag from "../../../compontents/Tag.js";


// import homeshadowImg from '../../assets/homeshadow.png'
// import homescrollImg from '../../assets/homescroll.png'

// import homepic1Img from '../../assets/homepic1.png'
// import homepic2Img from '../../assets/homepic2.png'


const Home = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const location = useLocation();
    const query = new URLSearchParams(location.search);



    const typeList = ["all", "newsFocus", "internationalNews", "artisticCrossing", "artCritique", "artIndustry", "specialProject", "auctionNews", "solicitation"]
    const [typeIndex, setTypeIndex] = useState(0)
    const first = useRef(false)



    const { setLoading, successToast, errorToast } = useGlobalFunc()
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {

        console.log(value)
        setPage(value);
        getDataList(value,typeList[typeIndex] )
    };

    const [dataList, setDataList] = useState(null)


    async function getDataList(page,type) {
        setLoading(true)
        try {
            let res = await UserApi.artArticleSearch({ page: page, type: type})
          //  console.log(res.data.total)
            setDataList(res.data.data)
            setTotalPage(res.data.total == 0 ? 1 : Math.ceil(res.data.total / 10))
            window.scroll(0, 0)

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)
    }

    useEffect(() => {
        let type = query.get('type')
        let typeIndex = typeList.indexOf(type)
        if (typeIndex != -1) {
            getDataList(1,typeList[typeIndex])
            setTypeIndex(typeIndex)
            window.scroll(0, 0)
        }
        else {
            setTypeIndex(0)
            getDataList('all')

        }

    }, [query.get('type')]);


    return <div className="w-full">

        <div className=" relative pt-[40px] pb-20 bg-main9" >
            <div className="session1">
                <PathLocation color={'text-white'} />


                <div className="flex flex-wrap gap-4 items-end">
                    <p className="text-2xl text-white font-semibold">{t("artFocus.news")}</p>
                    {
                        typeList.map((i, index) => <div className={`${index == typeIndex ? "text-white" : "text-[#47517C]"}  font-semibold text-base `} key={i}>
                            <button onClick={() => navigate(`?type=${typeList[index]}`)}>{t(`newsType.${i}`)}</button>
                        </div>)
                    }
                </div>

                {
                    dataList != null && dataList.length > 0 ?
                        <div onClick={() => navigate(`${dataList[0].id}`)} className=" cursor-pointer px-8 flex flex-col sm:flex-row sm:items-center mt-8 mb-12 text-black9 text-base  ">
                            <div className="aspect-[16/9] rounded-[10px] w-full sm:w-1/3 flex-none mb-4 sm:mb-0 overflow-hidden ">
                                <img className="w-full h-full object-cover anim hover:scale-105" src={dataList[0].images && dataList[0].images.banner} />
                            </div>
                            <div className="sm:px-8 flex flex-col ">

                                <div className="flex mb-4">
                                    <Tag title={t(`newsType.${dataList[0].type}`)} />
                                </div>
                                <div className="text-white text-base mb-3">
                                    {dataList[0].name}
                                </div>
                                <div className="text-white text-sm">
                                    {time2date(dataList[0].createdAt)}｜撰文者： {dataList[0].author}
                                </div>
                            </div>
                        </div>
                        : ""
                }
                <Line2 />
                {
                    dataList != null && dataList.length > 1 ?
                        <div onClick={() => navigate(`${dataList[1].id}`)} className=" cursor-pointer px-8 flex  flex-col sm:flex-row sm:items-center justify-end mt-8 mb-12 text-black9 text-base  ">

                            <div className=" order-last sm:order-first sm:px-8 flex flex-col">
                               
                                <div className="flex mb-4 ml-auto">
                                    <Tag title={t(`newsType.${dataList[1].type}`)} />
                                </div>
                                <div className="text-white text-right text-base mb-3">
                                    {dataList[1].name}
                                </div>
                                <div className="text-white  text-right text-sm">
                                    {time2date(dataList[1].createdAt)}｜撰文者： {dataList[1].author}
                                </div>
                            </div>
                            <div className="aspect-[16/9] rounded-[10px] mb-4 sm:mb-0 w-full sm:w-1/3 flex-none overflow-hidden ">
                                <img className="w-full h-full object-cover anim hover:scale-105" src={dataList[1].images && dataList[1].images.banner} />
                            </div>

                        </div>
                        : ""
                }

            </div>
        </div>
        {
            dataList != null ?
                <div className="session1 relative pt-[40px] pb-20 bg-white ">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-10">

                        {
                            dataList.slice(2).map((i, index) => <ArtNewsBox key={index} data={i} />
                            )

                        }



                    </div>

                </div>
                : ""
        }
        {
            dataList ?
                <>
                    {
                        dataList.length == 0 ?
                            <div className="  text-center text-gray-400 text-lg w-full font-bold mb-20 ">
                                <div>暫無紀錄</div>
                            </div> : ""

                    }

                    <div className="flex w-full  mt-2 mb-10">
                        <div className="mx-auto">
                            <Pagination count={totalPage} page={page} onChange={handleChange} />
                        </div>
                    </div>
                </>
                : ""
        }




    </div >
}

export default Home