

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";


import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import { useSelector } from "react-redux";
import ArtNewsBox from "../../../../compontents/ArtNewsBox.js";
import UserApi from "../../../../services/UserApi.jsx";
import { useGlobalFunc } from "../../../../global/constants.js";
import { Pagination } from "@mui/material";



// import homeshadowImg from '../../assets/homeshadow.png'
// import homescrollImg from '../../assets/homescroll.png'

// import homepic1Img from '../../assets/homepic1.png'
// import homepic2Img from '../../assets/homepic2.png'


const MyFavorite = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const userInfo = useSelector(state => state.userInfo);

    const banner = []
    const [functionIndex, setFunctionIndex] = useState(0)
    const countRef = useRef()

    const functionList = ['artArticle', 'artVideo', 'artGathering', 'artist', 'product']

    const handleTypeChange = (event) => {
        setFunctionIndex(event.target.value);
    };

    const loading = useSelector(state => state.loading);

    const { setLoading, successToast, errorToast } = useGlobalFunc()
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
        getDataList(value, functionList[functionIndex])
    };

    const [dataList, setDataList] = useState(null)


    async function getDataList(page, type) {
       // console.log(page, type)
        // setLoading(true)
        try {
            let res = await UserApi.favoriteSearch({ page: page, type: type })
            console.log(res.data)
            setDataList(res.data.data)
            setTotalPage(res.data.total == 0 ? 1 : Math.ceil(res.data.total / 10))
        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        //setLoading(false)

    }

    useEffect(() => {
        setPage(1);
        getDataList(1, functionList[functionIndex])

    }, [functionIndex]);



    return <div className="relative w-full  py-14">


        <div className="hidden lg:flex flex-wrap gap-3 flex-none w-full  mb-6 ">

            {
                functionList.map((i, index) => <div className={`  font-bold text-base `} key={i}>
                    <button onClick={() => setFunctionIndex(index)} className={`${index == functionIndex ? "bg-main8 text-white" : "bg-main1 text-main4 "} px-4 py-2 rounded-lg`}>{t(`favoriteType.${i}`)}</button>
                </div>)
            }

        </div>
        <div className=" lg:hidden mb-6 text-black">

            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={functionIndex}
                label=""
                className="w-full"
                onChange={handleTypeChange}
            >
                {
                    functionList.map((i, index) =>
                        <MenuItem key={i} value={index}>{t(`favoriteType.${i}`)}</MenuItem>
                    )
                }
            </Select>

        </div>







        <div className=" w-full grow ">


            {
                functionList[functionIndex] == 'artArticle' ?
                    <div className="grid grid-cols-2 gap-4 ">
                        {
                            dataList && dataList.map(i => <ArtNewsBox key={i.id} data={i.ArtArticle} />)
                        }

                    </div>
                    : ""
            }
            {
                functionList[functionIndex] == 'artVideo' ?
                    <div className="grid grid-cols-2 gap-4 ">
                        {
                            dataList && dataList.map(i => <ArtNewsBox key={i.id} data={i.ArtArticle} />)
                        }

                    </div>
                    : ""
            }
            {
                functionList[functionIndex] == 'artGathering' ?
                    <div className="grid grid-cols-2 gap-4 ">
                        {
                            dataList && dataList.map(i => <ArtNewsBox key={i.id} data={i.ArtArticle} />)
                        }

                    </div>
                    : ""
            }
            {
                functionList[functionIndex] == 'artist' ?
                    <div className="grid grid-cols-2 gap-4 ">
                        {
                            dataList && dataList.map(i => <ArtNewsBox key={i.id} data={i.ArtArticle} />)
                        }

                    </div>
                    : ""
            }
            {
                functionList[functionIndex] == 'product' ?
                    <div className="grid grid-cols-2 gap-4 ">
                        {
                            dataList && dataList.map(i => <ArtNewsBox key={i.id} data={i.ArtArticle} />)
                        }

                    </div>
                    : ""
            }



            {
                dataList ?
                    <>
                        {
                            dataList.length == 0 ?
                                <div className="  text-center text-gray-400 text-lg w-full font-bold mb-12 ">
                                    <div>暫無</div>
                                </div> : ""

                        }

                        <div className="flex w-full  mt-8 mb-10">
                            <div className="mx-auto">
                                <Pagination count={totalPage} page={page} onChange={handleChange} />
                            </div>
                        </div>
                    </>
                    : ""
            }


        </div>

    </div>



}

export default MyFavorite