

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";

import PathLocation from "../../../compontents/PathLocation.js";



const Home = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const location = useLocation();
    const query = new URLSearchParams(location.search);



    const typeList = ["會員及使用者註冊條款", "隱私權政策", "免責聲明"]
    const [typeIndex, setTypeIndex] = useState(0)
    const first = useRef(false)



    useEffect(() => {
        let type = query.get('type')
        let typeIndex = typeList.indexOf(typeList[Number(type)])
        if (typeIndex != -1) {
            setTypeIndex(typeIndex)
            window.scroll(0, 0)
        }
        else {
            setTypeIndex(0)

        }

    }, [query.get('type')]);

    return <div className="session1 relative pt-[40px] pb-20 bg-white" >

        <PathLocation />
        <div className="flex flex-wrap gap-4 items-end">
            <p className="text-2xl text-black9 font-semibold">服務條款</p>
            {
                typeList.map((i, index) => <div className={`${index == typeIndex ? "text-main7" : "text-black6"}  font-bold text-base `} key={i}>
                    <button onClick={() => navigate(`?type=${index}`,{replace:true})}>{i}</button>
                </div>)
            }
        </div>

        <div className="py-14 ">

            <div className="text-black8 mb-5 w-full border border-solid border-main2 rounded-[40px] bg-white px-[20px] sm:px-[56px] py-[40px] ">
                <p>感謝您加入天堯藝術網的會員，我們提供內容刊登等多種服務。在使用本服務前，請仔細閱讀以下使用條款。本條款適用於所有使用本服務的會員及使用者，包括內容刊登者、廣告主、內容提供廠商以及網站瀏覽者。</p>
            </div>

            <div className="doc text-black8 mb-5 w-full border border-solid border-main2 rounded-[40px] bg-white px-[20px] sm:px-[56px] pb-[60px]">
                {
                    typeIndex == 0 && <div>
                        <h3>一、適用範圍</h3>
                        <p>天堯藝術網服務條款 (以下簡稱「本條款」) 是根據天堯科技文化股份有限公司 (以下簡稱「本公司」) 設立的「天堯藝術網」(以下簡稱「本網站」) 本公司為提供天堯藝術網之各項網路應用服務所制定的天堯藝術網服務條款，適用於所有註冊會員及使用者。當您進行註冊程序時，請詳閱本條款，一旦註冊成功，即表示同意本條款所有內容。若未滿二十歲，應得到法定代理人同意或陪同進行交易，並遵守本條款及相關法律規定。若本公司提供其他服務，並訂定相關使用條款，則以該規範為依歸。當您使用或繼續使用本服務時，即表示您的家長 (或監護人) 已閱讀、瞭解並同意接受本條款之所有內容及其後修改變更。如有任何疑問，可向本網站客服諮詢。</p>
                        <h3>二、條款變更</h3>
                        <p>本公司保留修改本條款的權利，而不需事先個別通知會員及使用者。修改後的 內容將公布於網頁上，並立即生效，以取代原有的條款。因此，請定期或不定 期查看本條款的最新內容。如果您不同意本條款的變更內容，可以停止使用本 網站提供的服務。當您在本條款變更後再度使用本網站之服務時，則視為您已 接受變更後的條款。</p>
                        <h3>三、帳號、密碼與安全性</h3>
                        <ol>
                            <li>
                                <p>當您註冊成為本網站的會員和使用者時，必須確保所填寫的資料完整且正確，因為任何因為資料錯誤所引起的問題，本公司將不負相關責任。如果您提供任何錯誤或虛假的資料，本網站有權暫停或終止您的帳號，並拒絕您使用本服務的全部或一部分。</p>
                            </li>
                            <li>
                                <p>您必須妥善保管註冊時所填寫的會員和使用者 ID、密碼以及使用的電子郵件地址。我們建議您在使用完畢後及時登出，並且不要將它們轉讓或借給第三方使用。當您使用這些資料登入後，您必須對在本網站上進行的所有行為負責。</p>
                            </li>
                            <li>
                                <p>如果您發現您的 ID、密碼或使用的電子郵件地址已被洩露或被盜用，並且出現了異常的登錄和使用行為，請立即告知我們。我們將協助處理和解決這個問題，但不承擔任何賠償責任。</p>
                            </li>
                        </ol>
                        <h3>四、責任限制</h3>
                        <ol>
                            <li>
                                <p>本網站上的資訊由會員及使用者自行發表，因此會員及使用者必須自行確保其內容的合法性及可信度，本公司不對此負責任。</p>
                            </li>
                            <li>
                                <p>本公司提供的所有服務都是，均根據該服務當時之功能提供使用，本公司不對其速度、安全性、完整性、正確性等做任何形式的保證責任。</p>
                            </li>
                            <li>
                                <p>會員及使用者上傳的資料，包括文字、圖片、影音等，應評估其是否適合公開於網路上，本公司無法保證所有上傳及儲存資料之安全性、完整性、正確性、合法性。</p>
                            </li>
                            <li>
                                <p>會員及使用者必須自行安裝防毒、防駭等軟體，確保其使用的資料及機器設備安全。本公司不對因本網站內容造成的任何軟硬體損害負責。</p>
                            </li>
                            <li>
                                <p>本網站的資訊可能包含成人內容，未滿 18 歲的青少年或兒童應取得父母同意後註冊使用，並且在父母的陪同下觀看。</p>
                            </li>
                        </ol>
                        <h3>五、智慧財產權</h3>
                        <ol>
                            <li>
                                <p>本網站及其服務所使用的程式碼、網頁設計語言和軟件都受到智慧財產權相關法律的保護。除非得到本網站的授權，否則會員和使用者不能修改、出租、出售、散佈本服務的全部或部分，也不能用它們來創作衍生作品，或以其他方式轉移軟件的任何權利。</p>
                            </li>
                            <li>
                                <p>本網站所展示的贊助廣告和資訊受到著作權、商標權、專利權或其他屬權利的法律保護。未經本網站或廣告商的授權，會員和使用者不能複製、修改、創作衍生作品。</p>
                            </li>
                            <li>
                                <p>會員和使用者上傳的所有內容必須保證具有合法和完整的著作權和智慧財產權，並且保證這些內容不違反法律或侵犯任何第三方權益。任何資料一旦上載、傳送、輸入、公開傳輸或提供到本網站，就視為會員和使用者已經永久、非專屬性、無償的授權本網站可以基於公益或私益之目的，無條件使用，包括但不限於修改、重製、公開播送、改作、散布、發行、公開發表、公開傳輸該等資料。會員和使用者對此沒有任何異議。本網站有權匯總、編輯該等資料，但在未得到會員和使用者的授權之前，不會對上傳的資料進行個別的銷售或轉讓等行為。</p>
                            </li>
                            <li>
                                <p>當會員或使用者在本網站上傳任何內容時，應保證本網站使用、修改、重製、公開播送、改作、散布、發行、公開發表、轉授權該等資料，且必須確保該內容不侵犯他人的智慧財產權，否則該會員或使用者需對本公司承擔賠償責任。如果因此產生任何糾紛，會員或使用者將完全負責，並需賠償因其行為造成的任何損害，包括本公司所遭受的損失。</p>
                            </li>
                            <li>
                                <p>本公司重視智慧財產權，我們要求會員及使用者也同樣尊重他人的智慧財產權。如果我們認為會員或使用者可能涉嫌侵犯他人的智慧財產權，我們有權暫停或終止其帳戶和服務。如果您認為自己的著作權或其他智慧財產權被侵犯，請提供以下資訊予本公司：</p>
                            </li>
                        </ol>
                        <p>(1) 您是著作權或其他智慧財產權利益的所有人，並提供相關授權的簽章</p>
                        <p>(2) 您的著作權或其他智慧財產權的描述(3) 您所認為侵權資料的位置描述(4) 您的聯繫方式，包括地址、電話號碼和電子郵件地址(5) 您認為該利用未經著作權人、代理人或法律許可之聲明(6) 您在了解虛假陳述責任的前提下，對於上述載於您的通知上之資訊之正確性，且您是著作權人或智慧財產權人或合法代表著作權人或智慧財產權人之聲明。</p>
                        <h3>六、 廣告與廣告主</h3>
                        <p>本網站所刊載的廣告訊息，由廣告主及提供廠商、組織自行負責其內容的正確性和可能造成之後果，本公司不對此負任何法律責任。另外，本網站的藝術品訊息，包括但不限於藝術品價格和售出狀態等，是由會員和使用者自行上傳，並由提供廠商、組織或個人等提供者自行負責其內容的正確性和能造成之後果，本公司不對此負任何法律責任。</p>
                        <h3>七、禁止事項</h3>
                        <p>使用本服務的使用者應禁止以下行為事項：</p>
                        <ol>
                            <li>禁止發表、傳輸及發送任何違反中華民國法令之資料及內容，包括但不 限於誹謗、詐欺、猥褻、傷害、賭博等。</li>
                            <li>禁止發表、傳輸及發送侵犯他人商標權、著作權、智慧財產權之資料及內容。</li>
                            <li>禁止發表、傳輸及發送侵犯他人肖像權之資料及內容。</li>
                            <li>禁止未取得他人同意下，傳輸及發送廣告、促銷之資料及內容。</li>
                            <li>禁止任何干擾、妨礙他人使用之行為。</li>
                            <li>禁止破壞、干擾本網站系統運作、增加系統負荷之行為。</li>
                            <li>禁止發表、傳輸及發送病毒，或其他可能破壞或干擾電腦系統或資料的程式與訊息。</li>
                            <li>禁止擷取、收集、篡改或刪除他人資訊、電子郵件或其他個人資料，將此類資料用於不正當目的。</li>
                            <li>禁止發表、傳輸及發送可能造成其他使用者不快之訊息、內容。</li>
                            <li>禁止發表其他本網站判斷不適宜公開傳播之訊息、內容或行為。若本網站發現任何上述內容或行為，有權予以刪除，並且無需另行通知。</li>
                            <li><br /></li>
                        </ol>
                        <h3>八、買賣或其他交易行為</h3>
                        <ol>
                            <li>
                                <p>您可能透過本網站連結至其他提供商品買賣、服務或其他交易行為的相關網站，但這些交易只涉及您和相關的第三方之間的合約關係。</p>
                            </li>
                            <li>
                                <p>如果因為您透過本網站連結到第三方網站進行交易而產生任何糾紛或爭議，您必須自行向該第三方尋求救濟或解決方案。本公司不會參與或調解您和第三方之間的任何交易或服務，對於您透過這些交易獲得的任何商品、服務或其他標的物，本公司也不負擔保責任。</p>
                            </li>
                        </ol>
                        <h3>九、服務中斷</h3>
                        <ol>
                            <li>
                                <p>本網站可能因維護或系統更新而暫停服務。在此之前，本公司會以適當的方式通知會員及使用者，例如網站公告、電子郵件等。</p>
                            </li>
                            <li>
                                <p>若服務中斷是因為下列情況所致，本公司對使用者所造成的任何直接或間接損害不負任何責任：</p>
                            </li>
                        </ol>
                        <p>(1) 與網站相關的軟硬體設備進行搬遷、更換、升級或維修。</p>
                        <p>(2) 使用者違反政府法令或本條款。</p>
                        <p>(3) 天災或其他不可抗力因素。</p>
                        <p>(4) 其他不可歸責於本公司的原因。</p>
                        <h3>十、服務終止</h3>
                        <ol>
                            <li>
                                <p>根據本公司的營運方向及策略，可能會停止提供部分或全部會員及使用者服務。如果發生這種情況，本公司將以網站公告、Email 或其他適當方式告知會員及使用者，使用者不得因此要求任何形式的賠償。</p>
                            </li>
                            <li>
                                <p>如果會員或使用者違反政府法令或本條款，導致其資格被取消或無法使用部分或全部服務，本公司將不承擔任何形式的賠償責任。</p>
                            </li>
                        </ol>
                        <h3>十一、隱私保護</h3>
                        <ol>
                            <li>
                                <p>本公司保護所有會員及使用者的隱私權，本公司未經同意，不會監視、修改或交換註冊資料給第三方。</p>
                            </li>
                            <li>
                                <p>當會員或使用者參加本網站的活動或使用第三方廠商合作提供的服務時，可能需要提供相關資料。請確認再提供相關資料，本公司不對因使用者參加活動向第三方提供的個人資料負責。因此，請小心處理您的個人資訊。</p>
                            </li>
                        </ol>
                        <p>在以下情況，本公司可能查看或提供您的資料給第三方，包括但不限於法院、主管機關警方，或第三人提出證明其權益受到侵害的情況：</p>
                        <p>(1)接獲執法機關的命令</p>
                        <p>(2)為執行本條款所需</p>
                        <p>(3)為維護服務的正常運作和安全</p>
                        <p>(4)為維護第三方的權益</p>
                        <p>(5)在其他本公司認為必要的情況下</p>
                        <h3>十二、使用授權</h3>
                        <p>根據本網站的第十一條規定，會員和使用者的註冊資料將受到隱私保護，本公司不會監視、增刪或修改。在其他會員和使用者上傳或張貼到本網站上的任何文字、圖像、影像等資料中，其權利為會員及使用者或其授權人所有。但是如果任何會員或使用者上傳或張貼了圖片、文字或影像，他們同意授權本網站基於公益或為宣傳、推廣本網站相關服務及推薦優質會員和使用者、文字、圖像和影像，進行使用、修改、重製、散布、發行、 公開發表、公開傳輸、公開播映和翻譯等行為。同時，會員和使用者保證所提供的資料和圖片不會侵害他人的智慧財產權，否則將對本網站承擔損害賠償責任。在不違反個資法和智慧財產權法的前提下，本網站可以使用會員和使用者在網站上產生和上傳的資料和圖片等內容，製作廣告圖示和相關廣告宣傳，以作行銷和品牌推廣用途。</p>
                        <h3>十三、準據法與管轄權</h3>
                        <ol>
                            <li>
                                <p>本條款的解釋、補充和適用均受中華民國法律的管轄和約束。</p>
                            </li>
                            <li>
                                <p>任何因本條款而引起的訴訟，應由台灣高雄地方法院作為第一審的管轄法院。</p>
                            </li>
                        </ol>
                        <h3>十四、免責聲明</h3>
                        <p>會員及使用者需要明確了解並同意，本公司所提供的服務並不提供任何明示或默示的擔保，包括但不限於商業適性、特定目的的適用性及未侵害他人權利。本公司所提供的服務是基於其「現狀」及「提供使用時」的基礎提供，因此會員及使用者使用本服務時，需自行承擔相關風險。本公司不保證以下事項：</p>
                        <ol>
                            <li>本服務能完全符合會員及使用者的需求</li>
                            <li>本服務不會受到干擾，能及時提供、安全可靠且沒有錯誤</li>
                            <li>由本服務購買之商品或服務能夠完全符合會員及使用者的期望</li>
                            <li>會員及使用者經由本服務取得的任何服務、資訊或其他資料之正確性</li>
                            <li>任何軟體中之錯誤都能被及時修正。會員及使用者使用本服務下載或取得任何資料，應自行考量且自負風險。會員及使用者自本服務或經由本服務取得之建議和資訊，無論其為書面或口頭，均不構成本服務之保證。</li>
                        </ol>
                        <h3>十五、兒童及青少年之保護</h3>
                        <p>上網已成為兒童及青少年的日常活動，但網路上卻存在許多不適合他們接觸的內容，例如色情或暴力。這些內容可能對兒童及青少年的身心造成傷 害，因此家長或監護人應承擔以下責任以確保他們使用網路的安全：</p>
                        <ol>
                            <li>在兒童或青少年使用網路之前，應檢查網站的隱私政策以確保其個人資料的保護，並提醒他們不要洩露任何個人資料，例如姓名、地址、電話 號碼、電子郵件地址、照片或信用卡號碼等，也不要獨自與網友見面或接受禮物。</li>
                            <li>家長或監護人應謹慎選擇適合兒童或青少年瀏覽的網站，特別是未滿 12 歲的兒童，應在其上網時全程陪伴，十二歲以上未滿十八歲的青少 年則應慎重考慮是否同意上網。</li>
                        </ol>
                        <h3>十六、藝術品上架政策</h3>
                        <ol>
                            <li>網站對上架藝術品所作之承諾</li>
                        </ol>
                        <p>(1) 本平台只會接受有以下其中一種證明文件，且其販售價格必須在市場行情合理範圍內為藝術品。</p>
                        <p>(1-1) 畫廊保證書</p>
                        <p>(1-2) 售賣的藝術品必須有藝術家出具或授權的原作保證</p>
                        <p>(1-3) 售賣的藝術品必須附有藝術家配偶或直系親屬出具的相關證明文件</p>
                        <p>(1-4) 售賣的藝術品必須附有經本公司認可之鑑定單位所出具的鑑定報告書</p>
                        <p>(1-5) 售賣的藝術品必須附有創作藝術家、代理畫廊或相關展覽單位所出版的畫冊、出版物等相關文件</p>
                        <p>(2) 畫廊保證其有權代理或銷售藝術品(例如藝術家的書面授權或其他法律權源)，並可以完整無缺地將藝術品所有權轉讓給買家。同時畫廊還保證沒有第三方對藝術品主張任何權利或請求任何損害賠償 (包括著作權侵權的賠償)。</p>
                        <p>(3) 畫廊承諾遵守所有與藝術品進出口相關的法律和規定，並且已書面通知本公司，如畫廊曾經與這些法律或規定有違反行為的第三方有過往合作關係。</p>
                        <p>(4) 畫廊承諾，如有任何有關藝術品的修改或任何第三方對藝術品的所有權、狀況或歸屬有所聲明，已書面通知本公司。</p>
                        <p>(5) 如果畫廊提供的保證或信息是虛假的，畫廊必須賠償本公司和買家因此而遭受的所有損失和費用 (包括但不限於第三方對本公司提出的索賠)，不論這些損失和費用是因為藝術品本身還是因為販售過程引起的。</p>
                        <ol start="2">
                            <li>如果畫廊在上架藝術品後有下列情況，本公司有權撤銷、解除或終止委託販售：</li>
                        </ol>
                        <p>(1) 畫廊所做的說明或保證可能存在疑問</p>
                        <p>(2) 畫廊違反了本藝術品上架政策規則</p>
                        <ol start="3">
                            <li>
                                <p>圖片使用權利畫廊同意其在網站上上傳的所有資料和圖片，可供「天堯藝術網」在不違反相關法律法規的前提下進行重製、編輯、改作、散布、發行、展示、播 送、傳輸、上映或發表該藝術品本身等方式，以用於廣告圖示和相關廣告文宣，以推廣品牌和行銷目的。</p>
                            </li>
                            <li>
                                <p>使用者守法義務及承諾畫廊保證不會以任何非法方式使用本網站服務，包括但不限於上架販售藝術品、進行網站廣告、提供行銷服務等。畫廊同時承諾遵守中華民國的相關法規以及國際上使用網際網路的慣例。對於位於中華民國以外的使用者，畫廊同意遵守其所屬國家或地區的法律法規。畫廊也承諾不會利用本服務從事侵害他人權益或違法的行為，包括但不限於：</p>
                            </li>
                        </ol>
                        <p>(1) 不得上載、張貼、公布或傳送任何誹謗、侮辱、具威脅性、攻擊性、不雅、猥褻、不實、違反公共秩序或善良風俗或其他不法之文字、圖片或任何形式的檔案於本服務上</p>
                        <p>(2) 不得侵害他人的名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利</p>
                        <p>(3) 不得違反依法律或契約所應負之保密義務</p>
                        <p>(4) 不得冒用他人名義使用本服務</p>
                        <p>(5) 不得上載、張貼、傳輸或散佈任何含有電腦病毒或任何對電腦軟、硬體產生中斷、破壞或限制功能之程式碼之資料</p>
                        <p>(6) 不得從事不法交易行為或張貼虛假不實、引人犯罪之訊息</p>
                        <p>(7) 不得在本服務上販賣槍枝、毒品、禁藥、盜版軟體或其他違禁物品</p>
                        <p>(8) 不得提供賭博資訊或以任何方式引誘他人參與賭博活動</p>
                        <p>(9) 不得濫發廣告郵件、垃圾郵件、連鎖信、違法之多層次傳銷訊息等垃圾訊息</p>
                        <p>(10) 不得以任何方式傷害未成年人的利益或權益</p>
                        <p>(11) 不得干擾或中斷本服務或伺服器或連結本服務之網路，或不遵守連結至本服務之相關需求、程序、政策或規則等</p>
                        <p>(12) 不得販售含有或暗示暴力意涵之商品</p>
                        <p>(13) 不得販售針對特定人物人身攻擊或含有歧視意涵之商品</p>
                        <p>(14) 不得販售帶有攻擊或有歧視種族、宗教、性別認同、殘疾意涵或鼓吹犯罪之商品</p>
                        <p>(15) 不得販售其他違反公共秩序、善良風俗或依法令禁止販售、廣告之商品</p>
                        <p>(16) 其他經天堯科技文化股份有限公司有正當理由認為不適當之行為</p>
                    </div>
                }
                {
                    typeIndex == 1 && <div>

                        <h3>一、 個人資料之安全</h3>
                        <p>「天堯藝術網」非常注重保護會員的個人隱私，並且遵循「個人資料保護法」的相關法律規定。在未經會員同意之下，我們絕不會向任何與本 網站服務無關的第三人提供會員的個人資料。會員應當妥善保密自己的網路密碼和個人資料，不要將任何個人資料，特別是網路密碼提供給任 何人。在使用完「天堯藝術網」所提供的各項服務功能後，請務必登出帳戶，以免資料被不當使用。如果是與他人共享電腦或使用公共電腦， 務必關閉瀏覽器視窗，以保障個人隱私安全。</p>
                        <h3>二、 個人資料的蒐集、處理、利用</h3>
                        <p>「天堯藝術網」非常重視會員的個人隱私，因此當您註冊「天堯藝術網」帳號、使用我們的產品或服務、瀏覽我們的網頁或參加宣傳等活動 時，我們會蒐集您的個人資料，例如姓名、電子郵件地址、出生日期、 性別、郵遞區號及個人興趣等資料。此外，我們也會自動接收並記錄您電腦和瀏覽器上的資料，例如 IP 位址、cookie 資料、軟體和硬體屬性以及您瀏覽的網頁紀錄。「天堯藝術網」所取得的個人資料，僅供我們於內部依照原來所說明的使用目的和範圍使用。除非經過事先說明或依照相關法律規定，否則「天堯藝術網」不會將資料提供給第三人或移作其他目的使用。我們一般會將資料用於客製化廣告及網頁內容、滿足您對產品和服務的需求、 改進我們的服務、聯絡您、進行研究，以及提供內部及外部客戶匿名報告。</p>
                        <h3>三、 資料安全</h3>
                        <p>為了保護會員的隱私和安全，「天堯藝術網」會使用密碼保護會員帳號資料，並會使用合理的技術和程序來保障所有個人資料的安全。我們會定期檢查我們的系統和技術，以確保其符合最新的安全標準和最佳實踐。 此外，我們也會限制員工和承包商對會員資料的訪問，以確保這些資料不會被不當使用或洩漏。如果您有任何安全方面的疑慮，請立即聯繫我們的客戶服務團隊。</p>
                        <h3>四、 個人資料查詢或更正的方式</h3>
                        <p>如果會員需要查詢、閱覽、製作複製本、更正、補充、停止電腦處理及利用、刪除其個人資料，可以聯絡客服中心向「天堯藝術網」提出需 求，我們會快速處理。</p>
                        <h3>五、 Cookie</h3>
                        <p>為了方便會員使用「天堯藝術網」網站所提供的服務，我們會使用一種稱為 cookie 的技術。這是一種網站伺服器和會員瀏覽器進行溝通的技 術，可以儲存一些字串在會員的電腦中，以便辨識和區分不同的使用者。如果會員關閉了 cookie，可能會導致在使用本網站的過程中出現不 便或部分功能無法使用的情況。</p>
                        <h3>六、 機密性和安全性</h3>
                        <p>本網站所刊載的廣告訊息，由廣告主及提供廠商、組織自行負責其內容的正確性和可能造成之後果，本公司不對此負任何法律責任。另外，本網站的藝術品訊息，包括但不限於藝術品價格和售出狀態等，是由會員和使用者自行上傳，並由提供廠商、組織或個人等提供者自行負責其內容的正確性和能造成之後果，本公司不對此負任何法律責任。</p>
                        <h3>七、 隱私權保護政策修訂</h3>
                        <p>「天堯藝術網」可能會定期檢討和更新本政策。如果在我們處理個人資料時發生重大變更，我們將透過您在「天堯藝術網」帳戶所提供的主要電子郵件地址或在我們的網站上張貼告示的方式通知您。</p>
                        <h3>八、 問題和建議</h3>
                        <p>如果您有任何疑問或建議，歡迎透過我們的客服信箱與我們聯繫。</p>


                    </div>
                }

                {
                    typeIndex == 2 && <div>
                        <h3>一、 責任限制</h3>
                        <ol>
                            <li>
                                <p>本網站上的所有資訊都是由會員及使用者自行發表的，本公司不為其行。為與張貼資訊負任何法律責任。因此會員及使用者必須自行擔保其內容的合法性及可信度。</p>
                            </li>
                            <li>
                                <p>本公司提供的所有服務，都是按照當時的功能提供使用本公司不保證會員及使用者所要求的速度、安全性、完整性、正確性等方面的任何保證責任。</p>
                            </li>
                            <li>
                                <p>會員及使用者上傳的所有資料，包括但不限於文字、圖片、影音等，都應自行判斷是否適合公開於網路上。本公司無法保證所有上傳及儲存資料之安全性、完整性、正確性、合法性。</p>
                            </li>
                            <li>
                                <p>會員及使用者應自行安裝防毒、防駭等軟體，確保自身使用資料及機器設備安全。本公司對於因本網站內容造成之任何軟硬體損害，不負任何法律責任。</p>
                            </li>
                            <li>
                                <p>本網站的資訊可能含有成人內容，未滿18歲之青少年或兒童應取得父母同意註冊使用並且由父母陪同觀看。</p>
                            </li>
                        </ol>
                        <h3>二、 智慧財產權</h3>
                        <ol>
                            <li>
                                <p>本網站及其服務所使用的程式碼、網頁設計語言和軟體，均受到智慧財產權相關法律的保護。未經本網站的授權，會員和使用者不得修改、出 租、出售、散佈本服務的全部或部分，或據此創作衍生作品，或以其他方式轉讓軟體的任何權利。</p>
                            </li>
                            <li>
                                <p>本網站所呈現的贊助廣告和資訊，均受到著作權、商標權、專利權或其他專屬權利的法律保護。未經本網站或廣告商的授權，不得複製、修改或創作衍生作品。</p>
                            </li>
                            <li>
                                <p>會員和使用者上傳的所有內容，必須保證具有合法和完整的著作權和智慧財產權，並保證這些內容不違反法律或侵犯第三方的權益。一旦資料上載、傳送、輸入、公開傳輸或提供至本網站，即視為您已永久、非專屬性、無償的授權本網站可以基於公益或私益之目的，無條件使用，包括但不限於修改、重製、公開播送、改作、散布、發行、公開發表、公開傳輸該等資料。您必須同意這一點。本網站對前述資料的使用有匯整、編輯之權利，但在未經您的授權下，不會以您上傳的資料個別進行銷售、轉讓等行為。</p>
                            </li>
                            <li>
                                <p>會員和使用者上傳的所有內容，必須保證本網站使用、修改、重製、公 開播送、改作、散布、發行、公開發表、轉授權該等資料，不會侵犯任 何他人的智慧財產權。否則，您必須對本公司負損害賠償責任。若因違 反上述要求所衍生之糾紛，由會員和使用者負完全責任。如果由此造成 本公司的損失，則必須負賠償責任。</p>
                            </li>
                            <li>
                                <p>本公司重視智慧財產權，我們要求會員及使用者也同樣尊重他人的智慧財產權。如果我們認為會員或使用者可能涉嫌侵犯他人的智慧財產權， 我們有權暫停或終止其帳戶和服務。如果您認為自己的著作權或其他智慧財產權被侵犯，請提供以下資訊予本公司：</p>
                            </li>
                        </ol>
                        <p>(1) 您是著作權或其他智慧財產權利益的所有人，並提供相關授權的簽章(2) 您的著作權或其他智慧財產權的描述(3) 您所認為侵權資料的位置描述(4) 您的聯繫方式，包括地址、電話號碼和電子郵件地址(5) 您認為該利用未經著作權人、代理人或法律許可之聲明(6) 您在了解虛假陳述責任的前提下，對於上述載於您的通知上之資訊之正確性，且您是著作權人或智慧財產權人或合法代表著作權人或智慧財產權 人之聲明</p>
                        <h3>三、 免責聲明</h3>
                        <p>會員及使用者需要明確了解並同意，本公司所提供的服務並不提供任何明 示或默示的擔保，包括但不限於商業適性、特定目的的適用性及未侵害他人權利。本公司所提供的服務是基於其「現狀」及「提供使用時」的基礎 提供，因此會員及使用者使用本服務時，需自行承擔相關風險。本公司不保證以下事項：</p>
                        <ol>
                            <li>本服務能完全符合會員及使用者的需求</li>
                            <li>本服務不會受到干擾，能及時提供、安全可靠且沒有錯誤</li>
                            <li>由本服務購買之商品或服務能夠完全符合會員及使用者的期望</li>
                            <li>會員及使用者經由本服務取得的任何服務、資訊或其他資料之正確性</li>
                            <li>任何軟體中之錯誤都能被及時修正。 會員及使用者使用本服務下載或取得任何資料，應自行考量且自負風險。 會員及使用者自本服務或經由本服務取得之建議和資訊，無論其為書面或口頭，均不構成本服務之保證。</li>
                        </ol>
                        <h3>四、 第三方服務</h3>
                        <p>本服務提供了指定的第三方服務，例如銀行和金流服務，由第三方自行負責其提供的服務品質和內容。因此在使用本服務時，如果第三方本身系統出現問題、相關作業網路連線品質不佳或其他不可抗拒因素，可能會導致驗證無法完成。</p>
                        <p>如果您所提供的基本資料有誤，並因此造成本服務無法及時通知您異常狀況的緊急處理方式，「天堯藝術網」將不對此負責任何損害賠償責任。為了保障消費者權益，如果您有任何疑問，歡迎您來信至「天堯藝術網」的客服信箱。</p>
                        <h3>五、 藝術品狀況之注意事項</h3>
                        <p>在我們的網站上，我們和其他公司、廠商等第三方合作，由他們提供內容供我們展示。我們對於這些內容不提供任何保證或擔保，因此我們建議會員及使用者在購買前仔細閱讀藝術品相關資訊。如果會員或使用者對於畫廊上架的內容或商品資訊有任何疑問，我們建議直接透過「天堯藝術網」的聯絡畫廊或傳送訊息等功能向畫廊查詢。</p>
                        <p>在這方面，相關的法律責任應由畫廊自行負責。此外，我們也不對畫廊上架的內容或商品資訊提供任何法律上的保證、擔保或連帶賠償責任。如果在選購藝術品時產生任何消費爭議，我們會盡力協助消費者與畫廊聯繫，但我們並不負有介入處理義務，亦不負責任何因消費爭議所產生之法律責任。</p>
                        <p>如果會員或使用者在「天堯藝術網」購買藝術品時，遇到了消費爭議，例如畫廊拒絕履行出貨或售後服務，或者難以聯繫等情況，「天堯藝術網」會 盡力協助消費者與畫廊聯繫，必要時可能會提供畫廊的聯絡方式給消費者。但是，這並不代表「天堯藝術網」有介入處理消費爭議的義務，也不會為消費爭議中的任何一方承擔法律責任或連帶責任。</p>


                    </div>
                }
            </div>

        </div>




    </div >
}

export default Home