import { brandLink } from "../global/constants"
import { useTranslation } from "react-i18next";
import facebookImg from '../assets/brand/facebook.png'
import telegramImg from '../assets/brand/telegram.png'
import twitterImg from '../assets/brand/twitter.png'
import youtubeImg from '../assets/brand/youtube.png'
import tiktokImg from '../assets/brand/tiktok.png'
import discordImg from '../assets/brand/discord.png'
import igImg from '../assets/brand/ig.png'
import mediumImg from '../assets/brand/medium.png'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux';


const Loading = () => {
  const location = useLocation()
  const { t } = useTranslation();

  const loading = useSelector(state => state.loading);

  return <>
    {
      loading == true ?
        <div className="fixed left-0 top-0 w-screen h-screen bg-black bg-opacity-70 z-[5000]">
          <div className="flex w-full h-full justify-center items-center">
            <div className="text-3xl text-white  !font-bold text-center ">
              Loading...
        </div>

          </div>

        </div> : ""
    }
  </>
}
export default Loading