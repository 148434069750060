

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";
import UserApi from "../../../../services/UserApi";
import { useGlobalFunc } from "../../../../global/constants";
import { Pagination } from "@mui/material";


const Home = ({ nextStep }) => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);



    const { setLoading, successToast, errorToast } = useGlobalFunc()

    const [isSale, setIsSale] = useState(true)

    const [dataList, setDataList] = useState(null)

    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
        getDataList(value)
    };


    async function getDataList(page) {
        setLoading(true)
        try {
            let res = ""
            if (isSale) {
                res = await UserApi.manageProductPricedSearch({ page: page, type: "artist" })
            }
            else {
                res = await UserApi.manageProductAuctionSearch({ page: page, type: "artist" })
            }
            console.log(res.data)
            setDataList(res.data.data)
            setTotalPage(res.data.total == 0 ? 1 : Math.ceil(res.data.total / 10))

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)

    }

    useEffect(() => {
        setPage(1)
        getDataList(1)
    }, [isSale]);


    return <div className="mt-8">
        <div className="mb-2 flex">
            <div className="flex bg-gray1 rounded-full p-1  mb-2">
                <button onClick={() => setIsSale(true)} className={`${isSale ? "btn1" : "btn1 !bg-gray1"}`}>定價</button>
                <button onClick={() => setIsSale(false)} className={`${!isSale ? "btn1" : "btn1 !bg-gray1"}`}>拍賣</button>
            </div>
        </div>
        <div className="mt-8 px-6 py-3 bg-white border-solid border-4 border-main3 rounded-[20px] relative  w-full overflow-x-auto whitespace-nowrap">

            <table className="w-full text-sm" >
                <thead className="">

                    <tr className="  text-main6 font-semibold ">
                        <th className="">圖片</th>
                        <th className="">名稱/藝術品ID</th>
                        <th className="">建立時間</th>
                        <th className="">價格</th>
                        <th className="">狀態</th>


                    </tr>
                </thead>

                <tbody>
                    {
                        dataList && dataList.map(i => <tr key={i.id} className=" text-black9 font-semibold ">
                            <td className=""><img src={i.images.length > 0 && i.images[0].image} className="w-10 h-10 object-cover" /></td>
                            <td className="">{i.collectionName}</td>
                            <td className="">{i.createdAt}</td>
                            <td className="">NT${isSale ? i.salePrice : i.startingPrice}</td>
                            <td className="">{t('manage.'+i.status)}</td>

                        </tr>
                        )
                    }


                </tbody>
            </table>
            {
                dataList ?
                    <>
                        {
                            dataList.length == 0 ?
                                <div className="  text-center text-gray-400 text-lg w-full font-bold mb-6 ">
                                    <div>暫無紀錄</div>
                                </div> : ""

                        }

                        <div className="flex w-full  mt-2">
                            <div className="mx-auto">
                                <Pagination count={totalPage} page={page} onChange={handleChange} />
                            </div>
                        </div>
                    </>
                    : ""
            }
        </div>
    </div>

}

export default Home