
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, Link, useNavigate } from "react-router-dom";


import arrowRightWLImg from '../assets/arrowRightWL.png'

const PathLocation = ({ color = 'text-black' }) => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const location = useLocation()


  const [pathList, setPathList] = useState([])

  function navigatePath(index) {
    let temp = ""
    for (let i = 1; i <= index; i++) {
      temp += "/" + pathList[i]
    }

    if (temp == "") {
      navigate("/")
    }
    else {
      navigate(temp)
    }
  }

  useEffect(() => {
    const pathnameSegments = location.pathname.split('/');
    let filteredSegments = pathnameSegments.map((i, index) => {
      if (index === pathnameSegments.length - 1 && /^\d+$/.test(i)) {
        return null;
      }
      if ( i == 'detail'){
        return null
      }
      return i;
    })

    setPathList(filteredSegments.filter((i) => i !== null));

  }, []);

  return (
    <div className={`${color} text-sm text-right mb-3 flex justify-end flex-wrap`}>

      {pathList.map((i, index) => {
        return <div key={index} >
          <span onClick={() => navigatePath(index)} className="cursor-pointer hover:underline">{t(`path.${i}`)}</span>
          {index < pathList.length - 1 && <span className="px-1">/</span>}
        </div>
      })}
    </div>
  )
}
export default PathLocation