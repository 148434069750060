exports.isValidPhoneNumber = (phoneNumber) => {
  // +886 地區碼，10 個或 9 個長度數字，前置可以有 0 或沒有 0
  const taiwanFormat = /^\+886(0\d{9}|[1-9]\d{8})$/;
  if (taiwanFormat.test(phoneNumber)) {
    console.log("符合 +886 格式");
    return true;
  }

  return false;
}


exports.isValidEmail = (str) => {
  const emailRegex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  const isEmailRegexValid = emailRegex.test(str);
  if (isEmailRegexValid) {
    return true;
  } else {
    return false;
  }
}

exports.isValidPassword = (str) => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
  const isPasswordValid = passwordRegex.test(str);
  if (isPasswordValid) {
    return true;
  } else {
    return false;
  }
}