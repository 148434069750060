

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";

import MemberCenterNavBar from "../compontents/MemberCenterNavBar.js";


import { useSelector } from "react-redux";
import MonthlySystem from "./compontents/MonthlySystem.js";



// import homeshadowImg from '../../assets/homeshadow.png'
// import homescrollImg from '../../assets/homescroll.png'

// import homepic1Img from '../../assets/homepic1.png'
// import homepic2Img from '../../assets/homepic2.png'


const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const userInfo = useSelector(state => state.userInfo);


    useEffect(() => {

    }, []);


    return <div className="session1 relative pt-[40px] pb-20 bg-white" >

        <MemberCenterNavBar />
        <MonthlySystem/>


    </div >
}

export default Home