import { brandLink, separatorNumber, useGlobalFunc } from "../global/constants"
import { useTranslation } from "react-i18next";
import facebookImg from '../assets/brand/facebook.png'
import telegramImg from '../assets/brand/telegram.png'
import twitterImg from '../assets/brand/twitter.png'
import youtubeImg from '../assets/brand/youtube.png'
import tiktokImg from '../assets/brand/tiktok.png'
import discordImg from '../assets/brand/discord.png'
import igImg from '../assets/brand/ig.png'
import mediumImg from '../assets/brand/medium.png'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";

import galleryBgImg from '../assets/galleryBg.png'

import { useSelector, useDispatch } from 'react-redux';
import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import UserApi from "../services/UserApi";


const ArtistBox = ({ data }) => {
  const location = useLocation()
  const { t } = useTranslation();



  return <Link to="/artist/1"><div className="bg-[#F2EFFF] rounded-[20px] aspect-[1/1] p-4">
    <div className="w-full h-full rounded-2xl overflow-hidden">
      <img src={data.avatar && data.avatar} className="  w-full aspect-[344/230] object-cover " />
      <div className="relative bg-black  w-full aspect-[344/114] px-4 flex items-center">
        <div className="absolute w-full h-full left-0 top-0">
          <img src={galleryBgImg} className="  w-full h-full object-cover " />
        </div>
        <div className=" relative">

          <p className="font-bold truncate ">{data.name}</p>
          <p className="truncate text-sm">27 Works For Sale</p>
        </div>
      </div>
    </div>
  </div>
  </Link>

}
export default ArtistBox