

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";

import Line1 from '../../compontents/Line1.js'
import Swiper from '../../compontents/Swiper.js'
import TitleName from '../../compontents/TitleName.js'
import SeeMore from '../../compontents/SeeMore.js'
import Btn from '../../compontents/Btn.js'

import circlebgImg from '../../assets/home/circlebg.png'
import circle1Img from '../../assets/home/circle1.png'
import circle2Img from '../../assets/home/circle2.png'
import circle3Img from '../../assets/home/circle3.png'
import crossImg from '../../assets/home/cross.png'

import galleryBgImg from '../../assets/galleryBg.png'
import cupImg from '../../assets/cup.png'

import pic1Img from '../../assets/1.png'
import pic2Img from '../../assets/2.png'
import pic3Img from '../../assets/3.png'


import homebannerImg from '../../assets/homebanner.jpg'


import linesImg from '../../assets/lines.png'
import lines2Img from '../../assets/home/lines2.png'

import swipe1Img from '../../assets/swipe1.png'
import swipe2Img from '../../assets/swipe2.png'
import swipe3Img from '../../assets/swipe3.jpg'

import seeMoreImg from '../../assets/seeMore.png'
import UserApi from "../../services/UserApi.jsx";
import { separatorNumber, useGlobalFunc } from "../../global/constants.js";
import PathLocation from "../../compontents/PathLocation.js";
import ArtistBox from "../../compontents/ArtistBox.js";
import ArtBox from "../../compontents/ArtBox.js";



// import homeshadowImg from '../../assets/homeshadow.png'
// import homescrollImg from '../../assets/homescroll.png'

// import homepic1Img from '../../assets/homepic1.png'
// import homepic2Img from '../../assets/homepic2.png'


const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search);

    function aw() {
        console.log('s')
        navigate('/')
    }


    const galleryList = [
        {
            img: swipe1Img,
            title: "斜陽佳色",
            name: "連雅綺"
        },
        {
            img: swipe2Img,
            title: "世界的末日又多一天",
            name: "黃柔閩"
        },
        {
            img: swipe3Img,
            title: "Water and Fire",
            name: "葛拉娜"
        },

    ]


    const { setLoading, successToast, errorToast } = useGlobalFunc()


    const [artistDataList, setArtistDataList] = useState(null)

    const [artDataList, setArtDataList] = useState(null)



    async function getArtistDataList() {
        setLoading(true)
        try {
            let res = await UserApi.artistSearch({ page: 1 })
            console.log(res.data.data)
            setArtistDataList(res.data.data)

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)

    }

    async function getArtDataList() {
        setLoading(true)
        try {
            let res = await UserApi.productPricedSearch({ page: 1 })
            console.log(res.data.data)
            setArtDataList(res.data.data)

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)

    }

    useEffect(() => {
        getArtistDataList()
        getArtDataList()
    }, []);


    return <div className=" bg-white pt-[40px] " >



        <div className="session1 mb-[80px]">
            <PathLocation />

            <TitleName title1="Online Gallery" title2="線上藝廊" />
        </div >


        <Swiper serviceList={galleryList} ></Swiper>


        <div className="z-10 relative session1 my-[80px]">
            <TitleName title1="Artist" title2="藝術家" />
            <div className="my-[80px]">
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-[50px]">
                    {
                        artistDataList && artistDataList.slice(0,3).map(i => <ArtistBox key={i.id} data={i} />
                        )
                    }

                </div>
                <div className="flex justify-end mt-8 mb-20">
                    <SeeMore to="/artistlist" />
                </div>

            </div>

            <TitleName title1="Artwork" title2="藝術品" />
            <div className="my-[80px]">
                {
                    artDataList != null ?

                        <div className="w-full grid grid-cols-1 sm:grid-cols-3 gap-10 mt-20 ">

                            {
                                artDataList.slice(0,3).map((i) => <ArtBox key={i.id} data={i} />)
                            }

                        </div>
                        : ""
                }

            </div>
            <div className="flex justify-end mt-8 mb-20">
                <SeeMore to="/artlist" />
            </div>



            <TitleName title1="Q&A" title2="常見問題" />

            <div className="mt-8 relative  grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-10 items-center">
                <Btn onClick={() => navigate('FAQ')} title={t('onlinegallery.artBtn1')} />
                <Btn onClick={() => navigate('QandA')} title={t('onlinegallery.artBtn2')} />
                <Btn onClick={() => navigate('artmaintenance')} title={t('onlinegallery.artBtn3')} />
                <Btn onClick={() => navigate('terms?type=0')} title={t('onlinegallery.artBtn4')} />
                <Btn onClick={() => navigate('terms?type=1')} title={t('onlinegallery.artBtn5')} />
                <Btn onClick={() => navigate('terms?type=2')} title={t('onlinegallery.artBtn6')} />

            </div>




        </div>
        <div className="w-full relative">
            <img src={lines2Img} className=" absolute left-0 bottom-0 w-full object-contain " />
        </div>



    </div >
}

export default Home