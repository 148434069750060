import { brandLink, separatorNumber, useGlobalFunc } from "../global/constants"
import { useTranslation } from "react-i18next";
import facebookImg from '../assets/brand/facebook.png'
import telegramImg from '../assets/brand/telegram.png'
import twitterImg from '../assets/brand/twitter.png'
import youtubeImg from '../assets/brand/youtube.png'
import tiktokImg from '../assets/brand/tiktok.png'
import discordImg from '../assets/brand/discord.png'
import igImg from '../assets/brand/ig.png'
import mediumImg from '../assets/brand/medium.png'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux';
import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import UserApi from "../services/UserApi";

const ArtBox = ({ data}) => {
  const location = useLocation()
  const { t } = useTranslation();



  return <Link to={`/art/${data.id}`}>
    <img src={data.images[0].image} className="w-full object-cover aspect-[1/1] rounded-xl  mb-4" />

    <p className="text-black9 font-semibold mb-1 textline2">{data.collectionName}</p>
    <p className="text-black9 text-sm mb-2">{data.artistName}</p>
    <p className="text-black9 mb-6">NT${separatorNumber(data.salePrice)}</p>
    <div className="bg-[#EFD890] w-full h-0.5 "></div>
  </Link>

}
export default ArtBox