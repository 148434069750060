import { brandLink, separatorNumber, useGlobalFunc,checkEventStatus,time2date } from "../global/constants"
import { useTranslation } from "react-i18next";
import facebookImg from '../assets/brand/facebook.png'
import telegramImg from '../assets/brand/telegram.png'
import twitterImg from '../assets/brand/twitter.png'
import youtubeImg from '../assets/brand/youtube.png'
import tiktokImg from '../assets/brand/tiktok.png'
import discordImg from '../assets/brand/discord.png'
import igImg from '../assets/brand/ig.png'
import mediumImg from '../assets/brand/medium.png'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  useNavigate
} from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux';
import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import UserApi from "../services/UserApi";

const ArtEventBox = ({ data}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation();


  const loading = useSelector(state => state.loading);

  const { setLoading, successToast, errorToast } = useGlobalFunc()
 

  useEffect(() => {

  }, []);

  return <div onClick={()=>navigate(`${data.id}`)} className=" cursor-pointer flex flex-col sm:flex-row items-center mt-8 mb-12 text-black9 text-base  ">
  <img className="w-full sm:w-1/3 flex-none mb-4 sm:mb-0 rounded-[10px] aspect-[16/9] object-cover" src={data.images && data.images.banner} />
  <div className="sm:px-8 flex flex-col">

      <div className="text-black9 font-semibold text-base mb-3">
          {data.name}
      </div>
      <div className="text-black9 text-sm mb-3">
          {time2date(data.startTime)} ~ {time2date(data.endTime)}｜{data.address}
      </div>
      <div className="text-[#EB5757] font-semibold">
          {checkEventStatus(data.startTime,data.endTime)}
      </div>
  </div>
</div>
}
export default ArtEventBox