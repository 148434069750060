import api from './api.jsx'

export default class UserApi {

    static signUp(data) {
        return api({
            url: '/auth/signUp',
            method: "POST",
            data
        })
    }
    static signIn(data) {
        return api({
            url: '/auth/signIn',
            method: "POST",
            data
        })
    }
    static sendKYC(data) {
        return api({
            url: '/auth/apply/kyc',
            method: "POST",
            data
        })
    }
    static me() {
        return api({
            url: '/auth/me',
            method: "GET",

        })
    }
    static artistApply(data) {
        return api({
            url: '/auth/artist/apply',
            method: "POST",
            data
        })
    }
    static cartAdd(data) {
        return api({
            url: '/auth/cart/add',
            method: "POST",
            data
        })
    }
    static cartDelete(data) {
        return api({
            url: '/auth/cart/delete',
            method: "DELETE",
            data
        })
    }
    static cartGet(data) {
        return api({
            url: '/auth/cart/get',
            method: "GET",
            data
        })
    }



    static appraisalApply(data) {
        return api({
            url: '/application/appraisal/apply',
            method: "POST",
            data
        })
    }
    static appraisalSearch(data) {
        return api({
            url: `/application/appraisal/search?page=${data.page}&pageSize=10`,
            method: "GET",
        })
    }
    static appraisalGet(data) {
        return api({
            url: `/application/appraisal/get?id=${data.id}`,
            method: "GET",
        })
    }

    static entrustedPricingApply(data) {
        return api({
            url: '/application/entrustedPricing/apply',
            method: "POST",
            data
        })
    }
    static entrustedPricingSearch(data) {
        return api({
            url: `/application/entrustedPricing/search?page=${data.page}&pageSize=10&type=${data.type}`,
            method: "GET",
        })
    }
    static entrustedPricingGet(data) {
        return api({
            url: `/application/entrustedPricing/get?id=${data.id}`,
            method: "GET",
        })
    }

    static entrustedAuctionApply(data) {
        return api({
            url: '/application/entrustedAuction/apply',
            method: "POST",
            data
        })
    }
    static entrustedAuctionSearch(data) {
        return api({
            url: `/application/entrustedAuction/search?page=${data.page}&pageSize=10&type=${data.type}`,
            method: "GET",
        })
    }
    static entrustedAuctionGet(data) {
        return api({
            url: `/application/entrustedAuction/get?id=${data.id}`,
            method: "GET",
        })
    }

    static artArticleSearch(data) {
        return api({
            url: `/artArticle/search?page=${data.page}&pageSize=10&type=${data.type}`,
            method: "GET",
        })
    }
    static artArticleGet(data) {
        return api({
            url: `/artArticle/get?id=${data.id}`,
            method: "GET",
        })
    }

    static exhibitionEventSearch(data) {
        return api({
            url: `/exhibitionEvent/search?page=${data.page}&pageSize=10`,
            method: "GET",
        })
    }
    static exhibitionEventGet(data) {
        return api({
            url: `/exhibitionEvent/get?id=${data.id}`,
            method: "GET",
        })
    }

    static artGatheringSearch(data) {
        return api({
            url: `/artGathering/search?page=${data.page}&pageSize=10`,
            method: "GET",
        })
    }
    static artGatheringGet(data) {
        return api({
            url: `/artGathering/get?id=${data.id}`,
            method: "GET",
        })
    }

    static physicalAuctionSessions(data) {
        return api({
            url: `/physicalAuction/sessions/get?id=${data.id}`,
            method: "GET",
        })
    }

    static physicalAuctionCatalogSearch(data) {
        return api({
            url: `/physicalAuction/catalog/search?id=${data.id}&page=${data.page}&pageSize=10`,
            method: "GET",
        })
    }


    static physicalAuctionSearch(data) {
        return api({
            url: `/physicalAuction/search?id=${data.id}&page=${data.page}&pageSize=10`,
            method: "GET",
        })
    }
    static physicalAuctionGet(data) {
        return api({
            url: `/physicalAuction/get?id=${data.id}`,
            method: "GET",
        })
    }

    static artVideoSearch(data) {
        return api({
            url: `/artVideo/search?page=${data.page}&pageSize=10`,
            method: "GET",
        })
    }
    static artVideoGet(data) {
        return api({
            url: `/artVideo/get?id=${data.id}`,
            method: "GET",
        })
    }

    static productPricedSearch(data) {
        return api({
            url: `/product/priced/search?page=${data.page}&pageSize=10`,
            method: "GET",
        })
    }
    static productPricedGet(data) {
        return api({
            url: `/product/priced/get?id=${data.id}`,
            method: "GET",
        })
    }

    static homeIndexGet() {
        return api({
            url: `/home/index`,
            method: "GET",
        })
    }

    static artFocusIndexGet() {
        return api({
            url: `/artFocus/index`,
            method: "GET",
        })
    }



    static updateMe(data) {
        return api({
            url: '/auth/me',
            method: "PATCH",
            data
        })
    }

    static getOtp(data) {
        return api({
            url: '/auth/getOtp',
            method: "POST",
            data
        })
    }

    static bidRegister(data) {
        return api({
            url: '/auth/bid/register',
            method: "POST",
            data
        })
    }

    static bidProduct(data) {
        return api({
            url: '/auth/bid/product',
            method: "POST",
            data
        })
    }


    static productAuctionSessionsSearch(data) {
        return api({
            url: `/product/auction/sessions/search?page=${data.page}&pageSize=10&status=${data.status}`,
            method: "GET",
        })
    }

    static productAuctionSessionsThemeGet(data) {
        return api({
            url: `/product/auction/sessions/theme/get?sessionsId=${data.sessionsId}`,
            method: "GET",
        })
    }

    static productAuctionSearch(data) {
        return api({
            url: `/product/auction/search?page=${data.page}&pageSize=10&themeId=${data.themeId}`,
            method: "GET",
        })
    }

    static productAuctionGet(data) {
        return api({
            url: `/product/auction/get?id=${data.id}`,
            method: "GET",
        })
    }

    static favoriteAdd(data) {
        return api({
            url: `/auth/favorite/add`,
            method: "POST",
            data: data
        })
    }
    static favoriteSearch(data) {
        return api({
            url: `/auth/favorite/search?page=${data.page}&pageSize=10&type=${data.type}`,
            method: "GET",
        })
    }
    static favoriteDelete(data) {
        return api({
            url: `/auth/favorite/delete`,
            method: "DELETE",
            data: data
        })
    }
    static getArtAuction() {
        return api({
            url: `/artsAuction/index`,
            method: "GET",
        })
    }



    static artistSearch(data) {
        return api({
            url: `/artist/search?page=${data.page}&pageSize=10`,
            method: "GET",
        })
    }
    static artistGet(data) {
        return api({
            url: `/artist/get?id=${data.id}`,
            method: "GET",
        })
    }

    static orderPay(data) {
        return api({
            url: `/order/pay`,
            method: "POST",
            data
        })
    }


    static nftRecordSearch(data) {
        return api({
            url: `/auth/me/nft/record?page=${data.page}&pageSize=10`,
            method: "GET",
        })
    }

    static orderSearch(data) {
        return api({
            url: `/order/search?page=${data.page}&pageSize=10`,
            method: "GET",
        })
    }

    static bidWinnerSearch(data) {
        return api({
            url: `/auth/me/bid/winner?page=${data.page}&pageSize=10`,
            method: "GET",
        })
    }

    static bidRegisterSearch(data) {
        return api({
            url: `/auth/me/bid/register?page=${data.page}&pageSize=10&type=${data.type}`,
            method: "GET",
        })
    }

    static productAuctionSubscribe(data) {
        return api({
            url: `/product/auction/subscribe`,
            method: "POST",
            data
        })
    }

    static memorabiliaSearch(data) {
        return api({
            url: `/memorabilia/search?year=${data.year}`,
            method: "GET",
        })
    }

    static manageProductPricedSearch(data) {
        return api({
            url: `/auth/product/priced/search?page=${data.page}&pageSize=10&type=${data.type}`,
            method: "GET",
        })
    }

    static manageProductAuctionSearch(data) {
        return api({
            url: `/auth/product/auction/search?page=${data.page}&pageSize=10&type=${data.type}`,
            method: "GET",
        })
    }

    static settlementRecord(data) {
        return api({
            url: `/auth/settlement/record/get?dateStr=${data.dateStr}`,
            method: "GET",
        })
    }

    static orderRemittanceCreate(data) {
        return api({
            url: `/order/remittance/create`,
            method: "POST",
            data:data
        })
    }

    static lineNotifyToken(data) {
        return api({
            url: `/auth/me/line/notifyToken`,
            method: "PATCH",
            data:data
        })
    }
}