

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";

import Line1 from '../../../compontents/Line1.js'
import Swiper from '../../../compontents/Swiper.js'
import TitleName from '../../../compontents/TitleName.js'
import TitleName2 from '../../../compontents/TitleName2.js'
import SeeMore from '../../../compontents/SeeMore.js'


import themeImg from '../../../assets/themeImg.png'

import cupImg from '../../../assets/cup.png'


import pic1Img from '../../../assets/1.png'
import pic2Img from '../../../assets/2.png'
import pic3Img from '../../../assets/3.png'
import pic4Img from '../../../assets/4.jpg'

import hallbgImg from '../../../assets/hallbg.png'

import moreArrowRightImg from '../../../assets/moreArrowRight.png'



import linesImg from '../../../assets/lines.png'
import lines2Img from '../../../assets/home/lines2.png'

import swipe1Img from '../../../assets/swipe1.png'
import swipe2Img from '../../../assets/swipe2.png'
import swipe3Img from '../../../assets/swipe3.jpg'
import PathLocation from "../../../compontents/PathLocation.js";
import UserApi from "../../../services/UserApi.jsx";
import { separatorNumber, useGlobalFunc } from "../../../global/constants.js";
import { Pagination } from "@mui/material";



// import homeshadowImg from '../../../assets/homeshadow.png'
// import homescrollImg from '../../../assets/homescroll.png'

// import homepic1Img from '../../../assets/homepic1.png'
// import homepic2Img from '../../../assets/homepic2.png'


const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search);

    const [sessionsId, setSessionsId] = useState('');
    const [themeId, setThemeId] = useState('');

    const { setLoading, successToast, errorToast } = useGlobalFunc()

    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
        getDataList(value, sessionsId)
    };

    const [data, setData] = useState(null)

    async function getDataList(page, themeId) {
        setLoading(true)
        try {
            let res = await UserApi.productAuctionSearch({ page: page, themeId: themeId })
            console.log(res.data.data)
            setData(res.data.data)
            setTotalPage(res.data.total == 0 ? 1 : Math.ceil(res.data.total / 10))

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)

    }

    async function registerBid() {
        console.log(themeId, sessionsId)
        setLoading(true)
        try {
            let res = await UserApi.bidRegister({
                "themeId": themeId,
                "sessionsId": sessionsId
            })
            console.log(res.data.data)
            successToast('登記成功')
        }
        catch (errors) {
            let error = errors?.response?.data?.message || errors.toString()
            if (error == "You are already participating in this auction") {
                successToast('你登記過該主題拍賣')
            }
            else {
                errorToast(errors?.response?.data?.message || errors.toString())
            }

        }
        setLoading(false)
    }


    useEffect(() => {
        let sessionsId = query.get('sessions')
        if (sessionsId == null) {
            navigate('../')
        }
        else {
            setSessionsId(Number(sessionsId))

            let themeId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
            setThemeId(Number(themeId))

            getDataList(1, themeId)

        }

    }, []);

    return <div className="session1 relative pt-[40px] pb-20 bg-white" >

        <PathLocation />

        {data ?
            <div >
                <div className="flex justify-between items-end text-black8 mb-4">
                    <div className="font-bold text-xl">{data.themeInfo.name}</div>
                    <div className="text-base">{data.themeInfo.name}</div>

                </div>
                <div className="w-full   ">
                    <img src={data.themeInfo.logo} className="w-full aspect-[16/9] object-cover  col-span-3   rounded-[20px] mb-4" />
                    <div className="flex items-center gap-5 text-black9 min-h-full   justify-between">


                        <button onClick={() => navigate(`../theme?sessionsId=${sessionsId}`)} className="text-center  w-full py-3 font-semibold   border-main8 border-solid border-2 text-main9 rounded-full" >其他主題拍賣</button>
                        <button onClick={() => registerBid()} className="text-center  w-full py-3 font-semibold   bg-main8 text-white rounded-full" >登記競投</button>


                    </div >
                </div >
                <div className="flex mt-2">
                    <button className=" text-sm text-black6 underline hidden ">如何參與競拍</button>
                </div>
            </div>
            : ""
        }


        <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-10 mt-20 ">

            {
                data && data.products.map((i) => <Link to={`/artauction/online/art/detail?id=${i.id}`}>
                    <img src={i.images.length > 0 && i.images[0].image} className="w-full object-cover aspect-[1/1] rounded-xl  mb-6" />
                    <div className="bg-[#EFD890] w-full h-0.5 mb-4"></div>
                    <p className="text-black mb-2">{i.lotNumber}</p>
                    <p className="text-black9 font-semibold mb-2">{i.collectionName}</p>
                    <p className="text-black9 mb-2">{i.artist}</p>
                    <p className="text-black9 mb-2">目前出價 NT${separatorNumber(i.currentPrice || i.startingPrice)} </p>
                    <p className="text-sec5 font-bold">下一口價 NT${separatorNumber((i.currentPrice || i.startingPrice) + i.biddingStep)}</p>

                </Link>)
            }

        </div>

        {
            data ?
                <>
                    {
                        data.products.length == 0 ?
                            <div className="  text-center text-gray-400 text-lg w-full font-bold mb-20 ">
                                <div>暫無紀錄</div>
                            </div> : ""

                    }

                    <div className="flex w-full  mt-2 mb-10">
                        <div className="mx-auto">
                            <Pagination count={totalPage} page={page} onChange={handleChange} />
                        </div>
                    </div>
                </>
                : ""
        }



    </div >
}

export default Home