

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";


import docImg from '../../assets/doc.png'


const Home = ({ data, prevStep, nextStep, type }) => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);


    const banner = []

    const countRef = useRef()



    const [faqs, setFAQ] = useState([


    ]);



    const [FAQType, setFAQType] = useState(0);




    useEffect(() => {

    }, []);


    return <div>

        <div className="mt-8 px-6 py-6 bg-white border-solid border-4 border-main3 rounded-[20px] relative  w-full overflow-x-auto whitespace-nowrap">

            <div className="text-black9 text-xl font-bold mb-4 ">鑑價藏品申請表單</div>

            <div className="text-black9 font-bold mb-6 mt-10">關於您的物品</div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">藝術品名稱: {data.collectionName}</div>

            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">藝術家: {data.artist}</div>

            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">原產國 / 地區: {data.countryOfOrigin}</div>
            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">媒材物料: {data.material}</div>

            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">品項: {data.type}</div>

            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">創作年份: {data.creationYear}</div>

            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">是否已表框？: {data.isFramed}</div>
            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">高度: {data.height} cm</div>
            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">寬度: {data.width} cm</div>
            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">深度: {data.depth} cm</div>
            </div>

            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">簽名 / 標籤或標記: {data.signature}</div>
            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">損壞位置: {data.damageLocation}</div>
            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">是否經過修復？如有，修復程度如何？: {data.repairDegree}</div>
            </div>

            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">遞藏及如何購入: {data.purchaseSource}</div>
            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">評論 / 出版文獻 / 展覽 / 專題目錄: {data.sourceInformation}</div>
            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">購入時付款金額: {data.paymentAmount}</div>
            </div>

            {
                type == "commission" &&
                <div className="flex gap-2 flex-col   text-black9 mb-4">
                    <div className=" ">欲起拍價格: {data.salePrice}</div>
                </div>
            }

            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">藝術品詳情: {data.details}</div>

            </div>

            <div className="text-black9 font-bold mb-6 mt-10">圖片及文件</div>
            <div className="w-full  grid grid-cols-5 gap-3">
                <div className="relative col-span-5 md:col-span-3 w-full aspect-[1/1] border border-main2 border-solid flex justify-center items-center  text-black8 text-xl font-bold text-center">
                    <div>
                        <img className="w-full h-full object-contain  mx-auto" src={data.img1} />


                    </div>
                </div>
                <div className="col-span-5 md:col-span-2 grid grid-cols-2 gap-3">
                    {
                        data.img2 &&
                        <div className="relative w-full aspect-[1/1]  border border-main2 border-solid flex justify-center items-center text-black8 text-xl font-bold text-center"><div>
                            <img className="w-full h-full object-contain  mx-auto" src={data.img2} />
                        </div>
                        </div>
                    }

                    {
                        data.img3 &&
                        <div className="relative w-full aspect-[1/1]  border border-main2 border-solid flex justify-center items-center text-black8 text-xl font-bold text-center"><div>
                            <img className="w-full h-full object-contain  mx-auto" src={data.img3} />
                        </div>
                        </div>
                    }

                    {
                        data.img4 &&
                        <div className="relative w-full aspect-[1/1]  border border-main2 border-solid flex justify-center items-center text-black8 text-xl font-bold text-center"><div>
                            <img className="w-full h-full object-contain  mx-auto" src={data.img4} />

                        </div>
                        </div>
                    }

                    {
                        data.img5 &&
                        <div className="relative w-full aspect-[1/1]  border border-main2 border-solid flex justify-center items-center text-black8 text-xl font-bold text-center"><div>
                            <img className="w-full h-full object-contain  mx-auto" src={data.img5} />

                        </div>
                        </div>
                    }



                    <div className="hidden relative w-full aspect-[1/1]  border border-main2 border-solid flex justify-center items-center text-black8 text-xl font-bold text-center"><div>
                        <img className="w-full h-full object-contain  mx-auto" src={data.img6} />

                    </div>
                    </div>
                    <div className="hidden relative w-full aspect-[1/1]  border border-main2 border-solid flex justify-center items-center text-black8 text-xl font-bold text-center"><div>
                        <img className="w-full h-full object-contain  mx-auto" src={docImg} />

                    </div>
                    </div>
                </div>

            </div>

            <div className="text-black6 mb-6 mt-10 text-sm">
                <div className="text-base font-bold mb-3">要求</div>
                <div className=" ">照片：JPEG, PNG, GIF</div>
                <div className=" ">文件：PDF</div>
                <div className=" ">最小尺寸：100px</div>
                <div className=" ">檔案大小限制：20MB</div>
            </div>

            <div className="text-black6 mb-20 mt-10 text-sm">
                <div className="text-base font-bold mb-3 ">注意</div>
                <div className=" ">請使用您所拍攝的照片，我們不能接受物品照片的翻拍照片。</div>
                <div className=" ">在可行情況下移除裝裱鏡框，以利顯示大小及比例、細節及材質。</div>
                <div className=" ">一張局部照片，除非物品過小，否則請顯示物品的25%。</div>
            </div>


            <div className="text-black9 font-bold mb-6 mt-10">聯絡及運送資料</div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">姓名: {data.name}</div>
            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">電話號碼: {data.phone}</div>
            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">電子信箱: {data.email}</div>
            </div>
            <div className="flex gap-2 flex-col   text-black9 mb-4">
                <div className=" ">地址: {data.address}</div>
            </div>


            <div className="flex gap-2 mt-20">
                <button onClick={() => prevStep()} className=" font-bold border-main4 border-solid border text-main4 w-full rounded-full py-3">上一步</button>
                <button onClick={() => nextStep()} className=" font-bold bg-main4 text-white w-full rounded-full py-3">確定</button>
            </div>



        </div>


    </div>

}

export default Home