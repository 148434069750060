
import { useTranslation } from "react-i18next";


const TitleName = (props, { onClick }) => {
  const { t } = useTranslation();



  return (
    <div className="lg:flex items-center gap-x-8">
      <div className="text-5xl md:text-7xl text-main95 titleFont1 pb-8" >
        {props.title1}
      </div>
      <div className="hidden lg:block bg-main95 w-20 rounded-full h-0.5"></div>
      <div className="  text-xl text-[#2B2B2B]">
        {props.title2}
      </div>
    </div>
  )
}
export default TitleName