

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";

import PathLocation from "../../../compontents/PathLocation.js";



const Home = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const location = useLocation();
    const query = new URLSearchParams(location.search);



    const typeList = ["賣家", "買家",]
    const [typeIndex, setTypeIndex] = useState(0)
    const first = useRef(false)



    useEffect(() => {
        let type = query.get('type')
        let typeIndex = typeList.indexOf(typeList[Number(type)])
        if (typeIndex != -1) {
            setTypeIndex(typeIndex)
            window.scroll(0, 0)
        }
        else {
            setTypeIndex(0)

        }

    }, [query.get('type')]);

    return <div className="session1 relative pt-[40px] pb-20 bg-white" >

        <PathLocation />
        <div className="flex flex-wrap gap-4 items-end">
            <p className="text-2xl text-black9 font-semibold">藝品線上拍賣規則</p>
            {
                typeList.map((i, index) => <div className={`${index == typeIndex ? "text-main7" : "text-black6"}  font-bold text-base `} key={i}>
                    <button onClick={() => navigate(`?type=${index}`,{replace:true})}>{i}</button>
                </div>)
            }
        </div>

        <div className="py-14 ">

            <div className="text-black8 mb-5 w-full border border-solid border-main2 rounded-[40px] bg-white px-[20px] sm:px-[56px] py-[40px] ">
                <p>歡迎來到我們的線上拍賣平台，這裡集結了來自世界各地的珍品藝術品、收藏品、古董與稀有物品，每一件物品都是獨一無二的，可以滿足您對奢華、稀有和收藏的追求。無論您身在何處都能輕鬆參與拍賣，競爭價值更加公平透明。 除了獨特的藏品，我們還提供專業的鑑定服務和優質的客戶支援，讓您放心選購。不論您是藝術愛好者、收藏家或是尋找獨特禮物的消費者，這裡都能滿足您的需求。現在就加入我們，尋找您的夢幻藏品吧!</p>
            </div>

            <div className="doc text-black8 mb-5 w-full border border-solid border-main2 rounded-[40px] bg-white px-[20px] sm:px-[56px] pb-[60px]">
                {
                    typeIndex == 0 && <div>
                        <h3>賣家規則</h3>
                        <p>當你作為一個賣家，在我們的線上拍賣平台上出售物品時，需要遵守以下規 則：</p>
                        <p>出售物品：賣家須確保所出售物品的真實性、完整性、合法性、有效性，並且保證物品不存在任何法律糾紛和知識產權問題。如有不實之處，賣家需承擔相應責任。</p>
                        <p>物品描述：賣家需提供準確詳盡的物品描述，包括但不限於物品名稱、品牌、型號、規格、材質、尺寸、生產日期、保養方式、使用狀況等資訊。如有補充說明，也需要在描述中詳細說明。若賣家故意隱瞞物品的實際情況，將會承擔相應的責任。</p>
                        <p>拍賣價格：賣家須合理設定起拍價格。</p>
                        <p>拍賣成功：如該物品在拍賣期限內有買家出價，則該物品將成功拍賣。賣家需依照系統提示進行交易，如有違規行為，平台有權取消該次交易。</p>
                        <p>交易流程：買家付款&gt;&gt;買家需在規定的時間內完成付款。賣家發貨&gt;&gt;賣家需在規定的時間內完成發貨，並按照系統提示提供相關物流信息，以確保買家收到物品。買家確認收貨&gt;&gt;買家需在收到物品後，在規定的時間內確認收貨，如有瑕疵或不符合描述需在規定的時間內提出退貨或換貨要求。賣家收到貨款&gt;&gt;當買家確認收貨後，系統將於每個月的系統結算賬務時，會將買家的款項打入賣家的賬戶中。</p>
                        <p>物品交付：賣家需在拍賣成功後3天內，將物品寄給我們，由我們協助出貨給買家。如需延遲交付，需事先向我們提出，如因賣家原因無法完成交付， 賣家需承擔相應責任，並賠償買家相應的損失。</p>
                        <p>物品退換：如買家收到物品後發現存在質量問題或與描述不符，可申請退換。賣家需配合調解，並按照調解結果履行相關義務。</p>
                        <p>賣家責任：賣家需自行承擔所售物品的相關風險和責任。如因賣家原因產生的任何法律糾紛、知識產權問題、質量問題、物品運送損壞等問題，賣家需承擔相應責任並承擔損失賠償責任。</p>
                        <p>拍賣手續費：平台將收取一定比例的拍賣手續費用。賣家需在拍賣成功後根據平台規定的收費標準支付相應的手續費用。</p>
                        <p>違規處罰：如賣家違反拍賣規則，包括但不限於提供虛假物品信息、拍賣價格不合理、拒絕交付物品等行為，平台將根據情節嚴重程度，進行相應的處罰，包括但不限於限制賣家拍賣權限、凍結賣家賬戶等。</p>
                        <p>其他規定：如有其他未列明的事項，平台將根據相關法律法規和平台實際情況進行處理，並及時向賣家通知。賣家需遵守平台的相關規定和要求，並自行承擔相應的法律責任和風險。</p>
                        <p>為確保交易的順利進行，賣家需嚴格遵守上述拍賣規則和交易流程，如有違反，將承擔相應的責任和損失。我們將保留對違反拍賣規則的賣家進行相應處罰的權利，包括但不限於限制拍賣、扣除信用分等處罰措施。我們希望賣家能夠誠信經營、遵守法律法規和拍賣規則，提供優質的商品和服務，讓消費者能夠放心購買和交易。</p>

                    </div>
                }
                {
                    typeIndex == 1 && <div>

                        <h3>買家規則</h3>
                        <p>當你作為一個買家，在我們的線上拍賣平台上購買物品時，需要遵守以下規則：</p>
                        <p>參與拍賣：買家需先註冊平台帳號，並提供真實有效的個人信息，方可參與拍賣。買家需明確自己的出價意願和經濟實力，在參與拍賣前自行確定購買意願和能力，並承擔相應風險和責任。</p>
                        <p>出價：買家可在拍賣期限內進行出價，並須符合賣家所設定的出價規則和要求。出價需真實有效，且一旦出價後不得撤銷或修改，否則將承擔相應的責任和損失。</p>
                        <p>付款：拍賣成功後，買家需按照系統提示進行付款，並在付款期限內完成付款。如買家未能按時付款，系統將取消該次交易。買家需自行承擔由此產生的風險和損失。</p>
                        <p>物品領取：物品領取需在交易完成後進行，買家需按照系統所指定的時間和地點領取物品，或提供收件資料選擇郵寄。如買家未能及時領取物品，系統將視為買家自動放棄該次交易，我們有權將物品轉售或追究買家的相應責任。</p>
                        <p>物品損壞：如在物品領取過程中出現損壞，買家需及時聯繫我們進行處理。如是買家主觀原因造成的損壞，買家需承擔相應責任和賠償，如是賣家原因造成的損壞，賣家需承擔相應責任和賠償。</p>
                        <p>交易爭議：如在交易過程中出現任何爭議，買家可向平台客服申訴，平台將依據相關規定進行處理。</p>
                        <p>遵守規定：買家需遵守平台相關規定和國家法律法規，不得從事任何違法活動，如發現任何違法行為，平台將依法處理，並可能對買家進行相應處罰，包括但不限於凍結帳戶、限制交易等。</p>
                        <p>責任承擔：買家需自行承擔自己的交易風險和責任。在交易過程中，如因買家自身原因導致交易失敗或造成損失，買家需承擔相應的責任和損失。買家應自行承擔任何因自己的違法行為而引起的風險和責任。</p>
                        <p>信用評價：買家的信用評價將影響其在平台上的交易權益，請買家在交易過程中保持良好的信譽和評價。買家應該遵守交易承諾，履行自己的付款和領取物品等承諾，不得在交易中進行任何詐騙行為，否則將承擔相應的責任和損失。</p>
                        <p>違規處理：如果買家違反平台相關規定或國家法律法規，平台將依法處理，並保留追究買家相關責任的權利。如果買家的行為對他人造成損失或損害， 買家需承擔相應的法律責任和賠償責任。</p>
                        <p>此外，買家還應留意平台公告和通知，及時掌握平台政策和操作流程的變化。 如有任何疑問，可隨時聯繫平台客服進行咨詢和反饋。 最後，買家應認真閱讀並理解賣家所提供的物品信息，包括但不限於物品的品牌、型號、顏色、尺寸、材質、產地、保固等信息，如有任何疑問，買家應及時聯繫賣家進行咨詢。在購買物品前，買家應仔細核對自己的需求和預算，並自主作出購買決策，承擔相應的風險和責任。</p>

                    </div>
                }

            </div>

        </div>




    </div >
}

export default Home