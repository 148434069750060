

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";


import { useGlobalFunc } from "../../../global/constants.js";
import UserApi from "../../../services/UserApi.jsx";
import Line2 from "../../../compontents/Line2.js";
import ArtGrid from "../../../compontents/ArtBox.js";
import AddFavorite from "../../../compontents/AddFavorite.js";
import ArtNewsBox from "../../../compontents/ArtNewsBox.js";
import ArtEventBox from "../../../compontents/ArtEventBox.js";
import ArtVideoBox from "../../../compontents/ArtVideoBox.js";
import ArtistBox from "../../../compontents/ArtistBox.js";
import ArtBox from "../../../compontents/ArtBox.js";



// import homeshadowImg from '../../assets/homeshadow.png'
// import homescrollImg from '../../assets/homescroll.png'

// import homepic1Img from '../../assets/homepic1.png'
// import homepic2Img from '../../assets/homepic2.png'


const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);


    const [itemId, setItemId] = useState(0)

    const { setLoading, successToast, errorToast } = useGlobalFunc()

    const [data, setData] = useState(null)

    const [selIndex, setSelIndex] = useState(0)



    async function getData(id) {
        setLoading(true)
        try {
            let res = await UserApi.artGatheringGet({ id: id })
            console.log(res.data.data)
            setData(res.data.data)

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)

    }



    useEffect(() => {
        let id = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        setItemId(id)
        getData(id)
    }, []);

    return <div className=" bg-white " >
        {
            data != null ?
                <div className="relative w-full  ">
                    <div className="relative w-full  ">

                        <div className="w-full bg-black4">
                            <div className="session1  flex items-center gap-10 py-28">
                                <div className="w-60 aspect-[1/1]  rounded-[20px] overflow-hidden " >
                                    <img className=" bg-white w-full h-full object-cover" src={data.images.logo} />
                                </div>
                                <div className="text-black9 w-full">
                                    <div className="flex justify-between">
                                        <div>
                                            <p className="font-bold text-xl ">亞洲藝術中心</p>
                                            <p className="text-sm">台灣，台北市</p>
                                        </div>
                                        <div className="relative">
                                            <AddFavorite color="text-black" hoverColor="hover:text-sec6" data={{ itemId: data.id, type: "artGathering" }} />
                                        </div>

                                    </div>

                                    <div className=" brightness-0">
                                        <Line2 />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className=" absolute  bottom-0 left-0 flex justify-center w-full  ">
                            {
                                ["關於", "新聞", "展覽", "影音", "藝術家", "藝術品"].map((i, index) => <div onClick={() => setSelIndex(index)} className={`${selIndex == index ? 'bg-white rounded-t-[30px] text-black' : "text-black6"}  px-10 py-4  text-sm font-bold cursor-pointer `}>{i}</div>)
                            }
                        </div>

                    </div>


                    <div className="session1 mx-auto py-14">

                        {
                            selIndex == 0 ?
                                <div className="w-full ">
                                    {data.content}
                                </div>
                                : ""
                        }
                        {
                            selIndex == 1 ?
                                <div className="w-full ">
                                    <>
                                        <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-10">
                                            {
                                                data.artArticles.map(i => <ArtNewsBox key={i.id} data={i} />
                                                )
                                            }
                                        </div>
                                        {
                                            data.artArticles.length == 0 ?
                                                <div className="  text-center text-gray-400 text-lg w-full font-bold mb-20 ">
                                                    <div>暫無紀錄</div>
                                                </div> : ""
                                        }
                                    </>
                                </div>
                                : ""
                        }

                        {
                            selIndex == 2 ?
                                <div className="w-full ">
                                    <div className="w-full grid grid-cols-1  gap-4 sm:gap-10">
                                        {
                                            data.exhibitionEvent.map(i => <ArtEventBox key={i.id} data={i} />
                                            )
                                        }
                                    </div>
                                    {
                                        data.exhibitionEvent.length == 0 ?
                                            <div className="  text-center text-gray-400 text-lg w-full font-bold mb-20 ">
                                                <div>暫無紀錄</div>
                                            </div> : ""
                                    }

                                </div>
                                : ""
                        }
                        {
                            selIndex == 3 ?
                                <div className="w-full ">
                                    <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-10">
                                        {
                                            data.artVideo.map(i => <ArtVideoBox key={i.id} data={i} />
                                            )
                                        }
                                    </div>
                                    {
                                        data.artVideo.length == 0 ?
                                            <div className="  text-center text-gray-400 text-lg w-full font-bold mb-20 ">
                                                <div>暫無紀錄</div>
                                            </div> : ""
                                    }

                                </div>
                                : ""
                        }
                        {
                            selIndex == 4 ?
                                <div className="w-full ">
                                    <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-10">
                                        {
                                            data.artists.map(i => <ArtistBox key={i.id} data={i} />
                                            )
                                        }
                                    </div>
                                    {
                                        data.artists.length == 0 ?
                                            <div className="  text-center text-gray-400 text-lg w-full font-bold mb-20 ">
                                                <div>暫無紀錄</div>
                                            </div> : ""
                                    }

                                </div>
                                : ""
                        }
                        {
                            selIndex == 5 ?
                                <div className="w-full ">
                                    <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-10">
                                        {
                                            data.pricedProducts.map(i => <ArtBox key={i.id} data={i.productInfo} />
                                            )
                                        }
                                    </div>
                                    {
                                        data.pricedProducts.length == 0 ?
                                            <div className="  text-center text-gray-400 text-lg w-full font-bold mb-20 ">
                                                <div>暫無紀錄</div>
                                            </div> : ""
                                    }

                                </div>
                                : ""
                        }


                    </div>
                </div>
                : ""
        }


    </div >
}

export default Home