

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";

import Line1 from '../../../compontents/Line1.js'
import Swiper from '../../../compontents/Swiper.js'
import TitleName from '../../../compontents/TitleName.js'

import seeMoreImg from '../../../assets/seeMore.png'
import { separatorNumber, useGlobalFunc } from "../../../global/constants.js";
import UserApi from "../../../services/UserApi.jsx";
import { ImageList, Pagination } from "@mui/material";
import PathLocation from "../../../compontents/PathLocation.js";
import SeeMore from "../../../compontents/SeeMore.js";

// import homeshadowImg from '../../assets/homeshadow.png'
// import homescrollImg from '../../assets/homescroll.png'

// import homepic1Img from '../../assets/homepic1.png'
// import homepic2Img from '../../assets/homepic2.png'


const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search);


    const [boxType, setBoxType] = useState(0)

    const banner = []
    const [currBanner, setCurrBanner] = useState(0)
    const countRef = useRef()





    const { setLoading, successToast, errorToast } = useGlobalFunc()
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
        getSession(value)
    }

    const handleDataList = (selIndex,status) => {
        const temp = dataList.map((item, index) => {
            console.log(item)
            if (index === selIndex) {
                item.tabType = status
                console.log(item)
                return { ...item };
            }
            return item;
        });
        setDataList(temp);
    };


    const [dataList, setDataList] = useState(null)

    async function getSession(id) {
        setLoading(true)
        try {
            let res = await UserApi.physicalAuctionSessions({ id: id })
            console.log(res.data)
            setDataList([{ ...res.data.data, tabType: 0 }, { ...res.data.data, tabType: 0 }, { ...res.data.data, tabType: 0 }])
            // setTotalPage(res.data.total == 0 ? 1 : Math.ceil(res.data.total / 10))

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)
    }


    // async function getDataList(page, id) {
    //     setLoading(true)
    //     try {
    //         let res = await UserApi.physicalAuctionSearch({ id: id, page: page })
    //         console.log(res.data)
    //         setDataList(res.data.data)
    //         setTotalPage(res.data.total == 0 ? 1 : Math.ceil(res.data.total / 10))

    //     }
    //     catch (errors) {
    //         errorToast(errors?.response?.data?.message || errors.toString())
    //     }
    //     setLoading(false)
    // }

    useEffect(() => {
        //let id = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        getSession(1)
    }, []);



    return <div className="session1 relative pt-[40px] bg-white pb-20" >

        <PathLocation />
        <TitleName title1="Physical Art Auction" title2="藝品實體拍賣" />

        {
            dataList && dataList.map((i, index) => <div className="w-full  lg:flex my-[60px] mb-8">
                <div className=" boxShadow bg-[#222968] hover:bg-[#333B9D] anim  mx-auto px-4 py-8 sm:px-[40px] sm:py-[32px]  w-full lg:w-[550px] -bottom-[45px] left-[90px] text-main95  rounded-[20px] overflow-hidden">
                    <p className="text-white text-2xl font-semibold mb-6">{i.name}</p>
                    <p className="text-white text-sm mb-6">{i.eventStartDate} － {i.eventEndDate}</p>
                    <div className="flex gap-2 items-center mb-6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M10.0007 1.66699C12.759 1.66699 15.0007 3.88366 15.0007 6.62533C15.0007 10.342 10.0007 15.8337 10.0007 15.8337C10.0007 15.8337 5.00065 10.342 5.00065 6.62533C5.00065 3.88366 7.24232 1.66699 10.0007 1.66699ZM10.0007 5.00033C9.55862 5.00033 9.1347 5.17592 8.82214 5.48848C8.50958 5.80104 8.33398 6.22496 8.33398 6.66699C8.33398 7.10902 8.50958 7.53294 8.82214 7.8455C9.1347 8.15806 9.55862 8.33366 10.0007 8.33366C10.4427 8.33366 10.8666 8.15806 11.1792 7.8455C11.4917 7.53294 11.6673 7.10902 11.6673 6.66699C11.6673 6.22496 11.4917 5.80104 11.1792 5.48848C10.8666 5.17592 10.4427 5.00033 10.0007 5.00033ZM16.6673 15.8337C16.6673 17.6753 13.684 19.167 10.0007 19.167C6.31732 19.167 3.33398 17.6753 3.33398 15.8337C3.33398 14.7587 4.35065 13.8003 5.92565 13.192L6.45898 13.9503C5.55898 14.3253 5.00065 14.842 5.00065 15.417C5.00065 16.567 7.24232 17.5003 10.0007 17.5003C12.759 17.5003 15.0007 16.567 15.0007 15.417C15.0007 14.842 14.4423 14.3253 13.5423 13.9503L14.0756 13.192C15.6506 13.8003 16.6673 14.7587 16.6673 15.8337Z" fill="#F2F2F2" />
                        </svg>
                        <p className="text-white text-base ">{i.eventAddress}</p>
                    </div>

                    <div className="mb-10 border-solid border-[#D0D9FF] border-[1px] rounded-full grid grid-cols-2">
                        <button onClick={() => handleDataList(index,0)} className={`w-full font-semibold h-[44px] rounded-full ${i.tabType == 0 ? "bg-[#D0D9FF]" : "text-[#D0D9FF]"}`}>
                            拍賣時間
                        </button>
                        <button onClick={() => handleDataList(index,1)} className={`w-full font-semibold h-[44px] rounded-full ${i.tabType == 1 ? "bg-[#D0D9FF]" : "text-[#D0D9FF]"}`}>
                            預約看展
                        </button>
                    </div>

                    {
                        i.tabType == 0 ?
                            <div className=" grid sm:grid-cols-2 gap-4 text-white text-sm px-[5px] leading-loose">
                                <div style={{ "whiteSpace": "pre-line" }}  >
                                    {i.auctionTimeContent}
                                </div>
                                <div style={{ "whiteSpace": "pre-line" }} >
                                    {i.previewTimeContent}
                                </div>
                            </div>
                            :
                            <div className="  text-white text-sm px-[5px] leading-loose">
                                <div style={{ "whiteSpace": "pre-line" }}  >
                                    {i.previewContent}
                                </div>

                            </div>
                    }
                </div>

                <div className="hidden w-[236px] pt-10 mx-auto lg:pt-[120px] lg:ml-[120px]">
                    <div className=" w-full rounded-[10px] py-[13px] text-white bg-main8 text-center">
                        即將舉行
                    </div>
                    <button onClick={() => navigate('catolog')} className=" cursor-pointer my-6 text-main6 text-xl flex items-center font-bold">
                        <div>
                            電子圖鑑
                        </div>
                        <img className="ml-5 w-[80px]" src={seeMoreImg} />
                    </button>
                    <button onClick={() => navigate('intro')} className=" cursor-pointer text-main6 text-xl flex items-center font-bold">
                        <div>
                            拍賣簡介
                        </div>
                        <img className="ml-5 w-[80px]" src={seeMoreImg} />
                    </button>

                </div>
                <div className="flex justify-center items-center pt-8">
                    {
                        <SeeMore to={`${i.id}`} />
                    }
                </div>
            </div>
            )

        }


        {
            dataList ?
                <>
                    {
                        dataList.length == 0 ?
                            <div className="  text-center text-gray-400 text-lg w-full font-bold mb-20 ">
                                <div>暫無</div>
                            </div> : ""

                    }

                    <div className="flex w-full  mt-2 mb-10">
                        <div className="mx-auto">
                            <Pagination count={totalPage} page={page} onChange={handleChange} />
                        </div>
                    </div>
                </>
                : ""
        }


    </div >
}

export default Home