

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";

import PathLocation from "../../../compontents/PathLocation.js";



const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);


    const handleDataList = (selGroupIndex, selIndex) => {
        const temp = dataList.map((item, index) => {
            console.log(item)
            if (index === selGroupIndex) {
                item.groupList[selIndex].isOpen = !item.groupList[selIndex].isOpen
                console.log(item)
                return { ...item };
            }
            return item;
        });
        setDataList(temp);
    };


    const [dataList, setDataList] = useState(
        [
            {
                groupTitle: "成為會員 / 忘記密碼",
                groupList: [
                    {
                        isOpen: false,
                        title: "我該如何註冊成為會員?",
                        content: "請進入「註冊/登入」頁面，您可以選擇使用您的 Facebook 帳號或是 E- mail 的帳號登入。填寫完會員基本資料後，您即可成功成為我們的會員。"
                    },
                    {
                        isOpen: false,
                        title: "如果忘記密碼怎麼辦?",
                        content: "請進入「註冊/登入」頁面，點選「忘記密碼」即可修改。"
                    },
                ],
            },
            {
                groupTitle: "購買與訂單",
                groupList: [
                    {
                        isOpen: false,
                        title: "我是透過線上藝廊跟藝術家直接購買藝術品嗎?",
                        content: "線上藝廊提供藝術家展示作品及販售平台，而平台則負責買賣雙方的媒 合。如果在交易過程中，您對於購物體驗有任何不滿意或感到權益受損，請隨時聯繫線上藝廊客服，我們將全力協助您和藝術家進行溝通，以提供最佳的購物體驗。"
                    },
                    {
                        isOpen: false,
                        title: "我可以選擇哪些付款方式呢?",
                        content: "我們的平台提供信用卡、ATM 轉帳/臨櫃匯款等多種付款方式。"
                    },
                    {
                        isOpen: false,
                        title: "我已經完成線上刷卡，要怎麼確認是否付款成功?",
                        content: "當您完成線上刷卡後，信用卡公司會進行授權和認證，一旦完成後，系統將會自動發送訂單和付款確認通知信至您所提供的電子郵件信箱。為確保您能夠即時收到來自系統的通知，請確保填寫的電子郵件地址正確無誤。 此外，您也可以登入線上藝廊的「會員中心」，並進入「交易紀錄」查看已 經完成付款的訂單。"
                    },
                    {
                        isOpen: false,
                        title: "假如我使用 ATM 轉帳，需要主動告知線上藝廊我已經完成付款了嗎?",
                        content: "當您選擇使用 ATM 轉帳或匯款方式下訂單，請在完成匯款後前往「會員中心」的「消費記錄」中，填寫該筆訂單的「匯款帳戶後五碼」，以便我們快速核對您的付款。當線上藝廊確認收到您的付款後，會在會員中心的訂單狀態中顯示「已付款」，並且會儘快為您準備出貨。如果您有任何問題或疑慮，請隨時聯繫我們的客服，我們將竭盡所能協助您，確保您擁有最佳的購物體驗。"
                    },
                ],
            },
            {
                groupTitle: "運送方式與運費",
                groupList: [
                    {
                        isOpen: false,
                        title: "購買藝術品是否需要負擔額外的運費?",
                        content: "每一件藝術品的運費都是依據其尺寸來計算的，當您在結帳時，系統將自動換算運費並加上相關物流級距費用。運費會隨著作品體積和重量而加重。"
                    },
                    {
                        isOpen: false,
                        title: "完成付款後，大約何時可以收到作品?",
                        content: "付款後的 7 至 15 天內，您的訂單將會送達您所指定的運送地址。但是，具體送達時間將會因為作品的尺寸、所在地以及運送地點而有所不同。當您的訂單確認後，我們的專業人員會在 3 個工作天內與您聯繫，確認專屬出貨時程。您也可以透過會員中心的消費紀錄查詢您的訂單狀態。若有任何特殊情況，我們會透過電子郵件另行通知您。"
                    },
                ],
            },
            {
                groupTitle: "退換貨說明",
                groupList: [
                    {
                        isOpen: false,
                        title: "我是否有可能購買到有瑕疵的藝術品?",
                        content: "平台所販售的藝術品，都會在藝術品頁面中詳細描述可能存在的瑕疵狀況。由於作品的年份或創作材料特性等因素，可能會有一些不完美的地 方，例如畫布邊緣可能帶有自然的筆觸或其他創作痕跡。在購買前，請您了解作品的原始特性，也可以聯繫我們的客服。"
                    },
                    {
                        isOpen: false,
                        title: "如果我購買了作品，可以退貨嗎?",
                        content: "(1) 當您在本網站購買藝術品後，我們提供 7 天的鑑賞期。如果您在收到藝術品後 7 天內發現它不符合您的需求，您可以將作品退回我們，我們將全額退還您的購買金額。但如果在您持有藝術品期間造成損壞或瑕疵，我們將無法提供退貨和退款服務，您需要自行負責相關責任。請注意，原購買和退回的運費需由您自行承擔，除非是由於本公司的寄貨流程疏失所致，否則我們將不會退回這些費用。\n\n(2) 如果您需要退貨，請先聯繫我們，然後使用原始包裝自付運費將藝術品寄回。當我們確認作品的狀態後，退款程序可能需要 5-10 個工作日才能完成。如果是由於我們的錯誤而需要換貨，我們將承擔相關的運費。\n\n(3) 確認作品狀態的過程中，如果平台判斷有送鑑定的必要，將請專家對作品進行鑑定，若鑑定結果確定退貨品與平台售出的作品為同一件，我們會從退款金額中扣除鑑定費後退款給您;若您不同意鑑定，我們將無法接受退貨申請。"
                    },
                    {
                        isOpen: false,
                        title: "退換作品時，需要注意什麼?",
                        content: "請務必妥善包裝退換貨作品，本公司有權檢查作品完整性，以確認是否符合退換貨條件。請注意，如果在您持有藝術品的期間造成損壞或瑕疵，本公司將無法提供退貨或退款的服務，責任將由您承擔。"
                    },
                ],
            },

        ]
    )


    useEffect(() => {

    }, []);


    return <div className="session1 relative pt-[40px] pb-20 bg-white" >

        <PathLocation />
        <p className="text-2xl font-bold text-black9">線上藝廊常見問題</p>

        <div className="py-14">
            {
                dataList.map((group, groupIndex) =>
                    <div className=" mb-5 w-full border border-solid border-main2 rounded-[40px] bg-white px-[20px] sm:px-[56px] py-[40px]">
                        <p className="mb-12 text-base font-bold text-main7">{group.groupTitle}</p>

                        <div className="flex flex-col gap-6">
                            {
                                group.groupList.map((i, index) => <div className="cursor-pointer" onClick={() => handleDataList(groupIndex, index)}>
                                    <p className={`text-lg font-bold hover:text-main4 anim ${i.isOpen ? 'text-main4' : 'text-main9'}`}>Q{index + 1}. {i.title}</p>
                                    {
                                        <p className={`pl-6 text-sm font-medium text-black8  overflow-hidden ${i.isOpen ? ' opacity-100 anim mt-4 mb-6' : 'opacity-0 h-0'}`}>{i.content}</p>
                                    }

                                </div>)
                            }
                        </div>

                    </div>

                )
            }

        </div>




    </div >
}

export default Home