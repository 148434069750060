
import { useTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";

import seeMoreImg from '../assets/seeMore.png'
const SeeMore = (props, { onClick }) => {
  const { t } = useTranslation();



  return (
    <Link to={props.to}>
      <div className="titleFont1 w-full text-main6 hover:text-[#333B9D] anim  text-lg md:text-2xl font-semibold flex items-center">
        <div>
          See More
        </div>

        <div className="ml-2 md:ml-3 w-[50px] md:w-[84px] relative">
          <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 84 15" fill="none">
            <path d="M1 14H81L64.7126 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
          </svg>
        </div>
      </div>
    </Link>
  )
}
export default SeeMore