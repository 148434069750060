

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";


import swipe2Img from '../../../assets/swipe2.png'

import { separatorNumber, useGlobalFunc } from "../../../global/constants.js";
import UserApi from "../../../services/UserApi.jsx";
import SeeMore from "../../../compontents/SeeMore.js";
import PathLocation from "../../../compontents/PathLocation";



import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import { useSelector, useDispatch } from 'react-redux';
import ArtLightBox from "../../../compontents/ArtLightBox";
import CountDownTimer from "../../../compontents/CountDownTimer";

import { getDatabase, ref, onValue } from "firebase/database";
import SubscribeBtn from "./compontents/SubscribeBtn";


const OnlineArtDetail = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const dispatch = useDispatch()

    const db = getDatabase();

    const [isShowOrderPriceDialog, setShowOrderPriceDialog] = useState(false);

    const openOrderPriceDialog = () => {
        setShowOrderPriceDialog(true);
    }
    const closeOrderPriceDialog = () => {
        setShowOrderPriceDialog(false);
    }


    const [isShowBidRecordDialog, setShowBidRecordDialog] = useState(false);

    const openBidRecordDialog = () => {
        setShowBidRecordDialog(true);
    }
    const closeBidRecordDialog = () => {
        setShowBidRecordDialog(false);
    }

    const [orderInput, setOrderInput] = useState("")

    const [bidderId, setBidderId] = useState("")
    const [bidRecords, setBidRecords] = useState([])

    const [itemId, setItemId] = useState(0)

    const { setLoading, successToast, errorToast } = useGlobalFunc()

    const [data, setData] = useState(null)
    const isFirst = useRef(true)
    const [price, setPrice] = useState("")



    function checkOwnOrderStatus() {
        if (bidRecords.length > 0 && bidRecords[0].bidderId == bidderId) {
            return <div className="w-full mt-4  text-center  py-2 text-sm font-semibold  text-white rounded-full " style={{ "background": "linear-gradient(65deg, #BD924E 0%, #FFF4AF 100%)" }}>
                您為目前最高出價
            </div>
        }
        else {
            for (let i = 1; i < bidRecords.length; i++) {
                if (bidRecords[i].bidderId == bidderId) {
                    return <div className="w-full mt-4  text-center  py-2 text-sm font-semibold  text-white rounded-full bg-black5" >
                        您的出價已被超越！
                    </div>
                }
            }
            return <div className="w-full mt-4  text-center  py-2 text-sm font-semibold  text-white rounded-full bg-black5" >
                您尚未出價
            </div>
        }

    }




    function orderPriceDialog() {
        openOrderPriceDialog()
        return
        dispatch({
            type: "UPDATE_ACTIONDIALOG",
            payload: {
                actionDialog: {
                    isOpen: true,
                    title: "我要出價",
                    content: <div className="text-black text-center ">
                        請先登入會員<br />
                        方可參與登記競投
                    </div>,
                    btnName1: "暫不登記",
                    btnFunc1: "cancel",
                    btnName2: "前往登入",
                    //btnFunc2: asd,

                }
            }
        });
    }



    async function getOnlineData(id) {
        //setLoading(true)
        try {
            let res = await UserApi.productAuctionGet({ id: id })
            console.log(res.data.data)
            setBidderId(res.data.data.bidderId)
            setData(res.data.data.product)

            if (isFirst.current === true) {
                isFirst.current = false
                let path = `/auction/sessions/${res.data.data.product.sessionsThemeInfo.sessionsId}/themes/${res.data.data.product.themeId}/products/${res.data.data.product.id}`
                const starCountRef = ref(db, path);

                onValue(starCountRef, (snapshot) => {
                    const tempData = snapshot.val();
                    console.log(tempData)
                    const dataArray = Object.values(tempData.records)
                    dataArray.sort((a, b) => b.amount - a.amount);
                    console.log(dataArray)
                    setBidRecords(dataArray)
                });
            }
        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        // setLoading(false)
    }

    async function bidProduct() {
        if (orderInput == "") {
            errorToast('請選擇價格')
            return
        }
        setLoading(true)
        try {
            let res = await UserApi.bidProduct({
                "bidderId": bidderId,
                "auctionProductId": Number(itemId),
                "bidAmount": Number(orderInput)
            })
            console.log(res.data.data)
            successToast('出價成功')
            getOnlineData(itemId)

        }
        catch (errors) {
            let error = errors?.response?.data?.message || errors.toString()
            if (error == "You are already participating in this auction") {
                successToast('你登記過該主題拍賣')
            }
            else {
                errorToast(errors?.response?.data?.message || errors.toString())
            }

        }
        setLoading(false)
        closeOrderPriceDialog()
    }



    useEffect(() => {
        //let id = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        let id = query.get("id")
        setItemId(id)

        getOnlineData(id)



        // const interval = setInterval(() => {
        //     getOnlineData(id)
        // }, 5000);

        // return () => clearInterval(interval); // 在組件卸載時清除 interval


    }, []);

    return <div className="session1 bg-white pt-8 pb-20" >
        <PathLocation />

        {
            data != null ?
                <div className="w-full mx-auto max-w-[500px] md:max-w-none">
                    <div className="mt-4">
                        <div className="w-full  flex flex-wrap ">
                            <div className="w-full md:w-[55%]  md:pr-6 lg:pr-14 mb-4 md:mb-0">
                                <ArtLightBox images={data.images} />



                            </div>

                            <div className="w-full md:w-[45%]    text-black8 text-base flex  ">
                                <div className="hidden md:block flex-none w-0.5 h-full bg-gradient-to-b from-[#CA9849] to-[#FFF4AF] md:mr-6 lg:mr-14"></div>
                                <div className="pt-2 md:pt-8 pb-28 w-full">


                                    {
                                        data.lotNumber && <p className="text-2xl text-black8 font-semibold">Lot .{data.lotNumber}</p>
                                    }
                                    <p className="text-black9 font-semibold text-3xl  mb-4">{data.collectionName}</p>
                                    <p>{data.artistName}</p>

                                    {
                                        data.NT && <p>NT${data.NT}</p>
                                    }


                                    <div className="hidden">
                                        <p className="font-semibold text-lg mt-14">媒材／尺寸</p>
                                        <p>鏡框 設色 紙本, 44.5x70cm</p>

                                        <p className="font-semibold text-lg mt-8">款識</p>
                                        <p>雨浥紅蕖冉冉香。六十七年戊午拈老杜句寫於摩耶精舍，八十叟爰。</p>

                                        <p className="font-semibold text-lg mt-8">鈐印</p>
                                        <p>張爰之印(白)、大千居士(朱)、摩耶精舍(朱)。</p>

                                        <p className="font-semibold text-lg mt-8">出版／備註</p>
                                        <p>1.《登峰造極：張大千逝世三十週年紀念精選》，長流美術館，2013 年 7 月，P.109。
                                            2.《江山萬里－張大千藝術展》，中國美術館，2014 年 1 月，P.107。
                                            3.《千古傳奇：張大千藝術展作品集》，長流美術館，2014 年 4 月，P.80。</p>
                                    </div>


                                    <CountDownTimer endDate={data.sessionsThemeInfo.endDate} />


                                    <p className="mt-8 mb-2 text-base font-bold">目前出價({bidRecords.length}) NT${separatorNumber(bidRecords.length > 0 && bidRecords[0].amount || data.startingPrice)}</p>
                                    <p className="text-xs mb-10">下一口價 NT${ separatorNumber((bidRecords.length > 0 && bidRecords[0].amount || data.startingPrice) + data.biddingStep)} </p>
                                    {
                                        checkOwnOrderStatus()
                                    }
                                    <button onClick={() => orderPriceDialog()} className="w-full mt-4 mb-2 py-2 text-base font-semibold  text-white rounded-full bg-main9 ">我要出價</button>
                                    <p className="mb-8 text-xs text-gray1">使用者請先登記競投才能參與拍賣</p>

                                    {
                                        bidRecords.length != 0 && <div className=" rounded-2xl border-solid border border-black9 overflow-hidden">
                                            <div className="text-sm px-4 pt-2 pb-1 grid grid-cols-2 gap-2 border-solid border-b border-black5">
                                                <div>
                                                    出價價格
                                                </div>
                                                <div>
                                                    出價時間
                                                </div>
                                            </div>
                                            {
                                                bidRecords.slice(0, 4).map(i => <div className=" hover:bg-sec2 px-4 py-2 grid grid-cols-2 gap-2 items-center ">
                                                    <div className="text-sm font-bold text-black9">
                                                        NT${separatorNumber(i.amount)}
                                                    </div>
                                                    <div className="text-sm text-black7">
                                                        {i.createdAt}
                                                    </div>
                                                </div>
                                                )
                                            }


                                        </div>
                                    }
                                    <button onClick={() => openBidRecordDialog()} className="text-gray1 text-sm hover:underline cursor-pointer ml-2 mt-2">競價階梯</button>
                                    <SubscribeBtn sessionsId={data.sessionsThemeInfo.sessionsId} themeId={data.themeId} itemId={data.id} />
                                </div>


                            </div>


                        </div>
                        <div className="flex-none w-full h-0.5 bg-gradient-to-r from-[#CA9849] to-[#FFF4AF] "></div>

                        <div className="text-black9 py-10">
                            <p className="font-semibold text-lg mb-2">藝術品詳情</p>
                            <p dangerouslySetInnerHTML={{ "__html": data.details }} />
                        </div>

                        <div className="flex-none w-full h-0.5 bg-gradient-to-r from-[#CA9849] to-[#FFF4AF]  mb-20"></div>
                    </div>


                    <div className="flex justify-between mb-8 hidden">
                        <div className="text-3xl font-semibold text-black9">更多拍品</div>
                        <SeeMore to="/artlist" />
                    </div>

                    <div className="w-full grid grid-cols-4 gap-10  hidden">

                        {
                            [1, 1, 1, 1].map((i) => <div>
                                <img src={swipe2Img} className="w-full object-cover aspect-[1/1] rounded-xl  mb-6" />
                                <div className="bg-[#EFD890] w-full h-0.5 mb-4"></div>
                                <p className="text-black mb-2">2304</p>
                                <p className="text-black9 font-semibold mb-2">王壯為刻郭夢華印章一方（附汪亞塵畫冊兩本）</p>
                                <p className="text-black9 mb-2">王壯為</p>
                                <p className="text-black9 mb-2">預估價：NT$1,100,000-200,200,000</p>
                                <p className="text-sec5 font-semibol">2022 秋拍</p>

                            </div>)
                        }

                    </div>
                </div>
                : ""
        }
        <Dialog
            PaperProps={{
                style: {
                    width: "400px",
                    margin:"12px",
                    backgroundColor: 'transparent'
                },
            }}
            open={isShowOrderPriceDialog}
            onClose={closeOrderPriceDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {
                data ?
                    <div className="bg-white rounded-xl  border border-main border-solid">
                        <DialogTitle className="flex justify-between items-center gap-2 border-b border-main border-solid">
                            <p className='text-base font-bold'>我要出價</p>

                            <div className=' flex-none w-8 cursor-pointer p-1' onClick={closeOrderPriceDialog}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" fill="none">
                                    <path d="M7.28033 4.54L15.0003 12.26L22.6803 4.58C22.85 4.39943 23.0543 4.25499 23.2812 4.15532C23.508 4.05566 23.7526 4.00283 24.0003 4C24.5308 4 25.0395 4.21071 25.4145 4.58579C25.7896 4.96086 26.0003 5.46957 26.0003 6C26.005 6.2452 25.9595 6.48877 25.8666 6.71576C25.7738 6.94275 25.6355 7.14837 25.4603 7.32L17.6803 15L25.4603 22.78C25.79 23.1025 25.9832 23.5392 26.0003 24C26.0003 24.5304 25.7896 25.0391 25.4145 25.4142C25.0395 25.7893 24.5308 26 24.0003 26C23.7454 26.0106 23.4911 25.968 23.2536 25.8751C23.016 25.7821 22.8003 25.6408 22.6203 25.46L15.0003 17.74L7.30033 25.44C7.13134 25.6145 6.92945 25.7539 6.70633 25.85C6.4832 25.9461 6.24325 25.9971 6.00033 26C5.46989 26 4.96118 25.7893 4.58611 25.4142C4.21104 25.0391 4.00033 24.5304 4.00033 24C3.99566 23.7548 4.04116 23.5112 4.13402 23.2842C4.22687 23.0572 4.36514 22.8516 4.54033 22.68L12.3203 15L4.54033 7.22C4.2107 6.89752 4.0174 6.46082 4.00033 6C4.00033 5.46957 4.21104 4.96086 4.58611 4.58579C4.96118 4.21071 5.46989 4 6.00033 4C6.48033 4.006 6.94033 4.2 7.28033 4.54Z" fill="#666666" />
                                </svg>
                            </div>
                        </DialogTitle>
                        <DialogContent>
                            <div className=" pt-6 pb-4">
                                <p className="mb-1 text-base text-black7">目前出價({bidRecords.length}) NT${separatorNumber(bidRecords.length > 0 && bidRecords[0].amount || data.startingPrice)}</p>
                                <p className="text-sm mb-4 text-black7">下一口價 NT${separatorNumber((bidRecords.length > 0 && bidRecords[0].amount || data.startingPrice) + data.biddingStep)}</p>


                                <div className="text-black9 font-bold mb-4 flex items-center gap-2">
                                    <p className="flex-none">我要出價</p>
                                    <select defaultValue={""} onChange={(e) => setOrderInput(e.target.value)} className="w-full input !py-1  relative    ">
                                        <option value="" disabled >選擇價格</option>
                                        {
                                            new Array(20).fill('0').map((i, index) => <option key={index}>{(bidRecords.length > 0 && bidRecords[0].amount || data.startingPrice) + (data.biddingStep * (index + 1))}</option>)
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className='flex  justify-center gap-2'>

                                <button onClick={closeOrderPriceDialog} className="btn2 rounded-full w-full">取消出價</button>


                                <button onClick={() => bidProduct()} className="btn1 rounded-full w-full">確定出價</button>


                            </div>
                        </DialogContent>

                    </div>
                    : ""
            }
        </Dialog>

        <Dialog
            PaperProps={{
                style: {
                    width: "400px",
                    margin:"12px",
                    backgroundColor: 'transparent'
                },
            }}
            open={isShowBidRecordDialog}
            onClose={closeBidRecordDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {
                data ?
                    <div className="bg-white rounded-xl  border border-main border-solid">
                        <DialogTitle className="flex justify-between items-center gap-2 border-b border-main border-solid">
                            <p className='text-base font-bold'>競價階梯</p>

                            <div className=' flex-none w-8 cursor-pointer p-1' onClick={closeBidRecordDialog}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" fill="none">
                                    <path d="M7.28033 4.54L15.0003 12.26L22.6803 4.58C22.85 4.39943 23.0543 4.25499 23.2812 4.15532C23.508 4.05566 23.7526 4.00283 24.0003 4C24.5308 4 25.0395 4.21071 25.4145 4.58579C25.7896 4.96086 26.0003 5.46957 26.0003 6C26.005 6.2452 25.9595 6.48877 25.8666 6.71576C25.7738 6.94275 25.6355 7.14837 25.4603 7.32L17.6803 15L25.4603 22.78C25.79 23.1025 25.9832 23.5392 26.0003 24C26.0003 24.5304 25.7896 25.0391 25.4145 25.4142C25.0395 25.7893 24.5308 26 24.0003 26C23.7454 26.0106 23.4911 25.968 23.2536 25.8751C23.016 25.7821 22.8003 25.6408 22.6203 25.46L15.0003 17.74L7.30033 25.44C7.13134 25.6145 6.92945 25.7539 6.70633 25.85C6.4832 25.9461 6.24325 25.9971 6.00033 26C5.46989 26 4.96118 25.7893 4.58611 25.4142C4.21104 25.0391 4.00033 24.5304 4.00033 24C3.99566 23.7548 4.04116 23.5112 4.13402 23.2842C4.22687 23.0572 4.36514 22.8516 4.54033 22.68L12.3203 15L4.54033 7.22C4.2107 6.89752 4.0174 6.46082 4.00033 6C4.00033 5.46957 4.21104 4.96086 4.58611 4.58579C4.96118 4.21071 5.46989 4 6.00033 4C6.48033 4.006 6.94033 4.2 7.28033 4.54Z" fill="#666666" />
                                </svg>
                            </div>
                        </DialogTitle>
                        <DialogContent style={{padding:"8px"}}>
                            <div className=" pt-4 pb-4">
                                {
                                    bidRecords.length != 0 && <div className="overflow-hidden">
                                        <div className="text-sm px-4 py-1 grid grid-cols-2 gap-2 border-solid border-b border-black5 ">
                                            <div>
                                                出價價格
                                            </div>
                                            <div>
                                                出價時間
                                            </div>
                                        </div>
                                        {
                                            bidRecords.map(i => <div className=" hover:bg-sec2 px-4 py-2 grid grid-cols-2 gap-2 items-center ">
                                                <div className="text-sm font-bold text-black9">
                                                    NT {separatorNumber(i.amount)}
                                                </div>
                                                <div className="text-sm text-black7">
                                                    {i.createdAt}
                                                </div>
                                            </div>
                                            )
                                        }


                                    </div>
                                }
                            </div>


                        </DialogContent>

                    </div>
                    : ""
            }
        </Dialog>


    </div >

}

export default OnlineArtDetail