import { menuData, brandLink, useGlobalFunc } from "../global/constants"
import { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";

import axios from 'axios'

import tianyaoImg from '../assets/brand/tianyao.png'
import facebookImg from '../assets/brand/facebook.png'

import searchImg from '../assets/search.png'
import personImg from '../assets/person.png'
import shopbagImg from '../assets/shopbag.png'

import logoImg from '../assets/logo.png'

import arrowImg from '../assets/arrow.png'
import arrowrightImg from '../assets/arrowright.png'
import globalImg from '../assets/global.png'

import logoutImg from '../assets/logout.png'

import menuImg from '../assets/menu.png'

import avatarImg from '../assets/avatar.png'

import closeImg from '../assets/close.png'
import closewtImg from '../assets/closewt.png'

import globalbkImg from '../assets/icon_global.png'


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useSelector, useDispatch } from 'react-redux';
import ActionDialog from "./ActionDialog";
import AlertDialog from "./AlertDialog";



function DownWalletDialog(props) {
    const { onClose, selectedValue, open } = props;
    //const theme = useTheme();

    const [checkboxStatus, setCheckboxStatus] = useState(false)
    const [balanceWarning, setBalanceWarning] = useState(false)

    const [count, setCount] = useState(1)

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('768'));



    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };





    return (
        <Dialog maxWidth="2000" PaperProps={{ sx: { bgcolor: "transparent" } }} fullScreen={fullScreen} onClose={handleClose} open={open} >
            <div className="w-full h-full" onClick={handleClose}></div>
            <div className="lg:w-[510px] text-white absolute lg:relative bottom-0 left-0 w-full bg-[#1C1E21]  p-[28px] pt-[17px] rounded-t-md">
                <div className="relative py-7">

                    <div className="text-black absolute top-0 right-0 text-base font-medium"  >
                        <img className="w-6 h-6 object-contain cursor-pointer" alt="" onClick={handleClose} />
                    </div>



                    <p className="text-lg lg:text-2xl text-[#FFFFFF] mb-[37px] font-bold text-center ">使用 APP 來設置錢包</p>



                    <div className="px-3 bg-[#FFFFFF] bg-opacity-10 rounded-[10px] pt-[64px] pb-[45px]">



                    </div>
                </div>


            </div>
        </Dialog>
    );
}

const Header = () => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.userInfo);
    const userPoint = useSelector(state => state.userPoint);

    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const { updateMe } = useGlobalFunc();

    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const openLang = Boolean(anchorEl);
    const handleLangClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleLangClose = () => {
        setAnchorEl(null);
    };

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        handleLangClose()
        return
        console.log(i18n.language)
        if (i18n.language == 'zh') {
            i18n.changeLanguage('en');
        }
        else {
            i18n.changeLanguage('zh');
        }


    };


    const routeList = [
        {
            //img: timelineImg,
            title: 'artAuction',
            path: "/artauction",
        },
        {
            //img: timelineImg,
            title: 'onlineGallery',
            path: "/onlinegallery",
        },
        {
            //img: timelineImg,
            title: 'artFocus',
            path: "/artfocus",
        },
        {
            //img: timelineImg,
            title: 'customerService',
            path: "/customerservice",
        },
        {
            //img: timelineImg,
            title: 'aboutUs',
            path: "/aboutus",
        },

        // {
        //     img: timelineImg,
        //     title: 'Timeline',
        //     path: "/timeline",
        // },
        // {
        //     img: worldImg,
        //     title: 'World',
        //     path: "/world",
        // },
    ]

    const loginRouteList = [
        // {
        //     img: KOLpassImg,
        //     title: "KOL 通證",
        //     path: "/ownerworld?tag=0",
        // },
        // {
        //     img: racepassImg,
        //     title: '種族通證',
        //     path: "/ownerworld?tag=1",
        // },
        // {
        //     img: brandpassImg,
        //     title: '品牌通證',
        //     path: "/ownerworld?tag=2",
        // },
        // {
        //     img: proppassImg,
        //     title: '道具通證',
        //     path: "/ownerworld?tag=3",
        // },
    ]

    const [showSwapLink, setShowSwapLink] = useState(false)

    const [currPath, setCurrPath] = useState()
    const [showStoryMenu, setShowStoryMenu] = useState(false)

    const [showUserInfo, setShowUserInfo] = useState(false)


    function saveScrollId(scrollId) {
        console.log('sss')
        localStorage.setItem('scrollId', scrollId)

        scroller.scrollTo(scrollId, { smooth: true, offset: -150 })

        console.log(localStorage.getItem('scrollId'))
    }


    function isCurrentPath(selPath) {
        let path = location.pathname.split("/");
        //console.log(path, selPath)
        if ('/' + path[1] == selPath) {
            return true
        }
        else {
            return false
        }

    }


    function logout() {
        localStorage.removeItem('userToken')
        dispatch({
            type: "UPDATE_USERINFO",
            payload: { userInfo: null }
        });
        navigate('/')
        window.location.reload()
    }

    useEffect(() => {

        const token = query.get('token')
        if (token != null && window.location.pathname == '/login') {
            localStorage.setItem("userAuth", token)
        }

        //if (localStorage.getItem('userAuth') != null){
        updateMe()
        //}
    }, [])

    return <div className="relative">
        <ToastContainer closeButton={false} icon={false} position="top-center" toastClassName="!rounded !overflow-hidden !bg-main !bg-opacity-90  !backdrop-blur-sm mb-1 !border !border-solid !border-main" className=" !p-2  " bodyClassName={() => "flex items-center text-black9"} />

        {showStoryMenu == true ?
            <div className="lg:hidden fixed w-screen h-screen left-0 top-0 z-30 ">
                <div className=" absolute left-0 top-0 w-full h-full bg-main95 bg-opacity-40" onClick={() => setShowStoryMenu(false)}>
                </div>
                <div className="relative flex flex-col  bg-white   w-[280px] h-full ">
                    <div className="relative w-full h-full mx-auto  ">


                        <div className="bg-main8 p-4 mb-8">
                            <div className="relative w-full mb-3">
                                <div className="w-full flex items-center justify-between   ">
                                    <div className="bg-white p-[2px] w-12 h-12 relative rounded-[8px] mr-4">
                                        <img className="w-full h-full object-cover rounded-full" src={logoImg} onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = avatarImg;
                                        }} alt="" />
                                    </div>
                                    <img className="w-6 h-6 object-contain cursor-pointer" src={closewtImg} alt="" onClick={() => setShowStoryMenu(false)} />
                                </div>
                            </div>
                            {
                                userInfo != null ?
                                    <>
                                        <Link to="/membercenter" className="mb-4 flex items-center">
                                            <img className=" object-contain w-[36px] h-[36px] mr-6" src={personImg} alt="" />
                                            會員中心
                                        </Link>
                                        <Link to="/cart" className=" mb-4 flex items-center">
                                            <img className=" object-contain w-[36px] h-[36px] mr-6" src={shopbagImg} alt="" />
                                            購物車
                                        </Link>
                                        <button className="object-contain w-full px-4 h-[36px] font-bold text-sm border-white border border-solid rounded-lg" onClick={() => logout()}>
                                            {t('logout')}
                                        </button>
                                    </>
                                    :
                                    <Link to="/login" onClick={() => showStoryMenu(false)}>
                                        <button className="w-full object-contain px-4 h-[36px] font-bold text-sm bg-white text-main8 rounded-lg" >
                                            {t('login')}
                                        </button>
                                    </Link>

                            }
                        </div>

                        <div className="px-5">
                            {
                                routeList.map((i, index) =>
                                    <Link key={index} to={i.path} className={`${(isCurrentPath(i.path)) ? " bg-main8 text-white" : "text-black9"} px-5 py-2 rounded-lg w-full flex justify-start items-center cursor-pointer   mb-4 `} onClick={() => setShowStoryMenu(false)}>

                                        <p className=" text-base ">{t(`header.${i.title}`)}</p>
                                    </Link>

                                )
                            }



                            <div>
                                <div className={"  rounded-lg w-full flex justify-between items-center cursor-pointer text-[#111111]  mt-10 "} onClick={handleLangClick}>
                                    <div className="flex">
                                        <img className="w-6 h-6 object-contain cursor-pointer" src={globalbkImg} alt="" />
                                        <p className="ml-2 text-base ">Language</p>
                                    </div>
                                    <div className="flex items-center ">
                                        <div className="text-sm relative  w-full  text-[#888888] whitespace-nowrap mr-2" >{i18n.language == "zh" ? "中文" : "EN"}</div>
                                        <img className="w-4 h-4" src={arrowrightImg} />

                                    </div>
                                </div>

                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={openLang}
                                    onClose={handleLangClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={e => changeLanguage('zh')}>中文</MenuItem>
                                    <MenuItem onClick={e => changeLanguage('en')}>EN</MenuItem>
                                </Menu>


                            </div>
                        </div>





                    </div>




                </div>
            </div>

            : ""}



        <div className="hidden fixed right-0 top-1/2 transform -translate-y-1/2">
            <a href="" target="_blank">
                <img className=" object-contain w-[50px] h-[50px] mb-3" src={tianyaoImg} alt="" />
            </a>
            <a href="" target="_blank">
                <img className=" object-contain w-[50px] h-[50px] " src={facebookImg} alt="" />
            </a>
        </div>

        <div className="hidden lg:block fixed left-0 top-0 w-full bg-main95   h-[88px]  z-20 px-10" style={{ 'box-shadow': '2px 0px 10px 1px rgba(0, 0, 0, 0.2)' }}>

            <div className="relative w-full h-full flex justify-between items-center">

                <Link to={'/'} className="relative " >
                    <img className=" object-contain w-[88px] h-[88px] " src={logoImg} alt="" />
                </Link>


                <div className="h-full justify-end flex items-center ">


                    {
                        routeList.map((i, index) =>
                            <Link key={index} to={i.path} className={`${(isCurrentPath(i.path)) ? " text-sec4 " : "text-white "} " rounded-lg  flex justify-start items-center  cursor-pointer "`} >

                                <p className="text-center mr-6 text-base font-bold ">{t(`header.${i.title}`)}</p>
                            </Link>

                        )
                    }

                    <div>
                        <button className="flex-none flex w-[66px]  items-center justify-between mr-9" onClick={handleLangClick}>

                            <div className=" relative py-3 w-full font-bold text-white whitespace-nowrap" >{i18n.language == "zh" ? "中文" : "EN"}</div>
                            <img className="w-[18px] h-[18px]" src={arrowImg} />
                        </button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openLang}
                            onClose={handleLangClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={e => changeLanguage('zh')}>中文</MenuItem>
                            <MenuItem onClick={e => changeLanguage('en')}>EN</MenuItem>
                        </Menu>


                    </div>



                    {
                        userInfo != null ?
                            <>
                                <Link to="/membercenter">
                                    <img className=" object-contain w-[36px] h-[36px] mr-6" src={personImg} alt="" />
                                </Link>
                                <Link to="/cart">
                                    <img className=" object-contain w-[36px] h-[36px] mr-6" src={shopbagImg} alt="" />
                                </Link>
                                <button className=" object-contain px-4 h-[36px] font-bold text-sm border-white border border-solid rounded-lg" onClick={() => logout()}>
                                    {t('logout')}
                                </button>
                            </>
                            :
                            <Link to="/login">
                                <button className=" object-contain px-4 h-[36px] font-bold text-sm bg-white text-main9 rounded-lg" >
                                    {t('login')}
                                </button>
                            </Link>

                    }




                </div>



            </div>

        </div>
        <div className="h-[49px] lg:h-[88px]">

        </div>

        <div className={`${location.pathname == "/login" ? " " : ""} bg-main95 bg-opacity-100 fixed lg:hidden left-0 top-0 w-full  h-[49px]  z-20 px-5`} style={{ 'box-shadow1': '2px 0px 10px 1px rgba(0, 0, 0, 0.2)' }}>


            <div className="relative w-full h-full flex justify-between items-center ">

                <img className="w-6 h-6 cursor-pointer" src={menuImg} onClick={() => setShowStoryMenu(true)} />

                <Link to={'/'} className="relative " >
                    <img className=" object-contain w-full h-[40px] " src={require('../../src/assets/logo.png')} alt="" />
                </Link>

                <div className="w-6 h-6"></div>





            </div>

        </div>

        <ActionDialog />
        <AlertDialog />



    </div>

}
export default Header