

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";

import Line1 from '../../../compontents/Line1.js'
import Line2 from '../../../compontents/Line2.js'

import { useGlobalFunc, time2date, checkEventStatus } from "../../../global/constants.js";
import { Pagination } from "@mui/material";
import UserApi from "../../../services/UserApi.jsx";
import moment from 'moment'
import PathLocation from "../../../compontents/PathLocation.js";
import ArtEventBox from "../../../compontents/ArtEventBox.js";


const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const navigate = useNavigate()

    const { setLoading, successToast, errorToast } = useGlobalFunc()
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
        getDataList(value)
    };

    const [dataList, setDataList] = useState(null)






    async function getDataList(page) {
        setLoading(true)
        try {
            let res = await UserApi.exhibitionEventSearch({ page: page })
            console.log(res.data.data)
            setDataList(res.data.data)
            setTotalPage(res.data.total == 0 ? 1 : Math.ceil(res.data.total / 10))

        }
        catch (errors) {
            errorToast(errors?.response?.data?.message || errors.toString())
        }
        setLoading(false)

    }

    useEffect(() => {
        getDataList(1)
    }, []);


    return <div className="w-full">

        <div className="session1 relative pt-[40px] pb-20 bg-white" >

            <PathLocation />

            <div className="flex flex-wrap gap-4 items-end mb-12">
                <p className="text-2xl text-black9 font-semibold">展覽活動</p>

            </div>

            {
                dataList != null ?

                    <div className="pl-4 sm:pl-12">
                        {
                            dataList.map(i => <div><ArtEventBox key={i.id} data={i} />
                                <Line2 />
                            </div>
                            )
                        }
                    </div>
                    : ""
            }
            {
                dataList ?
                    <>
                        {
                            dataList.length == 0 ?
                                <div className="  text-center text-gray-400 text-lg w-full font-bold mb-20 ">
                                    <div>暫無紀錄</div>
                                </div> : ""

                        }

                        <div className="flex w-full  mt-2 mb-10">
                            <div className="mx-auto">
                                <Pagination count={totalPage} page={page} onChange={handleChange} />
                            </div>
                        </div>
                    </>
                    : ""
            }



        </div>





    </div >
}

export default Home