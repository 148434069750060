

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";

import Line1 from '../../compontents/Line1.js'
import Line2 from '../../compontents/Line2.js'
import Swiper from '../../compontents/Swiper.js'
import TitleName from '../../compontents/TitleName.js'
import TitleName2 from '../../compontents/TitleName2.js'
import SeeMore from '../../compontents/SeeMore.js'

import Btn from '../../compontents/Btn.js'

import circlebgImg from '../../assets/home/circlebg.png'
import circle11Img from '../../assets/home/circle11.png'
import circle22Img from '../../assets/home/circle22.png'
import circle33Img from '../../assets/home/circle33.png'
import crossImg from '../../assets/home/cross.png'

import pic1Img from '../../assets/1.png'
import pic2Img from '../../assets/2.png'
import pic3Img from '../../assets/3.png'
import pic4Img from '../../assets/4.jpg'

import hallbgImg from '../../assets/hallbg.png'

import moreArrowRightImg from '../../assets/moreArrowRight.png'



import linesImg from '../../assets/lines.png'
import lines2Img from '../../assets/home/lines2.png'

import swipe1Img from '../../assets/swipe1.png'
import swipe2Img from '../../assets/swipe2.png'
import swipe3Img from '../../assets/swipe3.jpg'
import PathLocation from "../../compontents/PathLocation.js";
import { MenuItem, Select } from "@mui/material";



// import homeshadowImg from '../../assets/homeshadow.png'
// import homescrollImg from '../../assets/homescroll.png'

// import homepic1Img from '../../assets/homepic1.png'
// import homepic2Img from '../../assets/homepic2.png'


const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate()
    const query = new URLSearchParams(location.search);


    const banner = []
    const [currBanner, setCurrBanner] = useState(0)
    const countRef = useRef()

    const [introIndex, setIntroIndex] = useState(0)
    const [introList, setIntroList] = useState(["page1", "page2", "page3", "page4", "page5", "page6", "page7", "page8"])


    function changeType(index) {
        navigate(`?type=${index}`,{replace:true})
        window.scroll(0, 0)
    }


    useEffect(() => {
        let typePage = query.get('type')
        let typeIndex = introList.indexOf("page" + typePage)
        if (typeIndex != -1) {
            setIntroIndex(typeIndex)
        }

    }, [query.get('type')]);


    return <div className="session1 relative pt-[40px] pb-20 bg-white" >

        <PathLocation />

        <TitleName title1="Customer Service" title2="客戶服務" />



        <div className="flex flex-col lg:flex-row mt-8">
            <div className="hidden lg:block  flex-none w-[250px] min-h-full pr-10">
                <div className="sticky top-40 bg-main8 rounded-[20px] p-6 ">
                    {
                        introList.map((i, index) =>
                            <div onClick={() => changeType(index + 1)} className={`${index == introIndex ? "text-sec3" : "text-main3"}  cursor-pointer gap-2 flex items-center justify-between mb-6 font-bold text-base `} key={i}>
                                <p>{t(`customerService.${i}`)}</p>
                                <div className="w-4 h-4 flex-none">
                                    {
                                        index == introIndex && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" fill="none">
                                            <path d="M8.78789 24.7574C8.52923 24.4622 8.3999 24.1128 8.3999 23.7091C8.3999 23.3061 8.52923 22.9571 8.78789 22.662L16.3666 14.0148L8.76203 5.33813C8.52061 5.06268 8.3999 4.71836 8.3999 4.30519C8.3999 3.89201 8.52923 3.53786 8.78789 3.24274C9.04655 2.94761 9.35281 2.80005 9.70666 2.80005C10.0598 2.80005 10.3657 2.94761 10.6244 3.24274L19.3154 13.1885C19.4188 13.3065 19.4923 13.4344 19.5358 13.5721C19.5785 13.7098 19.5999 13.8574 19.5999 14.0148C19.5999 14.1722 19.5785 14.3198 19.5358 14.4575C19.4923 14.5952 19.4188 14.7231 19.3154 14.8412L10.5985 24.7869C10.3571 25.0623 10.0598 25.2001 9.70666 25.2001C9.35281 25.2001 9.04655 25.0525 8.78789 24.7574Z" fill="#FFF6BF" stroke="#FFF6BF" />
                                        </svg>
                                    }
                                </div>

                            </div>)
                    }
                </div>
            </div>

            <div className=" lg:hidden mb-6 text-black">

                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={introIndex}
                    label=""
                    className="w-full"
                    onChange={(e) => changeType(e.target.value + 1)}
                >
                    {
                        introList.map((i, index) =>
                            <MenuItem key={i} value={index}>{t(`customerService.${i}`)}</MenuItem>
                        )
                    }
                </Select>

            </div>

            <div className="w-full lg:w-0 grow">



                <div className="  rounded-2xl border-main95 border-solid border-2 w-full  px-6 sm:px-12 py-10 ">

                    <div className="text-black9 text-base">


                        {
                            introIndex == 0 &&
                            <div>
                                <TitleName2 title1={t("customerService.inPage1.text0")} />
                                <div className="pt-8 pb-12">
                                    {
                                        [1, 3, 5, 7, 9,].map(i => <div className="mb-4">
                                            <p className=" font-bold mb-1">{t(`customerService.inPage1.text${i}`)}</p>
                                            <p className=" ">{t(`customerService.inPage1.text${i + 1}`)}</p>
                                        </div>)
                                    }
                                </div>
                                <TitleName2 title1={t("customerService.inPage1.text100")} />
                                <ul className="pt-8 pb-12 pl-6" style={{ "list-style-type": "number" }}>
                                    {
                                        [1, 2, 3, 4,].map(i => <li className=" mb-4">
                                            <p className=" ">{t(`customerService.inPage1.text10${i}`)}</p>
                                        </li>)
                                    }
                                </ul>
                                <TitleName2 title1={t("customerService.inPage1.text200")} />
                                <ul className="pt-8  pl-6" style={{ "list-style-type": "number" }}>
                                    {
                                        [1, 2, 3, 4,].map(i => <li className=" mb-4">
                                            <p className=" ">{t(`customerService.inPage1.text20${i}`)}</p>
                                        </li>)
                                    }
                                </ul>

                                <p className="text-black9 text-base pb-12">{t(`customerService.inPage1.text301`)}</p>
                            </div>
                        }

                        {
                            introIndex == 1 &&
                            <div>
                                <TitleName2 title1={t("customerService.inPage2.text0")} />
                                <div className="pt-8 pb-12">
                                    {
                                        [1, 3, 5, 7, 9, 11].map(i => <div className="mb-4">
                                            <p className=" font-bold mb-1">{t(`customerService.inPage2.text${i}`)}</p>
                                            <p className=" ">{t(`customerService.inPage2.text${i + 1}`)}</p>
                                        </div>)
                                    }
                                </div>
                                <TitleName2 title1={t("customerService.inPage2.text100")} />
                                <ul className="pt-8 pb-12 pl-6" style={{ "list-style-type": "number" }}>
                                    {
                                        [1, 2, 3, 4, 5, 6].map(i => <li className=" mb-4">
                                            <p className=" ">{t(`customerService.inPage2.text10${i}`)}</p>
                                        </li>)
                                    }
                                </ul>
                                <TitleName2 title1={t("customerService.inPage2.text200")} />
                                <ul className="pt-8 pb-12 pl-6" style={{ "list-style-type": "number" }}>
                                    {
                                        [1, 2, 3, 4, 5, 6, 7].map(i => <li className=" mb-4">
                                            <p className=" ">{t(`customerService.inPage2.text20${i}`)}</p>
                                        </li>)
                                    }
                                </ul>

                            </div>
                        }

                        {
                            introIndex == 2 &&
                            <div>
                                <TitleName2 title1={t("customerService.inPage3.text0")} />
                                <div className="pt-8 pb-12">
                                    <p className=" ">{t(`customerService.inPage3.text1`)}</p>

                                </div>
                                <TitleName2 title1={t("customerService.inPage3.text100")} />
                                <ul className="pt-8 pb-12 pl-6" style={{ "list-style-type": "number" }}>
                                    {
                                        [1, 2, 3, 4, 5].map(i => <li className=" mb-4">
                                            <p className=" ">{t(`customerService.inPage3.text10${i}`)}</p>
                                        </li>)
                                    }
                                </ul>
                            </div>
                        }
                        {
                            introIndex == 3 &&
                            <div>
                                <TitleName2 title1={t("customerService.inPage4.text0")} />
                                <div className="pt-8 pb-12">
                                    <p className=" ">{t(`customerService.inPage4.text1`)}</p>

                                </div>
                                <TitleName2 title1={t("customerService.inPage4.text100")} />
                                <ul className="pt-8 pb-12 pl-6" style={{ "list-style-type": "number" }}>
                                    {
                                        [1, 2, 3, 4, 5, 6].map(i => <li className=" mb-4">
                                            <p className=" ">{t(`customerService.inPage4.text10${i}`)}</p>
                                        </li>)
                                    }
                                </ul>
                                <TitleName2 title1={t("customerService.inPage4.text200")} />
                                <div className="pt-8 pb-12">
                                    {
                                        [1, 3, 5, 7,].map(i => <div className="mb-4">
                                            <p className=" font-bold mb-1">{t(`customerService.inPage4.text20${i}`)}</p>
                                            <p className=" ">{t(`customerService.inPage4.text20${i + 1}`)}</p>
                                        </div>)
                                    }
                                </div>
                            </div>
                        }
                        {
                            introIndex == 4 &&
                            <div>
                                <TitleName2 title1={t("customerService.inPage5.text0")} />
                                <div className="pt-8 pb-12">
                                    <p className=" ">{t(`customerService.inPage5.text1`)}</p>
                                </div>

                                <TitleName2 title1={t("customerService.inPage5.text100")} />
                                <ul className="pt-8 pb-12 pl-6" style={{ "list-style-type": "number" }}>
                                    {
                                        [1, 2, 3, 4, 5].map(i => <li className=" mb-4">
                                            <p className=" ">{t(`customerService.inPage5.text10${i}`)}</p>
                                        </li>)
                                    }
                                </ul>
                                <TitleName2 title1={t("customerService.inPage5.text200")} />
                                <ul className="pt-8 pb-12 pl-6" style={{ "list-style-type": "number" }}>
                                    {
                                        [1, 2, 3, 4,].map(i => <li className=" mb-4">
                                            <p className=" ">{t(`customerService.inPage5.text20${i}`)}</p>
                                        </li>)
                                    }
                                </ul>
                            </div>
                        }
                        {
                            introIndex == 5 &&
                            <div>
                                <TitleName2 title1={t("customerService.inPage6.text0")} />
                                <div className="pt-8 pb-12">
                                    <p className=" ">{t(`customerService.inPage6.text1`)}</p>
                                </div>

                                <TitleName2 title1={t("customerService.inPage6.text100")} />
                                <div className="pt-8 pb-12">
                                    {
                                        [1, 3, 5, 7,].map(i => <div className="mb-4">
                                            <p className=" font-bold mb-1">{t(`customerService.inPage6.text10${i}`)}</p>
                                            <p className=" ">{t(`customerService.inPage6.text10${i + 1}`)}</p>
                                        </div>)
                                    }
                                </div>
                                <TitleName2 title1={t("customerService.inPage6.text200")} />
                                <div className="pt-8 pb-12">
                                    {
                                        [1, 3, 5,].map((i, index) => <div className="mb-4">
                                            <p className=" font-bold mb-1">{index + 1}. {t(`customerService.inPage6.text20${i}`)}</p>
                                            <p className=" ">{t(`customerService.inPage6.text20${i + 1}`)}</p>
                                        </div>)
                                    }
                                </div>
                            </div>
                        }
                        {
                            introIndex == 6 &&
                            <div>
                                <TitleName2 title1={t("customerService.inPage7.text0")} />
                                <div className="pt-8 pb-12">
                                    <p className="mb-4">{t(`customerService.inPage7.text1`)}：(07)xxxx</p>
                                    <p className="mb-4">{t(`customerService.inPage7.text2`)}：(07)xxxx</p>
                                    <p className=" ">E-mail：xxxx@gmail.com</p>
                                </div>

                            </div>
                        }
                        {
                            introIndex == 7 &&
                            <div>
                                <TitleName2 title1={t("customerService.inPage8.text0")} />
                                <div className="pt-8 pb-12">
                                    <p className=" ">{t(`customerService.inPage8.text1`)}</p>
                                </div>

                                <TitleName2 title1={t("customerService.inPage2.text100")} />
                                <ul className="pt-8 pb-12 pl-6" style={{ "list-style-type": "number" }}>
                                    {
                                        [1, 2, 3, 4,].map(i => <li className=" mb-4">
                                            <p className=" ">{t(`customerService.inPage8.text10${i}`)}</p>
                                        </li>)
                                    }
                                </ul>
                                <img className="w-1/3 aspect-[1/1] object-contain bg-black4 mx-auto mb-12" alt="QRcode" />
                            </div>
                        }

                    </div>

                    <Line1 />
                    <div className="hidden flex justify-between gap-4 flex-wrap pt-1">
                        <p className="text-gray1">{t(`customerService.cue1`)}</p>
                        <button onClick={() => navigate('/login')} className=" hover:underline text-sec5 font-bold">{t(`customerService.login`)}</button>

                    </div>
                </div>





            </div>

        </div>




    </div >
}

export default Home