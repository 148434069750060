
import { useTranslation } from "react-i18next";


const TitleName = (props, { onClick }) => {
  const { t } = useTranslation();



  return (
    <div className="">
      <div className=" text-2xl text-main95 font-bold titleFont1 tracking-[0.2em]  mb-3" >
        {props.title1}
      </div>
      <div className="relative w-full Line1 h-[2px] " >
        <div className="absolute left-0 top-1/2 w-2 h-2 bg-[#D49F4A] transform -translate-y-1/2 rotate-45"> </div>
        <div className="absolute right-0 top-1/2 w-2 h-2 bg-[#FFF4AF] transform -translate-y-1/2 rotate-45"> </div>
      </div>
    </div>
  )
}
export default TitleName